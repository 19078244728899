export interface QuestionStatus {
  state: QuestionState;
  atRisk: boolean;
}

export interface QuestionQueue {
  assignments: QuestionAssignment[];
  questionStatus: { [key: string]: QuestionStatus };
}

export interface QuestionAssignment {
  questionId: string;
  answerPayment: { amount: string; currencyCode: string };
  commentPayment: { amount: string; currencyCode: string };
}

export interface ApiQuestionQueueAssignment {
  question: { id: string };
  answerPayment: { amount: string; currencyCode: string };
  commentPayment: { amount: string; currencyCode: string };
}

export enum QuestionState {
  // Question is awaiting assignment to a reviewer
  OPEN = 'OPEN',
  // Question is assigned to the reviewer and is in the reviewers queue
  QUEUED = 'QUEUED',
  // Question has been answered by a reviewer
  REVIEWED = 'REVIEWED',
  // Question has been commented on by a reviewer
  PENDING = 'PENDING',
  // Question has been accepted as completed OR has expired due to asker
  // inaction.
  CLOSED = 'CLOSED'
}
