import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import * as queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import SearchFilter from 'app/components/SearchFilter';
import SearchFilterOverlay from 'app/components/SearchFilter/SearchFilterOverlay';
import { searchFilterStateKey } from 'app/models/helpers/appStateConstants';
import { readLocalState } from 'app/models/helpers/appStateHelpers';
import { Nanodegree, Project } from 'app/models/nanodegree/nanodegreeTypes';
import { actionCreators } from 'app/models/search/searchActions';
import {
  selectFilterByUnanswered,
  selectNanodegreeProjectFilter
} from 'app/models/search/searchSelectors';
import { SearchParams } from 'app/models/search/searchTypes';

interface FilterOptions {
  nanodegreeKey?: string;
  projectID?: number;
  rubricID?: number;
  unanswered?: boolean;
}

interface LocationProps extends RouteComponentProps<any> {}

export interface SearchFilterContainerStateProps {
  nanodegreeProjectFilter: {
    nanodegreeKey?: string;
    projectID?: number;
    rubricID?: number;
  };
  filterByUnanswered: boolean;
}

export interface DispatchProps {
  setSearchParameters: (params: SearchParams) => {};
}

interface DefaultProps {
  layout?: 'default' | 'fullscreen';
}

export interface OwnProps extends DefaultProps {
  handleClose?: () => void;
}

export type SearchFilterContainerProps = SearchFilterContainerStateProps &
  DispatchProps &
  OwnProps &
  LocationProps;

export interface State {
  selectedNanodegree?: Nanodegree;
  selectedProject?: Project;
}

const mapStateToProps = (state): SearchFilterContainerStateProps => ({
  nanodegreeProjectFilter: selectNanodegreeProjectFilter(state),
  filterByUnanswered: selectFilterByUnanswered(state)
});

const mapDispatchToProps: DispatchProps = {
  setSearchParameters: actionCreators.setSearchParameters
};

export class SearchFilterContainer extends React.PureComponent<
  SearchFilterContainerProps,
  State
> {
  static defaultProps: DefaultProps = {
    layout: 'default'
  };

  constructor(props: SearchFilterContainerProps) {
    super(props);

    this.state = {
      selectedNanodegree: undefined,
      selectedProject: undefined
    };

    /*
     * Constructor restores search filter settings from url local storage or query string
     */

    const { location } = this.props;

    const { nanodegreeKey, projectID, rubricID, unanswered } = readLocalState(
      searchFilterStateKey
    );

    if (!_isEmpty(location.search)) {
      this.restoreFiltersFromQueryString(location.search);
    } else if (nanodegreeKey || projectID || rubricID || unanswered) {
      this.restoreFiltersFromLocalState({
        nanodegreeKey,
        projectID,
        rubricID,
        unanswered
      });
    }
  }

  restoreFiltersFromQueryString(searchLocation) {
    const { setSearchParameters } = this.props;
    const { nanodegree, project, rubric, unanswered } = queryString.parse(
      searchLocation,
      {
        parseNumbers: true,
        parseBooleans: true
      }
    );
    setSearchParameters({
      nanodegreeKey: nanodegree as string | undefined,
      projectID: project as number | undefined,
      rubricID: rubric as number | undefined,
      unanswered: !!unanswered
    });
  }

  restoreFiltersFromLocalState({
    nanodegreeKey,
    projectID,
    rubricID,
    unanswered
  }: FilterOptions): void {
    const { setSearchParameters } = this.props;
    setSearchParameters({
      nanodegreeKey,
      projectID,
      rubricID,
      unanswered
    });

    this.setUrlQueryString(
      _omitBy(
        {
          nanodegree: nanodegreeKey,
          project: projectID,
          rubric: rubricID,
          unanswered
        },
        (val) => !val // remove falsey values
      )
    );
  }

  setUrlQueryString(params: any = {}): void {
    const { history, location } = this.props;
    const prevParams = queryString.parse(location.search);

    history.push({
      search: queryString.stringify(
        _omitBy(
          { ...prevParams, ...params },
          (val) => !val // remove falsey values
        )
      )
    });
  }

  componentDidUpdate(prevProps: SearchFilterContainerProps, prevState: State) {
    const { nanodegreeProjectFilter: prevNanodegreeProjectFilter } = prevProps;
    const { nanodegreeProjectFilter } = this.props;

    if (
      !!prevNanodegreeProjectFilter.nanodegreeKey &&
      !nanodegreeProjectFilter.nanodegreeKey
    ) {
      this.resetFilter();
    }
  }

  resetFilter(): void {
    this.setState({
      selectedNanodegree: undefined,
      selectedProject: undefined
    });
  }

  handleFilterChange = (selection: {
    nanodegree?: Nanodegree;
    project?: Project;
  }) => {
    const { setSearchParameters } = this.props;
    const searchParams = {
      page: 1,
      nanodegreeKey: selection.nanodegree
        ? selection.nanodegree.key
        : undefined,
      projectID: selection.project
        ? selection.project.reviewsProjectId
        : undefined,
      rubricID: selection.project ? selection.project.rubricId : undefined
    };

    setSearchParameters(searchParams);

    this.setUrlQueryString({
      nanodegree: searchParams.nanodegreeKey,
      project: searchParams.projectID,
      rubric: searchParams.rubricID,
      page: 1
    });

    this.setState({
      selectedNanodegree: selection.nanodegree,
      selectedProject: selection.project
    });
  };

  handleFilterByUnanswered = (filterByUnanswered: boolean) => {
    const { setSearchParameters } = this.props;

    setSearchParameters({ page: 1, unanswered: filterByUnanswered });

    this.setUrlQueryString({
      page: 1,
      unanswered: filterByUnanswered
    });
  };

  render() {
    const {
      layout,
      handleClose,
      nanodegreeProjectFilter,
      filterByUnanswered
    } = this.props;

    const { selectedNanodegree, selectedProject } = this.state;

    return layout === 'default' ? (
      <SearchFilter
        handleFilterChange={this.handleFilterChange}
        handleFilterByUnanswered={this.handleFilterByUnanswered}
        selectedNanodegree={selectedNanodegree}
        selectedProject={selectedProject}
        initialNanodegreeKey={nanodegreeProjectFilter.nanodegreeKey}
        initialProjectId={nanodegreeProjectFilter.projectID}
        filterByUnanswered={filterByUnanswered}
      />
    ) : (
      <SearchFilterOverlay
        isOpen={layout === 'fullscreen'}
        handleFilterChange={this.handleFilterChange}
        handleFilterByUnanswered={this.handleFilterByUnanswered}
        selectedNanodegree={selectedNanodegree}
        selectedProject={selectedProject}
        initialNanodegreeKey={nanodegreeProjectFilter.nanodegreeKey}
        initialProjectId={nanodegreeProjectFilter.projectID}
        handleClose={handleClose ?? (() => {})}
        filterByUnanswered={filterByUnanswered}
      />
    );
  }
}

export default withRouter(
  connect<SearchFilterContainerStateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SearchFilterContainer)
);
