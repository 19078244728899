import { CallEffect, put, PutEffect, TakeEffect } from 'redux-saga/effects';

import { actionCreators as alertsActions } from 'app/models/alerts/alertsActions';
import i18n from 'i18n';

import { createErrorAction } from './actionHelper';
import { EventChannel } from 'redux-saga';
import { NotUndefined } from '@redux-saga/types';
import { ActionDispatch } from './actionTypes';

export interface IAction {
  payload: { [key: string]: any };
  type: string;
}

export function makeSagaLifecycle<ActionInterface = IAction>(
  generator: (
    action: ActionInterface
  ) =>
    | IterableIterator<any>
    | Generator<
        | CallEffect<EventChannel<NotUndefined>>
        | TakeEffect
        | PutEffect<ActionDispatch>
      >,
  actionName: string,
  actionTypes: {}
) {
  const pendingAction = `${actionName}_PENDING`;
  const rejectAction = `${actionName}_REJECTED`;

  return function* sagaStartEndActions(action: any): IterableIterator<any> {
    try {
      yield put({
        type: actionTypes[pendingAction]
      });
      yield generator(action);
    } catch (error) {
      yield put(
        alertsActions.addAlert(
          'error',
          i18n.t('error.requestHasNotBeenProcessed')
        )
      );
      yield put(createErrorAction(actionTypes[rejectAction], error as Error));
    }
  };
}
