import { Box, Button } from '@chakra-ui/react';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Trans } from 'react-i18next';

import i18n from 'i18n';

export interface Props {
  unanswered: boolean;
  nodeTitle?: string;
  projectTitle?: string;
  handleFilterReset: () => void;
}

export default class SearchFilterSummary extends React.PureComponent<Props> {
  formatSummaryText() {
    const { unanswered, nodeTitle, projectTitle } = this.props;

    const preface = unanswered ? (
      <Trans i18nKey="search.unansweredQuestions">
        <strong>Unanswered</strong> questions
      </Trans>
    ) : (
      i18n.t('search.results')
    );

    const nanodegreeDescription = !_isEmpty(nodeTitle) ? (
      <Trans i18nKey="search.fromTitle">
        from <strong>{{ title: nodeTitle }}</strong>
      </Trans>
    ) : null;

    const projectDescription = !_isEmpty(projectTitle) ? (
      <Trans i18nKey="search.forTitle">
        for <strong>{{ title: projectTitle }}</strong>
      </Trans>
    ) : null;

    return (
      <div>
        {preface} {nanodegreeDescription} {projectDescription}.
      </div>
    );
  }

  render() {
    const {
      unanswered,
      nodeTitle,
      projectTitle,
      handleFilterReset
    } = this.props;

    // don't render component if data props are empty
    if (_every([unanswered, nodeTitle, projectTitle], (prop) => !prop)) {
      return null;
    }

    const summaryText = this.formatSummaryText();

    return (
      <Box
        display={{ base: 'block', md: 'none' }}
        bgColor="silver-lightest"
        borderRadius="secondary"
        fontSize="sm"
        lineHeight="h5"
        p="0.75rem 1rem 0.5rem"
        my={2}
        w="full"
      >
        {summaryText}
        <Button
          variant="minimal"
          color="cerulean"
          size="sm"
          onClick={handleFilterReset}
          height="auto"
          ms="-0.25rem"
          p="0 0.25rem"
          fontSize="0.8125rem"
          lineHeight="h5"
        >
          {i18n.t('search.clearFilters')}
        </Button>
      </Box>
    );
  }
}
