import {
  Box,
  Grid,
  GridItem,
  Link,
  useBreakpointValue
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { NewtabIcon } from '@udacity/chakra-veritas-icons';

import Header from 'app/components/Header';
import PostBodyContent from 'app/components/PostBodyContent';
import PostMetadata from 'app/components/PostMetadata';
import CommentListContainer from 'app/containers/CommentListContainer';
import PostVoteContainer from 'app/containers/PostVoteContainer';
import QuestionActionBarContainer from 'app/containers/QuestionActionBarContainer';
import TagListContainer from 'app/containers/TagListContainer';
import { IQuestion } from 'app/models/entities/questions/questionsEntitiesTypes';
import { IUserDefault } from 'app/models/entities/users/usersEntitiesTypes';
import { unescapeQuotes } from 'app/models/helpers/escapeQuotes';

import i18n from 'i18n';

export interface Props {
  question: IQuestion;
  deepLinkId?: string;
}

const QuestionDetail: React.FC<Props> = ({
  question: {
    user = IUserDefault,
    title = '',
    createdAt = '',
    modifiedAt = '',
    content = '',
    id = '',
    link = '',
    node,
    projectID
  },
  deepLinkId = ''
}) => {
  useEffect(() => {
    document.title = i18n.t('common.knowledgeUdacity');
  }, []);

  const wrapperStyles = useBreakpointValue({
    base: { m: '0 1rem 0.625rem 0' },
    lg: { display: 'flex', justifyContent: 'center', mt: 1 }
  });

  return (
    <Grid
      p={{ base: '1.5rem 0 0 0', 'sm-md': '3rem 1rem 1.5rem' }}
      gridTemplateColumns={{ 'sm-md': '2.813rem 1fr' }}
      columnGap={{ 'sm-md': 4 }}
      mb={{ base: 8, 'sm-md': 0 }}
    >
      <Box {...wrapperStyles}>
        <PostVoteContainer postId={id} />
      </Box>
      <GridItem>
        <Box as="article" mb={2}>
          <Header size="h1" styleType="heavyHeader">
            {unescapeQuotes(title)}
          </Header>
          <Box py={2}>
            <PostMetadata user={user} createdAt={createdAt} />
          </Box>
          <Box mt={2}>
            <PostBodyContent
              content={content}
              modifiedAt={modifiedAt}
              createdAt={createdAt}
            />
          </Box>
          {link && (
            <Box mt="1.75rem">
              <Link href={link} isExternal>
                {i18n.t('question.githubOrProjectLink')}
                <NewtabIcon w={6} h={6} />
              </Link>
            </Box>
          )}
          <TagListContainer
            styleProps={{ mt: 4 }}
            nodeTitle={node.title}
            projectId={projectID}
          />
        </Box>
        <QuestionActionBarContainer questionId={id} />
        <CommentListContainer parentId={id} deepLinkId={deepLinkId} />
      </GridItem>
    </Grid>
  );
};

export default QuestionDetail;
