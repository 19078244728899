import React from 'react';
import { datadogRum } from '@datadog/browser-rum';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export default class ErrorBoundary extends React.Component<Props> {
  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo): void {
    datadogRum.addError(error);
  }

  render() {
    return this.props.children;
  }
}
