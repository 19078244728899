import { List, Map, fromJS } from 'immutable';

import { actionTypes } from './alertsActions';

type State = List<Map<string, string>>;

export const initialState: State = List([]);

export default function reducer(state: State = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.ADD_ALERT: {
      return state.push(
        fromJS({
          type: payload.type,
          message: payload.message
        })
      );
    }
    case actionTypes.CLEAR_FIRST_ALERT: {
      return state.shift();
    }
  }

  return state;
}
