import { Map } from 'immutable';
import _partial from 'lodash/partial';
import React, { useEffect } from 'react';
import { HitsProvided } from 'react-instantsearch-core';
import { connect, useDispatch } from 'react-redux';

import { ConnectedNoResultsFound } from 'app/components/SearchNoResultsFound';
import SearchResultItem from 'app/components/SearchResultItem';
import { actionCreators as searchActions } from 'app/models/search/searchActions';
import { normalizeSearchResults } from 'app/models/search/searchNormalizers';
import {
  selectSearchQuery,
  selectAdditionalQuestionData
} from 'app/models/search/searchSelectors';
import {
  AlgoliaDisplayResult,
  AlgoliaHit,
  QuestionData,
  SearchClickAnalytics
} from 'app/models/search/searchTypes';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';

import CONFIG from 'appConfigLoader';

interface SearchResultListStateProps {
  searchQuery: Map<string, any>;
  questionData: Map<string, { toJS: () => QuestionData }>;
}

interface Props {
  handleSearchResultClick: (id: string, searchPosition: number) => void;
  unreads: UnreadPostByParentId;
}

const mapStateToProps = (state): SearchResultListStateProps => ({
  searchQuery: selectSearchQuery(state),
  questionData: selectAdditionalQuestionData(state)
});

interface DispatchProps {
  trackSearchClick: (
    questionId: string,
    analyticsObject: SearchClickAnalytics
  ) => void;
  retrieveQuestionData: typeof searchActions.retrieveQuestionData;
}

const mapDispatchToProps = {
  trackSearchClick: searchActions.trackSearchClick,
  retrieveQuestionData: searchActions.retrieveQuestionData
};

export type SearchResultListProps = Props &
  SearchResultListStateProps &
  HitsProvided<AlgoliaHit> &
  DispatchProps;

const MAX_HEADING_LENGTH = 75;
const MAX_DISPLAY_HITS = 50;

const generateKnowledgePath = (rootId: number): string =>
  `${CONFIG.serverUrl}questions/${rootId}`;

const getAbbreviatedTextForResult = (text: string): string => {
  return text.length < MAX_HEADING_LENGTH
    ? text
    : text.substring(0, MAX_HEADING_LENGTH);
};

const constructKnowledgeResult = (
  questions: AlgoliaHit[]
): AlgoliaDisplayResult[] =>
  (questions ?? []).slice(0, MAX_DISPLAY_HITS).map((h: AlgoliaHit) => {
    const url = generateKnowledgePath(h.root_id);
    let abbreviatedContent = '';
    if (h.content) {
      abbreviatedContent = getAbbreviatedTextForResult(h.content);
    }

    return {
      acceptedAnswerId: h.accepted_answer_id,
      verifiedAnswerId: h.verified_answer_id,
      createdAt: String(h.created_at),
      answerCount: Number(h.answer_count),
      id: h.root_id,
      content: abbreviatedContent || '',
      myVote: h.myVote,
      programTitle: h.programTitle,
      projectId: h.project_id,
      projectTitle: h.project_title,
      title: h.question_title,
      index: 'knowledge',
      objectId: h.objectID,
      url,
      user: h.user,
      score: h.score,
      _highlightResult: h._highlightResult,
      _snippetResult: h._snippetResult
    };
  });

export const SearchResultList: React.FC<SearchResultListProps> = ({
  handleSearchResultClick,
  unreads,
  trackSearchClick,
  searchQuery,
  hits,
  questionData,
  retrieveQuestionData
}) => {
  const dispatch = useDispatch();

  const questions = hits
    .map((h, i) => {
      const extraHitData = questionData?.get(String(h.root_id))?.toJS() ?? {
        isDeletedQuestion: false,
        myVote: 0,
        user: {
          id: null,
          displayName: '',
          // Display a gray (240, 240, 240) circle while loading the avatar
          profileImageUrl:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP88B8AAuUB8e2ujYwAAAAASUVORK5CYII='
        },
        programTitle: ''
      };
      return {
        ...(extraHitData ?? {}),
        ...h
      };
    })
    // Algolia can return search results for deleted questions. We filter
    // these out to prevent showing broken links.
    .filter(({ isDeletedQuestion }) => !isDeletedQuestion);

  useEffect(() => {
    const normalizedResults = normalizeSearchResults({
      questions: questions.map((q) => ({
        ...q,
        id: q.objectID
      }))
    });

    dispatch(
      searchActions.upsertSearchQuestions({ results: normalizedResults })
    );
  }, [dispatch, questions]);

  useEffect(() => {
    retrieveQuestionData(
      hits.map((h) => {
        return h.root_id;
      })
    );
  }, [hits, retrieveQuestionData]);

  if (!questions?.length) {
    return <ConnectedNoResultsFound />;
  }

  const displayResults: AlgoliaDisplayResult[] = constructKnowledgeResult(
    questions
  );

  return (
    <ul>
      {displayResults.map((result, ind) => {
        const qid = result?.id;
        const clickCallback = _partial(
          handleSearchResultClick,
          searchQuery.toJS(),
          result.id,
          ind + 1,
          trackSearchClick
        ) as () => void;
        return result ? (
          <li key={qid}>
            <SearchResultItem
              question={result}
              unreads={unreads[qid]}
              handleClick={clickCallback}
            />
          </li>
        ) : null;
      })}
    </ul>
  );
};

export default connect<SearchResultListStateProps, DispatchProps, Props>(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultList);
