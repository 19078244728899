import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { isBlankInput } from 'app/helpers/validationHelpers';
import { CommentFormType } from 'app/models/posts/postsTypes';
import i18n from 'i18n';

export interface ValidationError {
  field: string;
  message: string;
}

export const validate = (content: Mobiledoc): ValidationError[] => {
  const errors: ValidationError[] = [];

  if (isBlankInput(content)) {
    errors.push({
      field: 'content',
      message: i18n.t('comment.addDetails')
    });
  }

  return errors;
};

export interface ReplyFormText {
  title: string;
  subtitle: string;
  submitButtonText?: string;
}

export function replyFormTextForFormType(
  type?: CommentFormType
): ReplyFormText {
  switch (type) {
    case CommentFormType.NEED_MORE_HELP: {
      return {
        title: i18n.t('comment.postCommentHelp'),
        subtitle: i18n.t('comment.describeIssues'),
        submitButtonText: i18n.t('comment.requestHelp')
      };
    }
    case CommentFormType.REPLY_TO_MENTOR_COMMENT: {
      return {
        title: i18n.t('comment.postCommentReply'),
        subtitle: i18n.t('comment.respondToMentor')
      };
    }
    case CommentFormType.COMMENT:
    default: {
      return {
        title: i18n.t('comment.yourComment'),
        subtitle: i18n.t('comment.writeCommentDescribeAnswer')
      };
    }
  }
}
