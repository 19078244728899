import isUrl from 'is-url';
import _isFunction from 'lodash/isFunction';
import _omit from 'lodash/omit';
import React from 'react';
import Tooltip from 'rc-tooltip';

import { safeUserInput } from 'app/helpers/userContentHelpers';

import LinkEditDialog from './LinkEditDialog';
import LinkTooltip from './LinkTooltip';

interface Payload {
  url: string;
  anchor: string;
  newAtom?: boolean;
}

interface Props {
  value: string;
  payload: Payload;
  save?: (value: string, payload: Payload) => {};
}

interface State {
  isEditing: boolean;
}

export default class LinkAtom extends React.PureComponent<Props, State> {
  /* Required mobiledoc attributes */
  static type = 'dom';

  static displayName = 'Link';
  /* End mobiledoc attributes */

  state = {
    isEditing: !!this.props.payload.newAtom
  };

  // discern whether viewing in mobiledoc editor context
  _isInEditor(): boolean {
    const { save } = this.props;
    return _isFunction(save);
  }

  openDialog = () => {
    this.setState({ isEditing: true });
  };

  closeDialog = () => {
    this.setState({ isEditing: false });
  };

  handleClick = (e: React.MouseEvent) => {
    if (this._isInEditor()) {
      e.preventDefault();
      this.openDialog();
    }
  };

  handleSave = ({ anchor, url }: any) => {
    const { save, payload } = this.props;
    if (save) {
      save(anchor, { ..._omit(payload, ['newAtom']), anchor, url });
    }
  };

  render() {
    const { payload, value } = this.props;
    const { isEditing } = this.state;

    const {
      newAtom,
      url = isUrl(value) ? value : '',
      anchor = isUrl(value) ? '' : value
    } = payload;

    const EditTooltip: React.FC = ({ children }) => (
      <Tooltip
        data-testid="edit-tooltip"
        visible={isEditing}
        placement="bottom"
        trigger="click"
        overlay={
          <LinkEditDialog
            handleSave={this.handleSave}
            handleClose={this.closeDialog}
            anchor={anchor}
            url={url}
            isNewLink={newAtom}
          />
        }
      >
        {children}
      </Tooltip>
    );

    const HoverTooltip: React.FC = ({ children }) => (
      <Tooltip
        data-testid="hover-tooltip"
        placement="bottom"
        overlay={<LinkTooltip editLink={this.openDialog} url={url} />}
      >
        {children}
      </Tooltip>
    );

    // CAUTION: don't wrap ExternalLink in function for hover tooltip to work
    const ExternalLink = (
      <a
        data-testid="link"
        href={safeUserInput(url)}
        onClick={this.handleClick}
        rel="external noreferrer"
        target="_blank"
      >
        {anchor || '\u00A0'}
      </a>
    );

    // writing/editing a question in editor context
    if (this._isInEditor()) {
      return (
        <>
          {/* Editing Link or Reading/Viewing Link */}
          {isEditing ? (
            <EditTooltip>{ExternalLink}</EditTooltip>
          ) : (
            <HoverTooltip>{ExternalLink}</HoverTooltip>
          )}
        </>
      );
    }

    // default (reading question)
    return ExternalLink;
  }
}
