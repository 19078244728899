import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

import ContentWrapper from 'app/components/ContentWrapper';
import ActivityDashContainer from 'app/containers/ActivityDashContainer';
import UserActivityStatsContainer from 'app/containers/UserActivityStatsContainer';
import { trackPageview } from 'app/helpers/analyticsHelper';

import i18n from 'i18n';

export class Activity extends React.PureComponent<any> {
  componentDidMount() {
    document.title = i18n.t('common.knowledgeUdacity');
    this.trackEvent('activity');
  }

  trackEvent(eventName: string) {
    trackPageview(eventName);
  }

  render() {
    return (
      <Box bgColor="white" minW="full" minH="full">
        <ContentWrapper>
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            pt={{ base: '1rem', lg: '1.12rem' }}
          >
            <Box
              flex="0 0 17.813rem"
              m="0 1.5rem 2rem"
              alignSelf={{ md: 'start' }}
            >
              <Box
                mb={6}
                borderBottom="1px solid"
                borderBottomColor="silver-lighter"
                p="1.3rem 2rem 0 0"
                letterSpacing="0.09375em"
              >
                <Box mb={6}>
                  <Heading size="h2">{i18n.t('activity.myActivity')}</Heading>
                </Box>
              </Box>
              <UserActivityStatsContainer />
            </Box>
            <Box flex="1 1 22.25rem" mt="2.54rem">
              <ActivityDashContainer />
            </Box>
          </Flex>
        </ContentWrapper>
      </Box>
    );
  }
}

export default Activity;
