import { Flex, Image, keyframes } from '@chakra-ui/react';
import { PureComponent } from 'react';

import ghostShadow from 'assets/images/illustration_ghost-shadow.svg';
import ghost from 'assets/images/illustration_ghost.svg';

import i18n from 'i18n';

const ghostBounce = keyframes(`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.75rem);
  }
  100% {
    transform: translateY(0);
  }
`);

const shadowShrink = keyframes(`
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.65, 0.85);
  }
  100% {
    transform: scale(1, 1);
  }
  `);

export default class IllustrationGhost extends PureComponent {
  render() {
    return (
      <Flex direction="column" align="center" gap=".15rem">
        <Image
          src={ghost}
          alt={i18n.t('error.ghostPageNotFound')}
          w={16}
          animation={`${ghostBounce} 2.5s ease-in-out infinite`}
        />
        <Image
          src={ghostShadow}
          alt={i18n.t('error.ghostShadowPageNotFound')}
          w={16}
          animation={`${shadowShrink} 2.5s ease-in-out infinite`}
        />
      </Flex>
    );
  }
}
