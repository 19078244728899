import React from 'react';
import { connect } from 'react-redux';

import UserProfileOverlay from 'app/components/UserProfileOverlay';
import { actionCreators as activityActions } from 'app/models/activity/activityActions';
import { selectApiStatus } from 'app/models/activity/activitySelectors';
import { selectCurrentUserIsStaff } from 'app/models/session/sessionSelectors';
import { IUser } from 'app/models/entities/users/usersEntitiesTypes';
import { isPending } from 'app/models/helpers/apiStatusHelpers';

export interface DispatchProps {
  getUserActivityStats: (userId: string) => void;
}

interface StateProps {
  apiStatus: any;
  currentUserIsStaff: boolean;
}

export interface OwnProps {
  user: IUser;
}

export type UserProfileOverlayContainerProps = DispatchProps &
  OwnProps &
  StateProps;

const mapStateToProps = (state): StateProps => ({
  apiStatus: selectApiStatus(state, 'getUserActivityStats'),
  currentUserIsStaff: selectCurrentUserIsStaff(state)
});

const mapDispatchToProps: DispatchProps = {
  getUserActivityStats: activityActions.getUserActivityStats
};

export class UserProfileOverlayContainer extends React.Component<
  UserProfileOverlayContainerProps,
  StateProps
> {
  componentDidMount() {
    const { getUserActivityStats, user } = this.props;
    getUserActivityStats(user.id);
  }

  render() {
    const { user, apiStatus, currentUserIsStaff } = this.props;

    if (isPending(apiStatus)) {
      return <div />;
    } else {
      return (
        <UserProfileOverlay user={user} viewerIsStaff={currentUserIsStaff} />
      );
    }
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileOverlayContainer);
