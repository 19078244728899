import { Flex, Heading } from '@chakra-ui/react';
import { FilterIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';

import i18n from 'i18n';

export default class SearchFilter extends React.PureComponent<{}> {
  render() {
    return (
      <Flex gap={2} w="full" align="center">
        <FilterIcon w={6} h={6} color="silver" />
        <Heading
          size="h6"
          as="h6"
          color="dark-night-blue"
          fontSize="0.8125rem"
          fontWeight="semibold"
          lineHeight="h5"
        >
          {i18n.t('search.filter')}
        </Heading>
      </Flex>
    );
  }
}
