import React from 'react';
import { connect } from 'react-redux';

import SearchResultsBar from 'app/components/SearchResultsBar';
import { selectTotalResults } from 'app/models/search/searchSelectors';

interface SearchResultsBarContainerStateProps {
  searchResultsCount?: number;
}

const mapStateToProps = (state): SearchResultsBarContainerStateProps => ({
  searchResultsCount: selectTotalResults(state)
});

export class SearchResultsBarContainer extends React.PureComponent<
  SearchResultsBarContainerStateProps
> {
  render() {
    const { searchResultsCount } = this.props;
    return <SearchResultsBar searchResultsCount={searchResultsCount} />;
  }
}

export default connect<{}, SearchResultsBarContainerStateProps, {}>(
  mapStateToProps
)(SearchResultsBarContainer);
