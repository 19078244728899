import { Nanodegree, Project, PseudoNanodegreeType } from './nanodegreeTypes';
import i18n from 'i18n';

const PSEUDO_ND_KEY_PREFIX = '__pseudo_nd_';

export function isPseudoNanodegreeKey(
  ndKey?: string,
  type?: PseudoNanodegreeType
): boolean {
  if (type) {
    const pseudoKey = titleAndKeyForPseudoNanodegreeType(type).key;
    return ndKey === pseudoKey;
  }

  return !!ndKey && ndKey.startsWith(PSEUDO_ND_KEY_PREFIX);
}

function makePseudoNanodegreeKey(title: string): string {
  return `${PSEUDO_ND_KEY_PREFIX}_${title.replace(/\s+/g, '').toLowerCase()}`;
}

export function titleAndKeyForPseudoNanodegreeType(
  type: PseudoNanodegreeType
): { title: string; key: string } {
  switch (type) {
    case PseudoNanodegreeType.ANY: {
      const title = i18n.t('pseudoNanodegreeType.any');
      return { title, key: makePseudoNanodegreeKey(title) };
    }
    case PseudoNanodegreeType.CERTIFICATIONS: {
      const title = i18n.t('pseudoNanodegreeType.reviewsCertifiedProjects');
      return { title, key: makePseudoNanodegreeKey(title) };
    }
    default: {
      throw new Error(i18n.t('pseudoNanodegreeType.unrecognized'));
    }
  }
}

export function makePseudoNanodegree(
  type: PseudoNanodegreeType,
  projectIdTitlePairs: Array<{ id: number; title: string }>
): Nanodegree {
  const projects: Project[] = projectIdTitlePairs.map((pair) => {
    return {
      title: pair.title,
      reviewsProjectId: pair.id
    };
  });

  return {
    ...titleAndKeyForPseudoNanodegreeType(type),
    projects,
    hasKnowledgeReviews: false
  };
}
