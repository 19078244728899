import { Map } from 'immutable';

export enum SupportedTypes {
  bmp = 'image/bmp',
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  png = 'image/png'
}

export interface RenderLocalArgs {
  filename: string;
  width: number;
  height: number;
  localImageSrc: string;
  fileIdentifierString: string;
}

export type FileUploadState = Map<string, any>;
