import { Map, List } from 'immutable';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { isReady, isPending } from 'app/models/helpers/apiStatusHelpers';
import {
  selectCertifications,
  selectCurrentUserIsStaff
} from 'app/models/session/sessionSelectors';
import {
  selectApiStatus,
  selectHasAnyNanodegreeWithKnowledgeReviews
} from 'app/models/nanodegree/nanodegreeSelectors';

import CONFIG from 'appConfigLoader';

interface Props {
  // For test injection.
  children: ReactNode;
  goToClassroom?: () => void;
}

const AccessCheckContainer: React.FC<Props> = ({
  goToClassroom = () => {
    window.location.assign(CONFIG.classroomWebUrl);
  },
  children
}) => {
  const isStaff: boolean = useSelector(selectCurrentUserIsStaff);
  const certifications: List<number> = useSelector(selectCertifications);
  const hasKnowledgeReviews: boolean = useSelector(
    selectHasAnyNanodegreeWithKnowledgeReviews
  );
  const fetchNanodegreesStatus: Map<string, string> = useSelector((state) =>
    selectApiStatus(state, 'fetchNanodegreeEnrollments')
  );

  const hasAccess =
    isReady(fetchNanodegreesStatus) ||
    isPending(fetchNanodegreesStatus) ||
    hasKnowledgeReviews ||
    certifications.size > 0 ||
    isStaff;
  if (!hasAccess) {
    goToClassroom();
  }

  return <>{children}</>;
};

export default AccessCheckContainer;
