export const chakraSelectStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    paddingX: '1rem',
    paddingY: 0,
    height: '2.5rem'
  }),
  input: (provided: any) => ({
    ...provided,
    paddingY: '0.575rem'
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none'
  }),
  dropdownIndicator: (provided: any, { selectProps }: any) => ({
    ...provided,
    background: 'white',
    border: 'none',
    '> svg': {
      transition: '300ms ease all',
      transform: `rotate(${selectProps.menuIsOpen ? 180 : 0}deg)`
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    overflow: 'visible'
  }),
  option: (provided: any, { isSelected, isFocused }: any) => ({
    ...provided,
    padding: '0.625rem 1rem',
    ...(isSelected && {
      bgColor: 'cerulean-dark'
    }),
    ...(isFocused &&
      !isSelected && {
        bgColor: 'silver-lighter'
      }),
    _hover: {
      ...(isSelected && {
        bgColor: 'cerulean-dark'
      })
    },
    _active: {
      ...(isSelected && {
        bgColor: 'cerulean-dark'
      })
    }
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'slate-light'
  })
};
