import _get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';

import ToastPortal from 'app/components/Toast/ToastPortal';
import { actionCreators } from 'app/models/alerts/alertsActions';
import { selectFirstAlert } from 'app/models/alerts/alertsSelectors';

interface IDispatchProps {
  clearFirstAlert?: () => {};
}

interface IOwnProps {
  alert?: any;
}

export type Props = IDispatchProps & IOwnProps;

const mapStateToProps = (state: any): any => ({
  alert: selectFirstAlert(state)
});

const mapDispatchToProps: IDispatchProps = {
  clearFirstAlert: actionCreators.clearFirstAlert
};

export class ToastContainer extends React.PureComponent<Props> {
  render() {
    const { alert, clearFirstAlert } = this.props;

    if (!_get(alert, 'size')) {
      return null;
    }

    return !!alert ? (
      <ToastPortal
        type={alert.get('type')}
        message={alert.get('message')}
        autoHide
        delay={500}
        clearFirstAlert={clearFirstAlert}
      />
    ) : null;
  }
}

export default connect<{}, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ToastContainer);
