import { chakra } from '@chakra-ui/react';
import React from 'react';
import { connect } from 'react-redux';

import { actionCreators } from 'app/models/posts/postsActions';

interface DispatchProps {
  setLatestFileInputRef: (el: HTMLInputElement) => void;
}

interface OwnProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept: string;
}

export type Props = DispatchProps & OwnProps;

const mapDispatchToProps: DispatchProps = {
  setLatestFileInputRef: actionCreators.setLatestFileInputRef
};

export class FileInputContainer extends React.Component<Props> {
  setRef = (el: HTMLInputElement) => {
    const { setLatestFileInputRef } = this.props;
    setLatestFileInputRef(el);
  };

  render() {
    const { handleChange, accept } = this.props;

    return (
      <chakra.input
        ref={this.setRef}
        type="file"
        onChange={handleChange}
        accept={accept}
        position="absolute"
        top={0}
        left={0}
        opacity={0}
        cursor="pointer"
        w="full"
        h="full"
      />
    );
  }
}

export default connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(FileInputContainer);
