import { Box, Grid } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import scrollIntoView from 'scroll-into-view';

import PostBodyContent from 'app/components/PostBodyContent';
import PostMetadata from 'app/components/PostMetadata';
import AnswerAcceptVerifyContainer from 'app/containers/AnswerAcceptVerifyContainer';
import AnswerActionBarContainer from 'app/containers/AnswerActionBarContainer';
import CommentListContainer from 'app/containers/CommentListContainer';
import PostToggleCommentsContainer from 'app/containers/PostToggleCommentsContainer';
import PostVoteContainer from 'app/containers/PostVoteContainer';
import EditAnswerFormContainer from 'app/containers/answerForms/EditAnswerFormContainer';
import RatingFormContainer from 'app/containers/RatingFormContainer';
import { IAnswer } from 'app/models/entities/answers/answersEntitiesTypes';
import { UnreadPost } from 'app/models/unreads/unreadsTypes';

import { AnswerListItemWrapper } from './AnswerListItemWrapper';

export interface Props {
  answer: IAnswer;
  deepLinkId?: string;
  unreads: { [postId: string]: UnreadPost } | undefined;
}

interface State {
  isEdit: boolean;
}

export default class AnswerListItem extends React.PureComponent<Props, State> {
  answerDomWrapper: HTMLLIElement;

  state = {
    isEdit: false
  };

  componentDidMount() {
    const { deepLinkId, answer } = this.props;
    if (deepLinkId === answer.id) {
      scrollIntoView(this.answerDomWrapper, { align: { top: 0 } });
    }
  }

  setRef = (el: HTMLLIElement) => {
    this.answerDomWrapper = el || document.createElement('li');
  };

  hideEdit = () => {
    this.setState({ isEdit: false });
  };

  showEdit = () => {
    this.setState({ isEdit: true });
  };

  render() {
    const {
      answer,
      answer: { user, fromMentor, id: answerId },
      deepLinkId,
      unreads
    } = this.props;
    const { isEdit } = this.state;

    return (
      <AnswerListItemWrapper
        ref={this.setRef}
        isFocused={deepLinkId === answerId}
        isUnread={!_isEmpty(unreads) && !!unreads![answerId]}
      >
        <Box
          m={{ base: '0 1rem 0.625rem 0', 'sm-md': '-0.25rem 0 0 0' }}
          display={{ 'sm-md': 'flex' }}
          justifyContent={{ 'sm-md': 'center' }}
          gridRow={{ 'sm-md': '1 / 3' }}
        >
          <PostVoteContainer postId={answerId} />
        </Box>

        <Grid>
          <AnswerAcceptVerifyContainer answerId={answerId} />
        </Grid>

        <Box as="section" gridColumn={{ base: '1 / 3', 'sm-md': '2 / 3' }}>
          {!isEdit && (
            <div>
              <PostBodyContent
                content={answer.content}
                createdAt={answer.createdAt}
                modifiedAt={answer.modifiedAt}
              />
              <Box m="0.5rem 0">
                <PostMetadata
                  user={user}
                  fromMentor={fromMentor}
                  createdAt={answer.createdAt}
                />
              </Box>
              <RatingFormContainer postId={answerId} />
              <AnswerActionBarContainer
                answerId={answerId}
                onEdit={this.showEdit}
              />
            </div>
          )}

          {isEdit && (
            <>
              <Box
                data-ref="answer-edit-form"
                bgColor="silver-lightest"
                p={{ base: '1rem 2rem 2rem', 'sm-md': '3rem 4rem 4rem' }}
              >
                <EditAnswerFormContainer
                  answerId={answerId}
                  onCancel={this.hideEdit}
                />
              </Box>
              {answer.comments.length > 0 && (
                <Box mt={8}>
                  <PostToggleCommentsContainer
                    initialState="collapse"
                    postId={answerId}
                  />
                </Box>
              )}
            </>
          )}

          <CommentListContainer parentId={answerId} deepLinkId={deepLinkId} />
        </Box>
      </AnswerListItemWrapper>
    );
  }
}
