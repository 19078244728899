import { Box, Button } from '@chakra-ui/react';
import { Map } from 'immutable';
import _get from 'lodash/get';
import React from 'react';
import scrollIntoView from 'scroll-into-view';

import PostLegalDisclaimer from 'app/components/PostLegalDisclaimer';
import RichTextEditorContainer from 'app/containers/RichTextEditorContainer';
import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { isFulfilled, isPending } from 'app/models/helpers/apiStatusHelpers';

import i18n from 'i18n';

export interface Props {
  onSubmit: (event: any) => void;
  onChange: (event: any) => void;
  onCancel: () => void;
  errorMessage: string;
  apiStatus: Map<string, string>;
  title: string;
  subtitle: string;
  submitButtonText?: string;
  initialContent?: {
    content: Mobiledoc;
  };
  isImageUploadPending: boolean;
  additionalDisclaimer?: JSX.Element;
}

interface State {
  content: {};
}

export default class ReplyForm extends React.PureComponent<Props, State> {
  state = {
    content: {}
  };

  scrollDuration: number = 500;

  editor: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.editor = React.createRef();
  }

  componentDidMount() {
    scrollIntoView(this.editor.current, {
      time: this.scrollDuration
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { apiStatus } = this.props;
    const { apiStatus: prevApiStatus } = prevProps;

    if (isFulfilled(apiStatus) && isPending(prevApiStatus)) {
      this.clearContent();
    }
  }

  clearContent = () => {
    this.setState({
      content: {}
    });
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.onSubmit(this.state.content);
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleMobiledocInput = (content: {}) => {
    const { errorMessage, onChange } = this.props;
    if (errorMessage) {
      // revalidate on input
      onChange(content);
    }
    this.setState({ content });
  };

  render() {
    const {
      errorMessage,
      apiStatus,
      title,
      subtitle,
      initialContent,
      isImageUploadPending,
      submitButtonText = i18n.t('answer.submit'),
      additionalDisclaimer
    } = this.props;

    const isFormDisabled = isPending(apiStatus) || isImageUploadPending;
    const errorText = errorMessage || i18n.t('question.addDetails');

    return (
      <form data-ref="reply-form" onSubmit={this.handleSubmit}>
        <div ref={this.editor}>
          <Box as="fieldset" mb="2.5rem">
            <RichTextEditorContainer
              title={title}
              subtitle={subtitle}
              hasError={!!errorMessage}
              defaultContent={_get(initialContent, 'content')}
              handleChange={this.handleMobiledocInput}
              errorText={errorText}
              autofocus
              autofocusDelay={this.scrollDuration}
            />
          </Box>

          <footer>
            <Button
              variant="primary"
              disabled={isFormDisabled}
              type="submit"
              bgColor="cerulean"
              borderRadius="secondary"
              fontSize="0.8125rem"
              fontWeight="semibold"
              padding="0 2rem"
              me={2}
            >
              {submitButtonText}
            </Button>
            <Button
              variant="minimal"
              onClick={this.handleCancel}
              color="cerulean"
              borderRadius="secondary"
              fontSize="0.8125rem"
              fontWeight="semibold"
              padding="0 2rem"
            >
              {i18n.t('common.cancel')}
            </Button>
            <Box m="1.5rem 0 0">
              {additionalDisclaimer}

              <PostLegalDisclaimer />
            </Box>
          </footer>
        </div>
      </form>
    );
  }
}
