import { FormLabel, Grid, UnorderedList } from '@chakra-ui/react';
import {
  StyleBoldIcon,
  CodeIcon,
  StyleItalicIcon,
  LinkIcon,
  StyleBulletedIcon,
  StyleNumberedIcon,
  ImageIcon,
  QuoteIcon
} from '@udacity/chakra-veritas-icons';
import { useRef, useState } from 'react';
import { MarkupButton, SectionButton } from 'react-mobiledoc-editor';

import { AtomButton } from './AtomButton';
import { CardButton } from './CardButton';
import { SectionDivider, ToolbarItem, TooltipLabel } from './factoryComponents';
import { usePositionSticky } from './richTextToolbarHooks';
import ToolbarTooltip from './ToolbarToolitp';

import i18n from 'i18n';

export interface Props {
  handleInsertImage: () => void;
  title: string;
}

export default function RichTextToolbar(props: Props) {
  const [isActive, setIsActive] = useState({
    bold: false,
    italic: false,
    quote: false,
    code: false,
    ol: false,
    ul: false,
    link: false,
    image: false
  });

  const { handleInsertImage, title } = props;
  const toolbarRef = useRef<HTMLDivElement>(null);
  const isSticky = usePositionSticky(toolbarRef);

  const handleIsActive = (
    icon: 'bold' | 'italic' | 'quote' | 'code' | 'ol' | 'ul' | 'link' | 'image'
  ) => {
    setIsActive((currentState) => ({
      ...currentState,
      [icon]: !currentState[icon]
    }));
  };

  return (
    <Grid
      gridTemplateColumns={{ md: isSticky ? '1fr' : 'auto 1fr' }}
      position="sticky"
      top={{ base: '-1.75rem', md: '-1px' }}
      alignItems="center"
      zIndex="sticky"
      ref={toolbarRef}
    >
      <FormLabel
        size="lg"
        lineHeight="base"
        mb={1}
        htmlFor="details"
        display={{ md: isSticky ? 'none' : 'block' }}
      >
        {title}
      </FormLabel>

      <UnorderedList
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(1.25rem, 1fr))"
        alignItems="center"
        justifyItems="center"
        justifySelf={{ md: 'end' }}
        bgColor={isSticky ? 'white' : 'silver-lightest'}
        w={{
          base: 'calc(100% + 1rem)',
          md: isSticky ? 'calc(100% + 1rem)' : '60%'
        }}
        p={2}
        m="0 -0.5rem 0.25rem"
        shadow={isSticky ? 'shadow-1' : 'none'}
        borderBottomRadius={isSticky ? 'secondary' : 0}
        transition="background 0.3s, box-shadow 0.3s, width 0.3s"
      >
        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.bold')}</TooltipLabel>}
          >
            <MarkupButton
              aria-label={i18n.t('text.boldButton')}
              aria-pressed={isActive.bold}
              className="toolbar-button"
              tag="strong"
              tabIndex={-1}
              type="button"
            >
              <StyleBoldIcon
                w={6}
                h={6}
                onClick={() => handleIsActive('bold')}
              />
            </MarkupButton>
          </ToolbarTooltip>
        </ToolbarItem>

        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.italic')}</TooltipLabel>}
          >
            <MarkupButton
              aria-label={i18n.t('text.italicizeButton')}
              aria-pressed={isActive.italic}
              className="toolbar-button"
              tag="em"
              type="button"
              tabIndex={-1}
            >
              <StyleItalicIcon
                w={6}
                h={6}
                onClick={() => handleIsActive('italic')}
              />
            </MarkupButton>
          </ToolbarTooltip>
        </ToolbarItem>

        <SectionDivider aria-hidden />

        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.link')}</TooltipLabel>}
          >
            <AtomButton
              ariaLabel={i18n.t('text.addLink')}
              aria-pressed={isActive.link}
              className="toolbar-button"
              type="button"
              tabIndex={-1}
              atom={{ name: 'Link' }}
            >
              <LinkIcon w={6} h={6} onClick={() => handleIsActive('link')} />
            </AtomButton>
          </ToolbarTooltip>
        </ToolbarItem>

        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.blockquote')}</TooltipLabel>}
          >
            <SectionButton
              aria-label={i18n.t('text.addQuote')}
              aria-pressed={isActive.quote}
              className="toolbar-button"
              tag="blockquote"
              tabIndex={-1}
              type="button"
            >
              <QuoteIcon w={6} h={6} onClick={() => handleIsActive('quote')} />
            </SectionButton>
          </ToolbarTooltip>
        </ToolbarItem>

        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.image')}</TooltipLabel>}
          >
            <CardButton
              ariaLabel={i18n.t('image.add')}
              aria-pressed={isActive.image}
              className="toolbar-button"
              tabIndex={-1}
              card={{ name: 'Image' }}
              type="button"
              afterInsertCallback={handleInsertImage}
            >
              <ImageIcon w={6} h={6} onClick={() => handleIsActive('image')} />
            </CardButton>
          </ToolbarTooltip>
        </ToolbarItem>

        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.codeBlock')}</TooltipLabel>}
          >
            <CardButton
              ariaLabel={i18n.t('text.addCodeBlock')}
              aria-pressed={isActive.code}
              className="toolbar-button"
              tabIndex={-1}
              card={{ name: 'Code' }}
              type="button"
            >
              <CodeIcon w={6} h={6} onClick={() => handleIsActive('code')} />
            </CardButton>
          </ToolbarTooltip>
        </ToolbarItem>

        <SectionDivider aria-hidden />

        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.numberedList')}</TooltipLabel>}
          >
            <SectionButton
              aria-label={i18n.t('text.addNumberedList')}
              aria-pressed={isActive.ol}
              className="toolbar-button"
              tag="ol"
              type="button"
              tabIndex={-1}
            >
              <StyleNumberedIcon
                w={6}
                h={6}
                onClick={() => handleIsActive('ol')}
              />
            </SectionButton>
          </ToolbarTooltip>
        </ToolbarItem>

        <ToolbarItem>
          <ToolbarTooltip
            label={<TooltipLabel>{i18n.t('text.bulletList')}</TooltipLabel>}
          >
            <SectionButton
              aria-label={i18n.t('text.addBulletList')}
              aria-pressed={isActive.ul}
              className="toolbar-button"
              tag="ul"
              type="button"
              tabIndex={-1}
            >
              <StyleBulletedIcon
                w={6}
                h={6}
                onClick={() => handleIsActive('ul')}
              />
            </SectionButton>
          </ToolbarTooltip>
        </ToolbarItem>
      </UnorderedList>
    </Grid>
  );
}
