import { Box, chakra, Spacer } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Trans } from 'react-i18next';

import CenteredLoader from 'app/components/CenteredLoader';
import { IStats } from 'app/models/activity/activityTypes';

import { StatNumber, StatText } from './factoryComponents';

export interface Props {
  userActivityStats: IStats;
  isStatsFulfilled?: boolean;
}

export default class UserActivityStats extends React.Component<Props> {
  render() {
    const { userActivityStats, isStatsFulfilled } = this.props;

    if (!_isEmpty(userActivityStats)) {
      return (
        <chakra.dl
          display="block"
          color="slate"
          data-ref="user-post-stats-wrapper"
        >
          <Trans
            i18nKey="activityStats.upvotesReceived"
            count={userActivityStats.upVotesReceived}
          >
            <StatNumber>
              {{ count: userActivityStats.upVotesReceived }}
            </StatNumber>
            <StatText>upvote received</StatText>
          </Trans>
          <Spacer h={8} />

          <Trans
            i18nKey="activityStats.answersPosted"
            count={userActivityStats.answersPosted}
          >
            <StatNumber>
              {{ count: userActivityStats.answersPosted }}
            </StatNumber>
            <StatText>answer posted</StatText>
          </Trans>
          <Spacer h={8} />

          <Trans
            i18nKey="activityStats.answersAccepted"
            count={userActivityStats.answersAccepted}
          >
            <StatNumber>
              {{ count: userActivityStats.answersAccepted }}
            </StatNumber>
            <StatText>answer accepted</StatText>
          </Trans>
          <Spacer h={8} />

          <Trans
            i18nKey="activityStats.upVotesGiven"
            count={userActivityStats.upVotesGiven}
          >
            <StatNumber>{{ count: userActivityStats.upVotesGiven }}</StatNumber>
            <StatText>upvote given</StatText>
          </Trans>
          <Spacer h={8} />
        </chakra.dl>
      );
    } else if (_isEmpty(userActivityStats) && !isStatsFulfilled) {
      return (
        <Box ms={{ 'sm-md': '-2rem' }}>
          <CenteredLoader />;
        </Box>
      );
    }
    return null;
  }
}
