import { Button } from '@chakra-ui/react';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import _noop from 'lodash/noop';
import React from 'react';

import { ThemeConsumer } from '../themeContext';

export interface Props {
  primary?: {
    text: string;
    callback: () => void;
  };
  secondary?: {
    text: string;
    callback: () => void;
  };
  disabled?: boolean;
  theme: 'red' | 'blue';
}

export class ActionModalButtons extends React.PureComponent<Props> {
  render() {
    const { primary, secondary, disabled, theme } = this.props;

    const actionButtonVariant = theme === 'red' ? 'destructive' : 'default';

    return (
      <>
        {_isFunction(_get(primary, 'callback')) && (
          <Button
            variant={actionButtonVariant}
            onClick={_get(primary, 'callback', _noop)}
            disabled={disabled}
            me={4}
          >
            {_get(primary, 'text')}
          </Button>
        )}
        {_isFunction(_get(secondary, 'callback')) && (
          <Button
            variant="minimal"
            onClick={_get(secondary, 'callback', _noop)}
            padding="0 2rem"
            borderRadius="secondary"
            fontSize="0.813rem"
            color="cerulean"
          >
            {_get(secondary, 'text')}
          </Button>
        )}
      </>
    );
  }
}

export default (props: Props) => {
  return (
    <ThemeConsumer>
      {(theme) => <ActionModalButtons {...props} theme={theme} />}
    </ThemeConsumer>
  );
};
