import {
  Alert,
  AlertDescription,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage
} from '@chakra-ui/react';
import React from 'react';
import { WarningIcon } from '@udacity/chakra-veritas-icons';

import i18n from 'i18n';

export interface Props {
  onFeedbackSubmit: (userInput: string) => void;
}

export const isValid = (feedback: string) => feedback.trim().length > 0;

export const FeedbackInput: React.FC<Props> = ({ onFeedbackSubmit }) => {
  const [feedback, setFeedback] = React.useState<string>('');
  const [hasFeedbackError, setHasFeedbackError] = React.useState(false);

  const setAnswerFeedback = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (hasFeedbackError) {
        setHasFeedbackError(false);
      }
      setFeedback(e.target.value);
    },
    [hasFeedbackError]
  );

  const handleSubmit = React.useCallback(() => {
    isValid(feedback) ? onFeedbackSubmit(feedback) : setHasFeedbackError(true);
  }, [feedback, onFeedbackSubmit]);

  return (
    <>
      <FormControl mb={4}>
        <FormLabel>{i18n.t('answer.howMoreHelpful')}</FormLabel>
        <Input
          id="reason"
          value={feedback}
          onChange={setAnswerFeedback}
          isRequired
        />

        {hasFeedbackError && (
          <FormErrorMessage mb={4}>
            <Alert status="error" variant="embedded">
              <WarningIcon w={8} h={8} color="red" />
              <AlertDescription>{i18n.t('common.required')}</AlertDescription>
            </Alert>
          </FormErrorMessage>
        )}
      </FormControl>

      <Button type="submit" variant="default" onClick={handleSubmit}>
        {i18n.t('common.submit')}
      </Button>
    </>
  );
};

export default FeedbackInput;
