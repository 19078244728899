import { Button, ChakraProvider } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';

import Header from 'app/components/Header';
import TextInput from 'app/components/TextInput';
import { defaultLinkProtocol } from 'app/helpers/userContentHelpers';

import i18n from 'i18n';
import styles from './linkEditDialog.module.scss';
import { theme } from 'assets/styles/theme';

export interface Props {
  handleSave: (arg: { url: string; anchor: string }) => void;
  handleClose: () => void;
  anchor: string;
  url: string;
  isNewLink?: boolean;
}

interface State {
  anchor: string;
  url: string;
}

export default class LinkDialog extends React.PureComponent<Props, State> {
  state = {
    anchor: this.props.anchor,
    url: this.props.url
  };

  linkForm: React.RefObject<HTMLFormElement>;

  constructor(props) {
    super(props);

    this.linkForm = React.createRef();
  }

  componentDidMount() {
    window.requestAnimationFrame(() => {
      window.addEventListener('click', this.handleBodyClick);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick = (e: any) => {
    const { handleClose } = this.props;
    if (!e.path.includes(this.linkForm.current) && handleClose) {
      handleClose();
    }
  };

  // safari tooltip fix
  cancelEventBubble = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      ...state,
      [name]: value
    }));
  };

  handleSave = () => {
    const { anchor, url } = this.state;
    this.props.handleSave({ anchor, url: defaultLinkProtocol(url) });
  };

  render() {
    const { url, anchor } = this.state;
    const { isNewLink } = this.props;
    // autofocus `anchor` if:
    // 1. string is empty, OR
    // 2. if both url and anchor are filled in.
    const anchorFocus =
      _isEmpty(anchor) ||
      (!_isEmpty(anchor) && !_isEmpty(url) && url !== 'https://');
    // Otherwise autofocus url.
    const urlFocus = !anchorFocus;

    return (
      <ChakraProvider theme={theme}>
        <form
          ref={this.linkForm}
          className={styles['link-dialog']}
          onSubmit={this.handleSave}
          onClick={this.cancelEventBubble}
        >
          <fieldset>
            <Header size="h3" styleType="fieldset">
              {i18n.t('text.text')}
            </Header>
            <TextInput
              type="text"
              name="anchor"
              handleChange={this.handleChange}
              value={anchor}
              errorText={i18n.t('text.addLinkTitle')}
              placeholder="Udacity"
              autofocus={anchorFocus}
              required
            />
          </fieldset>

          <fieldset>
            <Header size="h3" styleType="fieldset">
              {i18n.t('text.link')}
            </Header>
            <TextInput
              type="text"
              name="url"
              handleChange={this.handleChange}
              value={url}
              errorText={i18n.t('text.addValidUrl')}
              placeholder="https://www.udacity.com"
              autofocus={urlFocus}
              required
            />
          </fieldset>

          <fieldset>
            <Button type="submit" variant="primary">
              {(isNewLink && i18n.t('text.addLink')) ||
                i18n.t('text.updateLink')}
            </Button>
          </fieldset>
        </form>
      </ChakraProvider>
    );
  }
}
