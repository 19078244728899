import { Box, Button, Heading } from '@chakra-ui/react';
import React from 'react';

import Select from 'app/components/Select';
import { Reason } from 'app/models/ratings/ratingsTypes';

import i18n from 'i18n';

interface ReasonOption {
  value: Reason;
  label: string;
}

export const OPTIONS: ReasonOption[] = [
  {
    value: 'incomplete',
    label: i18n.t('rating.moreInfo')
  },
  {
    value: 'unclear',
    label: i18n.t('rating.unclear')
  },
  {
    value: 'accuracy',
    label: i18n.t('rating.incorrect')
  },
  {
    value: 'other',
    label: i18n.t('rating.other')
  }
];

export interface Props {
  onSelect: (reason: Reason) => void;
}

export const isValid = (reason: ReasonOption) => reason.value && reason.label;

export const ReasonSelect: React.FC<Props> = ({ onSelect }) => {
  const [reason, setReason] = React.useState<ReasonOption>({
    value: '',
    label: ''
  });
  const [hasReasonError, setHasReasonError] = React.useState(false);

  const handleSubmit = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      isValid(reason) ? onSelect(reason.value) : setHasReasonError(true);
    },
    [reason, onSelect]
  );

  const setReasonOnChange = React.useCallback((option: ReasonOption) => {
    setReason(option);
    setHasReasonError(false);
  }, []);

  return (
    <>
      <Heading size="h4" as="h5" lineHeight="base" mb={6}>
        {i18n.t('answer.whatCouldBeImproved')}
      </Heading>

      <Box mb={4}>
        <Select
          data-ref="reason-select"
          value={reason}
          onChange={setReasonOnChange}
          options={OPTIONS}
          hasError={hasReasonError}
          errorText={i18n.t('rating.selectReason')}
          searchable={false}
        />
      </Box>
      <Button type="submit" variant="default" onClick={handleSubmit}>
        {i18n.t('common.submit')}
      </Button>
    </>
  );
};

export default ReasonSelect;
