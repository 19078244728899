import { Alert, AlertDescription, CloseButton, Grid } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface InfoBannerProps {
  icon: ReactNode;
  message: string;
  onClose: () => void;
}

const InfoBanner: React.FC<InfoBannerProps> = ({ icon, message, onClose }) => {
  return (
    <Alert role="alertdialog" status="info" variant="embedded">
      <Grid gridTemplateColumns="auto 1fr auto" alignItems="center" w="full">
        {icon}

        <AlertDescription
          fontSize={{ base: 'sm', 'sm-md': 'md' }}
          lineHeight="1.75rem"
        >
          {message}
        </AlertDescription>

        <CloseButton
          onClick={onClose}
          color="silver"
          _hover={{ bg: 'transparent' }}
        />
      </Grid>
    </Alert>
  );
};

export default InfoBanner;
