import apiStatuses from 'app/models/helpers/apiStatusHelpers';

export const createAction = (type: string, payload?: any): any => {
  const error = payload instanceof Error;

  return {
    type,
    payload: error
      ? {
          stack: payload.stack,
          message: payload.message
        }
      : payload,
    error
  };
};

export const createActionType = (actionName: string, actionClass: string) => {
  const statuses = ['READY', 'START', 'PENDING', 'FULFILLED', 'REJECTED'];

  return statuses.reduce((acc: any, status: string) => {
    acc[`${actionName}_${status}`] = `${actionClass}/${actionName}_${status}`;
    return acc;
  }, {});
};

export const createErrorAction = (actionType: string, error: Error) => ({
  type: actionType,
  payload: {
    state: apiStatuses.rejected,
    message: error.message,
    error
  }
});
