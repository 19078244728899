import { Box } from '@chakra-ui/react';
import React from 'react';

export interface Props {
  children: React.ReactNode;
}

export default class ContentWrapper extends React.PureComponent<Props> {
  render() {
    return (
      <Box px={{ base: 4, lg: 8 }} mx={{ lg: 'auto' }} w={{ lg: '64.5rem' }}>
        {this.props.children}
      </Box>
    );
  }
}
