import { Box, chakra } from '@chakra-ui/react';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _noop from 'lodash/noop';
import _values from 'lodash/values';
import React from 'react';

import ImageLightboxPortal from 'app/components/ImageLightboxPortal';
import ToastPortal from 'app/components/Toast/ToastPortal';
import FileInputContainer from 'app/containers/FileInputContainer';
import { FILE_SIZE_LIMIT } from 'app/models/files/filesConstants';
import { formatBytes } from 'app/models/files/filesHelpers';
import { SupportedTypes } from 'app/models/files/filesTypes';

import ImagePlaceholder from '../ImagePlaceholder';
import ProgressOverlay from '../ProgressOverlay';
import ResponsiveImage from '../ResponsiveImage';
import { ImageCardPayload } from '../imageCardTypes';
import i18n from 'i18n';

export interface Props extends ImageCardPayload {
  isEditing: boolean;
  isInEditor?: boolean;
  handleFileSelect: (fileRef: File) => void;
  fileSizeError: boolean;
  progress?: number;
}

interface State {
  isLightboxOpen: boolean;
}

export default class ImageCardLayout extends React.PureComponent<Props, State> {
  static defaultProps: Props = {
    payload: {
      desktopSrc: '',
      mobileSrc: '',
      originalSrc: '',
      filename: '',
      originalDimensions: {
        width: 0,
        height: 0
      }
    },
    handleFileSelect: _noop,
    fileSizeError: false,
    isEditing: false,
    progress: 0
  };

  state = {
    isLightboxOpen: false
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleFileSelect } = this.props;
    if (_has(window, 'File') && _has(window, 'FileReader')) {
      const selectedFile = _get(e.target, 'files.0');

      handleFileSelect(selectedFile);
    }
  };

  handleLightboxOpen = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { isInEditor } = this.props;
    if (!isInEditor) {
      this.setState({ isLightboxOpen: true });
    }
  };

  handleLightboxClose = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({ isLightboxOpen: false });
  };

  render() {
    const {
      payload,
      payload: { desktopSrc, filename, originalSrc, localImageSrc },
      isEditing,
      fileSizeError,
      isInEditor,
      progress
    } = this.props;
    const { isLightboxOpen } = this.state;

    const localOrExternalSrc = desktopSrc || localImageSrc;

    if (!localOrExternalSrc && !isEditing) {
      // don't render blank image cards in public content
      return null;
    }

    return (
      <Box
        data-testid="image-card-wrapper"
        position="relative"
        bgColor={fileSizeError ? 'red-lighter' : 'silver-lightest'}
        textAlign="center"
        p={2}
        sx={{
          '> img': {
            bgColor: 'silver-lighter)',
            maxW: 'full',
            maxH: '45rem'
          }
        }}
      >
        {isEditing && localImageSrc && <ProgressOverlay progress={progress} />}

        {isEditing && (
          <FileInputContainer
            handleChange={this.handleChange}
            accept={_values(SupportedTypes).join(', ')}
          />
        )}

        {localOrExternalSrc && (
          <chakra.button
            onClick={this.handleLightboxOpen}
            disabled={!!isInEditor}
            display="block"
            bg="transparent"
            textAlign="center"
            p={0}
            w="full"
          >
            <ResponsiveImage payload={payload} localImageSrc={localImageSrc} />
          </chakra.button>
        )}

        {!localOrExternalSrc && <ImagePlaceholder />}

        {fileSizeError && (
          <ToastPortal
            type="error"
            message={i18n.t('error.uploadedImagesSmaller', {
              size: formatBytes(FILE_SIZE_LIMIT)
            })}
          />
        )}

        {isLightboxOpen && (
          <ImageLightboxPortal
            handleClose={this.handleLightboxClose}
            filename={filename}
            src={originalSrc}
          />
        )}
      </Box>
    );
  }
}
