export const Checkbox = {
  sizes: {
    'sm-md': {
      control: { w: 6, h: 6 },
      label: { fontSize: 'sm', lineHeight: '1.5rem' },
      icon: { fontSize: '0.625rem' }
    }
  },
  defaultProps: {
    size: 'sm-md'
  }
};
