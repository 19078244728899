import gql from 'app/models/helpers/gql';

import constants from './activityConstants';

const { ALL_RESULTS } = constants;

interface ActivityParams {
  offset: number;
  limit: number;
  userUid: string;
}

export function getQuestions({
  limit = ALL_RESULTS,
  userUid = '',
  offset = 0
}: ActivityParams): Promise<any> {
  const request = {
    query: `query UserActivityQuestions(
      $limit: Int!,
      $userUid: String!,
      $offset: Int!
    ) {
      userActivityQuestions(
        offset: $offset
        limit: $limit
        userUid: $userUid
      )  {
        title
        createdAt
        id
        score
        summary
        commentCount
        answerCount
      }
    }
    `,
    variables: {
      limit,
      userUid,
      offset
    }
  };

  return gql(request).then((res) => res.data.userActivityQuestions);
}

export function getAnswers({
  limit = ALL_RESULTS,
  userUid = '',
  offset = 0
}: ActivityParams): Promise<any> {
  const request = {
    query: `query UserActivityAnswers(
      $limit: Int!,
      $userUid: String!,
      $offset: Int!
    ) {
      userActivityAnswers(
        offset: $offset
        limit: $limit
        userUid: $userUid
      )  {
        id
        questionId
        question {
          title
        }
        score
        summary
        createdAt
        acceptedAt
        commentCount
      }
    }
    `,
    variables: {
      limit,
      userUid,
      offset
    }
  };

  return gql(request).then((res) => res.data.userActivityAnswers);
}

export function getUserActivityStats(userId: string) {
  const request = {
    query: `query UserActivity(
      $userId: String!
    ) {
    userActivity(
        userUid: $userId
    ) {
      upVotesReceived
      upVotesGiven
      answersAccepted
      answersPosted
    }}`,
    variables: {
      userId
    }
  };

  return gql(request).then((res) => res.data.userActivity);
}
