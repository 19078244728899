const me = `{
  approvedActions,
  user {
    id,
    displayName,
    profileImageUrl
  },
  enrolledNanodegrees,
  reviewedNanodegrees,
  certifications
}`;

export default {
  Me: me
};
