import { IAnswer } from 'app/models/entities/answers/answersEntitiesTypes';
import { IComment } from 'app/models/entities/comments/commentsEntitiesTypes';
import { IQuestion } from 'app/models/entities/questions/questionsEntitiesTypes';

export type IPostDetail = IQuestion | IAnswer | IComment;

export enum CommentFormType {
  COMMENT = 'COMMENT',
  NEED_MORE_HELP = 'NEED_MORE_HELP',
  REPLY_TO_MENTOR_COMMENT = 'REPLY_TO_MENTOR_COMMENT'
}
