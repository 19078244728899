import { Box } from '@chakra-ui/react';
import React from 'react';

import ActionModal from 'app/components/modals/ActionModal';
import ActionModalButtons from 'app/components/modals/ActionModal/ActionModalButtons';
import PostBodyContentContainer from 'app/containers/PostBodyContentContainer';
import chatIllustration from 'assets/images/illustration-chat.svg';

import i18n from 'i18n';

export interface Props {
  handleToggleClose: () => void;
  handleMoveCommentToAnswer: () => void;
  postId: string;
}

export default class CommentToAnswerConfirmationModal extends React.PureComponent<
  Props,
  {}
> {
  render() {
    const { handleToggleClose, handleMoveCommentToAnswer, postId } = this.props;
    return (
      <ActionModal
        headerIcon={
          <img
            src={chatIllustration}
            width="70"
            alt={i18n.t('post.continueEditingPost')}
          />
        }
        theme="blue"
        onCancel={handleToggleClose}
        headerText={i18n.t('comment.changeCommentToAnswer')}
      >
        <Box
          borderRadius="base"
          border="1px solid"
          borderColor="silver-lighter"
          bgColor="silver-lightest"
          mb={8}
          p={4}
          overflow="hidden"
          position="relative"
          maxH="18.125rem"
        >
          <PostBodyContentContainer postId={postId} />
        </Box>
        <ActionModalButtons
          theme="blue"
          primary={{
            text: i18n.t('comment.changeToAnswer'),
            callback: handleMoveCommentToAnswer
          }}
          secondary={{
            text: i18n.t('common.cancel'),
            callback: handleToggleClose
          }}
        />
      </ActionModal>
    );
  }
}
