import {
  all,
  call,
  delay,
  put,
  race,
  take,
  takeLatest,
  debounce
} from 'redux-saga/effects';
import { handleFavicon } from 'app/models/helpers/faviconHelper';
import { makeSagaLifecycle } from 'app/models/helpers/sagaHelper';
import { actionCreators, actionTypes } from './unreadsActions';
import * as requests from './unreadsExternalRequests';

export const POLLING_INTERVAL = 60 * 1000;
export const UNREAD_DEBOUNCE_LIMIT = 1500;

export function* getUnreads({ payload }) {
  const result = yield call(requests.getUnreads, payload);

  handleFavicon(result);

  yield put({
    type: actionTypes.FETCH_UNREADS_FULFILLED,
    payload: { result }
  });
}

export function* markAsRead({ payload }) {
  const result = yield call(requests.markAsRead, payload.postIDs);
  yield put({
    type: actionTypes.MARK_AS_READ_FULFILLED,
    payload: { result, id: payload }
  });
}

export function* pollUnreads(pollingInterval = POLLING_INTERVAL) {
  while (true) {
    try {
      yield put(actionCreators.getUnreads());
      yield delay(pollingInterval);
    } catch (error) {
      return;
    }
  }
}

export function* watchGetUnreads() {
  yield takeLatest(
    actionTypes.FETCH_UNREADS_START,
    makeSagaLifecycle(getUnreads, 'FETCH_UNREADS', actionTypes)
  );
}

export function* watchMarkAsRead() {
  yield debounce(
    UNREAD_DEBOUNCE_LIMIT,
    actionTypes.MARK_AS_READ_START,
    makeSagaLifecycle(markAsRead, 'MARK_AS_READ', actionTypes)
  );
}

export function* watchPollUnreads() {
  while (true) {
    yield take(actionTypes.POLL_UNREADS_START);
    yield race({
      poll: call(pollUnreads),
      cancel: take(actionTypes.POLL_UNREADS_CANCEL)
    });
  }
}

export default function* moduleRoot() {
  yield all([watchGetUnreads(), watchMarkAsRead(), watchPollUnreads()]);
}
