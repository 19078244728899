import { extendTheme } from '@chakra-ui/react';
import { theme as veritasTheme } from '@udacity/chakra-veritas-theme';

import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { components } from './components';
import { globalStyles } from './global-styles';
import { shadows } from './shadows';

export const theme = extendTheme(veritasTheme, {
  styles: {
    global: globalStyles
  },
  breakpoints,
  colors,
  components,
  shadows
});
