import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';

export const actionClass = 'QUESTION_QUEUE';

export const actionTypes = {
  ...createActionType('FETCH_ASSIGNMENTS', actionClass),
  ...createActionType('UNASSIGN_QUESTION', actionClass),
  ...createActionType('FETCH_QUESTION_STATUS', actionClass),
  ...createActionType('POST_QUESTION_REQUEUE', actionClass),
  ...createActionType('MARK_AT_RISK', actionClass)
};

export const actionCreators = {
  fetchAssignments() {
    return createAction(actionTypes.FETCH_ASSIGNMENTS_START);
  },
  unassignQuestion(questionId: string, reason: string) {
    return createAction(actionTypes.UNASSIGN_QUESTION_START, {
      questionId,
      reason
    });
  },
  fetchQuestionStatus(questionId: string) {
    return createAction(actionTypes.FETCH_QUESTION_STATUS_START, questionId);
  },
  postQuestionRequeue(questionId: string) {
    return createAction(actionTypes.POST_QUESTION_REQUEUE_START, questionId);
  },
  markAtRisk(questionId: string) {
    return createAction(actionTypes.MARK_AT_RISK_START, questionId);
  }
};
