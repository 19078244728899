/*
 * Some simple helper functions to standardize
 * insertion and reading from graphql database.
 *
 * encodeURIComponent() is required to prevent json
 * double quotes from conflicting with GraphQL double
 * quotes.
 */

export function encodeMobiledoc(content) {
  return encodeURIComponent(JSON.stringify(content));
}

export function decodeMobiledoc(content) {
  return JSON.parse(decodeURIComponent(content));
}
