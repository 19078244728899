import { combineReducers } from 'redux-immutable'; // immutablejs equivalent of Redux's combineReducers

import activityApi from 'app/models/activity/activityApiReducer';
import activity from 'app/models/activity/activityReducer';
import alerts from 'app/models/alerts/alertsReducer';
import entities from 'app/models/entities/entitiesReducer';
import filesApi from 'app/models/files/filesApiReducer';
import files from 'app/models/files/filesReducer';
import nanodegrees from 'app/models/nanodegree/nanodegreeReducer';
import nanodegreeApi from 'app/models/nanodegree/nanodegreeApiReducer';
import postsApi from 'app/models/posts/postsApiReducer';
import posts from 'app/models/posts/postsReducer';
import questionQueue from 'app/models/QuestionQueue/questionQueueReducer';
import searchApi from 'app/models/search/searchApiReducer';
import search from 'app/models/search/searchReducer';
import sessionApi from 'app/models/session/sessionApiReducer';
import session from 'app/models/session/sessionReducer';
import ui from 'app/models/ui/uiReducer';
import unreads from 'app/models/unreads/unreadsReducer';

export default combineReducers({
  activity,
  activityApi,
  alerts,
  entities,
  files,
  filesApi,
  nanodegrees,
  nanodegreeApi,
  posts,
  postsApi,
  questionQueue,
  search,
  searchApi,
  session,
  sessionApi,
  ui,
  unreads
});
