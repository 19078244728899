import _find from 'lodash/find';
import _get from 'lodash/get';
import _invert from 'lodash/invert';
import React from 'react';
import { connect } from 'react-redux';

import { actionCreators as filesActions } from 'app/models/files/filesActions';
import { FILE_SIZE_LIMIT } from 'app/models/files/filesConstants';
import { readLocalFile } from 'app/models/files/filesHelpers';
import { selectFileStateByIdentifier } from 'app/models/files/filesSelectors';
import {
  FileUploadState,
  RenderLocalArgs,
  SupportedTypes
} from 'app/models/files/filesTypes';
import { isFulfilled } from 'app/models/helpers/apiStatusHelpers';

import ImageCardLayout from '../ImageCardLayout';
import { ImageCardPayload } from '../imageCardTypes';

interface OwnProps extends ImageCardPayload {
  handleSave: (payload: ImageCardPayload['payload']) => void;
  isEditing?: boolean;
  isInEditor?: boolean;
  handleFileSelect: (fileRef: File) => void;
  renderLocalFile: (file: RenderLocalArgs) => void;
}

interface StateProps {
  fileState: FileUploadState;
}

interface DispatchProps {
  imageUploadStart: (fileRef: File) => void;
}

export type Props = StateProps & OwnProps & DispatchProps;

export interface State {
  fileSizeError: boolean;
}

const mapStateToProps = (state, ownProps: OwnProps): StateProps => ({
  fileState: selectFileStateByIdentifier(
    state,
    ownProps.payload.fileIdentifierString
  )
});

const mapDispatchToProps: DispatchProps = {
  imageUploadStart: filesActions.imageUploadStart
};

export class ImageCardContainer extends React.Component<Props, State> {
  state = {
    fileSizeError: false
  };

  componentDidUpdate(prevProps: Props) {
    const { fileState } = this.props;
    const { fileState: prevFileState } = prevProps;

    if (fileState !== prevFileState) {
      if (isFulfilled(fileState)) {
        this.handleSave(fileState);
      }
    }
  }

  handleFileSelect = (fileRef: File) => {
    if (!fileRef) {
      return;
    }

    const { imageUploadStart, handleFileSelect, renderLocalFile } = this.props;

    const { type, size } = fileRef;

    if (type in _invert(SupportedTypes)) {
      if (size > FILE_SIZE_LIMIT) {
        this.setState({ fileSizeError: true });
      } else {
        handleFileSelect(fileRef);
        readLocalFile(fileRef, renderLocalFile);
        imageUploadStart(fileRef);
        if (this.state.fileSizeError) {
          this.setState({ fileSizeError: false });
        }
      }
    }
  };

  handleSave = (fileState: FileUploadState) => {
    const { handleSave, payload } = this.props;

    const { attachments } = fileState.toJS();

    const desktopImage = _find(attachments, { label: 'desktop' });
    const mobileImage = _find(attachments, { label: 'mobile' });
    const originalImage = _find(attachments, { label: 'original' });

    const newPayload: ImageCardPayload['payload'] = {
      filename: payload.filename,
      originalDimensions: payload.originalDimensions,
      desktopSrc: _get(desktopImage, 'url'),
      mobileSrc: _get(mobileImage, 'url'),
      originalSrc: _get(originalImage, 'url')
    };

    handleSave(newPayload);
  };

  render() {
    const { isEditing, isInEditor, payload, fileState } = this.props;
    const { fileSizeError } = this.state;

    return (
      <ImageCardLayout
        handleFileSelect={this.handleFileSelect}
        isEditing={!!isEditing}
        isInEditor={!!isInEditor}
        payload={payload}
        fileSizeError={fileSizeError}
        progress={fileState.get('progress', 0)}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ImageCardContainer);
