import {
  all,
  call,
  put,
  select,
  takeLatest,
  throttle
} from 'redux-saga/effects';

import { actionCreators as alertsActions } from 'app/models/alerts/alertsActions';
import { createErrorAction } from 'app/models/helpers/actionHelper';
import { IAction, makeSagaLifecycle } from 'app/models/helpers/sagaHelper';
import i18n from 'i18n';

import { actionTypes } from './postsActions';
import * as requests from './postsExternalRequests';
import {
  normalizeAdminDeleteReasons,
  normalizeAnswerModel,
  normalizeCommentModel,
  normalizeQuestionModel
} from './postsNormalizers';
import { selectSiblingAnswerIds } from './postsSelectors';

const VOTE_DELAY = 500;

export function* moveCommentToAnswer({
  payload: { commentId, parentId }
}: IAction) {
  yield put({
    type: actionTypes.MOVE_COMMENT_TO_ANSWER_PENDING
  });

  const answer = yield call(requests.moveCommentToAnswer, commentId);

  const normalizedAnswer = normalizeAnswerModel(answer);

  // set deepLinkId in url hash to scroll to new answer
  window.location.hash = answer.id;

  // remove old comment entity
  yield put({
    type: actionTypes.ADMIN_DELETE_POST_FULFILLED,
    payload: { postId: commentId, parentId }
  });

  // insert new answer entity
  yield put({
    type: actionTypes.CREATE_ANSWER_FULFILLED,
    payload: {
      questionId: answer.parentId,
      answerId: normalizedAnswer.result,
      entities: normalizedAnswer.entities
    }
  });

  // update api state to fulfilled
  yield put({ type: actionTypes.MOVE_COMMENT_TO_ANSWER_FULFILLED });
}

export function* getQuestion(action: IAction) {
  const { id } = action.payload;

  const question = yield call(requests.getQuestion, id);
  const normalizedQuestion = normalizeQuestionModel(question);

  yield put({
    type: actionTypes.FETCH_QUESTION_FULFILLED,
    payload: normalizedQuestion
  });
}

export function* getAnswersByQuestionId(action: IAction) {
  const { id } = action.payload;

  const answersAndComments = yield call(requests.getAnswersByQuestionId, id);

  const normalizedAnswersAndComments = normalizeQuestionModel(
    answersAndComments
  );

  yield put({
    type: actionTypes.FETCH_ANSWERS_BY_QUESTION_ID_FULFILLED,
    payload: normalizedAnswersAndComments
  });
}

export function* createQuestion(action: IAction) {
  try {
    const question = yield call(
      requests.createQuestion,
      action.payload as requests.MutateQuestionArgs
    );

    const normalizedQuestion = normalizeQuestionModel(question);

    yield put({
      type: actionTypes.CREATE_QUESTION_FULFILLED,
      payload: normalizedQuestion
    });

    yield put(
      alertsActions.addAlert('success', i18n.t('saga.questionCreated'))
    );
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.CREATE_QUESTION_REJECTED, error as Error)
    );
  }
}

export function* updateQuestion(action) {
  try {
    const {
      payload: { questionId, question }
    } = action;

    const updatedQuestion = yield call(
      requests.updateQuestion,
      questionId,
      question
    );

    const normalizedQuestion = normalizeQuestionModel(updatedQuestion);

    yield put({
      type: actionTypes.UPDATE_QUESTION_FULFILLED,
      payload: normalizedQuestion
    });

    yield put(
      alertsActions.addAlert('success', i18n.t('saga.questionUpdated'))
    );
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.UPDATE_QUESTION_REJECTED, error as Error)
    );
  }
}

export function* deletePost(action: IAction) {
  try {
    const {
      payload: { postId, parentId, postType }
    } = action;

    yield call(requests.archivePost, postId);

    yield put({
      type: actionTypes.DELETE_POST_FULFILLED,
      payload: { postId, parentId, postType }
    });

    yield put(alertsActions.addAlert('success', i18n.t('saga.postDeleted')));
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.DELETE_POST_REJECTED, error as Error)
    );
  }
}

// unarchives and fetches post
export function* unarchivePost(action: IAction) {
  const {
    payload: { postId }
  } = action;

  yield call(requests.unarchivePost, postId);

  yield put({
    type: actionTypes.FETCH_QUESTION_START,
    payload: { id: postId }
  });

  yield put({
    type: actionTypes.UNARCHIVE_POST_FULFILLED,
    payload: null
  });

  yield put(
    alertsActions.addAlert('success', i18n.t('saga.questionUnarchived'))
  );
}

export function* upvotePost(action: IAction) {
  try {
    const post = yield call(requests.upvotePost, action.payload.postId);

    yield put({
      type: actionTypes.UPVOTE_POST_FULFILLED,
      payload: post
    });
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.UPVOTE_POST_REJECTED, error as Error)
    );
  }
}

export function* downvotePost(action: IAction) {
  try {
    const post = yield call(requests.downvotePost, action.payload.postId);

    yield put({
      type: actionTypes.DOWNVOTE_POST_FULFILLED,
      payload: post
    });
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.DOWNVOTE_POST_REJECTED, error as Error)
    );
  }
}

export function* clearvotePost(action: IAction) {
  try {
    const post = yield call(requests.clearvotePost, action.payload.postId);

    yield put({
      type: actionTypes.CLEARVOTE_POST_FULFILLED,
      payload: post
    });
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.CLEARVOTE_POST_REJECTED, error as Error)
    );
  }
}

function* votePost(action: IAction) {
  switch (action.type) {
    case actionTypes.UPVOTE_POST_PENDING:
      return yield upvotePost(action);
    case actionTypes.DOWNVOTE_POST_PENDING:
      return yield downvotePost(action);
    default:
      return yield clearvotePost(action);
  }
}

export function* createAnswer(action: IAction) {
  const answer = yield call(
    requests.createAnswer,
    action.payload as requests.CreateAnswerArgs
  );

  const normalizedAnswer = normalizeAnswerModel(answer);

  yield put({
    type: actionTypes.CREATE_ANSWER_FULFILLED,
    payload: {
      questionId: action.payload.questionId,
      answerId: normalizedAnswer.result,
      entities: normalizedAnswer.entities
    }
  });

  yield put(alertsActions.addAlert('success', i18n.t('saga.answerCreated')));
}

export function* updateAnswer(action: IAction) {
  const { answer, answerId, parentId } = action.payload;

  const answerResponse = yield call(requests.updateAnswer, answerId, answer);

  const normalizedAnswer = normalizeAnswerModel(answerResponse);

  yield put({
    type: actionTypes.UPDATE_ANSWER_FULFILLED,
    payload: {
      answerId,
      questionId: parentId,
      entities: normalizedAnswer.entities
    }
  });

  yield put(alertsActions.addAlert('success', i18n.t('saga.answerUpdated')));
}

export function* acceptAnswer(action) {
  try {
    const { answerId } = action.payload;
    const answerResponse = yield call(requests.acceptAnswer, answerId);
    yield put({
      type: actionTypes.ACCEPT_ANSWER_FULFILLED,
      payload: answerResponse
    });

    const { parentId } = answerResponse;

    // unaccept all sibling answers locally
    const siblingAnswers = yield select(
      selectSiblingAnswerIds,
      answerResponse.parentId
    );

    const filteredSiblingAnswers = siblingAnswers.filter(
      (id) => id !== answerId
    );

    for (const siblingId of filteredSiblingAnswers.toJS()) {
      yield put({
        type: actionTypes.UNACCEPT_ANSWER_FULFILLED,
        payload: {
          parentId,
          id: siblingId
        }
      });
    }
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.ACCEPT_ANSWER_REJECTED, error as Error)
    );
  }
}

export function* unacceptAnswer(action) {
  try {
    const { answerId } = action.payload;
    const answerResponse = yield call(requests.unacceptAnswer, answerId);
    yield put({
      type: actionTypes.UNACCEPT_ANSWER_FULFILLED,
      payload: answerResponse
    });
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.UNACCEPT_ANSWER_REJECTED, error as Error)
    );
  }
}

export function* verifyAnswer(action) {
  try {
    const { answerId } = action.payload;
    const answerResponse = yield call(requests.verifyAnswer, answerId);
    yield put({
      type: actionTypes.VERIFY_ANSWER_FULFILLED,
      payload: answerResponse
    });

    const { parentId } = answerResponse;

    // unverify all sibling answers locally
    const siblingAnswers = yield select(
      selectSiblingAnswerIds,
      answerResponse.parentId
    );

    const filteredSiblingAnswers = siblingAnswers.filter(
      (id) => id !== answerId
    );

    for (const siblingId of filteredSiblingAnswers.toJS()) {
      yield put({
        type: actionTypes.UNVERIFY_ANSWER_FULFILLED,
        payload: {
          parentId,
          id: siblingId
        }
      });
    }
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.VERIFY_ANSWER_REJECTED, error as Error)
    );
  }
}

export function* unverifyAnswer(action) {
  try {
    const { answerId } = action.payload;
    const answerResponse = yield call(requests.unverifyAnswer, answerId);
    yield put({
      type: actionTypes.UNVERIFY_ANSWER_FULFILLED,
      payload: answerResponse
    });
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.UNVERIFY_ANSWER_REJECTED, error as Error)
    );
  }
}

export function* createComment(action: IAction) {
  const { parentId, comment } = action.payload;

  const commentResponse = yield call(requests.createComment, parentId, comment);

  const normalizedComment = normalizeCommentModel(commentResponse);

  yield put({
    type: actionTypes.CREATE_COMMENT_FULFILLED,
    payload: {
      parentId,
      commentId: normalizedComment.result,
      entities: normalizedComment.entities
    }
  });

  yield put(alertsActions.addAlert('success', i18n.t('saga.commentCreated')));
}

export function* updateComment(action: IAction) {
  const { comment, parentId, commentId } = action.payload;

  const commentResponse = yield call(
    requests.updateComment,
    commentId,
    comment
  );

  const normalizedComment = normalizeCommentModel(commentResponse);

  yield put({
    type: actionTypes.UPDATE_COMMENT_FULFILLED,
    payload: {
      commentId,
      parentId,
      entities: normalizedComment.entities
    }
  });

  yield put(alertsActions.addAlert('success', i18n.t('saga.commentUpdated')));
}

export function* getAdminDeletePostReasons() {
  const reasons = yield call(requests.adminArchivePostReasons);
  const normalizedReasons = normalizeAdminDeleteReasons(reasons);
  yield put({
    type: actionTypes.FETCH_MODERATE_POST_REASONS_FULFILLED,
    payload: normalizedReasons
  });
}

export function* adminDeletePost(action: IAction) {
  const {
    payload: { postId, parentId, reasonId }
  } = action;

  yield call(requests.adminArchivePost, postId, reasonId);

  yield put({
    type: actionTypes.ADMIN_DELETE_POST_FULFILLED,
    payload: { postId, parentId }
  });

  yield put(alertsActions.addAlert('success', i18n.t('saga.postDeleted')));
}

export function* reportPost(action: IAction) {
  const {
    payload: { postId, reasonId }
  } = action;

  yield call(requests.reportPost, postId, reasonId);

  yield put({
    type: actionTypes.REPORT_POST_FULFILLED,
    payload: { postId }
  });

  yield put(alertsActions.addAlert('success', i18n.t('saga.postReported')));
}

export function* watchMoveCommentToAnswer() {
  yield takeLatest(
    actionTypes.MOVE_COMMENT_TO_ANSWER_START,
    makeSagaLifecycle(
      moveCommentToAnswer,
      'MOVE_COMMENT_TO_ANSWER',
      actionTypes
    )
  );
}

export function* watchCreateQuestion() {
  yield takeLatest(actionTypes.CREATE_QUESTION_PENDING, createQuestion);
}

export function* watchGetQuestion() {
  yield takeLatest(
    actionTypes.FETCH_QUESTION_START,
    makeSagaLifecycle(getQuestion, 'FETCH_QUESTION', actionTypes)
  );
}

export function* watchGetAnswersByQuestionId() {
  yield takeLatest(
    actionTypes.FETCH_ANSWERS_BY_QUESTION_ID_START,
    makeSagaLifecycle(
      getAnswersByQuestionId,
      'FETCH_ANSWERS_BY_QUESTION_ID',
      actionTypes
    )
  );
}

export function* watchDeletePost() {
  yield takeLatest(actionTypes.DELETE_POST_PENDING, deletePost);
}

export function* watchVotePost() {
  yield throttle(
    VOTE_DELAY,
    [
      actionTypes.UPVOTE_POST_PENDING,
      actionTypes.DOWNVOTE_POST_PENDING,
      actionTypes.CLEARVOTE_POST_PENDING
    ],
    votePost
  );
}

export function* watchCreateAnswer() {
  yield throttle(
    2000,
    actionTypes.CREATE_ANSWER_START,
    makeSagaLifecycle(createAnswer, 'CREATE_ANSWER', actionTypes)
  );
}

export function* watchUpdateAnswer() {
  yield throttle(
    2000,
    actionTypes.UPDATE_ANSWER_START,
    makeSagaLifecycle(updateAnswer, 'UPDATE_ANSWER', actionTypes)
  );
}

export function* watchAcceptAnswer() {
  yield throttle(500, actionTypes.ACCEPT_ANSWER_PENDING, acceptAnswer);
}

export function* watchUnacceptAnswer() {
  yield throttle(500, actionTypes.UNACCEPT_ANSWER_PENDING, unacceptAnswer);
}

export function* watchVerifyAnswer() {
  yield throttle(500, actionTypes.VERIFY_ANSWER_PENDING, verifyAnswer);
}

export function* watchUnverifyAnswer() {
  yield throttle(500, actionTypes.UNVERIFY_ANSWER_PENDING, unverifyAnswer);
}

export function* watchCreateComment() {
  yield throttle(
    2000,
    actionTypes.CREATE_COMMENT_START,
    makeSagaLifecycle(createComment, 'CREATE_COMMENT', actionTypes)
  );
}

export function* watchUpdateComment() {
  yield throttle(
    2000,
    actionTypes.UPDATE_COMMENT_START,
    makeSagaLifecycle(updateComment, 'UPDATE_COMMENT', actionTypes)
  );
}

export function* watchUpdateQuestion() {
  yield throttle(2000, actionTypes.UPDATE_QUESTION_PENDING, updateQuestion);
}

export function* watchGetAdminDeletePostReasons() {
  yield takeLatest(
    actionTypes.FETCH_MODERATE_POST_REASONS_START,
    makeSagaLifecycle(
      getAdminDeletePostReasons,
      'FETCH_MODERATE_POST_REASONS',
      actionTypes
    )
  );
}

export function* watchAdminDeletePost() {
  yield takeLatest(
    actionTypes.ADMIN_DELETE_POST_START,
    makeSagaLifecycle(adminDeletePost, 'ADMIN_DELETE_POST', actionTypes)
  );
}

export function* watchReportPost() {
  yield takeLatest(
    actionTypes.REPORT_POST_START,
    makeSagaLifecycle(reportPost, 'REPORT_POST', actionTypes)
  );
}

export function* watchUnarchivePost() {
  yield takeLatest(
    actionTypes.UNARCHIVE_POST_START,
    makeSagaLifecycle(unarchivePost, 'UNARCHIVE_POST', actionTypes)
  );
}

export default function* moduleRoot() {
  yield all([
    watchGetQuestion(),
    watchGetAnswersByQuestionId(),
    watchCreateQuestion(),
    watchUpdateQuestion(),
    watchDeletePost(),
    watchVotePost(),
    watchCreateAnswer(),
    watchUpdateAnswer(),
    watchAcceptAnswer(),
    watchUnacceptAnswer(),
    watchVerifyAnswer(),
    watchUnverifyAnswer(),
    watchCreateComment(),
    watchUpdateComment(),
    watchGetAdminDeletePostReasons(),
    watchAdminDeletePost(),
    watchReportPost(),
    watchUnarchivePost(),
    watchMoveCommentToAnswer()
  ]);
}
