import { Map } from 'immutable';

type State = Map<string, string | {} | boolean | null>;

export const mergeEntities = (state: State, entities: {}): State => {
  if (entities) {
    return state.mergeDeep({ byId: entities });
  }
  return state;
};
