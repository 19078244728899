import { combineReducers } from 'redux-immutable';

import {
  makeApiReducer,
  normalizeApiActions
} from 'app/models/helpers/apiReducerHelpers';

import { actionClass } from './activityActions';

const getQuestionsApiActions = normalizeApiActions(
  'FETCH_QUESTIONS',
  actionClass
);

const getAnswersApiActions = normalizeApiActions('FETCH_ANSWERS', actionClass);

const getUserPostStatsApiActions = normalizeApiActions(
  'FETCH_USER_ACTIVITY_STATS',
  actionClass
);

const getUserActivityStatsApiActions = normalizeApiActions(
  'FETCH_USER_ACTIVITY_STATS',
  actionClass
);

export default combineReducers({
  getQuestions: makeApiReducer(getQuestionsApiActions),
  getAnswers: makeApiReducer(getAnswersApiActions),
  getUserPostStats: makeApiReducer(getUserPostStatsApiActions),
  getUserActivityStats: makeApiReducer(getUserActivityStatsApiActions)
});
