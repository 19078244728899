import { FormHelperText } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

export interface Props {
  text: string;
}

export interface State {
  value: string;
  caretRange: { start: number; end: number };
}

export default class FormInputHint extends React.PureComponent<Props, State> {
  render() {
    const { text } = this.props;

    if (_.isEmpty(text)) {
      return null;
    }

    return (
      <FormHelperText
        data-ref="hint"
        mt={3}
        fontSize="sm"
        lineHeight="1rem"
        color="silver-dark"
      >
        {text}
      </FormHelperText>
    );
  }
}
