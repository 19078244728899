import { Box } from '@chakra-ui/react';
import _compact from 'lodash/compact';
import React from 'react';

import { unescapeQuotes } from 'app/models/helpers/escapeQuotes';
import constants from 'app/models/search/searchConstants';

const { HIGHLIGHT_CLOSE_TAG, HIGHLIGHT_OPEN_TAG } = constants;

export interface Props {
  text: string;
  className?: string;
}

export const getHighlightParts = (text: string) => {
  return text
    .split(HIGHLIGHT_CLOSE_TAG)
    .reduce((parts: Array<string | JSX.Element>, part: string) => {
      const [plainText, highlight] = part.split(HIGHLIGHT_OPEN_TAG);
      const newTextArr = [
        ...parts,
        plainText,
        highlight && <strong>{highlight}</strong>
      ];
      return _compact(newTextArr);
    }, []);
};

export default class HighlightedText extends React.PureComponent<Props> {
  render() {
    const { text } = this.props;

    const parts = getHighlightParts(unescapeQuotes(text));

    return (
      <Box
        as="span"
        sx={{
          '> strong': {
            fontWeight: 'semibold',
            bgColor: 'orange-lighter'
          }
        }}
      >
        {parts.map((part, index) => {
          return <React.Fragment key={index}>{part}</React.Fragment>;
        })}
      </Box>
    );
  }
}
