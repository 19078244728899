import { Button } from './button';
import { Checkbox } from './checkbox';
import { Link } from './link';
import { Modal } from './modal';

export const components = {
  Button,
  Checkbox,
  Link,
  Modal
};
