import React from 'react';
import { match as IMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'app/scenes/NotFound';
import permissions from 'app/services/permissionsService';

import EditQuestionScene from './EditQuestionScene';
import NewQuestionScene from './NewQuestionScene';
import ViewQuestionScene from './ViewQuestionScene';

export interface Props {
  match: IMatch<{}>;
}

export default class Questions extends React.PureComponent<Props> {
  render() {
    const { match } = this.props;

    const routes = {
      newQuestion: `${match.url}/new`,
      viewQuestion: `${match.url}/:questionId`,
      editQuestion: `${match.url}/:questionId/edit`
    };

    const canAsk = permissions.canAsk();

    return (
      <Switch>
        {canAsk && (
          <Route exact path={routes.newQuestion} component={NewQuestionScene} />
        )}
        <Route exact path={routes.viewQuestion} component={ViewQuestionScene} />
        <Route exact path={routes.editQuestion} component={EditQuestionScene} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
