import { Map } from 'immutable';
import _noop from 'lodash/noop';
import _partial from 'lodash/partial';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import QuestionForm from 'app/components/QuestionForm';
import IncompletePostModal from 'app/components/QuestionForm/IncompletePostModal';
import { IQuestion } from 'app/models/entities/questions/questionsEntitiesTypes';
import { isFulfilled, isPending } from 'app/models/helpers/apiStatusHelpers';
import { Nanodegree } from 'app/models/nanodegree/nanodegreeTypes';

import {
  FormData,
  QuestionInput,
  buildFormData,
  getErrors
} from '../questionFormContainerHelpers';

export interface Props extends RouteComponentProps<any> {
  questionId: string;
  questionData?: IQuestion;
  apiStatus: Map<string, string>;
  questionMutation: (formData: FormData, questionId: string) => void;
  pageTitle: string;
  isImageUploadPending: boolean;
}

interface State {
  errors: any[];
  warnings: any[];
  warningModalOpen: boolean;
  selectedNanodegree?: Nanodegree;
}

export class QuestionFormContainer extends React.PureComponent<Props, State> {
  delayedQuestionMutation: () => void = _noop;

  state: State = {
    errors: [],
    warnings: [],
    warningModalOpen: false
  };

  componentDidUpdate() {
    this.checkForNewQuestionAndRedirect();
  }

  validate = (formInput: QuestionInput) => {
    const { errors, warnings } = getErrors(formInput);
    this.setState({ errors, warnings });
    return { errors, warnings };
  };

  submit = (formInput: QuestionInput) => {
    const { errors, warnings } = this.validate(formInput);

    if (errors.length === 0) {
      const formData = buildFormData(formInput);

      this.delayedQuestionMutation = _partial(
        this.dispatchQuestionMutation,
        formData
      );

      if (warnings.length === 0) {
        this.delayedQuestionMutation();
      } else {
        this.setState({ warningModalOpen: true });
      }
    }
  };

  closeWarningModal = () => {
    this.setState({ warningModalOpen: false });
  };

  dispatchQuestionMutation = (formData: FormData) => {
    const { questionMutation, questionId } = this.props;

    questionMutation(formData, questionId);
  };

  checkForNewQuestionAndRedirect() {
    const { questionId, history, apiStatus } = this.props;

    if (isFulfilled(apiStatus) && questionId) {
      history.push(`/questions/${questionId}`);
    }
  }

  render() {
    const {
      apiStatus,
      pageTitle,
      questionData,
      isImageUploadPending
    } = this.props;
    const { errors, warningModalOpen } = this.state;

    return (
      <>
        {warningModalOpen && (
          <IncompletePostModal
            isQuestionMutationPending={isPending(apiStatus)}
            onConfirm={this.delayedQuestionMutation}
            onCancel={this.closeWarningModal}
          />
        )}
        <QuestionForm
          handleSubmit={this.submit}
          validate={this.validate}
          errors={errors}
          apiStatus={apiStatus}
          pageTitle={pageTitle}
          questionData={questionData}
          isImageUploadPending={isImageUploadPending}
        />
      </>
    );
  }
}

export default withRouter(QuestionFormContainer);
