import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import UserActivityStats from 'app/components/UserActivityStats';
import { actionCreators as activityActions } from 'app/models/activity/activityActions';
import {
  selectApiStatus,
  selectUserActivityStats
} from 'app/models/activity/activitySelectors';
import { isFulfilled } from 'app/models/helpers/apiStatusHelpers';
import { selectUser } from 'app/models/session/sessionSelectors';

export interface StateProps {
  userActivityStats: Map<any, any>;
  userActivityStatsStatus?: Map<string, string>;
  user: Map<any, any>;
}

export interface DispatchProps {
  getUserActivityStats: (userId: string) => void;
}

export type Props = StateProps & DispatchProps;

const mapStateToProps = (state): StateProps => ({
  userActivityStats: selectUserActivityStats(state),
  userActivityStatsStatus: selectApiStatus(state, 'getUserPostStats'),
  user: selectUser(state)
});

const mapDispatchToProps: DispatchProps = {
  getUserActivityStats: activityActions.getUserActivityStats
};

export class UserActivityStatsContainer extends React.Component<Props> {
  componentDidMount() {
    const { getUserActivityStats, user } = this.props;
    const userId = user.get('id', '');
    getUserActivityStats(userId);
  }

  render() {
    const { userActivityStats, userActivityStatsStatus } = this.props;
    const isStatsFulfilled = isFulfilled(userActivityStatsStatus);

    return (
      <UserActivityStats
        userActivityStats={userActivityStats.toJS()}
        isStatsFulfilled={isStatsFulfilled}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(UserActivityStatsContainer);
