import { Box, Button, Grid, Text } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';
import { Trans } from 'react-i18next';

import i18n from 'i18n';

export interface Props {
  previousPage: () => void;
  nextPage: () => void;
  shouldShowNext: boolean;
  shouldShowPrevious: boolean;
  page: number;
  totalPages?: number;
  isLoading?: boolean;
  refine?: (value: number) => void;
}

export default class PaginationFooter extends React.PureComponent<Props> {
  componentDidMount() {
    const { refine, page } = this.props;

    if (refine) {
      refine(page);
    }
  }

  componentDidUpdate() {
    const { refine, page } = this.props;

    if (refine) {
      refine(page);
    }
  }

  nextPage = () => {
    const { refine, page, nextPage } = this.props;

    if (refine) {
      refine(page + 1);
    }

    nextPage();
  };

  previousPage = () => {
    const { refine, page, previousPage } = this.props;

    if (refine) {
      refine(page - 1);
    }

    previousPage();
  };

  render() {
    const {
      shouldShowNext,
      shouldShowPrevious,
      page,
      totalPages,
      isLoading
    } = this.props;

    const pageIndicator = totalPages ? (
      <Text fontSize="0.825rem" justifySelf="center" color="slate-light">
        <Trans i18nKey="pagination.pageOfTotal">
          {{ currentPage: page }}
          <Box as="span" mx="0.188rem">
            of
          </Box>
          {{ total: totalPages }}
        </Trans>
      </Text>
    ) : (
      <Text fontSize="0.825rem" justifySelf="center" color="slate-light">
        {page}
      </Text>
    );

    return (
      <Grid
        as="footer"
        gridTemplateColumns="auto 1fr auto"
        alignItems="center"
        borderTop="1px solid"
        borderTopColor="silver-lighter"
        textTransform="uppercase"
        fontWeight="semibold"
        p="0.5rem 0"
        mt={4}
        mb={{ 'sm-md': 6 }}
        borderBottom={{ 'sm-md': '1px solid' }}
        borderBottomColor={{ 'sm-md': 'silver-lighter' }}
      >
        <Button
          variant="minimal"
          onClick={this.previousPage}
          disabled={!shouldShowPrevious || isLoading}
          leftIcon={<ArrowLeftIcon w={6} h={6} />}
          fontSize="0.813rem"
          color="cerulean-dark"
          size="sm"
          padding="0 1rem 0 0.75rem"
        >
          {i18n.t('common.back')}
        </Button>

        {pageIndicator}

        <Button
          variant="minimal"
          onClick={this.nextPage}
          disabled={!shouldShowNext || isLoading}
          rightIcon={<ArrowRightIcon w={6} h={6} />}
          fontSize="0.813rem"
          color="cerulean-dark"
          size="sm"
          padding="0 0.75rem 0 1rem"
        >
          {i18n.t('common.next')}
        </Button>
      </Grid>
    );
  }
}
