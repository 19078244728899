import gql from 'app/models/helpers/gql';

export function getUnreads(questionIDs?: string[]) {
  const request = {
    query: `query PostsToRead($questionIDs: [String]) {
      postsToRead(questionIDs: $questionIDs) {
        posts {
          id
          questionID
          parentID
          type
        }
      }
    }`,
    variables: {
      questionIDs
    }
  };

  return gql(request).then((res) => res.data);
}

export function markAsRead(postIDs?: string[]) {
  const request = {
    query: `mutation MarkRead($postIDs: [String]) {
      markRead(postIDs: $postIDs) {
        success
      }
    }
    `,
    variables: {
      postIDs
    }
  };

  return gql(request).then((res) => res.data);
}
