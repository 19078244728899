import {
  FormControl,
  FormLabel,
  Grid,
  SelectProps,
  Tooltip
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { InfoIcon } from '@udacity/chakra-veritas-icons';

import FormInputHint from 'app/components/FormInputHint';
import Select, { Option } from 'app/components/Select/Select';
import { trackEvent } from 'app/helpers/analyticsHelper';
import { Nanodegree, Project } from 'app/models/nanodegree/nanodegreeTypes';

import i18n from 'i18n';

export interface Props {
  shouldLockPreviouslySelectedProgramAndProject?: boolean;
  handleChange: (selection: {
    nanodegree?: Nanodegree;
    project?: Project;
  }) => void;
  nanodegreeList: Nanodegree[];
  selectedNanodegree?: Nanodegree;
  selectedProject?: Project;
  hasNanodegreeError: boolean;
  hasProjectError: boolean;
  disableNanodegreeSelector: boolean;
  disableProjectSelector: boolean;
  showLessonQuestionTooltip?: boolean;
  size?: SelectProps['size'];
}

const NanodegreeTags: React.FC<Props> = ({
  shouldLockPreviouslySelectedProgramAndProject,
  handleChange,
  nanodegreeList,
  selectedNanodegree,
  selectedProject,
  hasNanodegreeError,
  hasProjectError,
  disableNanodegreeSelector,
  disableProjectSelector,
  showLessonQuestionTooltip,
  size = 'sm'
}) => {
  const nanodegreeSelectOptions = useMemo(
    () => nanodegreeList.map((nd) => ({ value: nd.key, label: nd.title })),
    [nanodegreeList]
  );

  const selectedNanodegreeOption = useMemo(() => {
    return selectedNanodegree
      ? { value: selectedNanodegree.key, label: selectedNanodegree.title }
      : null;
  }, [selectedNanodegree]);

  const projectSelectOptions = useMemo(() => {
    return selectedNanodegree
      ? selectedNanodegree.projects.map((p) => ({
          value: p.reviewsProjectId.toString(),
          label: p.title
        }))
      : [];
  }, [selectedNanodegree]);
  const selectedProjectOption = useMemo(() => {
    return selectedProject
      ? {
          value: selectedProject.reviewsProjectId.toString(),
          label: selectedProject.title
        }
      : null;
  }, [selectedProject]);

  const handleNanodegreeFocus = () => {
    trackEvent('Browse Nanodegree Dropdown');
  };

  const handleProjectFocus = () => {
    trackEvent('Browse Project Dropdown');
  };

  const handleNanodegreeInput = (option: Option | null) => {
    const inputNanodegree = nanodegreeList.find(
      (nd) => nd.key === option?.value
    );
    const selection = { nanodegree: inputNanodegree };

    handleChange(selection);
    trackEvent('Select Nanodegree', option?.value);
  };

  const handleProjectInput = (option: Option | null) => {
    const inputProject = selectedNanodegree
      ? selectedNanodegree.projects.find(
          (p) => p.reviewsProjectId === Number(option?.value)
        )
      : undefined;
    const selection = { nanodegree: selectedNanodegree, project: inputProject };

    handleChange(selection);
    trackEvent('Select Project', option?.value);
  };

  return (
    <Grid
      gridTemplateColumns="repeat(auto-fit, minmax(12.5rem, 1fr))"
      gap={6}
      mb={8}
    >
      <FormControl>
        <FormLabel mb={1}>{i18n.t('nanodegreeTag.program')}</FormLabel>
        <Select
          ariaLabel={i18n.t('nanodegreeTag.selectNanodegree')}
          data-ref="nanodegree-select"
          value={selectedNanodegreeOption}
          onFocus={handleNanodegreeFocus}
          onChange={handleNanodegreeInput}
          options={nanodegreeSelectOptions}
          hasError={hasNanodegreeError}
          errorText={i18n.t('nanodegreeTag.pleaseSelectProgram')}
          selectProps={{
            size,
            isDisabled:
              disableNanodegreeSelector ||
              shouldLockPreviouslySelectedProgramAndProject
          }}
          searchable
        />
      </FormControl>

      <FormControl>
        <FormLabel mb={1} display="inline-flex" alignItems="center">
          {i18n.t('nanodegreeTag.project')}
          {showLessonQuestionTooltip && (
            <Tooltip
              hasArrow
              label={i18n.t('nanodegreeTag.questionAboutLessonContent')}
              placement="end"
            >
              {<InfoIcon w={6} h={6} />}
            </Tooltip>
          )}
        </FormLabel>
        <Select
          ariaLabel={i18n.t('nanodegreeTag.selectProject')}
          data-ref="project-select"
          value={selectedProjectOption}
          onFocus={handleProjectFocus}
          onChange={handleProjectInput}
          options={projectSelectOptions}
          hasError={hasProjectError}
          errorText={i18n.t('nanodegreeTag.pleaseSelectProject')}
          searchable
          selectProps={{
            size,
            isDisabled:
              disableProjectSelector ||
              projectSelectOptions.length <= 0 ||
              shouldLockPreviouslySelectedProgramAndProject
          }}
        />
        {!selectedNanodegreeOption && (
          <FormInputHint
            data-ref="project-hint"
            text={i18n.t('nanodegreeTag.selectProgramFirst')}
          />
        )}
      </FormControl>
    </Grid>
  );
};

export default NanodegreeTags;
