import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { MentorshipIcon } from '@udacity/chakra-veritas-icons';
import React, { useState } from 'react';

import ActionModal from 'app/components/modals/ActionModal';
import ActionModalButtons from 'app/components/modals/ActionModal/ActionModalButtons';

import i18n from 'i18n';

export interface Props {
  handleUnassign: (reason: string) => void;
  handleToggleClose: () => void;
  initialReason?: string;
}

const UnassignPostModal: React.FC<Props> = (props) => {
  const { handleUnassign, handleToggleClose, initialReason } = props;
  const [reason, setReason] = useState(initialReason || '');

  const handleSubmit = () => {
    if (!reason) {
      return;
    }
    handleUnassign(reason);
    handleToggleClose();
  };

  return (
    <ActionModal
      headerIcon={<MentorshipIcon w={10} h={10} color="blue" />}
      theme="blue"
      onCancel={handleToggleClose}
      headerText={i18n.t('post.unassign')}
    >
      <FormControl>
        <FormLabel>{i18n.t('post.reasonForUnassigning')}</FormLabel>
        <Textarea
          id="unassign-reason-text-area"
          placeholder={i18n.t('question.whyUnassigned')}
          onChange={({ target: { value } }) => {
            setReason(value);
          }}
          value={reason}
          isRequired
          rows={6}
        />
      </FormControl>
      <ActionModalButtons
        theme="blue"
        disabled={!reason}
        primary={{
          text: i18n.t('action.unassign'),
          callback: handleSubmit
        }}
        secondary={{
          text: i18n.t('common.cancel'),
          callback: handleToggleClose
        }}
      />
    </ActionModal>
  );
};
export default UnassignPostModal;
