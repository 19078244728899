import {
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import React from 'react';

import UserIcon from 'app/components/UserIcon';
import {
  IUser,
  IUserDefault
} from 'app/models/entities/users/usersEntitiesTypes';

import CONFIG from 'appConfigLoader';
import i18n from 'i18n';

export interface Props {
  user: IUser;
  viewerIsStaff: boolean;
}

export type UserProfileOverlayProps = Props;

export class UserProfileOverlay extends React.Component<
  UserProfileOverlayProps
> {
  static defaultProps = {
    user: IUserDefault,
    viewerIsStaff: false
  };

  render() {
    const {
      user,
      user: { id, displayName },
      viewerIsStaff
    } = this.props;

    return (
      <Box p={6}>
        <Flex
          direction="column"
          align="center"
          justify="center"
          lineHeight="1.7"
          mb={2}
          color="stone"
        >
          <Box mb={1} alignSelf="center" textAlign="center">
            <UserIcon size="large" user={user} />
          </Box>
          <Heading size="h5" as="h5" mb={4}>
            {displayName}
          </Heading>
          {viewerIsStaff && (
            <Link
              variant="sm-secondary"
              href={`${CONFIG.studentsFrontend}/accounts/${id}`}
              isExternal
              mb={4}
              border="1px solid"
              borderColor="cerulean"
              color="cerulean"
              borderRadius="secondary"
            >
              {i18n.t('profileStats.viewInStan')}
            </Link>
          )}
          <Text size="sm" fontWeight="semibold">
            {i18n.t('profileStats.knowledgeStats')}:
          </Text>
          <UnorderedList
            display="flex"
            flexDir="column"
            flexWrap="wrap"
            fontSize="sm"
          >
            <ListItem display="flex">
              <Text>
                {i18n.t('profileStats.upvotesReceived', {
                  count: user?.activity?.upVotesReceived || 0
                })}
              </Text>
            </ListItem>
            <ListItem display="flex">
              <Text>
                {i18n.t('profileStats.answersPosted', {
                  count: user?.activity?.answersPosted || 0
                })}
              </Text>
            </ListItem>
            <ListItem display="flex">
              <Text>
                {i18n.t('profileStats.answersAccepted', {
                  count: user?.activity?.answersAccepted || 0
                })}
              </Text>
            </ListItem>
          </UnorderedList>
        </Flex>
      </Box>
    );
  }
}

export default UserProfileOverlay;
