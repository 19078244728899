import algoliasearch, { SearchClient } from 'algoliasearch';
import { Box, Grid } from '@chakra-ui/react';
import { List } from 'immutable';
import _isEqual from 'lodash/isEqual';
import React from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import ContentWrapper from 'app/components/ContentWrapper';
import ErrorBoundary from 'app/components/ErrorBoundary';
import NavHeaderLarge from 'app/components/NavHeaderLarge';
import ReadOnlyBanner from 'app/components/ReadOnlyModeBanner';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import ScrollToComponentContainer from 'app/containers/ScrollToComponentContainer';
import SearchFilterContainer from 'app/containers/SearchFilterContainer';
import SearchFooterCta from 'app/components/SearchFooterCta';
import SearchResultListContainer from 'app/containers/SearchResultListContainer';
import SearchResultsBarContainer from 'app/containers/SearchResultsBarContainer';
import SearchResultsPaginationContainer from 'app/containers/SearchResultsPaginationContainer';
import { trackPageview } from 'app/helpers/analyticsHelper';
import { actionCreators } from 'app/models/search/searchActions';
import { START_PAGE } from 'app/models/search/searchConstants';
import { selectSearchQuery } from 'app/models/search/searchSelectors';
import { SearchParams } from 'app/models/search/searchTypes';
import { selectReviewedNanodegrees } from 'app/models/session/sessionSelectors';

import CONFIG from 'appConfigLoader';
import i18n from 'i18n';

interface LocationProps extends RouteComponentProps<any> {}

interface SearchStateProps {
  searchResults: number;
  searchText: string;
  reviewedNanodegrees?: List<string>;
}

interface DispatchProps {
  setSearchParameters: (params: SearchParams) => void;
  resetSearchQuestions: () => void;
}

export type SearchSceneProps = LocationProps & SearchStateProps & DispatchProps;

const mapStateToProps = (state): SearchStateProps => ({
  searchResults: selectSearchQuery(state).getIn('results'),
  searchText: selectSearchQuery(state).getIn(['query']),
  reviewedNanodegrees: selectReviewedNanodegrees(state)
});

const mapDispatchToProps: DispatchProps = {
  setSearchParameters: actionCreators.setSearchParameters,
  resetSearchQuestions: actionCreators.resetSearchQuestions
};

const searchClient: SearchClient = algoliasearch(
  CONFIG.algoliaApplicationId,
  CONFIG.algoliaSearchAllEnrollmentsKey
);

export class SearchScene extends React.Component<SearchSceneProps> {
  componentDidMount() {
    document.title = i18n.t('common.knowledgeUdacity');

    this.props.setSearchParameters({
      page: START_PAGE
    });
  }

  componentDidUpdate(prevProps: SearchSceneProps) {
    if (!_isEqual(prevProps.location, this.props.location)) {
      this.props.setSearchParameters({
        page: 1
      });
      this.trackEvent('Search');
    }
  }

  trackEvent(eventName: string) {
    trackPageview(eventName);
  }

  render() {
    const { searchResults, searchText, reviewedNanodegrees } = this.props;

    const showAskMentorCTA =
      searchText &&
      searchResults > 0 &&
      reviewedNanodegrees &&
      reviewedNanodegrees.size > 0;

    return (
      <>
        <Box position="sticky" top={0} zIndex="dropdown">
          <PermissionsServiceContainer showIf={{ isReadOnly: true }}>
            <ReadOnlyBanner />
          </PermissionsServiceContainer>
        </Box>

        <InstantSearch
          searchClient={searchClient}
          indexName={CONFIG.algoliaSearchIndex}
        >
          <NavHeaderLarge />

          <ContentWrapper>
            <ScrollToComponentContainer />

            <Grid
              mt={{ base: 4, md: 12 }}
              mb={{ base: 8, md: 16 }}
              gridTemplateColumns={{ base: '1fr', md: '17.813rem 1fr' }}
              gridColumnGap={{ md: 12 }}
              sx={{
                base: { '> aside': { display: 'none' } },
                md: { '> aside': { display: 'block' } }
              }}
            >
              <section>
                <SearchFilterContainer />
              </section>

              <div>
                <SearchResultsBarContainer />

                <ErrorBoundary>
                  <SearchResultListContainer />
                </ErrorBoundary>

                <SearchResultsPaginationContainer />

                {showAskMentorCTA && <SearchFooterCta />}
              </div>
            </Grid>
          </ContentWrapper>
        </InstantSearch>
      </>
    );
  }
}

export default connect<{}, DispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(SearchScene);
