import { QuestionQueue } from './questionQueueTypes';

import { actionTypes } from './questionQueueActions';

export const initialState: QuestionQueue = {
  assignments: [],
  questionStatus: {}
};

export default function reducer(
  state: QuestionQueue = initialState,
  action: any
): QuestionQueue {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.MARK_AT_RISK_FULFILLED: {
      const { questionStatus } = state;
      return {
        ...state,
        questionStatus: {
          ...questionStatus,
          [payload.id]: {
            state: questionStatus[payload.id].state,
            atRisk: true
          }
        }
      };
    }
    case actionTypes.FETCH_ASSIGNMENTS_FULFILLED:
    case actionTypes.UNASSIGN_QUESTION_FULFILLED: {
      return {
        ...state,
        assignments: payload.map((assigned) => ({
          questionId: assigned.question.id,
          answerPayment: assigned.opportunity.answerPayment,
          commentPayment: assigned.opportunity.commentPayment
        }))
      };
    }

    case actionTypes.FETCH_QUESTION_STATUS_FULFILLED:
    case actionTypes.POST_QUESTION_REQUEUE_FULFILLED: {
      const { questionStatus } = state;
      return {
        ...state,
        questionStatus: {
          ...questionStatus,
          [payload.id]: {
            state: payload.state,
            atRisk: payload.atRisk
          }
        }
      };
    }

    default: {
      return state;
    }
  }
}
