import _delay from 'lodash/delay';
import _has from 'lodash/has';
import React from 'react';
import {
  Container,
  Editor,
  classToDOMAtom,
  classToDOMCard
} from 'react-mobiledoc-editor';
import { FormControl } from '@chakra-ui/react';
import i18n from 'i18n';

import RichTextToolbar from 'app/components/RichTextToolbar';
import TextInputWrapper from 'app/components/TextInputWrapper';
import { Mobiledoc, emptyMobiledoc } from 'app/helpers/mobiledocHelpers';
import {
  decodeMobiledoc,
  encodeMobiledoc
} from 'app/models/helpers/encodeGraphqlContent';

import FileDropTarget from './FileDropTarget';
import LinkAtom from './atoms/LinkAtom';
import CodeBlockCard from './cards/CodeBlockCard';
import ImageCard from './cards/ImageCard';
import { hotKeyCommands, linkToAtomParser } from './richTextEditorHelpers';
import styles from './richTextEditor.module.scss';

import CONFIG from 'appConfigLoader';

export interface Props {
  title: string;
  subtitle: string;
  hasError: boolean;
  errorText: string;
  autofocus: boolean;
  isDraggingFile: boolean;
  autofocusDelay: number;
  defaultContent: any;
  latestFileInputRef: HTMLInputElement; // most recent image card file picker
  handleChange: (content: Mobiledoc) => void;
  handleDrop: (fileRef?: File) => void;
}

export interface State {
  renderEditor: boolean;
}

export default class RichTextEditor extends React.PureComponent<Props, State> {
  static defaultProps = {
    autofocusDelay: 0,
    defaultContent: encodeMobiledoc(emptyMobiledoc)
  };

  mounted: boolean;

  autofocusTimeout: number;

  textInput: React.RefObject<any>;

  constructor(props: Props) {
    super(props);

    this.textInput = React.createRef();

    this.state = {
      renderEditor: true
    };
  }

  componentDidMount() {
    const { autofocus, autofocusDelay } = this.props;
    if (autofocus) {
      this.autofocusTimeout = _delay(this.focusCursor, autofocusDelay);
    }
    this.mounted = true;
  }

  componentWillReceiveProps(nextProps: Props) {
    const { defaultContent } = this.props;
    const { defaultContent: nextContent } = nextProps;
    if (defaultContent !== nextContent) {
      this.reMountEditor();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.clearTimeout(this.autofocusTimeout);
  }

  focusCursor = () => {
    if (_has(this.textInput.current, 'refs.editor')) {
      this.textInput.current.refs.editor.focus();
    }
  };

  handleInsertImage = () => {
    const { latestFileInputRef } = this.props;
    if (latestFileInputRef) {
      latestFileInputRef.click();
    }
  };

  reMountEditor = () => {
    /*
     * Mobiledoc <Container /> requires a full re-mount
     * to display updated default content
     */
    this.setState({
      renderEditor: false
    });
    window.requestAnimationFrame(() => {
      if (this.mounted) {
        this.setState({
          renderEditor: true
        });
      }
    });
  };

  // eslint-disable-next-line class-methods-use-this
  didCreateEditor(editor: any) {
    editor.registerKeyCommand(hotKeyCommands.link);
    editor.registerKeyCommand(hotKeyCommands.exit);
  }

  render() {
    const {
      hasError,
      errorText,
      defaultContent,
      handleChange,
      title,
      subtitle,
      isDraggingFile,
      handleDrop
    } = this.props;

    if (!this.state.renderEditor) {
      return null;
    }

    const editorOptions = {
      parserPlugins: [linkToAtomParser]
    };

    return (
      <Container
        serializeVersion={CONFIG.mobiledoc}
        placeholder={subtitle}
        options={editorOptions}
        onChange={handleChange}
        didCreateEditor={this.didCreateEditor}
        cards={[classToDOMCard(CodeBlockCard), classToDOMCard(ImageCard)]}
        atoms={[classToDOMAtom(LinkAtom)]}
        mobiledoc={decodeMobiledoc(defaultContent)}
      >
        {/*
         * <Mobiledoc.Container /> adds react context to
         * sync interactions between the toolbar and content
         */}
        <FormControl>
          <RichTextToolbar
            title={title}
            handleInsertImage={this.handleInsertImage}
          />

          <TextInputWrapper
            hasError={hasError}
            errorText={errorText}
            hintText={i18n.t('question.pressEscapeToExit')}
          >
            <FileDropTarget
              isDraggingFile={isDraggingFile}
              handleDrop={handleDrop}
            >
              <Editor
                id="details"
                className={`${styles['rich-text-editor']} post-body-content`}
                aria-label={title + ' ' + i18n.t('question.pressEscapeToExit')}
                data-gramm="false"
                ref={this.textInput}
              />
            </FileDropTarget>
          </TextInputWrapper>
        </FormControl>
      </Container>
    );
  }
}
