import _find from 'lodash/find';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import NanodegreeTags from 'app/components/NanodegreeTags';
import { Nanodegree, Project } from 'app/models/nanodegree/nanodegreeTypes';
import {
  selectEnrolledNanodegrees,
  selectGraduatedNanodegrees,
  selectPseudoNanodegrees
} from 'app/models/nanodegree/nanodegreeSelectors';

interface Error {
  field: string;
}

export interface Options {
  includeGraduatedNanodegrees?: boolean;
  includePseudoNanodegrees?: boolean;
  autoselectSingleOptions?: boolean;
  showLessonQuestionTooltip?: boolean;
}

export interface Props {
  shouldLockPreviouslySelectedProgramAndProject?: boolean;
  handleChange: (selection: {
    nanodegree?: Nanodegree;
    project?: Project;
  }) => void;
  errors?: Error[];
  selectedProject?: Project;
  selectedNanodegree?: Nanodegree;
  initialNanodegreeKey?: string;
  initialProjectId?: number;
  options?: Options;
}

const NanodegreeTagsContainer: React.FC<Props> = ({
  handleChange,
  errors,
  selectedProject,
  selectedNanodegree,
  initialNanodegreeKey,
  initialProjectId,
  shouldLockPreviouslySelectedProgramAndProject,
  options: {
    includeGraduatedNanodegrees = false,
    includePseudoNanodegrees = false,
    autoselectSingleOptions = false,
    showLessonQuestionTooltip = false
  } = {}
}) => {
  const enrolledNanodegrees: Nanodegree[] = useSelector(
    selectEnrolledNanodegrees
  );
  const graduatedNanodegrees: Nanodegree[] = useSelector(
    selectGraduatedNanodegrees
  );
  const pseudoNanodegrees: Nanodegree[] = useSelector(selectPseudoNanodegrees);

  const allNanodegrees: Nanodegree[] = useMemo(() => {
    let nds = enrolledNanodegrees;
    if (includeGraduatedNanodegrees) {
      nds = nds.concat(graduatedNanodegrees);
    }
    if (includePseudoNanodegrees) {
      nds = nds.concat(pseudoNanodegrees);
    }
    return nds;
  }, [
    enrolledNanodegrees,
    graduatedNanodegrees,
    pseudoNanodegrees,
    includeGraduatedNanodegrees,
    includePseudoNanodegrees
  ]);

  const hasNanodegreeError = useMemo(
    () => !!_find(errors, { field: 'nanodegree' }),
    [errors]
  );
  const hasProjectError = useMemo(() => !!_find(errors, { field: 'project' }), [
    errors
  ]);

  const disableNanodegreeSelector = useMemo(
    () => autoselectSingleOptions && enrolledNanodegrees.length === 1,
    [autoselectSingleOptions, enrolledNanodegrees]
  );

  const disableProjectSelector = useMemo(() => {
    return (
      autoselectSingleOptions &&
      !!selectedNanodegree &&
      selectedNanodegree.projects.length === 1
    );
  }, [autoselectSingleOptions, selectedNanodegree]);

  const handleSelectionChange = useCallback(
    (selection) => {
      const { nanodegree } = selection;
      // If the user has selected a nanodegree with only one project child and
      // autoselect single options is enabled, immediately select the only project
      // option.
      if (
        autoselectSingleOptions &&
        nanodegree &&
        nanodegree.projects.length === 1
      ) {
        handleChange({ nanodegree, project: nanodegree.projects[0] });
      } else {
        handleChange(selection);
      }
    },
    [autoselectSingleOptions, handleChange]
  );

  useEffect(() => {
    let nanodegree: Nanodegree | undefined;
    if (initialNanodegreeKey) {
      nanodegree = allNanodegrees.find((nd) => nd.key === initialNanodegreeKey);
    } else if (autoselectSingleOptions && enrolledNanodegrees.length === 1) {
      // If there is only one nanodegree to pick, autoselect it.
      nanodegree = enrolledNanodegrees[0];
    }

    if (nanodegree) {
      let project: Project | undefined;
      if (initialProjectId) {
        project = nanodegree.projects.find(
          (p) => p.reviewsProjectId === initialProjectId
        );
      } else if (autoselectSingleOptions && nanodegree.projects.length === 1) {
        // If there is only one project to pick, autoselect it.
        project = nanodegree.projects[0];
      }
      handleChange({ nanodegree, project });
    }
  }, [
    enrolledNanodegrees,
    allNanodegrees,
    initialNanodegreeKey,
    initialProjectId,
    handleChange,
    autoselectSingleOptions
  ]);

  return (
    <NanodegreeTags
      shouldLockPreviouslySelectedProgramAndProject={
        shouldLockPreviouslySelectedProgramAndProject
      }
      hasNanodegreeError={hasNanodegreeError}
      hasProjectError={hasProjectError}
      handleChange={handleSelectionChange}
      nanodegreeList={allNanodegrees}
      selectedNanodegree={selectedNanodegree}
      selectedProject={selectedProject}
      disableNanodegreeSelector={disableNanodegreeSelector}
      disableProjectSelector={disableProjectSelector}
      showLessonQuestionTooltip={showLessonQuestionTooltip}
    />
  );
};

export default NanodegreeTagsContainer;
