import { theme as veritasTheme } from '@udacity/chakra-veritas-theme';

export const Link = {
  baseStyle: {
    color: 'cerulean',
    textDecoration: 'none'
  },
  variants: {
    'sm-secondary': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...veritasTheme.components.Button.baseStyle,
      ...veritasTheme.components.Button.variants.secondary,
      ...veritasTheme.components.Button.sizes.sm
    },
    'sm-minimal-slate': {
      ...veritasTheme.components.Button.baseStyle,
      ...veritasTheme.components.Button.variants.minimal,
      color: 'slate',
      fontSize: '0.813rem',
      padding: '0.5rem 1rem',
      borderRadius: 'secondary'
    },
    primary: {
      ...veritasTheme.components.Button.baseStyle,
      ...veritasTheme.components.Button.variants.primary,
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: 'secondary',
      bgColor: 'cerulean',
      color: 'white',
      fontSize: '0.8125rem',
      fontWeight: 'semibold',
      padding: '0 2rem',
      h: '3rem',
      _hover: { color: 'white' }
    }
  }
};
