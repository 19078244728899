import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Select } from 'chakra-react-select';
import { Alert, AlertDescription, FormErrorMessage } from '@chakra-ui/react';
import { WarningIcon } from '@udacity/chakra-veritas-icons';

import { chakraSelectStyles } from 'assets/styles/chakra-select';
import i18n from 'i18n';

export interface Option {
  label: string;
  value: string;
}

export interface Props {
  value?: Option | null;
  onChange: (value: any) => void;
  onFocus?: () => any;
  options: Option[];
  selectProps?: {};
  hasError?: boolean;
  errorText?: string;
  hint?: string;
  searchable: boolean;
  ariaLabel?: string;
}

export default class SelectInput extends React.PureComponent<Props> {
  static defaultProps = {
    selectProps: {},
    hasError: false,
    errorText: ''
  };

  render() {
    const {
      onChange,
      options,
      selectProps,
      hasError,
      errorText,
      onFocus,
      searchable,
      ariaLabel
    } = this.props;

    const value = _isEmpty(this.props.value) ? null : this.props.value;

    return (
      <>
        <Select
          aria-label={ariaLabel}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          chakraStyles={chakraSelectStyles}
          placeholder={i18n.t('common.select')}
          options={options}
          isSearchable={searchable}
          isClearable
          blurInputOnSelect
          size="sm"
          {...selectProps}
        />
        {hasError && (
          <FormErrorMessage>
            <Alert status="error" variant="embedded">
              <WarningIcon w={8} h={8} color="red" />
              <AlertDescription>{errorText}</AlertDescription>
            </Alert>
          </FormErrorMessage>
        )}
      </>
    );
  }
}
