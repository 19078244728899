import React from 'react';
import { match } from 'react-router';

import FixedWidthWrapper from 'app/components/FixedWidthWrapper';
import IncompletePostBannerContainer from 'app/containers/IncompletePostBannerContainer';
import EditQuestionContainer from 'app/containers/questionFormContainers/EditQuestionContainer';
import { trackPageview } from 'app/helpers/analyticsHelper';

import i18n from 'i18n';

export interface Props {
  match: match<{ questionId: string }>;
}

export class EditQuestionScene extends React.PureComponent<Props> {
  componentDidMount() {
    document.title = i18n.t('common.knowledgeUdacity');
    this.trackEvent('Edit Question');
  }

  trackEvent(eventName: string) {
    trackPageview(eventName);
  }

  render() {
    const {
      match: {
        params: { questionId }
      }
    } = this.props;

    return (
      <>
        <IncompletePostBannerContainer postId={questionId} />
        <FixedWidthWrapper>
          <EditQuestionContainer questionId={questionId} />
        </FixedWidthWrapper>
      </>
    );
  }
}

export default EditQuestionScene;
