import { actionTypes } from './unreadsActions';
import { makeUnreadPosts } from './unreadsReducerHelpers';
import _omit from 'lodash/omit';

export const initialState = {
  byParentId: {}
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_UNREADS_FULFILLED: {
      const postsPayload = payload.result.postsToRead.posts;
      const unreadByParentId = makeUnreadPosts(postsPayload, 'parentID');
      const unreadByQuestionId = makeUnreadPosts(postsPayload, 'questionID');
      return {
        ...state,
        byParentId: { ...unreadByParentId, ...unreadByQuestionId }
      };
    }
    case actionTypes.MARK_AS_READ_FULFILLED: {
      const {
        id: { questionID }
      } = payload;
      return {
        ...state,
        byParentId: _omit(state.byParentId, questionID)
      };
    }
  }

  return state;
}
