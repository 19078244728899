import {
  BadgeCheckedIcon,
  ChatIcon,
  ChatCheckedIcon
} from '@udacity/chakra-veritas-icons';
import React from 'react';

export interface Props {
  totalPosts: number;
  verifiedId?: string | null;
  acceptedId?: string | null;
}

export default class AnswerIcon extends React.PureComponent<Props> {
  render() {
    const { totalPosts, verifiedId = null, acceptedId = null } = this.props;

    if (Number(verifiedId)) {
      return <BadgeCheckedIcon color="purple" w={6} h={6} />;
    } else if (acceptedId && totalPosts > 0) {
      return <ChatCheckedIcon color="blue" w={6} h={6} />;
    } else if (totalPosts > 0) {
      return <ChatIcon color="cerulean" w={6} h={6} />;
    } else {
      return <ChatIcon color="silver" w={6} h={6} />;
    }
  }
}
