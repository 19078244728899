import { combineReducers } from 'redux-immutable'; // immutablejs equivalent of Redux's combineReducers

import answers from './answers/answersEntitiesReducer';
import comments from './comments/commentsEntitiesReducer';
import moderatePostReasons from './moderatePostReasons/moderatePostReasonsEntitiesReducer';
import questions from './questions/questionsEntitiesReducer';
import users from './users/usersEntitiesReducer';

export default combineReducers({
  questions,
  answers,
  comments,
  users,
  moderatePostReasons
});
