import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';

import constants from './activityConstants';

export const actionClass = 'ACTIVITY';

export const actionTypes = {
  ...createActionType('FETCH_QUESTIONS', actionClass),
  ...createActionType('FETCH_ANSWERS', actionClass),
  ...createActionType('FETCH_USER_ACTIVITY_STATS', actionClass)
};

export const actionCreators = {
  getQuestions(userId = '', offset = 0, limit = constants.PAGE_SIZE) {
    return createAction(actionTypes.FETCH_QUESTIONS_START, {
      userId,
      offset,
      limit
    });
  },
  getAnswers(userId = '', offset = 0, limit = constants.PAGE_SIZE) {
    return createAction(actionTypes.FETCH_ANSWERS_START, {
      userId,
      offset,
      limit
    });
  },
  getUserActivityStats(userId: string) {
    return createAction(actionTypes.FETCH_USER_ACTIVITY_STATS_START, userId);
  }
};
