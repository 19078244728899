import {
  getCurrentUser,
  handleAuthorization
} from 'app/services/authenticationService';

import CenteredLoader from 'app/components/CenteredLoader';
import ENV from 'config';
import { Map } from 'immutable';
import React from 'react';
import _includes from 'lodash/includes';
import { connect } from 'react-redux';
import { isFulfilled } from 'app/models/helpers/apiStatusHelpers';
import { onInactive } from 'app/helpers/activityHelper';
import { actionCreators as searchActionCreators } from 'app/models/search/searchActions';
import { selectApiStatus } from 'app/models/session/sessionSelectors';
import { actionCreators as sessionActionCreators } from 'app/models/session/sessionActions';

interface StateProps {
  bootstrapStatus: Map<string, string>;
}

interface DispatchProps {
  bootstrap: () => void;
  setRoles: (roles: string[]) => void;
  initializeSearchSession: () => void;
}

interface OwnProps {
  children: JSX.Element;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state): StateProps => ({
  bootstrapStatus: selectApiStatus(state, 'bootstrap')
});

const mapDispatchToProps: DispatchProps = {
  bootstrap: sessionActionCreators.bootstrap,
  setRoles: sessionActionCreators.setRoles,
  initializeSearchSession: searchActionCreators.initializeSearchSession
};

const REINITIALIZE_SEARCH_SESSION_THRESHOLD_MS = 30 * 60 * 1000;

export class UserSessionContainer extends React.PureComponent<Props> {
  async componentDidMount() {
    const { bootstrap, setRoles, initializeSearchSession } = this.props;

    // skip auth in development
    // this allows for impersonating users through local knowledge
    if (!_includes(['development', 'test'], ENV.REACT_APP_ENV)) {
      await handleAuthorization().catch(() => {
        return new Error('Auth Token is invalid or expired.');
      });
    }

    const user = getCurrentUser();
    if (user && user.roles) {
      setRoles(user.roles);
    }

    initializeSearchSession();
    // Reinitialize search session after a period of inactivity within
    // Knowledge, so that session analytics are unique to a period of activity
    // and do not contain long breaks between searchs.
    onInactive(
      window,
      initializeSearchSession,
      REINITIALIZE_SEARCH_SESSION_THRESHOLD_MS
    );

    bootstrap();
  }

  render() {
    const { bootstrapStatus, children } = this.props;

    if (!isFulfilled(bootstrapStatus)) {
      return <CenteredLoader />;
    }

    return children;
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(UserSessionContainer);
