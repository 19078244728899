import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  debounce
} from 'redux-saga/effects';
import { makeSagaLifecycle } from 'app/models/helpers/sagaHelper';
import { getQuestionAndLogClick } from 'app/models/posts/postsExternalRequests';
import { normalizeQuestionModel } from 'app/models/posts/postsNormalizers';
import { actionTypes as postsActions } from 'app/models/posts/postsActions';
import { actionTypes as searchActions } from './searchActions';
import { selectSearchSession } from './searchSelectors';
import { SearchClickAnalytics } from './searchTypes';
import { makeUserDataForSearchQuestions } from './searchExternalRequests';

export function* getAlgoliaSearchResultsStats({ payload }): any {
  const { totalResults, totalAnsweredResults, totalPages } = payload;
  const unansweredResults = totalResults - totalAnsweredResults;

  const formattedAlgoliaResults = {
    totalResults: totalResults,
    unansweredResults: unansweredResults,
    totalPages: totalPages
  };

  yield put({
    type: searchActions.SET_ALGOLIA_RESULTS_FULFILLED,
    payload: formattedAlgoliaResults
  });

  yield put({
    type: searchActions.SEARCH_QUESTIONS_START,
    payload
  });
}

function* retrieveQuestionData(action: {
  payload: number[];
}): IterableIterator<any> {
  const { payload: questionIds } = action;

  const missingQids: any = yield select((state) => {
    return questionIds
      .map((qid) => String(qid))
      .filter(
        (qid: string) =>
          !state
            .get('search')
            .get('questionData')
            .has(String(qid))
      );
  }) ?? [];

  if (missingQids.length) {
    yield put({
      type: searchActions.RETRIEVE_QUESTION_DATA_PENDING,
      payload: missingQids
    });
    const data: any = yield call(makeUserDataForSearchQuestions, missingQids);

    yield put({
      type: searchActions.RETRIEVE_QUESTION_DATA_FULFILLED,
      payload: data
    });
  }
}

interface SearchClickAction {
  payload: {
    id: string;
    analyticsObject: SearchClickAnalytics;
  };
}
export function* searchClick(action: SearchClickAction): IterableIterator<any> {
  const {
    payload: { id, analyticsObject }
  } = action;

  yield put({
    type: postsActions.FETCH_QUESTION_PENDING
  });

  const searchSession = yield select(selectSearchSession);

  const question = yield call(
    getQuestionAndLogClick,
    id,
    analyticsObject,
    (searchSession as any).toJS()
  );

  const normalizedQuestion = normalizeQuestionModel(question);

  yield put({
    type: postsActions.FETCH_QUESTION_FULFILLED,
    payload: normalizedQuestion
  });

  yield put({
    type: searchActions.SEARCH_RESULT_CLICK_FULFILLED
  });
}

export function* watchSearchQuestions() {
  yield debounce(
    100,
    searchActions.SET_ALGOLIA_RESULTS_START,
    makeSagaLifecycle(
      getAlgoliaSearchResultsStats,
      'SEARCH_QUESTIONS',
      searchActions
    )
  );
}

export function* watchRetrieveQuestionData() {
  yield takeEvery(
    searchActions.RETRIEVE_QUESTION_DATA_START,
    retrieveQuestionData
  );
}

export function* watchResetSearchFilters() {
  yield takeLatest(
    searchActions.RESET_SEARCH_FILTERS,
    makeSagaLifecycle(
      getAlgoliaSearchResultsStats,
      'SEARCH_QUESTIONS',
      searchActions
    )
  );
}

export function* watchTrackSearchClick() {
  yield takeLatest(
    searchActions.SEARCH_RESULT_CLICK_START,
    makeSagaLifecycle<SearchClickAction>(
      searchClick,
      'SEARCH_RESULT_CLICK',
      searchActions
    )
  );
}

export default function* moduleRoot() {
  yield all([
    watchSearchQuestions(),
    watchResetSearchFilters(),
    watchTrackSearchClick(),
    watchRetrieveQuestionData()
  ]);
}
