import { Text } from '@chakra-ui/react';
import { Map } from 'immutable';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import React from 'react';
import { connect } from 'react-redux';

import ReplyForm from 'app/components/ReplyForm';
import ReplyFormPlaceholder from 'app/components/ReplyFormPlaceholder';
import ToastPortal from 'app/components/Toast/ToastPortal';
import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { CommentFormType } from 'app/models/posts/postsTypes';
import { selectIsImageUploadPending } from 'app/models/files/filesSelectors';
import { getError, hasError } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectIsCommentFormExpanded
} from 'app/models/posts/postsSelectors';
import { actionCreators as questionQueueActions } from 'app/models/QuestionQueue/questionQueueActions';
import { selectQuestionAssignments } from 'app/models/QuestionQueue/questionQueueSelectors';
import { QuestionAssignment } from 'app/models/QuestionQueue/questionQueueTypes';
import { selectUser } from 'app/models/session/sessionSelectors';

import {
  validate,
  ValidationError,
  replyFormTextForFormType
} from './commentFormContainerHelpers';
import i18n from 'i18n';

interface StateProps {
  formType?: CommentFormType;
  createCommentApiStatus: Map<string, string>;
  user: Map<string, string>;
  isImageUploadPending: boolean;
  assignments?: QuestionAssignment[];
}

interface DispatchProps {
  expandCommentForm: (parentId: string) => void;
  collapseCommentForm: (parentId: string) => void;
  createComment: (parentId: string, content: {}) => void;
  resetCreateComment: () => void;
  requeueParent: (parentId: string) => void;
}

interface OwnProps {
  parentId: string;
}

export type Props = StateProps & DispatchProps & OwnProps;

interface State {
  validationErrors: ValidationError[];
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => ({
  formType: selectIsCommentFormExpanded(state, ownProps.parentId),
  createCommentApiStatus: selectApiStatus(state, 'createComment'),
  user: selectUser(state),
  isImageUploadPending: selectIsImageUploadPending(state),
  assignments: selectQuestionAssignments(state)
});

const mapDispatchToProps: DispatchProps = {
  expandCommentForm: postsActions.expandCommentForm,
  collapseCommentForm: postsActions.collapseCommentForm,
  createComment: postsActions.createComment,
  resetCreateComment: postsActions.resetCreateComment,
  requeueParent: questionQueueActions.postQuestionRequeue
};

// All mentor comments are paid in USD.
const MENTOR_COMMENT_CURRENCY_FORMAT = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export class CommentFormContainer extends React.PureComponent<Props, State> {
  state = {
    validationErrors: []
  };

  componentWillUnmount() {
    const { resetCreateComment = _noop } = this.props;
    this.hideRichTextInput();
    resetCreateComment();
  }

  showRichTextInput = () => {
    const { parentId, expandCommentForm = _noop } = this.props;
    expandCommentForm(parentId);
  };

  hideRichTextInput = () => {
    const { parentId, collapseCommentForm = _noop } = this.props;
    collapseCommentForm(parentId);
  };

  validateComment(content: Mobiledoc): ValidationError[] {
    const validationErrors: ValidationError[] = validate(content);
    this.setState({ validationErrors });
    return validationErrors;
  }

  handleChange = (content: Mobiledoc): void => {
    this.validateComment(content);
  };

  dispatchCreateComment = (content: Mobiledoc): void => {
    const hasFormError = !!this.validateComment(content).length;
    if (hasFormError) {
      return;
    }

    const {
      formType,
      parentId,
      createComment = _noop,
      requeueParent = _noop
    } = this.props;
    createComment(parentId, content);
    if (formType === CommentFormType.NEED_MORE_HELP) {
      requeueParent(parentId);
    }
  };

  renderCommentDisclaimer = () => {
    const { parentId, assignments } = this.props;

    const parentAssignment = (assignments || []).find(
      (a) => a.questionId === parentId
    );
    if (!parentAssignment) {
      return undefined;
    }
    const commentPrice = MENTOR_COMMENT_CURRENCY_FORMAT.format(
      Number(parentAssignment.commentPayment.amount)
    );
    return (
      <Text size="sm">
        {i18n.t('comment.clarifyingCompensation', { price: commentPrice })}
      </Text>
    );
  };

  render() {
    const {
      formType,
      createCommentApiStatus,
      user,
      isImageUploadPending
    } = this.props;

    let error;
    if (hasError(createCommentApiStatus)) {
      error = getError(createCommentApiStatus);
    }

    const replyFormText = replyFormTextForFormType(formType);

    const replyFormError = _find(this.state.validationErrors, {
      field: 'content'
    });

    if (formType) {
      return (
        <>
          <ReplyForm
            title={replyFormText.title}
            subtitle={replyFormText.subtitle}
            submitButtonText={
              replyFormText.submitButtonText || i18n.t('comment.submit')
            }
            isImageUploadPending={isImageUploadPending}
            onSubmit={this.dispatchCreateComment}
            onChange={this.handleChange}
            onCancel={this.hideRichTextInput}
            errorMessage={_get(replyFormError, 'message', null)}
            apiStatus={createCommentApiStatus}
            additionalDisclaimer={this.renderCommentDisclaimer()}
          />
          {error && (
            <ToastPortal
              type="error"
              message={i18n.t('comment.failedToSubmit')}
            />
          )}
        </>
      );
    }

    const userObj = user ? user.toJS() : {};

    return (
      <ReplyFormPlaceholder
        showRichTextInput={this.showRichTextInput}
        placeholder={i18n.t('comment.writeCommentDescribeAnswer')}
        user={userObj}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(CommentFormContainer);
