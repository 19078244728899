import React from 'react';

import { PrivateActionButton } from 'app/components/PostActionBar/factoryComponents';

export interface Props {
  callToAction: string;
  isDisabled?: boolean;
  render: (arg: any) => void;
}

interface State {
  isOpen: boolean;
}

export default class PostModalToggle extends React.PureComponent<Props, State> {
  state = {
    isOpen: false
  };

  handleToggleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleToggleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { callToAction, isDisabled, render } = this.props;
    const { isOpen } = this.state;
    return (
      <>
        {isOpen && render(this.handleToggleClose)}
        <PrivateActionButton
          variant="minimal"
          size="sm"
          onClick={this.handleToggleOpen}
          disabled={isDisabled}
        >
          {callToAction}
        </PrivateActionButton>
      </>
    );
  }
}
