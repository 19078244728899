import { Box, Flex } from '@chakra-ui/react';
import { BellIcon, EditIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';

import NavListItem from 'app/components/NavListItem';
import NotificationBadge from 'app/components/NotificationBadge';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import i18n from 'i18n';

export default class NavList extends React.PureComponent<any> {
  render() {
    const { hasKnowledgeReviews, hasUnreads } = this.props;

    return (
      <Flex as="nav" align="center" gap={6}>
        <PermissionsServiceContainer showIf={{ canAsk: true }}>
          <NavListItem
            navText={
              hasKnowledgeReviews
                ? i18n.t('post.askMentor')
                : i18n.t('post.post')
            }
            navIcon={<EditIcon w={8} h={8} color="white" />}
            navLink="/questions/new"
          />
        </PermissionsServiceContainer>

        <PermissionsServiceContainer showIf={{ isReadOnly: false }}>
          <Flex justify="center">
            <NavListItem
              navText={i18n.t('activity.activity')}
              navIcon={<BellIcon w={8} h={8} color="white" />}
              navLink="/activity/questions"
            />
            {hasUnreads && (
              <Box alignSelf="start" mt={-2}>
                <NotificationBadge />
              </Box>
            )}
          </Flex>
        </PermissionsServiceContainer>
      </Flex>
    );
  }
}
