import { Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';

const SearchFooterCta = () => {
  return (
    <PermissionsServiceContainer showIf={{ canAsk: true }}>
      <Text size="sm">
        <Trans i18nKey="search.notFindingRelevant">
          Not finding a relevant question?{' '}
          <Link to="/questions/new">
            Ask a technical mentor a new question!
          </Link>
        </Trans>
      </Text>
    </PermissionsServiceContainer>
  );
};

export default SearchFooterCta;
