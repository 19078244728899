import { chakra, Flex, Grid, IconButton } from '@chakra-ui/react';

export const LightboxContainer = chakra('div', {
  baseStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 'dropdown',
    minW: 'full',
    minH: '100vh'
  }
});

export const LightboxHeader = chakra(Grid, {
  baseStyle: {
    position: 'sticky',
    top: 0,
    borderBottom: '1px solid',
    borderBottomColor: 'silver-lighter',
    bgColor: 'white',
    p: '0.75rem 1rem',
    alignItems: 'center',
    gridColumnGap: 4,
    gridTemplateColumns: '1fr auto auto'
  }
});

export const LightboxContent = chakra(Flex, {
  baseStyle: {
    bgColor: 'silver-lightest',
    p: 4,
    minH: 'calc(100vh - 3.75rem)',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export const CloseButton = chakra(IconButton, {
  baseStyle: {
    p: 0,
    transform: 'translateX(0.25rem)',
    bgColor: 'white',
    '&:hover': {
      svg: {
        color: 'black'
      }
    }
  }
});
