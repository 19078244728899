const user = `{
  id,
  displayName,
  profileImageUrl
}`;

const comment = `{
  content,
  createdAt,
  fromMentor,
  id,
  modifiedAt,
  user ${user}
  userUid,
  parentId
}`;

const answer = `{
  accepted,
  acceptedAt,
  comments ${comment}
  content,
  createdAt,
  fromMentor,
  id,
  lockedAt,
  lockedBy ${user},
  lockedReason,
  modifiedAt,
  myVote,
  parentId,
  score,
  user ${user},
  userUid,
  verified,
  verifiedAt
}`;

const question = `{
  acceptedAnswerId,
  answers {
    id
  },
  comments ${comment},
  content,
  createdAt,
  duplicateId,
  id,
  lockedAt,
  lockedBy ${user},
  lockedReason,
  modifiedAt,
  myVote,
  score,
  summary,
  title,
  user ${user},
  userUid,
  verifiedAnswerId,
  postType,
  link,
  node {
    key,
    title,
  },
  projectID,
  rubricID
}`;

const answersAndComments = `{
  answers ${answer},
  comments ${comment},
}`;

const archivedPost = `{
    id
    userUid
    postType
  }`;

const votePost = `{
  id,
  score,
  myVote
}`;

const acceptVerifyAnswer = `{
  id,
  parentId,
  accepted,
  acceptedAt,
  verified,
  verifiedAt
}`;

export default {
  Question: question,
  AnswersAndComments: answersAndComments,
  ArchivedPost: archivedPost,
  Answer: answer,
  Comment: comment,
  VotePost: votePost,
  AcceptVerifyAnswer: acceptVerifyAnswer
};
