import { Box } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';

import ActivityAnswerEmptyState from 'app/components/ActivityAnswerEmptyState';
import ActivityAnswerItem from 'app/components/ActivityAnswerItem';
import ActivityQuestionEmptyState from 'app/components/ActivityQuestionEmptyState';
import ActivityQuestionItem from 'app/components/ActivityQuestionItem';
import {
  ACTIVITY_OPTION,
  IActivityAnswer,
  IActivityOption,
  IActivityQuestion
} from 'app/models/activity/activityTypes';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';

type IActivityPosts = IActivityQuestion[] | IActivityAnswer[];

type IActivityPostsItem = IActivityQuestion | IActivityAnswer;

export interface Props {
  posts: IActivityPosts | undefined;
  selectedGroup: IActivityOption;
  unreads: UnreadPostByParentId;
}

const postItem = (
  post: IActivityPostsItem,
  key: string,
  selectedGroup: IActivityOption,
  unreads: UnreadPostByParentId
) => {
  const isAnswer = selectedGroup === ACTIVITY_OPTION.answers;
  const isQuestion = selectedGroup === ACTIVITY_OPTION.questions;

  // post.id handles archived posts
  if (isQuestion && !!post.id) {
    const currentQuestionUnreads = unreads[(post as IActivityQuestion).id];
    return (
      <ActivityQuestionItem
        post={post as IActivityQuestion}
        key={post.id}
        data-ref="activity-question-item"
        unreads={currentQuestionUnreads}
      />
    );

    // post.title handles archived parent questions
  } else if (isAnswer && !!post.id && !!post.title) {
    const currentAnswerUnreads = unreads[(post as IActivityAnswer).questionId];
    return (
      <ActivityAnswerItem
        post={post as IActivityAnswer}
        key={post.id}
        data-ref="activity-answer-item"
        unreads={currentAnswerUnreads}
      />
    );
  }
  return null;
};

export default class ActivityList extends React.PureComponent<Props> {
  render() {
    const { posts = [], selectedGroup, unreads } = this.props;
    const isAnswer = selectedGroup === ACTIVITY_OPTION.answers;
    const isQuestion = selectedGroup === ACTIVITY_OPTION.questions;

    if (!_isEmpty(posts)) {
      return (
        <Box
          sx={{
            '> :first-of-type': { borderTop: 'none' },
            '> article': {
              borderTop: '1px solid',
              borderTopColor: 'silver-lighter'
            }
          }}
        >
          {posts.map((post: any) => {
            return postItem(post, post.id, selectedGroup, unreads);
          })}
        </Box>
      );
    }
    if (isQuestion) {
      return <ActivityQuestionEmptyState />;
    }
    if (isAnswer) {
      return <ActivityAnswerEmptyState />;
    }
    return null;
  }
}
