import React from 'react';
import { connect } from 'react-redux';

import RichTextEditor from 'app/components/RichTextEditor';
import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { actionCreators as filesActions } from 'app/models/files/filesActions';
import { selectLatestFileInputRef } from 'app/models/posts/postsSelectors';
import { actionCreators } from 'app/models/ui/uiActions';
import { selectIsDraggingFile } from 'app/models/ui/uiSelectors';

interface StateProps {
  latestFileInputRef: HTMLInputElement;
  isDraggingFile: boolean;
}

interface OwnProps {
  title: string;
  subtitle: string;
  hasError: boolean;
  errorText: string;
  autofocus: boolean;
  autofocusDelay: number;
  defaultContent: any;
  handleChange: (content: Mobiledoc) => void;
}

interface DispatchProps {
  fileDragEnd: () => {};
  imageUploadStart: (fileRef: File) => void;
}

export type Props = StateProps & OwnProps & DispatchProps;

const mapStateToProps = (state): StateProps => ({
  latestFileInputRef: selectLatestFileInputRef(state),
  isDraggingFile: selectIsDraggingFile(state)
});

const mapDispatchToProps: DispatchProps = {
  fileDragEnd: actionCreators.fileDragEnd,
  imageUploadStart: filesActions.imageUploadStart
};

export class RichTextEditorContainer extends React.Component<Props> {
  handleDrop = (fileRef?: File) => {
    const { fileDragEnd, imageUploadStart } = this.props;
    fileDragEnd();
    if (fileRef) {
      imageUploadStart(fileRef);
    }
  };

  render() {
    const {
      latestFileInputRef,
      title,
      subtitle,
      hasError,
      errorText,
      autofocus,
      autofocusDelay,
      defaultContent,
      handleChange,
      isDraggingFile
    } = this.props;

    return (
      <RichTextEditor
        title={title}
        subtitle={subtitle}
        hasError={hasError}
        errorText={errorText}
        autofocus={autofocus}
        autofocusDelay={autofocusDelay}
        defaultContent={defaultContent}
        handleChange={handleChange}
        latestFileInputRef={latestFileInputRef}
        isDraggingFile={isDraggingFile}
        handleDrop={this.handleDrop}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(RichTextEditorContainer);
