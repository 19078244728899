import { Flex, Image } from '@chakra-ui/react';
import React from 'react';

import { memoizedIdenticon } from 'app/helpers/userIconHelpers';
import {
  IUser,
  IUserDefault
} from 'app/models/entities/users/usersEntitiesTypes';

import i18n from 'i18n';

const SIZES = {
  small: '1.75rem',
  medium: '2.25rem',
  large: '3.75rem'
};

export interface Props {
  user: IUser;
  size?: string;
}

export default class UserIcon extends React.PureComponent<Props> {
  static defaultProps = {
    user: {},
    size: 'small'
  };

  render() {
    const {
      user: { profileImageUrl, id } = IUserDefault,
      size = 'small'
    } = this.props;

    const avatarImage = profileImageUrl || memoizedIdenticon(id, size);
    const imgSize = SIZES[size];

    return (
      <Flex
        justify="center"
        borderRadius="circle"
        data-ref="profile-image-wrapper"
        w={imgSize}
        h={imgSize}
      >
        <Image
          src={avatarImage}
          alt={i18n.t('common.profileImage')}
          data-ref="profile-image"
          borderRadius="circle"
          w="full"
          h="full"
        />
      </Flex>
    );
  }
}
