import { Map } from 'immutable';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Configure,
  connectHits,
  connectStateResults,
  HitsProvided,
  Index,
  SearchResults,
  StateResultsProvided
} from 'react-instantsearch-core';

import SearchResultList from 'app/components/SearchResultList';
import { actionCreators as searchActions } from 'app/models/search/searchActions';
import { selectSearchQuery } from 'app/models/search/searchSelectors';
import {
  AlgoliaHit,
  AlgoliaSearchParams,
  SEARCH_INDEX_ID
} from 'app/models/search/searchTypes';
import { handleSearchResultClick } from './searchResultListContainerHelpers';
import { selectUnreads } from 'app/models/unreads/unreadsSelectors';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';
import {
  ANY_FILTER_KEY,
  PAGE_SIZE,
  SHARE_ALGOLIA_ANALYTICS
} from 'app/models/search/searchConstants';

import CONFIG from 'appConfigLoader';

interface CustomAlgoliaResultsData {
  searchState: SearchResults;
  error: string;
  searching: boolean;
  isSearchStalled: boolean;
}

interface SearchResultListContainerStateProps {
  searchQuery: Map<string, any>;
  unreads: UnreadPostByParentId;
}

interface DispatchProps {
  setAlgoliaSearchResults: (params: AlgoliaSearchParams) => void;
}

type AlgoliaResultsProps = StateResultsProvided<CustomAlgoliaResultsData>;

export type SearchResultListContainerProps = SearchResultListContainerStateProps &
  DispatchProps;

const mapStateToProps = (state): SearchResultListContainerStateProps => ({
  searchQuery: selectSearchQuery(state),
  unreads: selectUnreads(state)
});

const mapDispatchToProps = {
  setAlgoliaSearchResults: searchActions.setAlgoliaSearchResults
};

export const CustomResults = connectHits<
  HitsProvided<AlgoliaHit> & {
    unreads: UnreadPostByParentId;
    handleSearchResultClick: (...args: any) => void;
  },
  AlgoliaHit
>(SearchResultList);

export type CustomStateResultsProps = SearchResultListContainerStateProps &
  DispatchProps &
  AlgoliaResultsProps;

const StateResults: React.FC<CustomStateResultsProps> = ({
  allSearchResults,
  unreads,
  setAlgoliaSearchResults,
  searchQuery
}) => {
  const filterByUnanswered = useMemo(() => searchQuery.toJS().unanswered, [
    searchQuery
  ]);

  const selectedProgramKey = useMemo(() => {
    return searchQuery.toJS().nanodegreeKey;
  }, [searchQuery]);

  const selectedProjectId = useMemo(() => {
    return searchQuery.toJS().projectID;
  }, [searchQuery]);

  const filterQueryString = useMemo(() => {
    let filters = `post_type:question`;

    if (
      (!selectedProgramKey && !selectedProjectId) ||
      selectedProgramKey === ANY_FILTER_KEY
    ) {
      return filters;
    }

    if (selectedProgramKey) {
      filters += ` AND program_key:${selectedProgramKey}`;
    }

    if (selectedProjectId) {
      filters += ` AND project_id:${selectedProjectId}`;
    }

    return filters;
  }, [selectedProgramKey, selectedProjectId]);

  const filterString = useMemo(() => {
    return filterByUnanswered
      ? `${filterQueryString} AND answer_count<1`
      : filterQueryString;
  }, [filterQueryString, filterByUnanswered]);

  const results = allSearchResults?.[SEARCH_INDEX_ID.ALL_RESULTS];
  const totalResults = results?.nbHits ?? 0;
  const totalAnsweredResults = filterByUnanswered
    ? 0
    : allSearchResults?.answered?.nbHits ?? 0;

  const totalPages = results?.nbPages ?? 0;

  useEffect(() => {
    setAlgoliaSearchResults({
      totalResults,
      totalAnsweredResults,
      totalPages
    });
  }, [setAlgoliaSearchResults, totalResults, totalPages, totalAnsweredResults]);

  return (
    <div>
      <Index
        indexName={CONFIG.algoliaSearchIndex}
        indexId={SEARCH_INDEX_ID.ALL_RESULTS}
      >
        <Configure
          hitsPerPage={PAGE_SIZE}
          analytics={SHARE_ALGOLIA_ANALYTICS}
          distinct
          attributesToSnippet={['content']}
          filters={filterString}
        />
        <CustomResults
          handleSearchResultClick={handleSearchResultClick}
          unreads={unreads}
        />
      </Index>
      <Index
        indexName={CONFIG.algoliaSearchIndex}
        indexId={SEARCH_INDEX_ID.ANSWERED_RESULTS}
      >
        <Configure
          hitsPerPage={0}
          analytics={SHARE_ALGOLIA_ANALYTICS}
          distinct
          filters={`answer_count>0 AND ${filterQueryString}`}
        />
      </Index>
    </div>
  );
};

export const SearchResultListContainer = connectStateResults(StateResults);

export default connect<SearchResultListContainerStateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultListContainer);
