import { Map } from 'immutable';
import _ from 'lodash';

export const apiStatuses = {
  ready: 'ready',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected'
};

export const isReady = (state: Map<string, string> = Map({})): boolean => {
  return state.get('status') === apiStatuses.ready;
};

export const isPending = (state: Map<string, string> = Map({})): boolean => {
  return state.get('status') === apiStatuses.pending;
};

export const isFulfilled = (state: Map<string, string> = Map({})): boolean => {
  return state.get('status') === apiStatuses.fulfilled;
};

export const isRejected = (state: Map<string, string> = Map({})): boolean => {
  return state.get('status') === apiStatuses.rejected;
};

export const hasError = (state: Map<string, string> = Map({})): boolean => {
  return !_.isNull(state.get('error')) && !_.isUndefined(state.get('error'));
};

export const getError = (
  state: Map<string, string> = Map({})
): null | string => {
  const error = state.get('error');
  return error || null;
};

export default apiStatuses;
