import * as Immutable from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { searchFilterStateKey } from 'app/models/helpers/appStateConstants';
import { persistImmutableStoreMiddleware } from 'app/models/helpers/appStateHelpers';
import reducers from 'app/rootReducer';
import initializeRootSaga from 'app/rootSaga';

export const sagaMiddleware = createSagaMiddleware();
const windowAny: any = window;

const composeEnhancers =
  windowAny.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  Immutable.Map(),
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(persistImmutableStoreMiddleware(searchFilterStateKey))
  )
);

/*
 * Initialize Redux Saga asynchronous event watchers.
 * Must be ran after createStore();
 */
initializeRootSaga();

export default store;
