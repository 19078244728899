import React from 'react';
import { Link } from 'react-router-dom';

import ContentWrapper from 'app/components/ContentWrapper';
import Logo from 'app/components/Logo';
import NavListContainer from 'app/containers/NavListContainer';
import SearchInputContainer from 'app/containers/SearchInputContainer';

import { Box, Flex } from '@chakra-ui/react';

export interface Props {
  searchQueryString: string;
}

export default class NavHeaderSmall extends React.PureComponent<Props> {
  render() {
    const { searchQueryString } = this.props;

    return (
      <Box bgColor="blue-darker" p={{ base: '0.85rem 0', 'sm-md': '1rem 0' }}>
        <ContentWrapper>
          <Flex
            justify={{ base: 'space-between', md: 'center' }}
            align="center"
            wrap={{ base: 'wrap', md: 'nowrap' }}
            columnGap={8}
            rowGap={4}
          >
            <Link to={`/${searchQueryString}`}>
              <Logo isSmall />
            </Link>

            <Box order={{ base: 1, md: 2 }}>
              <NavListContainer />
            </Box>

            <Box order={{ base: 2, md: 1 }} w="full">
              <SearchInputContainer
                theme="blue"
                searchOnLoad={false}
                isAlgolia
              />
            </Box>
          </Flex>
        </ContentWrapper>
      </Box>
    );
  }
}
