import Identicon from 'identicon.js';
import _memoize from 'lodash/memoize';
import _padEnd from 'lodash/padEnd';

export function makeIdenticon(): any {
  const identiconSizeLookup = {
    small: 28,
    medium: 36,
    large: 44
  };

  return _memoize(
    (uid: string, size: string) => {
      if (!uid) {
        return '';
      }
      return `data:image/png;base64,${new Identicon(
        `${_padEnd(uid, 15, uid)}`,
        identiconSizeLookup[size]
      ).toString()}`;
    },
    (uid, size) => `${uid} ${identiconSizeLookup[size]}`
  );
}

export const memoizedIdenticon = makeIdenticon();
