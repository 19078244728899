import { fromJS } from 'immutable';

import { actionTypes } from './uiActions';

export const initialState = fromJS({
  isDraggingFile: false
});

export default function reducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case actionTypes.FILE_DRAG_START: {
      return state.set('isDraggingFile', true);
    }
    case actionTypes.FILE_DRAG_END: {
      return state.set('isDraggingFile', false);
    }
  }

  return state;
}
