import { Map, fromJS } from 'immutable';

import { actionTypes as activityActions } from 'app/models/activity/activityActions';
import { mergeEntities } from 'app/models/entities/entitiesReducerHelpers';
import { actionTypes as postActions } from 'app/models/posts/postsActions';
import { actionTypes as searchActions } from 'app/models/search/searchActions';

type State = Map<string, any>;

export const initialState: State = fromJS({
  byId: {}
});

export default function reducer(state: State = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case postActions.FETCH_QUESTION_FULFILLED:
    case postActions.FETCH_ANSWERS_BY_QUESTION_ID_FULFILLED:
    case postActions.UPDATE_QUESTION_FULFILLED:
    case postActions.CREATE_QUESTION_FULFILLED:
    case postActions.UPDATE_ANSWER_FULFILLED:
    case postActions.CREATE_ANSWER_FULFILLED:
    case postActions.UPDATE_COMMENT_FULFILLED:
    case postActions.CREATE_COMMENT_FULFILLED: {
      const { users } = payload.entities;
      return mergeEntities(state, users);
    }

    case searchActions.SEARCH_QUESTIONS_FULFILLED: {
      const { users } = payload.results.entities;
      return mergeEntities(state, users);
    }

    case activityActions.FETCH_USER_ACTIVITY_STATS_FULFILLED: {
      const { id } = payload;
      return state.mergeDeep({
        byId: { [id]: { activity: payload.result } }
      });
    }
  }

  return state;
}
