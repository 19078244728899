export type faviconType = 'default' | 'notification';

export class faviconService {
  public faviconElement: HTMLLinkElement | null;

  constructor(doc = document) {
    this.faviconElement =
      (doc.getElementById('favicon') as HTMLLinkElement) || null;
  }

  updateFavicon(type: faviconType = 'default'): void {
    if (!!this.faviconElement) {
      switch (type) {
        case 'notification': {
          this.faviconElement.href = '/favicon-notification.ico';
          break;
        }
        default: {
          this.faviconElement.href = '/favicon.ico';
        }
      }
    }
  }
}

export default new faviconService();
