export const colors = {
  stone: '#333c45',
  zircon: '#dde1e5',
  titanium: '#657482',
  azure: '#f2fcfe',
  solitude: '#e8e8ea',
  'white-dark': '#ffffffcc',
  'dark-night-blue': '#102b3c',
  'dark-red': '#dd463e',
  'light-orange': '#fff7f5',
  'dark-stone': '#122b3b',
  'pink-beige': '#f8f1f4',
  'light-purple': '#9f4cdf1a',
  'light-green': '#15c26b1a',
  'charcoal-light': '#f5f6f8'
};
