import { generateFileIdentifierString } from 'app/models/files/filesHelpers';
import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';

import { ActionDispatch } from 'app/models/helpers/actionTypes';

export const actionClass = 'FILES';

export const actionTypes = {
  ...createActionType('UPLOAD_IMAGE', actionClass),
  UPLOAD_IMAGE_PROGRESS: 'FILES/UPLOAD_IMAGE_PROGRESS'
};

export const actionCreators = {
  imageUploadStart(file): ActionDispatch {
    return createAction(actionTypes.UPLOAD_IMAGE_START, {
      file,
      fileIdentifierString: generateFileIdentifierString(file)
    });
  },
  imageUploadProgress(
    fileIdentifierString: string,
    progress: number
  ): ActionDispatch {
    return createAction(actionTypes.UPLOAD_IMAGE_PROGRESS, {
      progress,
      fileIdentifierString
    });
  },
  imageUploadSuccess(
    fileIdentifierString: string,
    response: any
  ): ActionDispatch {
    return createAction(actionTypes.UPLOAD_IMAGE_FULFILLED, {
      fileIdentifierString,
      response
    });
  },
  imageUploadRejected(
    fileIdentifierString: string,
    error: Error
  ): ActionDispatch {
    return createAction(actionTypes.UPLOAD_IMAGE_REJECTED, {
      fileIdentifierString,
      error
    });
  }
};
