import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  styleProps?: BoxProps;
}

export default class FixedWidthWrapper extends React.PureComponent<Props> {
  render() {
    const { children, styleProps } = this.props;

    return (
      <Box w="full" maxW="49.688rem" px={4} mx="auto" {...styleProps}>
        {children}
      </Box>
    );
  }
}
