import _ from 'lodash';
import webPerf from '@udacity/web-perf-metrics';
import CONFIG from 'appConfigLoader';

// global window.analytics object
declare let analytics: {
  identify: (id: string, traits: { name: string }) => void;
  track: (eventName: string, properties?: {}) => void;
  page: (pageName: string) => void;
};

/*
 * Helper functions provide a wrapper for global Segment analytics object.
 * Provides a more consistent interface for integrating with the 3rd party analytics,
 * and consolidates all analytics functionality in one place.
 */

export function initializeSegmentTracking() {
  const segmentScript = document.createElement('script');
  segmentScript.type = 'text/javascript';
  segmentScript.innerHTML = `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${CONFIG.segmentWriteKey}";;analytics.SNIPPET_VERSION="4.15.3";
    analytics.load("${CONFIG.segmentWriteKey}");
  }}();`;

  const head: HTMLHeadElement =
    document.querySelector<HTMLHeadElement>('head') ||
    document.createElement('head');

  // insert script into head tag
  head.appendChild(segmentScript);
  webPerf.track(CONFIG.siteIdentifier);
}

export function identify({ id, name }: { id: string; name: string }) {
  if (_.isFunction(_.get(window, 'analytics.identify'))) {
    analytics.identify(id, { name });
  }
}

export function trackEvent(eventName: string, properties?: {}) {
  if (_.isFunction(_.get(window, 'analytics.track'))) {
    // merge url into properties
    analytics.track(eventName, {
      path: window.location.pathname,
      ...properties
    });
  }
}

export function trackPageview(pageName: string) {
  if (_.isFunction(_.get(window, 'analytics.page'))) {
    analytics.page(pageName);
  }
}
