interface BuilderType {
  createAtom: (atomName: string, atomValue: string, payload: {}) => any;
}

interface EnvType {
  addSection: (card: any) => void;
  addMarkerable: (atom: any) => void;
  nodeFinished: () => void;
}

// Mobiledoc editor hotkeys. Documentation:
// https://github.com/bustle/mobiledoc-kit#configuring-hot-keys
export const hotKeyCommands = {
  link: {
    str: 'META+K',
    run() {
      // stub out link shortcut to prevent default link input from appearing
      // TODO: insert new link atom
    }
  },
  exit: {
    str: 'ESC',
    run(editor) {
      // Exit editor on escape
      editor.element.blur();
      editor.element.setAttribute('tabIndex', 0);
    }
  }
};

// convert pasted dom links to Link Atom
export function linkToAtomParser(
  node: HTMLAnchorElement,
  builder: BuilderType,
  { addMarkerable, nodeFinished }: EnvType
) {
  if (node.nodeType !== 1 || node.tagName !== 'A') {
    return;
  }
  const payload = { url: node.href, anchor: node.text };
  const linkAtom = builder.createAtom('Link', node.text, payload);
  addMarkerable(linkAtom);
  nodeFinished();
}
