import { Alert, AlertDescription, Box } from '@chakra-ui/react';
import { WarningIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';

import FormInputHint from 'app/components/FormInputHint';

import i18n from 'i18n';

export interface Props {
  children: JSX.Element;
  errorText?: string;
  hasError?: boolean;
  hintText?: string;
}

export default class TextInputWrapper extends React.PureComponent<Props> {
  static defaultProps = {
    errorText: i18n.t('question.fillOutField'),
    hasError: false
  };

  render() {
    const { children, hasError, errorText, hintText } = this.props;

    return (
      <>
        <Box
          position="relative"
          transition="box-shadow 0.25s"
          border="1px solid"
          borderColor={hasError ? 'dark-red' : 'silver-light'}
          borderRadius="secondary"
          bgColor={hasError ? 'pink-beige' : 'white'}
          color="dark-stone"
          sx={{
            '&:focus-within': { outline: '4px solid', outlineColor: 'cerulean' }
          }}
        >
          {children}
        </Box>
        {hintText && <FormInputHint text={hintText} />}
        {!!hasError && (
          <Alert bgColor="white">
            <WarningIcon w={6} h={6} color="red" me={2} />
            <AlertDescription color="dark-red" fontSize="sm">
              {errorText}
            </AlertDescription>
          </Alert>
        )}
      </>
    );
  }
}
