import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import PostModerationModal from 'app/components/modals/PostModerationModal';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import { selectModeratePostReasons } from 'app/models/posts/postsSelectors';

import i18n from 'i18n';

interface StateProps {
  moderatePostReasons: List<string>;
}

interface DispatchProps {
  getModeratePostReasons: () => void;
}

interface OwnProps {
  handleReportPost: (reasonId: string) => void;
  handleToggleClose: () => void;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state) => {
  return {
    moderatePostReasons: selectModeratePostReasons(state)
  };
};
const mapDispatchToProps: DispatchProps = {
  getModeratePostReasons: postsActions.getModeratePostReasons
};

export class ReportPostContainer extends React.Component<Props> {
  render() {
    const {
      moderatePostReasons,
      getModeratePostReasons,
      handleReportPost,
      handleToggleClose
    } = this.props;
    const reasons = moderatePostReasons
      ? moderatePostReasons
          .toJS()
          .map(({ id, value }) => ({ label: value, value: id }))
      : [];

    return (
      <PostModerationModal
        isCallToActionPending={false}
        handleToggleClose={handleToggleClose}
        handleModeration={handleReportPost}
        moderatePostReasons={reasons}
        getModeratePostReasons={getModeratePostReasons}
        callToAction={i18n.t('post.report')}
        optionsHeader={i18n.t('post.reasonForReport')}
        headerText={i18n.t('post.report')}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ReportPostContainer);
