import { Box } from '@chakra-ui/react';
import { MegaphoneIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';

import Header from 'app/components/Header';
import i18n from 'i18n';

export default class TipIcon extends React.PureComponent<any> {
  render() {
    return (
      <Box mt="1.4rem" mb="0.875rem">
        <MegaphoneIcon w={8} h={8} color="purple" me={2} />
        <Header size="h3" styleType="smallHeader">
          {i18n.t('tip.tip')}
        </Header>
      </Box>
    );
  }
}
