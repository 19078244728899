import { ToastId, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import Toast from 'app/components/Toast';

interface ToastPortalProps {
  type: 'success' | 'error';
  message?: string;
  autoHide?: boolean;
  delay?: number;
  clearFirstAlert?: () => void;
}

const ToastPortal: React.FC<ToastPortalProps> = ({
  type,
  message,
  autoHide,
  delay = 0,
  clearFirstAlert
}) => {
  const toast = useToast();
  const toastIdRef = React.useRef<ToastId | undefined>();

  const addToast = () => {
    toastIdRef.current = toast({
      position: 'bottom',
      duration: autoHide ? 5000 : null,
      render: () => <Toast type={type} message={message} />,
      containerStyle: {
        margin: '1rem 1rem 0 1rem',
        width: 'calc(100% - 2rem)',
        maxWidth: '49.688rem'
      },
      onCloseComplete: () => {
        if (clearFirstAlert) {
          clearFirstAlert();
        }
      }
    });
  };

  useEffect(() => {
    setTimeout(() => addToast(), delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ToastPortal;
