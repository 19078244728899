import {
  Box,
  chakra,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import React from 'react';

import Badge from 'app/components/Badge';
import DisplayName from 'app/components/DisplayName';
import UserIcon from 'app/components/UserIcon';
import UserProfileOverlayContainer from 'app/containers/UserProfileOverlayContainer';
import {
  IUser,
  IUserDefault
} from 'app/models/entities/users/usersEntitiesTypes';
import permissions from 'app/services/permissionsService';

import i18n from 'i18n';

const PostMetadataContainer = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '0.875rem',
    fontWeight: 'semibold',
    wordBreak: 'normal',
    m: 0
  }
});

export interface Props {
  user: IUser;
  fromMentor?: boolean;
  createdAt: string;
}

export default class PostMetadata extends React.PureComponent<Props> {
  static defaultProps = {
    createdAt: '0',
    user: IUserDefault
  };

  render() {
    const { user, createdAt, fromMentor } = this.props;
    const { displayName, id } = user;
    const isAdmin = permissions.isAdmin();
    const locales = { es };

    return (
      <PostMetadataContainer>
        <Popover trigger="hover" placement="auto-start">
          <PopoverTrigger>
            <PostMetadataContainer>
              <Flex align="center" me="0.4rem">
                <UserIcon user={user} />
              </Flex>
              <DisplayName
                displayName={displayName}
                id={id}
                isAdmin={isAdmin}
              />
            </PostMetadataContainer>
          </PopoverTrigger>
          <PopoverContent>
            {user.displayName ? (
              <UserProfileOverlayContainer user={user} />
            ) : null}
            <PopoverArrow />
          </PopoverContent>
        </Popover>
        {fromMentor && (
          <Box me={2}>
            <Badge type="mentor" />
          </Box>
        )}
        <Box as="span" color="silver" data-ref="created-at">
          {formatDistanceToNow(parseInt(createdAt, 10), {
            addSuffix: true,
            locale: locales[i18n.language]
          })}
        </Box>
      </PostMetadataContainer>
    );
  }
}
