import { createAction } from 'app/models/helpers/actionHelper';

export const actionClass = 'UI';

export const actionTypes = {
  FILE_DRAG_START: `${actionClass}/DRAG_START`,
  FILE_DRAG_END: `${actionClass}/DRAG_END`
};

export const actionCreators = {
  fileDragStart() {
    return createAction(actionTypes.FILE_DRAG_START);
  },
  fileDragEnd() {
    return createAction(actionTypes.FILE_DRAG_END);
  }
};
