import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import * as ReactMobiledoc from 'react-mobiledoc-editor';

export interface AtomButtonProps {
  className: string;
  atom: { name: string };
  type: 'button';
  tabIndex: number;
  ariaLabel?: string;
}

export const AtomButton: React.FC<AtomButtonProps> = ({
  className,
  atom,
  type,
  tabIndex,
  ariaLabel,
  children
}) => {
  const { editor } = React.useContext<any>(
    ReactMobiledoc.ReactMobileDocContext
  );

  const handleClick = (): void => {
    const payload = {
      newAtom: true
    };

    /** Grab selected content before overwriting with atom. */
    const atomValue = trim(window.getSelection()?.toString());
    const { isBlank, section } = get(editor, 'range.head');

    /** Only attempt atom insertion in markerable (standard markup) section.
     * Prevents throwing error if attempting to insert inside Card content.
     * */
    if (isBlank || section.isMarkerable) {
      editor.insertAtom(atom.name, atomValue, payload);
    }
  };

  return (
    <button
      aria-label={ariaLabel}
      className={className}
      type={type}
      onClick={handleClick}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};
