import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import PostBodyContent from 'app/components/PostBodyContent';
import { selectPostById } from 'app/models/posts/postsSelectors';

interface OwnProps {
  postId: string;
}

interface StateProps {
  postObject: Map<any, any>;
}

export type Props = StateProps & OwnProps;

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => ({
  postObject: selectPostById(state, ownProps.postId)
});

export class PostBodyContentContainer extends React.Component<Props> {
  render() {
    const { postObject } = this.props;

    if (!postObject.size) {
      return null;
    }

    return (
      <PostBodyContent
        content={postObject.get('content')}
        modifiedAt={postObject.get('content')}
        createdAt={postObject.get('content')}
      />
    );
  }
}

export default connect<StateProps, any, OwnProps>(mapStateToProps)(
  PostBodyContentContainer
);
