import { encodeMobiledoc } from 'app/models/helpers/encodeGraphqlContent';
import escapeQuotes from 'app/models/helpers/escapeQuotes';
import gql from 'app/models/helpers/gql';
import {
  SearchClickAnalytics,
  SearchSession
} from 'app/models/search/searchTypes';

import schema from './postsSchema';

const {
  Question,
  AnswersAndComments,
  ArchivedPost,
  Answer,
  AcceptVerifyAnswer,
  Comment,
  VotePost
} = schema;

export function moveCommentToAnswer(commentId: string): Promise<string> {
  const request = {
    query: `
      mutation MoveCommentToAnswer($commentId: String!) {
        moveCommentToAnswer(id: $commentId) {
          answer {
            ...answer
          }
        }
      }
      fragment answer on Answer ${Answer}
    `,
    variables: {
      commentId
    }
  };

  return gql(request).then((res) => res.data.moveCommentToAnswer.answer);
}

export function getQuestion(id: string): Promise<string> {
  const request = {
    query: `
      query GetQuestion($id: String!) {
        question(id: $id) { ...question, ...archivedQuestion }
      }
      fragment question on Question ${Question}
      fragment archivedQuestion on ArchivedPost ${ArchivedPost}

    `,
    variables: {
      id
    }
  };

  return gql(request).then((res) => res.data.question);
}

export function getAnswersByQuestionId(questionId: string): Promise<string> {
  const request = {
    query: `
      query GetAnswersByQuestionId($questionId: String!) {
        question(id: $questionId) { id, ...answersAndComments }
      }
      fragment answersAndComments on Question ${AnswersAndComments}
    `,
    variables: {
      questionId
    }
  };

  return gql(request).then((res) => res.data.question);
}

export function getQuestionAndLogClick(
  questionId: string,
  analyticsObject: SearchClickAnalytics,
  searchSession: SearchSession
): Promise<string> {
  const analytics = { ...analyticsObject, ...searchSession };
  const request = {
    query: `
      query GetQuestionAndLogClick($analytics: SearchAnalytics) {
        question(id: "${questionId}", analytics: $analytics) { ...question }
      }
      fragment question on Question ${Question}
    `,
    variables: {
      analytics
    }
  };

  return gql(request).then((res) => res.data.question);
}

export interface MutateQuestionArgs {
  title: string;
  body: any;
  projectLink?: string;
  nanodegreeKey?: string;
  projectId?: number;
  rubricId?: number;
}

export function createQuestion({
  title,
  body,
  projectLink,
  nanodegreeKey,
  projectId,
  rubricId
}: MutateQuestionArgs): Promise<string> {
  const request = {
    query: `mutation CreateQuestion($title: String!, $content: String!, $link: String, $nanodegreeKey: String, $projectId: Int, $rubricId: Int) {
      createQuestion(
        input: {
          title: $title,
          content: $content,
          link: $link,
          nanodegreeKey: $nanodegreeKey,
          projectID: $projectId,
          rubricID: $rubricId,
          source: "knowledge-web"
        }
      ) { question {...question }}
    }
    fragment question on Question ${Question}
    `,
    variables: {
      link: projectLink,
      title: escapeQuotes(title),
      content: encodeMobiledoc(body),
      nanodegreeKey,
      projectId,
      rubricId
    }
  };
  return gql(request).then((res) => res.data.createQuestion.question);
}

export function updateQuestion(
  questionId: string,
  {
    title,
    body,
    projectLink,
    nanodegreeKey,
    projectId,
    rubricId
  }: MutateQuestionArgs
): any {
  const request = {
    query: `mutation UpdateQuestion($questionId: String!, $title: String!, $content: String!, $link: String, $nanodegreeKey: String, $projectId: Int, $rubricId: Int) {
        updateQuestion(
          id: $questionId
          input: {
            title: $title,
            content: $content,
            link: $link,
            nanodegreeKey: $nanodegreeKey,
            projectID: $projectId,
            rubricID: $rubricId
          }
        ) { question {...question }}
      }
      fragment question on Question ${Question}
    `,
    variables: {
      link: projectLink,
      questionId,
      title: escapeQuotes(title),
      content: encodeMobiledoc(body),
      nanodegreeKey,
      projectId,
      rubricId
    }
  };

  return gql(request).then((res) => res.data.updateQuestion.question);
}

export function archivePost(postId: string): any {
  const request = {
    query: `mutation ArchivePost($id: String!) {
      archivePost(
        id: $id
      ) { post {id, userUid, postType}}
    }
    `,
    variables: {
      id: postId
    }
  };

  return gql(request).then((res) => res.data.archivePost);
}

export function upvotePost(postId: string): Promise<string> {
  const request = {
    query: `
      mutation UpvotePost($id: String!) {
        upvotePost(
          id: $id
        ) { post { ...post } }
      }
      fragment post on Post ${VotePost}
    `,
    variables: {
      id: postId
    }
  };

  return gql(request).then((res) => res.data.upvotePost.post);
}

export function downvotePost(postId: string): Promise<string> {
  const request = {
    query: `
      mutation DownvotePost($id: String!) {
        downvotePost(
          id: $id
        ) { post { ...post } }
      }
      fragment post on Post ${VotePost}
    `,
    variables: {
      id: postId
    }
  };

  return gql(request).then((res) => res.data.downvotePost.post);
}

export function clearvotePost(postId: string): Promise<string> {
  const request = {
    query: `
      mutation ClearvotePost($id: String!) {
        clearvotePost(
          id: $id
        ) { post { ...post } }
      }
      fragment post on Post ${VotePost}
    `,
    variables: {
      id: postId
    }
  };

  return gql(request).then((res) => res.data.clearvotePost.post);
}

export interface CreateAnswerArgs {
  body: any;
  questionId: string;
}

export function createAnswer({
  body,
  questionId
}: CreateAnswerArgs): Promise<string> {
  const request = {
    query: `mutation {
      createAnswer(
        questionId: "${questionId}"
        input: {
          content: "${encodeMobiledoc(body)}",
        }
      ) { answer { ...answer } }
    }
    fragment answer on Answer ${Answer}
    `
  };

  return gql(request).then((res) => res.data.createAnswer.answer);
}

interface UpdateAnswerArgs {
  content: any;
}

export function updateAnswer(
  answerId: string,
  { content }: UpdateAnswerArgs
): Promise<string> {
  const request = {
    query: `mutation UpdateAnswer($answerId: String!, $content: String!) {
      updateAnswer(
        id: $answerId
        input: {
          content: $content
        }
      ) { answer {...answer }}
    }
    fragment answer on Answer ${Answer}
    `,
    variables: {
      answerId,
      content: encodeMobiledoc(content)
    }
  };

  return gql(request).then((res) => res.data.updateAnswer.answer);
}

interface CreateCommentArgs {
  content: any;
}

export function createComment(
  postId: string,
  content: CreateCommentArgs
): Promise<string> {
  const request = {
    query: `mutation CreateComment(
        $postId: String!,
        $content: String!
      ) {
      createComment(
        postId: $postId
        input: {
          content: $content,
        }
      ) { comment { ...comment } }
    }
    fragment comment on Comment ${Comment}
    `,
    variables: {
      postId,
      content: encodeMobiledoc(content)
    }
  };

  return gql(request).then((res) => res.data.createComment.comment);
}

interface UpdateCommentArgs {
  content: any;
}

export function updateComment(
  commentId: string,
  { content }: UpdateCommentArgs
): Promise<string> {
  const request = {
    query: `mutation UpdateComment($commentId: String!, $content: String!) {
      updateComment(
        id: $commentId
        input: {
          content: $content
        }
      ) { comment {...comment }}
    }
    fragment comment on Comment ${Comment}
    `,
    variables: {
      commentId,
      content: encodeMobiledoc(content)
    }
  };

  return gql(request).then((res) => res.data.updateComment.comment);
}

export function acceptAnswer(answerId: string): Promise<string> {
  const request = {
    query: `mutation AcceptAnswer($id: String!) {
      acceptAnswer(
        id: $id
      ) { answer ${AcceptVerifyAnswer} }
    }
    `,
    variables: {
      id: answerId
    }
  };

  return gql(request).then((res) => res.data.acceptAnswer.answer);
}

export function unacceptAnswer(answerId: string): Promise<string> {
  const request = {
    query: `mutation UnacceptAnswer($id: String!) {
      unacceptAnswer(
        id: $id
      ) { answer ${AcceptVerifyAnswer} }
    }
    `,
    variables: {
      id: answerId
    }
  };

  return gql(request).then((res) => res.data.unacceptAnswer.answer);
}

export function verifyAnswer(answerId: string): Promise<string> {
  const request = {
    query: `mutation VerifyAnswer($id: String!) {
      verifyAnswer(
        id: $id
      ) { answer ${AcceptVerifyAnswer} }
    }
    `,
    variables: {
      id: answerId
    }
  };

  return gql(request).then((res) => res.data.verifyAnswer.answer);
}

export function unverifyAnswer(answerId: string): Promise<string> {
  const request = {
    query: `mutation UnverifyAnswer($id: String!) {
      unverifyAnswer(
        id: $id
      ) { answer ${AcceptVerifyAnswer} }
    }
    `,
    variables: {
      id: answerId
    }
  };

  return gql(request).then((res) => res.data.unverifyAnswer.answer);
}

export function adminArchivePostReasons(): any {
  const request = {
    query: `
      query {
        reportReasons{
          id
          value
        }
      }
      `
  };

  return gql(request).then((res) => res.data.reportReasons);
}

export function adminArchivePost(postId: string, reasonId: string): any {
  const request = {
    query: `mutation ArchivePost($id: String!, $reasonId: String) {
      archivePost(
        id: $id,
        reasonId: $reasonId
      ) { post {id, userUid, postType}}
    }
    `,
    variables: {
      id: postId,
      reasonId
    }
  };
  return gql(request).then((res) => res.data.archivePost);
}

export function reportPost(postId: string, reasonId: string): any {
  const request = {
    query: `mutation ReportPost($id: String!, $reasonId: String) {
      reportPost(
        id: $id,
        input: {reasonId: $reasonId}
      ) { id }
    }
    `,
    variables: {
      id: postId,
      reasonId
    }
  };
  return gql(request).then((res) => res.data.reportPost);
}

export function unarchivePost(postId: string): any {
  const request = {
    query: `mutation UnarchivePost($id: String!) {
      unarchivePost(
        id: $id
      ) { post {
        id} }
    }
    `,
    variables: {
      id: postId
    }
  };

  return gql(request).then((res) => res.data.unarchivePost);
}
