import _isFunction from 'lodash/isFunction';
import i18n from 'i18n';

import { RenderLocalArgs } from './filesTypes';

export function formatBytes(bytes: number): string {
  if (!bytes) {
    return '0 Bytes';
  }

  const KB = 1024;
  const sizeLabels = [
    i18n.t('fileSize.bytes'),
    i18n.t('fileSize.kb'),
    i18n.t('fileSize.mb'),
    i18n.t('fileSize.gb'),
    i18n.t('fileSize.tb'),
    i18n.t('fileSize.pb'),
    i18n.t('fileSize.eb'),
    i18n.t('fileSize.zb'),
    i18n.t('fileSize.yb')
  ];
  const scale = Math.floor(Math.log(bytes) / Math.log(KB));

  return `${bytes / Math.pow(KB, scale)} ${sizeLabels[scale]}`;
}

export function generateFileIdentifierString(fileRef?: File): string {
  if (fileRef) {
    const { lastModified, name, size } = fileRef;
    return [name, lastModified, size].join('');
  }
  return 'file-placeholder';
}

export function readLocalFile(
  fileRef: File,
  successCallback: (arg: RenderLocalArgs) => void
) {
  const reader = new FileReader();
  const localImageSrc = window.URL.createObjectURL(fileRef);

  reader.onload = (() => {
    return (e) => {
      // attach loaded image to element to calculate width/height dimensions
      const image = new Image();
      image.src = localImageSrc;
      image.onload = () => {
        if (_isFunction(successCallback)) {
          const callbackArgs: RenderLocalArgs = {
            localImageSrc,
            fileIdentifierString: generateFileIdentifierString(fileRef),
            filename: fileRef.name,
            width: image.width,
            height: image.height
          };
          successCallback(callbackArgs);
        }
      };
    };
  })();

  if (fileRef) {
    // Read in the image file as a data URL.
    reader.readAsDataURL(fileRef);
  }
}
