import { Button, chakra } from '@chakra-ui/react';

export const PublicActionButton = chakra(Button, {
  baseStyle: {
    fontSize: '0.813rem',
    padding: '0 1rem 0 0.75rem',
    color: 'cerulean',
    borderRadius: 'secondary',
    display: { base: 'flex', md: 'inline-flex' }
  }
});

export const PrivateActionButton = chakra(PublicActionButton, {
  baseStyle: {
    color: 'slate',
    alignSelf: 'center',
    padding: '0 1rem'
  }
});
