import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import PostModerationModal from 'app/components/modals/PostModerationModal';
import { isPending } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectModeratePostReasons
} from 'app/models/posts/postsSelectors';
import i18n from 'i18n';

export interface Props {
  moderatePostReasons: List<any>;
  adminDeletePostStatus: Map<any, any>;
  handleAdminDeletePost: (
    postId: string,
    parentId: string,
    reasonId: string
  ) => void;
  getModeratePostReasons: () => void;
  handleToggleClose: () => void;
}

const mapStateToProps = (state) => {
  return {
    adminDeletePostStatus: selectApiStatus(state, 'adminDeletePost'),
    moderatePostReasons: selectModeratePostReasons(state)
  };
};
const mapDispatchToProps = {
  getModeratePostReasons: postsActions.getModeratePostReasons
};

export class AdminDeletePostContainer extends React.Component<Props> {
  render() {
    const {
      adminDeletePostStatus,
      moderatePostReasons,
      getModeratePostReasons,
      handleAdminDeletePost,
      handleToggleClose
    } = this.props;

    const isAdminDeletePostPending = isPending(adminDeletePostStatus);
    const reasons = moderatePostReasons
      .toJS()
      .map(({ id, value }) => ({ label: value, value: id }));

    return (
      <PostModerationModal
        handleToggleClose={handleToggleClose}
        handleModeration={handleAdminDeletePost}
        isCallToActionPending={isAdminDeletePostPending}
        moderatePostReasons={reasons}
        getModeratePostReasons={getModeratePostReasons}
        callToAction={i18n.t('post.remove')}
        optionsHeader={i18n.t('post.reasonForRemoving')}
        headerText={i18n.t('post.remove')}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDeletePostContainer);
