import { all, call, put, takeEvery } from 'redux-saga/effects';

import { actionCreators as alertsActions } from 'app/models/alerts/alertsActions';
import { createAction } from 'app/models/helpers/actionHelper';
import { makeSagaLifecycle } from 'app/models/helpers/sagaHelper';
import i18n from 'i18n';

import { actionTypes } from './questionQueueActions';
import * as requests from './questionQueueExternalRequests';

export function* fetchAssignments() {
  const assignments = yield call(requests.fetchAssignments);
  yield put(createAction(actionTypes.FETCH_ASSIGNMENTS_FULFILLED, assignments));
}

export function* unassignQuestion(action) {
  const {
    payload: { questionId, reason }
  } = action;
  const assignments = yield call(requests.unassignQuestion, questionId, reason);
  yield put(createAction(actionTypes.UNASSIGN_QUESTION_FULFILLED, assignments));
}

export function* fetchQuestionStatus(action) {
  const result = yield call(requests.fetchQuestionStatus, action.payload);
  yield put(
    createAction(actionTypes.FETCH_QUESTION_STATUS_FULFILLED, {
      id: action.payload,
      state: result.state,
      atRisk: result.atRisk
    })
  );
}

export function* postQuestionRequeue(action) {
  const status = yield call(requests.postQuestionRequeue, action.payload);
  yield put(
    createAction(actionTypes.POST_QUESTION_REQUEUE_FULFILLED, {
      id: action.payload,
      state: status.state,
      atRisk: status.atRisk
    })
  );

  yield put(
    alertsActions.addAlert('success', i18n.t('saga.requestedMoreHelp'))
  );
}

export function* markAtRisk(action) {
  yield call(requests.markAtRisk, action.payload);
  yield put(
    createAction(actionTypes.MARK_AT_RISK_FULFILLED, {
      id: action.payload
    })
  );

  yield put(
    alertsActions.addAlert('success', i18n.t('saga.questionMarkedAtRisk'))
  );
}

export function* watchFetchAssignments() {
  yield takeEvery(
    actionTypes.FETCH_ASSIGNMENTS_START,
    makeSagaLifecycle(fetchAssignments, 'FETCH_ASSIGNMENTS', actionTypes)
  );
}

export function* watchUnassignQuestion() {
  yield takeEvery(
    actionTypes.UNASSIGN_QUESTION_START,
    makeSagaLifecycle(unassignQuestion, 'UNASSIGN_QUESTION', actionTypes)
  );
}

export function* watchFetchQuestionStatus() {
  yield takeEvery(
    actionTypes.FETCH_QUESTION_STATUS_START,
    makeSagaLifecycle(fetchQuestionStatus, 'FETCH_QUESTION_STATUS', actionTypes)
  );
}

export function* watchPostQuestionRequeue() {
  yield takeEvery(
    actionTypes.POST_QUESTION_REQUEUE_START,
    makeSagaLifecycle(postQuestionRequeue, 'POST_QUESTION_REQUEUE', actionTypes)
  );
}

export function* watchMarkAtRisk() {
  yield takeEvery(
    actionTypes.MARK_AT_RISK_START,
    makeSagaLifecycle(markAtRisk, 'MARK_AT_RISK', actionTypes)
  );
}

export default function* moduleRoot() {
  yield all([
    watchFetchAssignments(),
    watchUnassignQuestion(),
    watchFetchQuestionStatus(),
    watchPostQuestionRequeue(),
    watchMarkAtRisk()
  ]);
}
