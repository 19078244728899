import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import PostActionBar from 'app/components/PostActionBar';
import { isPending } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectCurrentQuestion
} from 'app/models/posts/postsSelectors';
import { actionCreators as questionQueueActions } from 'app/models/QuestionQueue/questionQueueActions';
import {
  selectQuestionAssignments,
  selectQuestionStatus
} from 'app/models/QuestionQueue/questionQueueSelectors';
import {
  QuestionAssignment,
  QuestionState,
  QuestionStatus
} from 'app/models/QuestionQueue/questionQueueTypes';
import { selectCertifications } from 'app/models/session/sessionSelectors';
import permissions from 'app/services/permissionsService';

import { canModerateQuestion } from './helpers';

interface LocationProps extends RouteComponentProps<any> {}

interface StateProps {
  deletePostStatus: Map<any, any>;
  assignments?: QuestionAssignment[];
  questionStatus?: QuestionStatus;
  canModerateQuestion: boolean;
}

interface DispatchProps {
  followQuestion: (questionId: string) => void;
  expandAnswerForm: (questionId: string) => void;
  collapseAnswerForm: (questionId: string) => void;
  reportPost: (questionId: string, reasonId: string) => void;
  deleteQuestion: (questionId: string) => void;
  unassignQuestion: (questionId: string, reason: string) => void;
  adminDeletePost: (
    questionId: string,
    reasonId: string,
    parentId?: string
  ) => void;
  markAtRisk: (questionId: string) => void;
}

interface OwnProps {
  questionId: string;
}

export type Props = StateProps & DispatchProps & OwnProps & LocationProps;

const mapStateToProps = (state): StateProps => {
  const currentQuestion = selectCurrentQuestion(state);
  const certifications = selectCertifications(state);
  const canModerate =
    currentQuestion &&
    certifications &&
    canModerateQuestion(currentQuestion.toJS(), certifications.toJS());

  return {
    deletePostStatus: selectApiStatus(state, 'deletePost'),
    assignments: selectQuestionAssignments(state),
    questionStatus: selectQuestionStatus(state, currentQuestion.toJS().id),
    canModerateQuestion: canModerate
  };
};

const mapDispatchToProps: DispatchProps = {
  expandAnswerForm: postsActions.expandAnswerForm,
  collapseAnswerForm: postsActions.collapseAnswerForm,
  deleteQuestion: postsActions.deletePost,
  adminDeletePost: postsActions.adminDeletePost,
  followQuestion: () => {
    return { type: '' };
  },
  reportPost: postsActions.reportPost,
  unassignQuestion: questionQueueActions.unassignQuestion,
  markAtRisk: questionQueueActions.markAtRisk
};

export class QuestionActionBarContainer extends React.Component<Props> {
  follow = () => {
    const { questionId, followQuestion } = this.props;
    followQuestion(questionId);
  };

  showAnswerForm = () => {
    const { expandAnswerForm, collapseAnswerForm, questionId } = this.props;
    // Quickly UNMOUNT/MOUNT ReplyForm to trigger scroll event:
    collapseAnswerForm(questionId);
    window.requestAnimationFrame(() => {
      expandAnswerForm(questionId);
    });
  };

  edit = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/edit`);
  };

  report = (reasonId: string) => {
    const { questionId, reportPost } = this.props;
    reportPost(questionId, reasonId);
  };

  unassign = (reason: string) => {
    const { questionId, unassignQuestion } = this.props;
    unassignQuestion(questionId, reason);
  };

  delete = () => {
    const { questionId, deleteQuestion } = this.props;
    deleteQuestion(questionId);
  };

  adminDelete = (reasonId: string) => {
    const { questionId, adminDeletePost } = this.props;
    adminDeletePost(questionId, reasonId);
  };

  markAtRisk = () => {
    const { questionId, markAtRisk } = this.props;
    markAtRisk(questionId);
  };

  render() {
    const {
      questionId,
      deletePostStatus,
      assignments,
      questionStatus,
      canModerateQuestion
    } = this.props;

    const handleFollow = null;
    const canEdit = permissions.canEditQuestion(questionId);
    const canDelete = permissions.canDeletePost(questionId);
    const handleEdit = canEdit ? this.edit : null;
    const handleDelete = canDelete ? this.delete : null;
    const deletePostPending = isPending(deletePostStatus);
    const canDeletePostAdmin =
      permissions.canDeletePostAdmin() || canModerateQuestion;
    const handleAdminDeletePost = canDeletePostAdmin ? this.adminDelete : null;
    const handleReport = permissions.canReport(questionId) ? this.report : null;
    const isAssignedQuestion = !!(assignments || []).find(
      (a) => a.questionId === questionId
    );
    const handleUnassign = isAssignedQuestion ? this.unassign : undefined;

    // Show the "Mark at Risk" button if the question is not already At Risk
    // and is in a state where that even makes sense (OPEN, QUEUED, PENDING).
    const canBeAtRisk = [
      QuestionState.OPEN,
      QuestionState.QUEUED,
      QuestionState.PENDING
    ];
    const handleMarkAtRisk =
      questionStatus &&
      !questionStatus.atRisk &&
      canBeAtRisk.includes(questionStatus.state)
        ? this.markAtRisk
        : undefined;

    return (
      <PostActionBar
        allowComments
        postId={questionId}
        handleFollow={handleFollow}
        handleAnswer={this.showAnswerForm}
        handleEdit={handleEdit}
        handleReport={handleReport}
        handleDelete={handleDelete}
        handleUnassign={handleUnassign}
        handleAdminDeletePost={handleAdminDeletePost}
        isDeletePostPending={deletePostPending}
        handleMarkAtRisk={handleMarkAtRisk}
        type="question"
      />
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(QuestionActionBarContainer)
);
