import gql from 'app/models/helpers/gql';
import {
  ApiQuestionQueueAssignment,
  QuestionStatus
} from './questionQueueTypes';

export async function fetchAssignments(): Promise<
  ApiQuestionQueueAssignment[]
> {
  const request = {
    query: `
      query KnowledgeWeb_FetchAssignments {
        queue {
          assigned {
            question {
              id
            }
            opportunity {
              answerPayment {
                amount
                currencyCode
              }
              commentPayment {
                amount
                currencyCode
              }
            }
          }
        }
      }
    `
  };
  const response = await gql(request);

  const { data } = response || {};
  const { queue } = data || {};
  const { assigned } = queue || {};
  return assigned || [];
}

export async function unassignQuestion(
  questionId: string,
  reason: string
): Promise<ApiQuestionQueueAssignment[]> {
  const request = {
    query: `
      mutation KnowledgeWeb_UnassignQuestion($id: Int!, $reason: String!) {
        queue {
          unassign(questionId: $id, reason: $reason) {
            question {
              id
            }
            opportunity {
              answerPayment {
                amount
                currencyCode
              }
              commentPayment {
                amount
                currencyCode
              }
            }
          }
        }
      }
    `,
    variables: { id: questionId, reason }
  };
  const response = await gql(request);

  const { data } = response || {};
  const { queue } = data || {};
  const { unassign } = queue || {};
  return unassign || [];
}

export async function fetchQuestionStatus(
  id: string
): Promise<QuestionStatus | undefined> {
  const request = {
    query: `
      query KnowledgeWeb_FetchQuestionStatus($id: Int) {
        queue {
          question(id: $id) {
            state
            atRisk
          }
        }
      }
    `,
    variables: { id }
  };
  const response = await gql(request);

  const { data } = response || {};
  const { queue } = data || {};
  const { question } = queue || {};
  return {
    state: question.state,
    atRisk: question.atRisk
  };
}

export async function postQuestionRequeue(
  id: string
): Promise<QuestionStatus | undefined> {
  const request = {
    query: `
      mutation KnowledgeWeb_QuestionRequeue($id: Int!) {
        queue {
          requeue(questionId: $id) {
            state
            atRisk
          }
        }
      }
    `,
    variables: { id }
  };
  const response = await gql(request);

  const { data } = response || {};
  const { queue } = data || {};
  const { requeue } = queue || {};
  return {
    state: requeue.state,
    atRisk: requeue.atRisk
  };
}

export async function markAtRisk(id: string) {
  const request = {
    query: `
      mutation KnowledgeWeb_MarkAtRisk($id: Int!) {
        queue {
          markAtRisk(questionId: $id) {
            success
          }
        }
      }
    `,
    variables: { id }
  };

  return gql(request).then((res) => res.data);
}
