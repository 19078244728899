import React from 'react';

type ThemeOptions = 'blue' | 'red';

const context = React.createContext<ThemeOptions>('blue');

export const ThemeConsumer = (props) => {
  return (
    <context.Consumer>
      {(state) => state && props.children(state)}
    </context.Consumer>
  );
};
export const ThemeProvider = context.Provider;
