import gql from 'app/models/helpers/gql';

interface AdditionalSearchResultData {
  [key: string]: {
    myVote: number;
    node: {
      title: string;
    };
    user: {
      displayName: string;
      id: string;
      profileImageUrl: string;
    };
  };
}

export function makeUserDataForSearchQuestions(
  ids: string[]
): AdditionalSearchResultData {
  const request = {
    query: ` 
      query AdditionalSearchData($ids: [String!]!) {
        searchData(posts: $ids) {
          id
          user {
            id
            displayName
            profileImageUrl
          }
          node {
            title
          }
          myVote
        }
      }`,
    variables: {
      ids
    }
  };

  return gql(request).then((res) => {
    return res.data;
  });
}
