import _ from 'lodash';

import activitySagas from 'app/models/activity/activitySagas';
import filesSagas from 'app/models/files/filesSagas';
import nanodegreeSagas from 'app/models/nanodegree/nanodegreeSagas';
import postsSagas from 'app/models/posts/postsSagas';
import questionQueueSagas from 'app/models/QuestionQueue/questionQueueSagas';
import ratingsSagas from 'app/models/ratings/ratingsSagas';
import searchSagas from 'app/models/search/searchSagas';
import sessionSagas from 'app/models/session/sessionSagas';
import unreadsSagas from 'app/models/unreads/unreadsSagas';
import { sagaMiddleware } from 'app/store';

const sagas = {
  searchSagas,
  postsSagas,
  sessionSagas,
  activitySagas,
  filesSagas,
  questionQueueSagas,
  nanodegreeSagas,
  ratingsSagas,
  unreadsSagas
};

export default function initalizeRootSaga() {
  // Load Watcher Sagas into redux middleware.
  _.forEach(sagas, (moduleRoot) => {
    sagaMiddleware.run(moduleRoot);
  });
}
