import { Box, Text } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import MobiledocRenderer, {
  getMarkupComponentDefault
} from 'mobiledoc-vdom-renderer';
import React from 'react';

import CenteredLoader from 'app/components/CenteredLoader';
import LinkAtom from 'app/components/RichTextEditor/atoms/LinkAtom';
import CodeBlockCard from 'app/components/RichTextEditor/cards/CodeBlockCard';
import { CodeBlockPayload } from 'app/components/RichTextEditor/cards/CodeBlockCard/codeBlockCardTypes';
import ImageCard from 'app/components/RichTextEditor/cards/ImageCard';
import { ImageCardPayload } from 'app/components/RichTextEditor/cards/ImageCard/imageCardTypes';
import { safeUserInput } from 'app/helpers/userContentHelpers';
import { decodeMobiledoc } from 'app/models/helpers/encodeGraphqlContent';

import { trimBodyContent } from './postBodyContentHelpers';

import i18n from 'i18n';

interface Props {
  content: string;
  modifiedAt: string;
  createdAt: string;
}

interface LinkPayload {
  value: string;
  payload: {
    newAtom: boolean;
    url: string;
    anchor: string;
  };
}

interface LinkProps {
  children: string;
  href: string;
}

// custom content cards
const cards = {
  Code: ({ payload }: CodeBlockPayload) => <CodeBlockCard payload={payload} />,
  Image: ({ payload }: ImageCardPayload) => <ImageCard payload={payload} />
};

const atoms = {
  Link: ({ payload }: LinkPayload) => <LinkAtom value="" payload={payload} />
};

// custom dom element rendering
const elements = {
  a: ({ href, ...props }: LinkProps) => (
    <a
      href={safeUserInput(href)}
      {...props}
      rel="external noopener noreferrer"
      target="_blank"
    >
      {props.children}
    </a>
  )
};

const renderContent = MobiledocRenderer({
  createElement: React.createElement,
  getCardComponent: (type) => cards[type],
  getAtomComponent: (type) => atoms[type],
  getMarkupComponent: (tag) => elements[tag] || getMarkupComponentDefault(tag)
});

export default class PostBodyContent extends React.PureComponent<Props> {
  render() {
    const { content, createdAt, modifiedAt } = this.props;

    if (_isEmpty(content)) {
      return <CenteredLoader />;
    }

    const renderedContent = trimBodyContent(
      renderContent(decodeMobiledoc(content))
    );

    const isPostEdited = createdAt !== modifiedAt;

    return (
      <Box maxW="none" className="post-body-content">
        {renderedContent.map((el, index) => ({
          ...el,
          key: index
        }))}
        {isPostEdited && (
          <Text as="span" size="sm" color="silver-light" fontWeight="bold">
            {i18n.t('post.edited')}
          </Text>
        )}
      </Box>
    );
  }
}
