const mediumHeaderBase = {
  fontSize: { base: '1.125rem', 'sm-md': '1.25rem' },
  color: 'slate',
  fontWeight: 'semibold',
  letterSpacing: 0,
  lineHeight: '1.5rem'
};

export const headerStyles = {
  fieldset: {
    color: 'dark-night-blue',
    fontWeight: 'semibold',
    mb: 2,
    fontSize: { base: 'sm', 'sm-md': 'md' }
  },
  heavyHeader: {
    fontWeight: 'semibold',
    lineHeight: 1.5,
    letterSpacing: 0,
    fontSize: { base: '1.125rem', 'sm-md': '1.5rem' }
  },
  mediumHeader: {
    ...mediumHeaderBase,
    fontSize: { base: '1.125rem', 'sm-md': '1rem' },
    lineHeight: '2rem'
  },
  mediumHeaderGrayDark: {
    ...mediumHeaderBase,
    color: 'silver-dark'
  },
  smallHeader: {
    fontSize: 'sm',
    fontWeight: 'semibold',
    color: 'slate',
    display: 'inline-block',
    letterSpacing: '0.0625em'
  }
};
