import { Map, fromJS } from 'immutable';

import { actionTypes as activityActions } from 'app/models/activity/activityActions';
import { mergeEntities } from 'app/models/entities/entitiesReducerHelpers';
import { actionTypes as postActions } from 'app/models/posts/postsActions';

type State = Map<string, string>;

export const initialState: State = fromJS({
  byId: {}
});

const deletePost = (state, postId) => {
  return state.deleteIn(['byId', postId]);
};

export default function questionsReducer(
  state: State = initialState,
  action: any
) {
  const { type, payload } = action;
  switch (type) {
    case postActions.FETCH_ANSWERS_BY_QUESTION_ID_FULFILLED:
    case postActions.CREATE_COMMENT_FULFILLED:
    case postActions.UPDATE_COMMENT_FULFILLED:
    case postActions.FETCH_QUESTION_FULFILLED: {
      const {
        entities: { comments }
      } = payload;
      return mergeEntities(state, comments);
    }

    case postActions.DELETE_POST_FULFILLED: {
      const { postId } = payload;
      return deletePost(state, postId);
    }

    case postActions.ADMIN_DELETE_POST_FULFILLED: {
      const { postId } = payload;
      return deletePost(state, postId);
    }

    case activityActions.FETCH_COMMENTS_FULFILLED: {
      const { comments } = payload.entities;
      return mergeEntities(state, comments);
    }
  }
  return state;
}
