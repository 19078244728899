import { List, Map, fromJS } from 'immutable';
import _isUndefined from 'lodash/isUndefined';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { selectEntities } from 'app/models/entities/entitiesSelectors';
import {
  answersSchema,
  questionsSchema
} from 'app/models/posts/postsNormalizers';

import constants from './activityConstants';

const { PAGE_SIZE, START_PAGE } = constants;

export const selectActivityParams = (state = Map({})) => {
  return state.getIn(['activity', 'activityParams'], fromJS([]));
};

// postType param makes selectActivityResultRefs reusable for questions, answers or comments

export const selectActivityResultRefs = (
  state: List<Map<string, string>> = fromJS({}),
  postType: string
) => {
  const results = state
    .getIn(['activity', 'activityResults'])
    .filter((value, key) => key === 'totalResults' || key === postType);

  if (postType && results.get(postType, List([])).isEmpty()) {
    return fromJS({
      [postType]: List([]),
      totalResults: 0
    });
  }

  return results;
};

export const selectQuestionsActivity = createSelector(
  selectActivityResultRefs,
  selectEntities,
  (activityResultIds, entities) => {
    return denormalize(activityResultIds, questionsSchema, entities).get(
      'questions'
    );
  }
);

export const selectAnswersActivity = createSelector(
  selectActivityResultRefs,
  selectEntities,
  (activityResultIds, entities) => {
    const denormalizedAnswerActivity = denormalize(
      activityResultIds,
      answersSchema,
      entities
    ).get('answers');
    return denormalizedAnswerActivity.map((item = fromJS([])) => {
      const title = item.getIn(['question', 'title'], '');
      const updatedItem = item.delete('question');
      return updatedItem.set('title', title);
    });
  }
);

export const selectTotalPages = createSelector(
  selectActivityResultRefs,
  (activityResultRefs) => {
    const totalResults = activityResultRefs.get('totalResults', 0);
    return Math.ceil(totalResults / PAGE_SIZE);
  }
);

export const selectTotalResults = createSelector(
  selectActivityResultRefs,
  (activityResultRefs) => {
    return activityResultRefs.get('totalResults', 0);
  }
);

export const selectPage = createSelector(
  selectActivityParams,
  (activityParamsRefs) => {
    return activityParamsRefs.get('page', START_PAGE);
  }
);

export const selectCurrentPaginationStart = createSelector(
  selectPage,
  (currentPage) => {
    const start = (currentPage - 1) * PAGE_SIZE + 1;
    return start;
  }
);

export const selectCurrentPaginationEnd = createSelector(
  selectPage,
  selectActivityResultRefs,
  (currentPage, activityResultRefs) => {
    const totalResults = activityResultRefs.get('totalResults', 0);
    const totalResultsPages = Math.ceil(totalResults / PAGE_SIZE);
    const end =
      currentPage === totalResultsPages
        ? totalResults
        : currentPage * PAGE_SIZE;
    return end;
  }
);

export const selectApiStatus = createSelector(
  (state: Map<string, string>, method?: string) => {
    if (method) {
      const methodState = state.getIn(['activityApi', method]);
      if (_isUndefined(methodState)) {
        throw Error(`Key "${method}" not found on activityApi.`);
      }
      return methodState;
    }
    return state.get('activityApi');
  },
  (status) => status
);

export const selectUserActivityStats = (state) => {
  const results = state.getIn(['activity', 'userActivityStats'], Map({}));
  return results;
};
