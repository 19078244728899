import * as queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { actionCreators } from 'app/models/search/searchActions';
import { START_PAGE } from 'app/models/search/searchConstants';
import {
  selectSearchPage,
  selectTotalPages
} from 'app/models/search/searchSelectors';
import CustomSearchResultsPaginationFooter from 'app/components/CustomSearchResultsPaginationFooter';

interface LocationProps extends RouteComponentProps<any> {}

interface SearchResultsPaginationContainerStateProps {
  currentPage: number;
  totalPages: number;
}

interface DispatchProps {
  setSearchParameters: (params) => void;
}

export type SearchResultsPaginationContainerProps = SearchResultsPaginationContainerStateProps &
  DispatchProps &
  LocationProps;

const mapStateToProps = (
  state
): SearchResultsPaginationContainerStateProps => ({
  currentPage: selectSearchPage(state),
  totalPages: selectTotalPages(state)
});

const mapDispatchToProps: DispatchProps = {
  setSearchParameters: actionCreators.setSearchParameters
};

class SearchResultsPaginationContainer extends React.PureComponent<
  SearchResultsPaginationContainerProps
> {
  componentDidMount() {
    this.maybeUpdatePageToMatchQueryParam();
  }

  componentDidUpdate() {
    this.maybeUpdatePageToMatchQueryParam();
  }

  maybeUpdatePageToMatchQueryParam() {
    const { location, currentPage, setSearchParameters } = this.props;
    const { page = START_PAGE } = queryString.parse(location.search, {
      parseNumbers: true
    });

    if (page !== currentPage) {
      setSearchParameters({ page });
    }
  }

  setUrlQueryString(params: any = {}): void {
    const { history, location } = this.props;
    const prevParams = queryString.parse(location.search);
    history.push({
      search: queryString.stringify({ ...prevParams, ...params })
    });
  }

  setPage(page: number) {
    this.props.setSearchParameters({ page });
    this.setUrlQueryString({ page });
  }

  previousPage = (): void => {
    const { currentPage } = this.props;

    if (currentPage <= START_PAGE) {
      return;
    }

    this.setPage(currentPage - 1);
  };

  nextPage = (): void => {
    const { currentPage, totalPages } = this.props;

    if (currentPage >= totalPages) {
      return;
    }

    this.setPage(currentPage + 1);
  };

  render() {
    const { totalPages, currentPage } = this.props;

    const shouldShowPrevious = currentPage > 1;

    const shouldShowNext = currentPage < totalPages;

    const shouldShowPagination = totalPages > 1;

    if (shouldShowPagination) {
      return (
        <CustomSearchResultsPaginationFooter
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          shouldShowNext={shouldShowNext}
          shouldShowPrevious={shouldShowPrevious}
          page={currentPage}
          totalPages={totalPages}
        />
      );
    }
    return null;
  }
}

export default withRouter(
  connect<SearchResultsPaginationContainerStateProps, DispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(SearchResultsPaginationContainer)
);
