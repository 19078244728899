import { Box, Text, UnorderedList } from '@chakra-ui/react';
import { FlagIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';

import ActionModal from 'app/components/modals/ActionModal';
import ActionModalButtons from 'app/components/modals/ActionModal/ActionModalButtons';

import i18n from 'i18n';

export interface Props {
  handleMarkAtRisk: () => void;
  handleToggleClose: () => void;
}

const MarkAtRiskModal: React.FC<Props> = (props) => {
  const { handleMarkAtRisk, handleToggleClose } = props;

  const handleSubmit = () => {
    handleMarkAtRisk();
    handleToggleClose();
  };

  return (
    <ActionModal
      headerIcon={<FlagIcon w={10} h={10} color="blue" />}
      theme="blue"
      onCancel={handleToggleClose}
      headerText={i18n.t('markAtRisk.markAtRisk')}
    >
      <Text>{i18n.t('markAtRisk.doYouWantToMark')}</Text>
      <Box mb={6}>
        <UnorderedList m="1em" listStyleType="initial">
          <li>{i18n.t('markAtRisk.givenPriority')}</li>
          <li>{i18n.t('markAtRisk.payReviewers')}</li>
          <li>{i18n.t('markAtRisk.onlyAssignedToHighLevel')}</li>
        </UnorderedList>
      </Box>
      <ActionModalButtons
        theme="blue"
        primary={{
          text: i18n.t('markAtRisk.markAtRisk'),
          callback: handleSubmit
        }}
        secondary={{
          text: i18n.t('common.cancel'),
          callback: handleToggleClose
        }}
      />
    </ActionModal>
  );
};
export default MarkAtRiskModal;
