import React from 'react';
import * as ReactDOM from 'react-dom';

export interface Props {
  children: JSX.Element | JSX.Element[];
}

export const appRoot: HTMLElement =
  document.getElementById('root') || document.createElement('div');

export default class Portal extends React.PureComponent<Props> {
  el: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    appRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    appRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
