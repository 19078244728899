import { actionTypes } from './nanodegreeActions';
import { nanodegreesByKeyFromApiEnrollments } from './nanodegreeReducerHelpers';
import {
  ApiEnrollment,
  ApiReviewsProject,
  Nanodegree,
  ReviewsProject
} from './nanodegreeTypes';

export interface NanodegreeState {
  readonly enrollmentsByKey: { [key: string]: Nanodegree };
  readonly graduatedByKey: { [key: string]: Nanodegree };
  readonly nodeTitlesByKey: { [key: string]: string };
  readonly reviewsProjectsById: { [id: number]: ReviewsProject };
}

export const initialState: NanodegreeState = {
  enrollmentsByKey: {},
  graduatedByKey: {},
  nodeTitlesByKey: {},
  reviewsProjectsById: {}
};

export default function reducer(
  state = initialState,
  action: any
): NanodegreeState {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_NANODEGREE_ENROLLMENTS_FULFILLED: {
      const nodeTitlesByKey = payload.enrolled
        .concat(payload.graduated)
        .reduce(
          (
            titlesByKey: { [key: string]: string },
            enrollment: Partial<ApiEnrollment>
          ) => {
            if (enrollment?.root_node?.title) {
              titlesByKey[enrollment.root_node.key] =
                enrollment.root_node.title;
            }
            return titlesByKey;
          },
          {}
        );

      return {
        ...state,
        nodeTitlesByKey,
        enrollmentsByKey: nanodegreesByKeyFromApiEnrollments(payload.enrolled),
        graduatedByKey: nanodegreesByKeyFromApiEnrollments(payload.graduated)
      };
    }

    case actionTypes.FETCH_ALL_REVIEWS_PROJECTS_FULFILLED: {
      const reviewsProjectsById = payload.reduce(
        (
          projectsById: { [id: number]: ReviewsProject },
          project: ApiReviewsProject
        ) => {
          projectsById[project.id] = {
            id: project.id,
            title: project.name,
            isCareer: !!project.is_career
          };
          return projectsById;
        },
        {}
      );
      return { ...state, reviewsProjectsById };
    }

    default: {
      return state;
    }
  }
}
