import { chakra, ListItem, Text } from '@chakra-ui/react';

export const ToolbarItem = chakra(ListItem, {
  baseStyle: {
    listStyleType: 'none',
    w: 'full'
  }
});

export const TooltipLabel = chakra(Text, {
  baseStyle: {
    fontSize: '0.8125rem',
    fontWeight: 'semibold',
    lineHeight: 'h5',
    textTransform: 'uppercase',
    p: '0.5rem 1.25rem'
  }
});

export const SectionDivider = chakra(ListItem, {
  baseStyle: {
    listStyleType: 'none',
    bgColor: 'silver-lighter',
    w: '1px',
    h: 'full'
  }
});
