import React from 'react';
import scrollIntoView from 'scroll-into-view';

export interface Props {
  searchPage: number;
}

export default class ScrollToComponent extends React.PureComponent<Props> {
  scrollTargetDomWrapper: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.scrollTargetDomWrapper = React.createRef();
  }

  componentDidUpdate(prevProps: Props) {
    scrollIntoView(this.scrollTargetDomWrapper.current, {
      time: 500,
      align: { top: 0.075 } // percent of window area to include. 0.5 = 50% of window
    });
  }

  render() {
    return <div ref={this.scrollTargetDomWrapper} />;
  }
}
