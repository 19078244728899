import { Map, fromJS } from 'immutable';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import React from 'react';
import { connect } from 'react-redux';

import ReplyForm from 'app/components/ReplyForm';
import ReplyFormPlaceholder from 'app/components/ReplyFormPlaceholder';
import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { actionCreators as alertsActions } from 'app/models/alerts/alertsActions';
import { selectIsImageUploadPending } from 'app/models/files/filesSelectors';
import { hasError, isFulfilled } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectIsAnswerFormExpanded
} from 'app/models/posts/postsSelectors';
import { selectUser } from 'app/models/session/sessionSelectors';

import { validate, ValidationError } from '../answerFormContainerHelpers';
import i18n from 'i18n';

interface StateProps {
  createAnswerApiStatus: Map<string, string>;
  getQuestionApiStatus: Map<string, string>;
  isFormExpanded: boolean;
  user: Map<string, string>;
  isImageUploadPending: boolean;
}

interface DispatchProps {
  addAlert: (type: string, message: string) => void;
  createAnswer: (questionId: string, content: {}) => void;
  resetCreateAnswer: () => void;
  expandAnswerForm: (questionId: string) => void;
  collapseAnswerForm: (questionId: string) => void;
}

interface OwnProps {
  questionId: string;
}

export type Props = StateProps & DispatchProps & OwnProps;

interface State {
  validationErrors: ValidationError[];
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => ({
  isFormExpanded: selectIsAnswerFormExpanded(state, ownProps.questionId),
  getQuestionApiStatus: selectApiStatus(state, 'getQuestion'),
  createAnswerApiStatus: selectApiStatus(state, 'createAnswer'),
  user: selectUser(state),
  isImageUploadPending: selectIsImageUploadPending(state)
});

const mapDispatchToProps: DispatchProps = {
  addAlert: alertsActions.addAlert,
  expandAnswerForm: postsActions.expandAnswerForm,
  collapseAnswerForm: postsActions.collapseAnswerForm,
  createAnswer: postsActions.createAnswer,
  resetCreateAnswer: postsActions.resetCreateAnswer
};

export class CreateAnswerFormContainer extends React.PureComponent<
  Props,
  State
> {
  state = {
    validationErrors: []
  };

  componentDidUpdate(prevProps: Props) {
    this.checkCreateAnswerStatus(prevProps);
  }

  componentWillUnmount() {
    const { resetCreateAnswer = _noop } = this.props;
    this.hideRichTextInput();
    resetCreateAnswer();
  }

  checkCreateAnswerStatus(prevProps: Props) {
    const { createAnswerApiStatus: prevStatus } = prevProps;
    const { createAnswerApiStatus, addAlert = _noop } = this.props;

    const shouldShowError =
      hasError(createAnswerApiStatus) && !hasError(prevStatus);

    if (shouldShowError) {
      addAlert('error', i18n.t('answer.failedToSubmit'));
    }
  }

  showRichTextInput = () => {
    const { questionId = '', expandAnswerForm = _noop } = this.props;
    expandAnswerForm(questionId);
  };

  hideRichTextInput = () => {
    const { questionId = '', collapseAnswerForm = _noop } = this.props;
    collapseAnswerForm(questionId);
  };

  validateAnswer(content: Mobiledoc): ValidationError[] {
    const validationErrors: ValidationError[] = validate(content);
    this.setState({ validationErrors });
    return validationErrors;
  }

  handleChange = (content: Mobiledoc): void => {
    this.validateAnswer(content);
  };

  dispatchCreateAnswer = (content: Mobiledoc): void => {
    const { questionId = '', createAnswer = _noop } = this.props;
    const hasFormError = !!this.validateAnswer(content).length;

    if (!hasFormError) {
      createAnswer(questionId, content);
    }
  };

  render() {
    const {
      isFormExpanded,
      createAnswerApiStatus,
      getQuestionApiStatus,
      user = fromJS({}),
      isImageUploadPending
    } = this.props;

    if (!isFulfilled(getQuestionApiStatus)) {
      return null;
    }

    const replyFormError = _find(this.state.validationErrors, {
      field: 'content'
    });

    if (isFormExpanded) {
      return (
        <ReplyForm
          title={i18n.t('answer.yourAnswer')}
          subtitle={i18n.t('answer.writeOrComment')}
          onSubmit={this.dispatchCreateAnswer}
          onChange={this.handleChange}
          onCancel={this.hideRichTextInput}
          errorMessage={_get(replyFormError, 'message', null)}
          apiStatus={createAnswerApiStatus}
          isImageUploadPending={isImageUploadPending}
        />
      );
    }

    return (
      <ReplyFormPlaceholder
        showRichTextInput={this.showRichTextInput}
        placeholder={i18n.t('answer.writeOrComment')}
        user={user.toJS()}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateAnswerFormContainer);
