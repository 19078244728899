import { Box, Checkbox } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import SearchFilterTitle from 'app/components/SearchFilter/SearchFilterTitle';
import NanodegreeTagsContainer from 'app/containers/NanodegreeTagsContainer';
import { Nanodegree, Project } from 'app/models/nanodegree/nanodegreeTypes';
import i18n from 'i18n';

export interface Props {
  handleFilterChange: (selection: {
    nanodegree?: Nanodegree;
    project?: Project;
  }) => void;
  handleFilterByUnanswered: (filterArg: boolean) => void;
  selectedNanodegree?: Nanodegree;
  selectedProject?: Project;
  initialNanodegreeKey?: string;
  initialProjectId?: number;
  filterByUnanswered: boolean;
}

const SearchFilter: React.FC<Props> = ({
  handleFilterChange,
  handleFilterByUnanswered,
  selectedNanodegree,
  selectedProject,
  initialNanodegreeKey,
  initialProjectId,
  filterByUnanswered
}) => {
  const handleUnansweredChange = useCallback(
    ({ target: { checked } }) => {
      handleFilterByUnanswered(checked);
    },
    [handleFilterByUnanswered]
  );

  return (
    <Box
      position="sticky"
      top={5}
      bgColor="silver-lightest"
      borderRadius="secondary"
      p="1rem 2rem 2.75rem 2rem"
    >
      <SearchFilterTitle />
      <Box borderTop="1px solid" borderTopColor="zircon" mt={3} pt={6}>
        <NanodegreeTagsContainer
          handleChange={handleFilterChange}
          selectedNanodegree={selectedNanodegree}
          selectedProject={selectedProject}
          initialNanodegreeKey={initialNanodegreeKey}
          initialProjectId={initialProjectId}
          options={{
            includeGraduatedNanodegrees: true,
            includePseudoNanodegrees: true
          }}
        />
        <Checkbox
          id="filter-by-unanswered"
          isChecked={filterByUnanswered}
          onChange={handleUnansweredChange}
          spacing={4}
        >
          {i18n.t('search.unanswered')}
        </Checkbox>
      </Box>
    </Box>
  );
};

export default SearchFilter;
