import { Flex, Text } from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import { CelebrateIcon, WarningIcon } from '@udacity/chakra-veritas-icons';

import i18n from 'i18n';

interface ToastProps {
  type: 'success' | 'error';
  message?: string;
}

const Toast: React.FC<ToastProps> = ({ type, message }) => {
  const isSuccess = type === 'success';
  const isError = type === 'error';

  const defaultMessage = isSuccess
    ? i18n.t('action.success')
    : i18n.t('error.requestHasNotBeenProcessed');
  const icon = isSuccess ? (
    <CelebrateIcon w={6} h={6} color="white" />
  ) : (
    <WarningIcon w={6} h={6} color="white" />
  );

  return (
    <Flex
      align="center"
      gap={4}
      bgColor={isSuccess ? 'green' : 'slate-light'}
      borderTopRadius="secondary"
      p="1rem 2rem"
      w="full"
    >
      {icon}
      <Text size="sm" color="white">
        {message ?? defaultMessage}{' '}
        {isError && (
          <Trans i18nKey="error.tryAgainOrContact">
            Please try again or contact <strong>support@udacity.com</strong> for
            assistance.
          </Trans>
        )}
      </Text>
    </Flex>
  );
};

export default Toast;
