import { Box, Text } from '@chakra-ui/react';

import i18n from 'i18n';

export interface Props {
  unreadTotal?: number;
}

export const NotificationBadge: React.FC<Props> = ({ unreadTotal }) => {
  const unreadCount = unreadTotal && unreadTotal > 99 ? '99+' : unreadTotal;

  return (
    <Box
      title={i18n.t('notification.notification')}
      display="inline-flex"
      justifyContent="center"
      alignSelf="center"
    >
      {unreadCount ? (
        <Text
          size="xs"
          title={i18n.t('notification.unreadPostCount')}
          color="white"
          fontWeight="semibold"
          borderRadius="1rem"
          minWidth="1.5rem"
          alignSelf="center"
          align="center"
          bgColor="red"
          shadow="shadow-1"
          p="0 0.25rem"
        >
          {unreadCount}
        </Text>
      ) : (
        <Box
          as="span"
          title={i18n.t('notification.unreadPosts')}
          borderRadius="circle"
          shadow="shadow-1"
          me={2}
          alignSelf="center"
          w={2}
          h={2}
          bgColor="red"
        />
      )}
    </Box>
  );
};

export default NotificationBadge;
