import { chakra } from '@chakra-ui/react';
import _merge from 'lodash/merge';
import React from 'react';

import { getCaret, hasSelection, insertTab } from 'app/helpers/tabInput';
import { activeKeys } from 'app/helpers/trackKeypress';

const InvisibleTextarea = chakra('textarea', {
  baseStyle: {
    bg: 'transparent',
    color: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'md',
    lineHeight: 'inherit',
    resize: 'none',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 'docked',
    caretColor: 'black',
    border: 0,
    p: 0,
    w: 'full',
    h: 'full',
    minH: 0
  }
});

export interface Props {
  handleInput: (arg: React.SyntheticEvent) => void;
  defaultValue: string;
  focusOnLoad: boolean;
}

export interface State {
  value: string;
  caretRange: { start: number; end: number };
}

export default class InvisibleInput extends React.PureComponent<Props, State> {
  textarea: HTMLTextAreaElement;

  state = {
    value: this.props.defaultValue || '',
    caretRange: {
      start: 0,
      end: 0
    }
  };

  componentDidMount() {
    const { focusOnLoad } = this.props;
    if (focusOnLoad) {
      window.requestAnimationFrame(() => {
        this.textarea.focus();
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (activeKeys.get('Tab') === true) {
      // restore selected text range after tabCapture content update
      this.restoreTextSelection(prevState);
    }
  }

  handleInput = (e: any) => {
    const { value } = e.target as HTMLInputElement;

    this.props.handleInput(e);
    this.setState({ value });
  };

  tabCapture = (e: any) => {
    if (activeKeys.get('Tab') === true) {
      // cancle tab exit from input
      e.preventDefault();

      // insert tab at cursor or selected range
      const tabbedValue = insertTab(this.textarea);

      // save content changes
      this.setState({ caretRange: getCaret(this.textarea) });
      this.handleInput(_merge(e, { target: { value: tabbedValue } }));
    }
  };

  restoreTextSelection(prevState: { value: string }) {
    const charDiff = this.state.value.length - prevState.value.length;
    // adjust selection to include additional spaces
    const selectEnd = this.state.caretRange.end + charDiff;
    // selectSart = selectEnd indicates single cursor position (no selected text)
    const selectStart = hasSelection(this.state.caretRange)
      ? this.state.caretRange.start
      : selectEnd;

    this.textarea.setSelectionRange(selectStart, selectEnd);
  }

  render() {
    return (
      <InvisibleTextarea
        rows={1}
        ref={(el) => {
          this.textarea = el || document.createElement('textarea');
        }}
        onKeyDown={this.tabCapture}
        onKeyUp={this.tabCapture}
        onChange={this.handleInput}
        value={this.state.value}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
      />
    );
  }
}
