import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { FlagIcon, BadgeIcon } from '@udacity/chakra-veritas-icons';

import i18n from 'i18n';

export interface Props {
  type?: 'staff' | 'mentor';
}

const Badge: React.FC<Props> = ({ type }) => {
  switch (type) {
    case 'mentor': {
      return (
        <Flex as="span" align="center">
          <FlagIcon w={6} h={6} color="cerulean" />
          <Text size="xs" color="cerulean-dark">
            {i18n.t('badge.mentor')}
          </Text>
        </Flex>
      );
    }
    case 'staff': {
      return (
        <Flex as="span" align="center">
          <BadgeIcon w={6} h={6} color="purple" />
          <Text size="xs" color="purple-dark">
            {i18n.t('badge.staff')}
          </Text>
        </Flex>
      );
    }
    default: {
      return null;
    }
  }
};
export default Badge;
