import {
  selectAnswerById,
  selectCommentById,
  selectQuestionById,
  selectMostRecentAnswer
} from 'app/models/posts/postsSelectors';
import {
  selectPermissions,
  selectSession,
  selectCurrentUserIsStaff
} from 'app/models/session/sessionSelectors';

import store from '../store';

const APPROVED_ACTIONS = {
  assignRole: 'assignRole',
  createTag: 'createTag',
  updateTag: 'updateTag',
  whitelistNanodegree: 'whitelistNanodegree',
  lockPost: 'lockPost',
  verifyPost: 'verifyPost',
  deletePost: 'deletePost',
  updateOtherPost: 'updateOtherPost',
  canAsk: 'canAsk',
  canComment: 'canComment',
  canAnswer: 'canAnswer',
  moveCommentToAnswer: 'moveCommentToAnswer'
};

export const makePermissions = (reduxStore: any): any => {
  const getState = (): any => reduxStore.getState();
  const getPermissions = (): any => selectPermissions(getState());
  const getSession = (): any => selectSession(getState());

  const isMe = (userId: string): boolean => {
    const session = getSession();
    return userId === session.getIn(['me', 'user', 'id']);
  };

  const hasPermission = (permission: string): boolean => {
    const permissions = getPermissions();
    if (!permissions) {
      return false;
    }
    return permissions.includes(permission);
  };

  const isQuestionAuthor = (questionId: string): boolean => {
    const question = selectQuestionById(getState(), questionId);
    if (!question) {
      return false;
    }
    const questionAuthorId = question.get('user');
    return isMe(questionAuthorId);
  };

  const isAnswerAuthor = (answerId: string): boolean => {
    const answer = selectAnswerById(getState(), answerId);
    if (!answer) {
      return false;
    }
    const answerAuthorId = answer.get('user');
    return isMe(answerAuthorId);
  };

  const isMentorAnswer = (answerId: string): boolean => {
    const answer = selectAnswerById(getState(), answerId);
    if (!answer) {
      return false;
    }
    return !!answer.get('fromMentor');
  };

  const isCommentAuthor = (commentId: string): boolean => {
    const comment = selectCommentById(getState(), commentId);
    if (!comment) {
      return false;
    }
    const commentAuthorId = comment.get('user');
    return isMe(commentAuthorId);
  };

  const isMentorComment = (commentId: string): boolean => {
    const comment = selectCommentById(getState(), commentId);
    if (!comment) {
      return false;
    }
    return comment.get('fromMentor');
  };

  const isMostRecentAnswer = (
    questionId: string,
    answerId: string
  ): boolean => {
    const mostRecentAnswer = selectMostRecentAnswer(getState(), questionId);
    return mostRecentAnswer.get('id') === answerId;
  };

  const canEditQuestion = (questionId: string): boolean => {
    return isQuestionAuthor(questionId);
  };

  const canEditAnswer = (answerId: string): boolean => {
    return isAnswerAuthor(answerId);
  };

  const canEditComment = (commentId: string): boolean => {
    return isCommentAuthor(commentId);
  };

  const canAcceptAnswer = (questionId: string): boolean => {
    return isQuestionAuthor(questionId);
  };

  const canVerifyAnswer = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.verifyPost);
  };

  const canRateAnswer = (answerId: string, questionId: string): boolean => {
    return (
      isMentorAnswer(answerId) &&
      isQuestionAuthor(questionId) &&
      isMostRecentAnswer(questionId, answerId)
    );
  };

  const canAsk = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.canAsk);
  };

  const canAnswer = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.canAnswer);
  };

  const canComment = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.canComment);
  };

  const isReadOnly = (): boolean => {
    // If you can't comment, you can't answer, and you can't ask then you are read only
    return (
      !hasPermission(APPROVED_ACTIONS.canComment) &&
      !hasPermission(APPROVED_ACTIONS.canAnswer) &&
      !hasPermission(APPROVED_ACTIONS.canAsk)
    );
  };

  const canMoveCommentToAnswer = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.moveCommentToAnswer);
  };

  const canLockPost = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.lockPost);
  };

  const canDeletePost = (postId: string): boolean => {
    return (
      isQuestionAuthor(postId) ||
      (isAnswerAuthor(postId) && !isMentorAnswer(postId)) ||
      (isCommentAuthor(postId) && !isMentorComment(postId))
    );
  };

  const canDeletePostAdmin = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.deletePost);
  };

  const canUpdatePostTags = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.updateOtherPost);
  };

  const canReport = (postId: string): boolean => {
    return !canDeletePost(postId) && !canDeletePostAdmin();
  };

  const isAdmin = (): boolean => {
    return hasPermission(APPROVED_ACTIONS.deletePost);
  };

  const isStaff = (): boolean => {
    return selectCurrentUserIsStaff(getState());
  };

  return {
    isQuestionAuthor,
    isAdmin,
    isStaff,
    canEditQuestion,
    canEditAnswer,
    canEditComment,
    canVerifyAnswer,
    canAcceptAnswer,
    canRateAnswer,
    canLockPost,
    canDeletePost,
    canDeletePostAdmin,
    canUpdatePostTags,
    canReport,
    isReadOnly,
    canAsk,
    canAnswer,
    canComment,
    canMoveCommentToAnswer,
    isMostRecentAnswer
  };
};

export default makePermissions(store);
