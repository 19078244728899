import React from 'react';
import { connectStateResults } from 'react-instantsearch-core';
import SearchNoResultsFound from './SearchNoResultsFound';

interface ConditionalNoResultsFoundProps {
  searching: boolean;
}

const ConditionalNoResultsFound: React.FC<ConditionalNoResultsFoundProps> = ({
  searching
}) => {
  // A loading indicator could be used instead of null, but the
  // transition is so quick, the indicator is visually disruptive
  return searching ? null : <SearchNoResultsFound />;
};

export const ConnectedNoResultsFound = connectStateResults(
  ConditionalNoResultsFound
);
