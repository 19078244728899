import { combineReducers } from 'redux-immutable'; // immutablejs equivalent of Redux's combineReducers

import {
  makeApiReducer,
  normalizeApiActions
} from 'app/models/helpers/apiReducerHelpers';

import { actionClass } from './postsActions';

const getQuestionApiActions = normalizeApiActions(
  'FETCH_QUESTION',
  actionClass
);

const getAnswersByQuestionIdActions = normalizeApiActions(
  'FETCH_ANSWERS_BY_QUESTION_ID',
  actionClass
);

const createQuestionApiActions = normalizeApiActions(
  'CREATE_QUESTION',
  actionClass
);

const updateQuestionApiActions = normalizeApiActions(
  'UPDATE_QUESTION',
  actionClass
);

const moveCommentToAnswerApiActions = normalizeApiActions(
  'MOVE_COMMENT_TO_ANSWER',
  actionClass
);

const deletePostApiActions = normalizeApiActions('DELETE_POST', actionClass);

const upvotePostApiActions = normalizeApiActions('UPVOTE_POST', actionClass);
const downvotePostApiActions = normalizeApiActions(
  'DOWNVOTE_POST',
  actionClass
);
const clearvotePostApiActions = normalizeApiActions(
  'CLEARVOTE_POST',
  actionClass
);

const getAnswersApiActions = normalizeApiActions('FETCH_ANSWERS', actionClass);

const createAnswerApiActions = normalizeApiActions(
  'CREATE_ANSWER',
  actionClass
);

const updateAnswerApiActions = normalizeApiActions(
  'UPDATE_ANSWER',
  actionClass
);

const acceptAnswerApiActions = normalizeApiActions(
  'ACCEPT_ANSWER',
  actionClass
);

const unacceptAnswerApiActions = normalizeApiActions(
  'UNACCEPT_ANSWER',
  actionClass
);

const verifyAnswerApiActions = normalizeApiActions(
  'VERIFY_ANSWER',
  actionClass
);

const unverifyAnswerApiActions = normalizeApiActions(
  'UNVERIFY_ANSWER',
  actionClass
);

const createCommentApiActions = normalizeApiActions(
  'CREATE_COMMENT',
  actionClass
);

const updateCommentApiActions = normalizeApiActions(
  'UPDATE_COMMENT',
  actionClass
);

const getAdminDeletePostReasonsApiActions = normalizeApiActions(
  'FETCH_MODERATE_POST_REASONS',
  actionClass
);
const adminDeletePostApiActions = normalizeApiActions(
  'ADMIN_DELETE_POST',
  actionClass
);
const unarchivePostApiActions = normalizeApiActions(
  'UNARCHIVE_POST',
  actionClass
);

export default combineReducers({
  getQuestion: makeApiReducer(getQuestionApiActions),
  getAnswersByQuestionId: makeApiReducer(getAnswersByQuestionIdActions),
  createQuestion: makeApiReducer(createQuestionApiActions),
  updateQuestion: makeApiReducer(updateQuestionApiActions),
  deletePost: makeApiReducer(deletePostApiActions),
  upvotePost: makeApiReducer(upvotePostApiActions),
  downvotePost: makeApiReducer(downvotePostApiActions),
  clearvotePost: makeApiReducer(clearvotePostApiActions),
  getAnswers: makeApiReducer(getAnswersApiActions),
  createAnswer: makeApiReducer(createAnswerApiActions),
  updateAnswer: makeApiReducer(updateAnswerApiActions),
  acceptAnswer: makeApiReducer(acceptAnswerApiActions),
  unacceptAnswer: makeApiReducer(unacceptAnswerApiActions),
  verifyAnswer: makeApiReducer(verifyAnswerApiActions),
  unverifyAnswer: makeApiReducer(unverifyAnswerApiActions),
  createComment: makeApiReducer(createCommentApiActions),
  updateComment: makeApiReducer(updateCommentApiActions),
  moderatePostReasons: makeApiReducer(getAdminDeletePostReasonsApiActions),
  adminDeletePost: makeApiReducer(adminDeletePostApiActions),
  unarchivePost: makeApiReducer(unarchivePostApiActions),
  moveCommentToAnswer: makeApiReducer(moveCommentToAnswerApiActions)
});
