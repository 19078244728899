import { Box, Flex, Link } from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import _isEmpty from 'lodash/isEmpty';
import _truncate from 'lodash/truncate';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import ActivityAcceptedAnswer from 'app/components/ActivityAcceptedAnswer';
import CommentCount from 'app/components/CommentCount';
import Header from 'app/components/Header';
import HighlightedText from 'app/components/HighlightedText';
import NotificationBadge from 'app/components/NotificationBadge';
import PostVoteContainer from 'app/containers/PostVoteContainer';
import { IActivityAnswer } from 'app/models/activity/activityTypes';
import { UnreadPost } from 'app/models/unreads/unreadsTypes';

import {
  AnswerCommentWrapper,
  CommentWrapper,
  CreatedAtText,
  PostItem,
  PostVoteWrapper
} from './factoryComponents';
import i18n from 'i18n';

export interface Props {
  post: IActivityAnswer;
  unreads: { [postId: string]: UnreadPost } | undefined;
}

export default class ActivityAnswerItem extends React.PureComponent<Props> {
  render() {
    const {
      post: {
        title,
        summary,
        createdAt,
        id,
        questionId,
        acceptedAt,
        commentCount = 0
      },
      unreads = {}
    } = this.props;

    const locales = { es };
    const truncatedSummary = _truncate(summary, { length: 140 });
    const unreadCount = Object.keys(unreads).length;

    return (
      <PostItem>
        <PostVoteWrapper>
          <PostVoteContainer postId={id} />
        </PostVoteWrapper>

        <Flex align="center" gridColumn="1 / -1">
          <Header size="h2" styleType="mediumHeader" data-ref="title">
            <Link
              as={ReactRouterLink}
              to={`/questions/${questionId}#${id}`}
              data-ref="header-link"
              color="inherit"
            >
              <HighlightedText text={title} />
            </Link>
          </Header>
          {unreadCount > 0 && (
            <Box p={2}>
              <NotificationBadge unreadTotal={unreadCount} />
            </Box>
          )}
        </Flex>

        {!_isEmpty(acceptedAt) && (
          <Box m="0.58rem 0 0.5rem">
            <ActivityAcceptedAnswer data-ref="accepted-at" />
          </Box>
        )}

        <HighlightedText text={truncatedSummary} />

        <Flex alignItems="center" justify="space-between" gap={{ 'sm-md': 4 }}>
          <Box display="inline-block" alignSelf="start">
            <CreatedAtText data-ref="created-at">
              {formatDistanceToNow(parseInt(createdAt, 10), {
                addSuffix: true,
                locale: locales[i18n.language]
              })}
            </CreatedAtText>
          </Box>

          <AnswerCommentWrapper>
            <CommentWrapper data-ref="comment-count">
              <Link
                as={ReactRouterLink}
                to={`/questions/${questionId}#${id}`}
                data-ref="comment-link"
              >
                <CommentCount totalComments={commentCount} />
              </Link>
            </CommentWrapper>
          </AnswerCommentWrapper>
        </Flex>
      </PostItem>
    );
  }
}
