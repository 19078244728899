import { UnreadPost, UnreadPostByParentId } from './unreadsTypes';

type IdType = 'parentID' | 'questionID';

export function makeUnreadPosts(payload, idType: IdType) {
  return payload.reduce((accum: UnreadPostByParentId, post: UnreadPost) => {
    const pid = post[idType];
    if (!accum[pid]) {
      accum[pid] = {};
    }
    accum[pid] = {
      ...accum[pid],
      [post.id]: post
    };
    return accum;
  }, {});
}
