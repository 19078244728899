import { Box, Link } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';

export default class PostLegalDisclaimer extends React.PureComponent<{}> {
  render() {
    return (
      <Box mt="0.938rem" fontSize="sm">
        <Trans i18nKey="legal.termsOfUse">
          By submitting, you agree to the
          <Link
            href="https://www.udacity.com/legal/en-us/terms-of-use"
            isExternal
            _before={{ content: '" "' }}
            _after={{ content: '" "' }}
            color="cerulean-dark"
          >
            Terms Of Use
          </Link>
          and
          <Link
            href="https://www.udacity.com/legal/privacy"
            isExternal
            _before={{ content: '" "' }}
            _after={{ content: '" "' }}
            color="cerulean-dark"
          >
            Privacy Policy.
          </Link>
        </Trans>
      </Box>
    );
  }
}
