import { Map } from 'immutable';
import _toString from 'lodash/toString';
import * as queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import SearchInput from 'app/components/SearchInput';
import ToastPortal from 'app/components/Toast/ToastPortal';
import { setUrlQueryString } from 'app/helpers/queryStringHelpers';
import { hasError } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators } from 'app/models/search/searchActions';

import { selectApiStatus } from 'app/models/search/searchSelectors';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SearchParams } from 'app/models/search/searchTypes';

interface SearchInputContainerStateProps {
  searchQuestionsStatus: Map<string, string>;
}

interface DispatchProps {
  setSearchParameters: (params: SearchParams) => void;
}

interface OwnProps extends RouteComponentProps<any> {
  searchOnLoad: boolean;
  theme: 'default' | 'blue';
  isAlgolia?: boolean;
}

export type SearchInputContainerProps = SearchInputContainerStateProps &
  DispatchProps &
  OwnProps;

export const CustomSearchInput = connectSearchBox(SearchInput);

interface State {
  initialQuery: string;
}

const mapStateToProps = (state): SearchInputContainerStateProps => {
  return {
    searchQuestionsStatus: selectApiStatus(state, 'searchQuestions')
  };
};

const mapDispatchToProps: DispatchProps = {
  setSearchParameters: actionCreators.setSearchParameters
};

export class SearchInputContainer extends React.PureComponent<
  SearchInputContainerProps,
  State
> {
  constructor(props: SearchInputContainerProps) {
    super(props);

    const {
      location: { search = '' }
    } = this.props;

    const { query } = queryString.parse(search);

    this.state = {
      initialQuery: _toString(query) || ''
    };
  }

  // calls api if existing query in url
  componentDidMount() {
    const { searchOnLoad, setSearchParameters } = this.props;
    const { initialQuery } = this.state;

    if (searchOnLoad) {
      setSearchParameters({
        query: initialQuery
      });
    }
  }

  handleSubmit = (query: string = ''): void => {
    const { setSearchParameters, history, location } = this.props;

    const targetPathname = '/';

    setUrlQueryString(history, location, targetPathname, {
      query,

      page: 1
    });

    setSearchParameters({ query, page: 1 });
  };

  clearForm = () => {
    const {
      location: { pathname }
    } = this.props;

    if (pathname === '/') {
      this.handleSubmit('');
    }
  };

  render() {
    const { searchQuestionsStatus, theme } = this.props;
    const { initialQuery } = this.state;
    const { location } = this.props;
    const { query } = queryString.parse(location.search);
    const hasApiError = hasError(searchQuestionsStatus);

    return (
      <>
        {this.props.isAlgolia ? (
          <SearchInput
            handleSubmit={this.handleSubmit}
            clearForm={this.clearForm}
            initialQuery={initialQuery}
            query={_toString(query)}
            theme={theme}
            isAlgolia
          />
        ) : (
          <CustomSearchInput
            handleSubmit={this.handleSubmit}
            clearForm={this.clearForm}
            initialQuery={initialQuery}
            query={_toString(query)}
            theme={theme}
          />
        )}

        {hasApiError && <ToastPortal type="error" />}
      </>
    );
  }
}

export default withRouter(
  connect<SearchInputContainerStateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SearchInputContainer)
);
