import React from 'react';
import { connect } from 'react-redux';

import { CurrentPagination } from 'app/components/CurrentPagination';
import {
  selectCurrentPaginationEnd,
  selectCurrentPaginationStart,
  selectTotalResults,
  selectTotalAnsweredPercent
} from 'app/models/search/searchSelectors';

interface CurrentPaginationContainerProps {
  searchResultsTotalCount?: number;
  totalAnsweredPercent?: number;
  currentPaginationStart?: number;
  currentPaginationEnd?: number;
}

const mapStateToProps = (state): any => ({
  totalAnsweredPercent: selectTotalAnsweredPercent(state),
  searchResultsTotalCount: selectTotalResults(state),
  currentPaginationStart: selectCurrentPaginationStart(state),
  currentPaginationEnd: selectCurrentPaginationEnd(state)
});

export class CurrentPaginationContainer extends React.PureComponent<
  CurrentPaginationContainerProps
> {
  render() {
    const {
      searchResultsTotalCount,
      currentPaginationStart,
      currentPaginationEnd,
      totalAnsweredPercent
    } = this.props;

    return (
      <CurrentPagination
        totalAnsweredPercent={totalAnsweredPercent}
        searchResultsTotalCount={searchResultsTotalCount}
        currentPaginationStart={currentPaginationStart}
        currentPaginationEnd={currentPaginationEnd}
      />
    );
  }
}

export default connect(mapStateToProps)(CurrentPaginationContainer);
