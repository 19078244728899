import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import CommentList from 'app/components/CommentList';
import { actionCreators } from 'app/models/posts/postsActions';
import {
  selectComments,
  selectIsThreadExpanded
} from 'app/models/posts/postsSelectors';
import { selectUnreads } from 'app/models/unreads/unreadsSelectors';
import { actionCreators as unreadsActions } from 'app/models/unreads/unreadsActions';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';

interface OwnProps {
  parentId: string;
  deepLinkId?: string;
}

interface StateProps {
  comments: List<Map<any, any>>;
  isThreadExpanded: boolean;
  unreads: UnreadPostByParentId;
}

interface DispatchProps {
  expandCommentThread: (parentId: string) => void;
  markPostsAsRead: (postIds: string[], parentId: string) => void;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => ({
  comments: selectComments(state, ownProps.parentId),
  isThreadExpanded: selectIsThreadExpanded(state, ownProps.parentId),
  unreads: selectUnreads(state)
});

const mapDispatchToProps: DispatchProps = {
  expandCommentThread: actionCreators.expandCommentThread,
  markPostsAsRead: unreadsActions.markAsRead
};

// check if the deepLinkId is one of the comments on this answer
export const hasLinkedComment = (
  deepLinkId: string = '',
  comments: List<any>
): boolean => {
  return comments.reduce((match, comment) => {
    return match || deepLinkId === comment.get('id');
  }, false);
};

export class CommentListContainer extends React.Component<Props> {
  componentDidMount() {
    const { deepLinkId, comments, isThreadExpanded } = this.props;

    // expand comment thread if there is a deep link
    if (hasLinkedComment(deepLinkId, comments) && !isThreadExpanded) {
      this.expandCommentThread();
    }
    if (isThreadExpanded) {
      this.markCommentsAsRead();
    }
  }

  componentDidUpdate({
    comments: prevComments,
    deepLinkId: prevDeepLinkId,
    isThreadExpanded: prevIsThreadExpanded
  }: Props) {
    const { deepLinkId, comments, isThreadExpanded } = this.props;

    if (
      hasLinkedComment(deepLinkId, comments) &&
      !hasLinkedComment(prevDeepLinkId, prevComments) &&
      !isThreadExpanded
    ) {
      this.expandCommentThread();
    }

    if (prevIsThreadExpanded !== isThreadExpanded && isThreadExpanded) {
      this.markCommentsAsRead();
    }
  }

  expandCommentThread() {
    const { expandCommentThread, parentId } = this.props;
    expandCommentThread(parentId);
  }

  markCommentsAsRead() {
    const {
      comments,
      isThreadExpanded,
      markPostsAsRead,
      parentId,
      unreads
    } = this.props;

    const markAsRead = comments
      .toJS()
      .filter(({ id }) => unreads?.[parentId]?.[id])
      .map(({ id }) => id);

    if (isThreadExpanded && !!markAsRead.length) {
      markPostsAsRead(markAsRead, parentId);
    }
  }

  render() {
    const {
      comments,
      parentId,
      isThreadExpanded,
      deepLinkId,
      unreads
    } = this.props;

    return (
      <CommentList
        parentId={parentId}
        comments={comments.toJS()}
        unreads={unreads}
        isThreadExpanded={isThreadExpanded}
        deepLinkId={deepLinkId}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(CommentListContainer);
