import _flow from 'lodash/flow';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ErrorBoundary from 'app/components/ErrorBoundary';
import InnerPageLayout from 'app/components/InnerPageLayout';
import { PageLayoutContainer } from 'app/containers/PageLayoutContainer';
import DragAndDropAppContainer from 'app/containers/DragAndDropAppContainer';
import ActivityScene from 'app/scenes/Activity';
import CleanPath from 'app/scenes/CleanPath';
import NotFound from 'app/scenes/NotFound';
import QuestionsScene from 'app/scenes/Questions';
import SearchScene from 'app/scenes/Search';

const wrapErrorBoundary = (Component) => {
  return (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

const wrapPageLayout = (Component) => {
  return (props) => (
    <PageLayoutContainer>
      <Component {...props} />
    </PageLayoutContainer>
  );
};

const wrapInnerPageLayout = (Component) => {
  return (props) => (
    <InnerPageLayout>
      <Component {...props} />
    </InnerPageLayout>
  );
};

const searchPageWrappers = _flow(wrapErrorBoundary, wrapPageLayout);

const innerPageWrappers = _flow(
  wrapErrorBoundary,
  wrapInnerPageLayout,
  wrapPageLayout
);

export default (
  <DragAndDropAppContainer>
    <BrowserRouter>
      <CleanPath>
        <Switch>
          <Route
            path="/robots.txt"
            render={() => {
              window.location.reload();
              return null;
            }}
          />
          <Route exact path="/" component={searchPageWrappers(SearchScene)} />
          <Route
            path="/questions/"
            component={innerPageWrappers(QuestionsScene)}
          />
          <Route
            exact
            path="/activity/questions"
            component={innerPageWrappers(ActivityScene)}
          />
          <Route
            exact
            path="/activity/answers"
            component={innerPageWrappers(ActivityScene)}
          />
          <Route component={innerPageWrappers(NotFound)} />
        </Switch>
      </CleanPath>
    </BrowserRouter>
  </DragAndDropAppContainer>
);
