import { Grid, GridItem, Link } from '@chakra-ui/react';
import { ChatIcon, FlagIcon } from '@udacity/chakra-veritas-icons';
import _noop from 'lodash/noop';
import React from 'react';

import PostModalToggle from 'app/components/modals/PostModalToggle';
import UnassignPostModal from 'app/components/modals/UnassignPostModal';
import MarkAtRiskModal from 'app/components/modals/MarkAtRiskModal';
import AdminDeletePostContainer from 'app/containers/AdminDeletePostContainer';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import PostToggleCommentsContainer from 'app/containers/PostToggleCommentsContainer';
import ReportPostContainer from 'app/containers/ReportPostContainer';

import CommentToAnswerConfirmationModal from './CommentToAnswerConfirmationModal';
import { PrivateActionButton, PublicActionButton } from './factoryComponents';

import CONFIG from 'appConfigLoader';
import i18n from 'i18n';

interface State {
  hasReportedPost: boolean;
  isCommentToAnswerModalOpen: boolean;
}

export interface Props {
  allowComments?: boolean;
  initialCommentThreadState?: 'expand' | 'collapse';
  postId: string;
  handleAdminDeletePost:
    | ((postId: string, parentId: string, reasonId: string) => void)
    | null;
  isDeletePostPending: boolean;
  handleEdit: (() => void) | null;
  handleReport: ((reasonId: string) => void) | null;
  handleFollow?: (() => void) | null;
  handleAnswer?: () => void;
  handleDelete: (() => void) | null;
  handleUnassign?: (reason: string) => void;
  handleMoveCommentToAnswer?: (() => void) | null;
  isMoveCommentToAnswerPending?: boolean;
  handleMarkAtRisk?: () => void;
  type: 'answer' | 'comment' | 'question';
}

export default class PostActionBar extends React.PureComponent<Props, State> {
  state = { hasReportedPost: false, isCommentToAnswerModalOpen: false };

  handleFollow = () => {
    const { handleFollow } = this.props;
    if (handleFollow) {
      handleFollow();
    }
  };

  openAnswerToCommentModal = () => {
    this.setState({ isCommentToAnswerModalOpen: true });
  };

  closeAnswerToCommentModal = () => {
    this.setState({ isCommentToAnswerModalOpen: false });
  };

  handleAnswer = () => {
    const { handleAnswer } = this.props;
    if (handleAnswer) {
      handleAnswer();
    }
  };

  handleEdit = () => {
    const { handleEdit } = this.props;
    if (handleEdit) {
      handleEdit();
    }
  };

  handleReport = (reasonId: string) => {
    const { handleReport } = this.props;
    const { hasReportedPost } = this.state;
    if (hasReportedPost) {
      return;
    }
    if (handleReport) {
      handleReport(reasonId);
      this.setState({ hasReportedPost: true });
    }
  };

  handleDelete = () => {
    const { handleDelete } = this.props;
    const message = i18n.t('post.wantToDelete');
    const conf = window.confirm(message); // eslint-disable-line no-alert
    if (conf && handleDelete) {
      handleDelete();
    }
  };

  renderReportPostModal = (handleToggleClose) => (
    <ReportPostContainer
      handleReportPost={this.handleReport}
      handleToggleClose={handleToggleClose}
    />
  );

  renderUnassignPostModal = (handleToggleClose) => (
    <UnassignPostModal
      handleUnassign={this.props.handleUnassign || _noop}
      handleToggleClose={handleToggleClose}
    />
  );

  renderRemovePostModal = (handleToggleClose) => {
    const { handleAdminDeletePost } = this.props;
    return (
      <AdminDeletePostContainer
        handleAdminDeletePost={handleAdminDeletePost || _noop}
        handleToggleClose={handleToggleClose}
      />
    );
  };

  renderMarkAtRiskModel = (handleToggleClose) => (
    <MarkAtRiskModal
      handleMarkAtRisk={this.props.handleMarkAtRisk || _noop}
      handleToggleClose={handleToggleClose}
    />
  );

  render() {
    const {
      allowComments,
      postId,
      isDeletePostPending,
      initialCommentThreadState,
      handleAdminDeletePost,
      handleDelete,
      handleFollow,
      handleAnswer,
      handleEdit,
      handleReport,
      handleUnassign,
      handleMoveCommentToAnswer,
      handleMarkAtRisk,
      isMoveCommentToAnswerPending,
      type
    } = this.props;

    const { isCommentToAnswerModalOpen } = this.state;

    const { hasReportedPost } = this.state;

    return (
      <Grid
        gridTemplateColumns={{ base: '1fr', md: 'auto auto' }}
        alignContent="center"
        whiteSpace={{ md: 'nowrap' }}
      >
        <GridItem>
          {handleFollow && (
            <PublicActionButton
              variant="minimal"
              size="sm"
              leftIcon={<FlagIcon w={6} h={6} />}
              onClick={this.handleFollow}
            >
              {i18n.t('action.follow')}
            </PublicActionButton>
          )}

          {allowComments && (
            <PostToggleCommentsContainer
              postId={postId}
              initialState={initialCommentThreadState || 'collapse'}
            />
          )}

          <PermissionsServiceContainer showIf={{ canAnswer: true }}>
            {handleAnswer ? (
              <PublicActionButton
                variant="minimal"
                size="sm"
                leftIcon={<ChatIcon w={6} h={6} />}
                onClick={this.handleAnswer}
                disabled={isDeletePostPending}
              >
                {i18n.t('action.answer')}
              </PublicActionButton>
            ) : null}
          </PermissionsServiceContainer>
        </GridItem>
        <PermissionsServiceContainer showIf={{ isReadOnly: false }}>
          <GridItem
            alignSelf={{ base: 'end', lg: 'center' }}
            justifySelf={{ md: 'end' }}
            ms={{ md: 0 }}
          >
            {handleMoveCommentToAnswer && (
              <>
                {isCommentToAnswerModalOpen && (
                  <CommentToAnswerConfirmationModal
                    handleMoveCommentToAnswer={handleMoveCommentToAnswer}
                    handleToggleClose={this.closeAnswerToCommentModal}
                    postId={postId}
                  />
                )}
                <PrivateActionButton
                  variant="minimal"
                  size="sm"
                  onClick={this.openAnswerToCommentModal}
                  disabled={isMoveCommentToAnswerPending}
                >
                  {i18n.t('action.moveToAnswer')}
                </PrivateActionButton>
              </>
            )}
            {handleEdit && (
              <PrivateActionButton
                variant="minimal"
                size="sm"
                onClick={this.handleEdit}
                disabled={isDeletePostPending}
              >
                {i18n.t('action.edit')}
              </PrivateActionButton>
            )}
            {handleReport && (
              <PostModalToggle
                callToAction={i18n.t('action.report')}
                isDisabled={hasReportedPost}
                render={this.renderReportPostModal}
              />
            )}
            {handleUnassign && (
              <PostModalToggle
                callToAction={i18n.t('action.unassign')}
                render={this.renderUnassignPostModal}
              />
            )}
            {handleDelete && (
              <PrivateActionButton
                variant="minimal"
                size="sm"
                onClick={this.handleDelete}
              >
                {i18n.t('action.delete')}
              </PrivateActionButton>
            )}
            {handleAdminDeletePost && !handleDelete && (
              <PostModalToggle
                callToAction={i18n.t('action.remove')}
                render={this.renderRemovePostModal}
              />
            )}
            <PermissionsServiceContainer showIf={{ isStaff: true }}>
              {handleMarkAtRisk && (
                <PostModalToggle
                  callToAction={i18n.t('markAtRisk.markAtRisk')}
                  render={this.renderMarkAtRiskModel}
                />
              )}

              {type === 'question' && (
                <Link
                  variant="sm-minimal-slate"
                  href={`${CONFIG.catoWebUrl}/knowledge/posts/${postId}/history`}
                  isExternal
                >
                  {i18n.t('action.history')}
                </Link>
              )}
            </PermissionsServiceContainer>
          </GridItem>
        </PermissionsServiceContainer>
      </Grid>
    );
  }
}
