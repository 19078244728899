import { List, Map } from 'immutable';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import ActivityDash from 'app/components/ActivityDash';
import { setUrlQueryString } from 'app/helpers/queryStringHelpers';
import { actionCreators as activityActions } from 'app/models/activity/activityActions';
import {
  selectActivityParams,
  selectAnswersActivity,
  selectApiStatus,
  selectQuestionsActivity
} from 'app/models/activity/activitySelectors';
import {
  ACTIVITY_OPTION,
  IActivityOption
} from 'app/models/activity/activityTypes';
import constants from 'app/models/activity/activityConstants';
import { isFulfilled, isReady } from 'app/models/helpers/apiStatusHelpers';
import { selectUser } from 'app/models/session/sessionSelectors';
import { selectUnreads } from 'app/models/unreads/unreadsSelectors';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';

interface LocationProps extends RouteComponentProps<any> {}

interface StateProps {
  QuestionsActivity: List<Map<string, string>>;
  AnswersActivity: List<Map<string, string>>;
  UserData: Map<string, string>;
  getQuestionsStatus: Map<string, string>;
  getAnswersStatus: Map<string, string>;
  unreads: UnreadPostByParentId;
  activityParams: {
    questions: { offset: number; limit: number };
    answers: { offset: number; limit: number };
  };
}

interface DispatchProps {
  getQuestions: (uid: string, offset: number, limit: number) => void;
  getAnswers: (uid: string, offset: number, limit: number) => void;
}

interface State {
  selectedGroup: IActivityOption;
}

export type Props = StateProps & DispatchProps & LocationProps;

const mapStateToProps = (state, ownProps): StateProps => ({
  QuestionsActivity: selectQuestionsActivity(state, 'questions'),
  AnswersActivity: selectAnswersActivity(state, 'answers'),
  UserData: selectUser(state),
  getQuestionsStatus: selectApiStatus(state, 'getQuestions'),
  getAnswersStatus: selectApiStatus(state, 'getAnswers'),
  unreads: selectUnreads(state),
  activityParams: selectActivityParams(state).toJS()
});

const mapDispatchToProps: DispatchProps = {
  getQuestions: activityActions.getQuestions,
  getAnswers: activityActions.getAnswers
};

export const isAnyActivityApiLoading = (
  activitiesStatuses: Array<Map<string, string>>
): boolean => {
  if (!_isEmpty(activitiesStatuses)) {
    return !activitiesStatuses.every((item) => {
      return isFulfilled(item) || isReady(item);
    });
  }
  return true;
};

export class ActivityDashContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { location } = this.props;
    let initialSelectedGroup;

    if (location.pathname.includes(ACTIVITY_OPTION.questions)) {
      initialSelectedGroup = ACTIVITY_OPTION.questions;
    } else if (location.pathname.includes(ACTIVITY_OPTION.answers)) {
      initialSelectedGroup = ACTIVITY_OPTION.answers;
    }
    this.state = {
      selectedGroup: initialSelectedGroup
    };
  }

  componentDidMount() {
    const { getQuestions, getAnswers, UserData, activityParams } = this.props;
    const { selectedGroup } = this.state;
    const userId = UserData.get('id');
    if (selectedGroup === ACTIVITY_OPTION.questions) {
      getQuestions(
        userId,
        activityParams.questions.offset,
        activityParams.questions.limit
      );
    } else if (selectedGroup === ACTIVITY_OPTION.answers) {
      getAnswers(
        userId,
        activityParams.answers.offset,
        activityParams.answers.limit
      );
    }
  }

  getActivity = (type: IActivityOption, offset: number, limit: number) => {
    const { getQuestions, getAnswers, UserData } = this.props;
    const userId = UserData.get('id');
    const { history, location } = this.props;

    if (type === ACTIVITY_OPTION.questions) {
      getQuestions(userId, offset, limit);
    } else if (type === ACTIVITY_OPTION.answers) {
      getAnswers(userId, offset, limit);
    }

    setUrlQueryString(history, location, `/activity/${type}`, {});
    this.setState({ selectedGroup: type });
  };

  render() {
    const {
      QuestionsActivity,
      AnswersActivity,
      getQuestionsStatus,
      getAnswersStatus,
      unreads,
      activityParams
    } = this.props;

    const { selectedGroup } = this.state;
    const activitiesStatuses = [getQuestionsStatus, getAnswersStatus];
    const isLoading = isAnyActivityApiLoading(activitiesStatuses);
    const posts = {
      questions: QuestionsActivity.toJS(),
      answers: AnswersActivity.toJS()
    };
    const pages = {
      questions: activityParams.questions.offset / constants.PAGE_SIZE + 1,
      answers: activityParams.answers.offset / constants.PAGE_SIZE + 1
    };

    return (
      <ActivityDash
        posts={posts}
        pages={pages}
        getActivity={this.getActivity}
        unreads={unreads}
        selectedGroup={selectedGroup}
        isLoading={isLoading}
      />
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(ActivityDashContainer)
);
