import React from 'react';
import { Heading } from '@chakra-ui/react';

import i18n from 'i18n';

interface Props {
  isSmall?: boolean;
}

export default class Logo extends React.PureComponent<Props> {
  render() {
    const { isSmall } = this.props;

    return (
      <Heading
        size="h1"
        as="h1"
        color="white"
        fontWeight={{ base: 'semibold', 'sm-md': 'light' }}
        fontSize={{ base: '1.25rem', 'sm-md': isSmall ? '1.5rem' : '2rem' }}
        lineHeight={{ base: '1.5rem', 'sm-md': isSmall ? '2rem' : '1.5rem' }}
        wordBreak="normal"
      >
        {i18n.t('common.knowledge')}
      </Heading>
    );
  }
}
