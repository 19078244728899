import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import NavList from 'app/components/NavList';
import {
  selectCertifications,
  selectCurrentUserIsStaff,
  selectReviewedNanodegrees
} from 'app/models/session/sessionSelectors';
import { selectHasAnyUnreads } from 'app/models/unreads/unreadsSelectors';

interface StateProps {
  reviewedNanodegrees: List<string[]>;
  certifications: List<string[]>;
  isStaff: boolean;
  hasUnreads: boolean;
}

export type Props = StateProps;

const mapStateToProps = (state): StateProps => ({
  reviewedNanodegrees: selectReviewedNanodegrees(state),
  certifications: selectCertifications(state),
  isStaff: selectCurrentUserIsStaff(state),
  hasUnreads: selectHasAnyUnreads(state)
});

export class NavListContainer extends React.Component<Props> {
  render() {
    const {
      reviewedNanodegrees,
      certifications,
      isStaff,
      hasUnreads
    } = this.props;

    const hasKnowledgeReviews = !!reviewedNanodegrees.size;
    const isMentor = !!certifications;

    return (
      <NavList
        hasKnowledgeReviews={hasKnowledgeReviews}
        hasUnreads={hasUnreads}
        isMentor={isMentor}
        isStaff={isStaff}
      />
    );
  }
}

export default connect<StateProps>(mapStateToProps)(NavListContainer);
