import { Box, chakra, Text } from '@chakra-ui/react';
import React from 'react';

import TextInputWrapper from 'app/components/TextInputWrapper';
import UserIcon from 'app/components/UserIcon';
import { IUser } from 'app/models/entities/users/usersEntitiesTypes';

export interface Props {
  showRichTextInput: any;
  placeholder: string;
  user: IUser;
}

export default class ReplyFormPlaceholder extends React.PureComponent<Props> {
  handleClick = () => {
    this.props.showRichTextInput();
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.showRichTextInput();
  };

  render() {
    const { placeholder, user } = this.props;

    return (
      <TextInputWrapper>
        <chakra.button
          onKeyDown={this.handleKeyDown}
          onClick={this.handleClick}
          display="grid"
          gridTemplateColumns="3.125rem 1fr"
          alignItems="center"
          userSelect="none"
          p={6}
          w="full"
        >
          <Box justifySelf="start">
            <UserIcon user={user} size="medium" />
          </Box>
          <Text size="sm" lineHeight="base" color="silver" align="start">
            {placeholder}
          </Text>
        </chakra.button>
      </TextInputWrapper>
    );
  }
}
