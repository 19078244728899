import { Box, Grid } from '@chakra-ui/react';
import React from 'react';
import scrollIntoView from 'scroll-into-view';

import PostBodyContent from 'app/components/PostBodyContent';
import PostMetadata from 'app/components/PostMetadata';
import CommentActionBarContainer from 'app/containers/CommentActionBarContainer';
import EditCommentFormContainer from 'app/containers/commentForms/EditCommentFormContainer';
import { IComment } from 'app/models/entities/comments/commentsEntitiesTypes';
import { UnreadPost } from 'app/models/unreads/unreadsTypes';

import { CommentListItemWrapper } from './CommentListItemWrapper';

export interface Props {
  comment: IComment;
  deepLinkId?: string;
  unreads: { [postId: string]: UnreadPost } | undefined;
}

interface State {
  isEdit: boolean;
}

export default class CommentListItem extends React.PureComponent<Props, State> {
  commentDomWrapper: React.RefObject<HTMLLIElement>;

  state = {
    isEdit: false
  };

  constructor(props) {
    super(props);
    this.commentDomWrapper = React.createRef();
  }

  componentDidMount() {
    const { deepLinkId, comment } = this.props;
    if (deepLinkId === comment.id) {
      scrollIntoView(this.commentDomWrapper.current, { align: { top: 0 } });
    }
  }

  showEdit = () => {
    this.setState({ isEdit: true });
  };

  hideEdit = () => {
    this.setState({ isEdit: false });
  };

  render() {
    const {
      comment,
      comment: { user, fromMentor, id: commentId },
      deepLinkId,
      unreads
    } = this.props;
    const { isEdit } = this.state;

    return (
      <CommentListItemWrapper
        ref={this.commentDomWrapper}
        isFocused={deepLinkId === commentId}
        isUnread={!!unreads?.[commentId]}
      >
        <article>
          {!isEdit && (
            <>
              <PostBodyContent
                content={comment.content}
                createdAt={comment.createdAt}
                modifiedAt={comment.modifiedAt}
              />
              <Grid
                as="footer"
                alignContent="center"
                gridTemplateColumns={{ 'sm-md': 'auto auto' }}
                mt={2}
              >
                <PostMetadata
                  user={user}
                  fromMentor={fromMentor}
                  createdAt={comment.createdAt}
                />
                <CommentActionBarContainer
                  commentId={comment.id}
                  onEdit={this.showEdit}
                />
              </Grid>
            </>
          )}
          {isEdit && (
            <Box
              bgColor="silver-lightest"
              p={{ base: '0.5rem 1rem 1rem', 'sm-md': '1.5rem 2rem 2rem' }}
            >
              <EditCommentFormContainer
                commentId={comment.id}
                onCancel={this.hideEdit}
              />
            </Box>
          )}
        </article>
      </CommentListItemWrapper>
    );
  }
}
