import { Button, chakra, Text } from '@chakra-ui/react';

export const AnswerStatusButton = chakra(Button, {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    order: 1,
    bgColor: 'transparent',
    borderRadius: 'secondary',
    textTransform: 'none',
    color: 'slate-light',
    border: '1px solid',
    borderColor: 'slate-darker',
    shadow: 'none',
    transition: 'all 0.2s 0.1s',
    padding: {
      base: '1px 0.375rem',
      'sm-md': '0.125rem 0.5rem 0.125rem 0.25rem'
    },
    height: 'max-content'
  }
});

export const ButtonText = chakra(Text, {
  baseStyle: {
    display: { base: 'none', 'sm-md': 'inline-flex' },
    fontSize: '0.825rem',
    lineHeight: '0.825rem',
    letterSpacing: 'normal',
    fontWeight: 'bold',
    ms: 1,
    justifySelf: 'center',
    alignSelf: 'center'
  },
  defaultProps: {
    as: 'span'
  }
});

export const verifiedHoverStyles = {
  color: { 'sm-md': 'slate' },
  bgColor: { 'sm-md': 'light-purple' },
  borderColor: { 'sm-md': 'purple' },
  shadow: 'none',
  svg: {
    color: { 'sm-md': 'purple' },
    transition: { 'sm-md': 'color 0.2s 0.1s' }
  }
};

export const acceptedHoverStyles = {
  color: { 'sm-md': 'slate' },
  bgColor: { 'sm-md': 'light-green' },
  borderColor: { 'sm-md': 'green' },
  shadow: 'none',
  svg: {
    color: { 'sm-md': 'green' },
    transition: { 'sm-md': 'color 0.2s 0.1s' }
  }
};
