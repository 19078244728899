import { Map, fromJS } from 'immutable';
import _noop from 'lodash/noop';
import React from 'react';
import { connect } from 'react-redux';

import AnswerAcceptVerify from 'app/components/AnswerAcceptVerify';
import { actionCreators as postActions } from 'app/models/posts/postsActions';
import {
  selectAnswerById,
  selectIsAcceptPending,
  selectIsVerifyPending
} from 'app/models/posts/postsSelectors';
import permissions from 'app/services/permissionsService';

interface StateProps {
  answer: Map<string, {} | boolean | string>;
  isAcceptPending: boolean;
  isVerifyPending: boolean;
}

interface DispatchProps {
  acceptAnswer: (answerId: string) => void;
  unacceptAnswer: (answerId: string) => void;
  verifyAnswer: (answerId: string) => void;
  unverifyAnswer: (answerId: string) => void;
}

interface OwnProps {
  answerId: string;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state, ownProps: OwnProps): StateProps => ({
  answer: selectAnswerById(state, ownProps.answerId),
  isAcceptPending: selectIsAcceptPending(state, ''),
  isVerifyPending: selectIsVerifyPending(state, '')
});

const mapDispatchToProps: DispatchProps = {
  acceptAnswer: postActions.acceptAnswer,
  unacceptAnswer: postActions.unacceptAnswer,
  verifyAnswer: postActions.verifyAnswer,
  unverifyAnswer: postActions.unverifyAnswer
};

export class AnswerAcceptVerifyContainer extends React.PureComponent<Props> {
  accept() {
    const { acceptAnswer = _noop, answerId } = this.props;
    acceptAnswer(answerId);
  }

  unaccept() {
    const { unacceptAnswer = _noop, answerId } = this.props;
    unacceptAnswer(answerId);
  }

  toggleAccepted = () => {
    const { answer = fromJS({}), isAcceptPending } = this.props;
    if (isAcceptPending) {
      return false;
    }
    if (answer.get('accepted')) {
      return this.unaccept();
    }
    return this.accept();
  };

  verify() {
    const { verifyAnswer = _noop, answerId } = this.props;
    verifyAnswer(answerId);
  }

  unverify() {
    const { unverifyAnswer = _noop, answerId } = this.props;
    unverifyAnswer(answerId);
  }

  toggleVerified = () => {
    const { answer = fromJS({}), isVerifyPending } = this.props;
    if (isVerifyPending) {
      return false;
    }
    if (answer.get('verified')) {
      return this.unverify();
    }
    return this.verify();
  };

  render() {
    const { answer, isAcceptPending, isVerifyPending } = this.props;

    if (!answer) {
      return null;
    }

    const isAccepted = !!answer.get('accepted');
    const isVerified = !!answer.get('verified');
    const canAccept = permissions.canAcceptAnswer(answer.get('parentId'));
    const canVerify = permissions.canVerifyAnswer();

    return (
      <AnswerAcceptVerify
        isAccepted={isAccepted}
        isVerified={isVerified}
        isAcceptPending={isAcceptPending}
        isVerifyPending={isVerifyPending}
        canAccept={canAccept}
        canVerify={canVerify}
        handleToggleAccepted={this.toggleAccepted}
        handleToggleVerified={this.toggleVerified}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(AnswerAcceptVerifyContainer);
