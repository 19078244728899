import apiService from '@udacity/ureact-api-service';

import gql from 'app/models/helpers/gql';
import CONFIG from 'appConfigLoader';

import { ApiEnrollment, ApiReviewsProject } from './nanodegreeTypes';

const SERVICE_NAME = 'KnowledgeWeb';

export async function fetchNanodegreeEnrollments(): Promise<{
  enrolled: ApiEnrollment[];
  graduated: ApiEnrollment[];
}> {
  const request = {
    query: `
      query ${SERVICE_NAME}_FetchNanodegrees {
        user {
          enrollments(states: [ENROLLED, GRADUATED], semantic_types: [NANODEGREE, PART]) {
            status
            includes_knowledge_reviews
            root_node {
              key
              title
              semantic_type
              ... on Nanodegree {
                parts {
                  modules {
                    lessons {
                      project {
                        title
                        reviews_project_id
                        rubric_id
                      }
                    }
                  }
                }
              }
              ... on Part {
                modules {
                  lessons {
                    project {
                      title
                      reviews_project_id
                      rubric_id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  };
  const response = await gql(request, CONFIG.classroomGraphqlEndpoint);

  const { data = {} } = response || {};
  const { user = {} } = data;
  const { enrollments = [] } = user;

  const enrolled = enrollments.filter(
    (e) => e.status.toLowerCase() === 'enrolled'
  );
  const graduated = enrollments.filter(
    (e) => e.status.toLowerCase() === 'graduated'
  );

  return { enrolled, graduated };
}

export async function fetchAllReviewsProjects(): Promise<ApiReviewsProject[]> {
  return apiService.makeGetRequest(CONFIG.reviewApiUrl, '/projects.json');
}
