import { ListItem } from '@chakra-ui/react';
import React from 'react';

export interface Props {
  name: string;
}

const TagItem: React.FC<Props> = ({ name }) => {
  return (
    <ListItem
      display="inline-block"
      data-ref="tag-link"
      borderRadius="secondary"
      bgColor="cerulean-lightest"
      border="1px solid"
      borderColor="cerulean-lightest"
      m={{ base: '1px 0', 'sm-md': '0.25rem 0.25rem 0.25rem 0' }}
      p="0.2rem 0.75rem"
      lineHeight="h5"
      color="cerulean-dark"
      fontSize="sm"
      fontWeight="semibold"
    >
      {name}
    </ListItem>
  );
};

export default TagItem;
