import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import FixedWidthWrapper from 'app/components/FixedWidthWrapper';
import IllustrationGhost from 'app/components/IllustrationGhost';

import i18n from 'i18n';

export interface LocationProps extends RouteComponentProps<any> {}

export interface OwnProps {
  handleRestore: () => void;
  canUnarchivePost: boolean;
}

export type Props = LocationProps & OwnProps;

export class ArchivedPostPage extends React.PureComponent<Props> {
  componentDidMount() {
    document.title = i18n.t('common.knowledgeUdacity');
  }

  handleHomeClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    const { history } = this.props;
    history.push('/');
  };

  render() {
    const { handleRestore, canUnarchivePost } = this.props;

    return (
      <Flex
        direction="column"
        justify="center"
        align="center"
        textAlign="center"
        w="full"
        h={{
          base: 'calc(100vh - 7.5rem)',
          'sm-md': 'calc(100vh - 8.375rem)',
          md: 'calc(100vh - 5.125rem)'
        }}
      >
        <Box as="main" maxW="22.5rem">
          <IllustrationGhost />
          <FixedWidthWrapper>
            <Text fontSize="h3" fontWeight="light" lineHeight="2.25rem" mb={6}>
              {i18n.t('post.hasBeenDeleted')}
            </Text>

            <Button
              variant="default"
              onClick={this.handleHomeClick}
              m="0.85rem 0 2.5rem"
            >
              {i18n.t('common.returnToKnowledge')}
            </Button>

            {(canUnarchivePost && (
              <Button
                variant="minimal"
                onClick={handleRestore}
                size="sm"
                color="cerulean"
                fontSize="0.8125rem"
              >
                {i18n.t('post.restore')}
              </Button>
            )) ||
              null}
          </FixedWidthWrapper>
        </Box>
      </Flex>
    );
  }
}

export default withRouter(ArchivedPostPage);
