import _ from 'lodash';
import * as queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

// TODO: ts migration change location to detailed props

export function setUrlQueryString(
  history: RouteComponentProps<any>['history'],
  location: RouteComponentProps<any>['location'],
  targetPathname: string,
  params: any = {}
): void {
  const prevParams = queryString.parse(location.search);
  history.push({
    pathname: targetPathname,
    search: queryString.stringify(
      _.omitBy({ ...prevParams, ...params }, (val) => val === '')
    )
  });
}
