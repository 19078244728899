import { Box, Grid } from '@chakra-ui/react';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _invert from 'lodash/invert';
import { object } from 'prop-types';
import React, { Component } from 'react';

import { ImageCardPayload } from 'app/components/RichTextEditor/cards/ImageCard/imageCardTypes';
import ToastPortal from 'app/components/Toast/ToastPortal';
import { FILE_SIZE_LIMIT } from 'app/models/files/filesConstants';
import { formatBytes, readLocalFile } from 'app/models/files/filesHelpers';
import { RenderLocalArgs, SupportedTypes } from 'app/models/files/filesTypes';

import FileDropTargetPlaceholder from './FileDropTargetPlaceholder';
import i18n from 'i18n';

export interface Props {
  children: React.ReactNode;
  isDraggingFile: boolean;
  handleDrop: (fileRef?: File) => void;
}

interface State {
  isDraggingOver: boolean;
  fileSizeError: boolean;
}

export default class FileDropTarget extends Component<Props, State> {
  static contextTypes = {
    editor: object
  };

  state = {
    isDraggingOver: false,
    fileSizeError: false
  };

  handleDragEnter = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isDraggingOver: true });
  };

  handleDragLeave = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isDraggingOver: false });
  };

  handleDrop = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const { handleDrop } = this.props;

    this.setState({ isDraggingOver: false });

    if (_has(window, 'File') && _has(window, 'FileReader')) {
      const fileRef = _get(e, 'dataTransfer.files.0', {
        type: 'unsupported',
        size: 0
      });

      const { type, size } = fileRef;

      const fileSizeError = size > FILE_SIZE_LIMIT;
      const unsupportedFile =
        !(type in _invert(SupportedTypes)) || fileSizeError;

      if (fileSizeError) {
        this.setState({ fileSizeError: true });
      }

      if (unsupportedFile) {
        handleDrop();
      } else {
        this.setState({ fileSizeError: false });
        readLocalFile(fileRef, this.insertCard);
        handleDrop(fileRef);
      }
    }
  };

  insertCard = ({
    filename,
    width,
    height,
    localImageSrc,
    fileIdentifierString
  }: RenderLocalArgs) => {
    const { editor } = this.context;

    const payload: ImageCardPayload['payload'] = {
      localImageSrc,
      filename,
      fileIdentifierString,
      originalDimensions: { width, height }
    };

    // insert image card into document
    editor.insertCard('Image', payload, true);
  };

  render() {
    const { children, isDraggingFile } = this.props;
    const { isDraggingOver, fileSizeError } = this.state;

    return (
      <Box position="relative">
        {isDraggingFile && (
          <Grid
            data-testid="drop-target"
            position="absolute"
            top={0}
            left={0}
            w="full"
            h="full"
            zIndex="dropdown"
            onDragEnter={this.handleDragEnter}
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDrop}
          />
        )}
        {isDraggingFile && (
          <FileDropTargetPlaceholder isDraggingOver={isDraggingOver} />
        )}
        {children}
        {fileSizeError && (
          <ToastPortal
            type="error"
            message={i18n.t('error.uploadedFilesSmaller', {
              size: formatBytes(FILE_SIZE_LIMIT)
            })}
          />
        )}
      </Box>
    );
  }
}
