import { Box } from '@chakra-ui/react';
import { PureComponent } from 'react';

export interface Props {
  progress?: number;
}

export default class ProgressOverlay extends PureComponent<Props, {}> {
  render() {
    const { progress = 0 } = this.props;

    return (
      <Box position="absolute" top={0} left={0} w="full" h="full">
        <Box
          data-testid="transparent-overlay"
          position="absolute"
          right={0}
          w={`${100 - progress}%`}
          h="full"
          bgColor="#00000080"
          transition="width 0.1s"
        />
        <Box
          data-testid="progress-bar"
          h={2}
          position="absolute"
          left={0}
          bottom={0}
          bgColor="cerulean"
          w={`${progress}%`}
          transition="width 0.2s"
        />
      </Box>
    );
  }
}
