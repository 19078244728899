import { chakra, Heading } from '@chakra-ui/react';
import React from 'react';

import { IActivityOption } from 'app/models/activity/activityTypes';

export interface Props {
  handleChange: (type: IActivityOption) => void;
  text: string;
  selectedGroup: IActivityOption;
  id: IActivityOption;
}

export default class TabItem extends React.PureComponent<Props> {
  handleTabClick = () => {
    const { handleChange, id } = this.props;
    handleChange(id);
  };

  render() {
    const { text, selectedGroup, id } = this.props;
    const isSelected = id === selectedGroup;

    return (
      <chakra.button
        onClick={this.handleTabClick}
        borderBottom="4px solid"
        borderBottomColor={isSelected ? 'slate-light' : 'transparent'}
        bgColor="transparent"
        padding={{ base: '0.5rem 1rem', 'sm-md': '1rem 1.7rem' }}
        textTransform="uppercase"
        letterSpacing="0.09375em"
        color={isSelected ? 'slate' : 'cerulean'}
        fontSize="0.825rem"
        fontWeight="semibold"
        _focus={{ outline: 'none' }}
      >
        <Heading
          size="h6"
          as="h6"
          fontSize="0.825rem"
          lineHeight="xs"
          letterSpacing="0.09375em"
        >
          {text}
        </Heading>
      </chakra.button>
    );
  }
}
