import React from 'react';
import { connect } from 'react-redux';

import IncompletePostBanner from 'app/components/IncompletePostBanner';
import { selectIsQuestionArchived } from 'app/models/posts/postsSelectors';

interface StateProps {
  isPostArchived: boolean;
}

interface OwnProps {
  postId: string;
}

export type Props = StateProps & OwnProps;

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    isPostArchived: selectIsQuestionArchived(state, ownProps.postId)
  };
};

export class IncompletePostBannerContainer extends React.PureComponent<Props> {
  static defaultProps = {
    isPostArchived: false
  };

  render() {
    const { isPostArchived } = this.props;

    if (!isPostArchived) {
      return null;
    }

    return <IncompletePostBanner />;
  }
}

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(
  IncompletePostBannerContainer
);
