import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { QuestionQueue } from './questionQueueTypes';

export const selectQuestionQueue = (state: Map<string, any>) =>
  state.get('questionQueue');

export const selectQuestionStatus = (
  state: Map<string, any>,
  questionId: string
) => {
  const questionQueue = selectQuestionQueue(state);
  return questionQueue && questionQueue.questionStatus[questionId];
};

export const selectQuestionAssignments = createSelector(
  selectQuestionQueue,
  (questionQueue: QuestionQueue) => {
    return questionQueue && questionQueue.assignments;
  }
);
