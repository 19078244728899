import ScrollToComponent from 'app/components/ScrollToComponent';
import React from 'react';
import { connect } from 'react-redux';

import { selectSearchPage } from 'app/models/search/searchSelectors';

/*
  This component facilitates scroll-into-view ux when any provided prop is updated.
  For example: track what search page we're viewing, and when that changes scroll to
  the dom target mounted here.

  Any selector can be used to track any redux data changes that should trigger
  auto scrolling behavior.
*/

interface StateProps {
  searchPage: number;
}

export type Props = StateProps;

const mapStateToProps = (state: any): StateProps => ({
  searchPage: selectSearchPage(state)
});

export class ScrollToComponentContainer extends React.PureComponent<Props> {
  render() {
    const { searchPage } = this.props;
    return <ScrollToComponent searchPage={searchPage} />;
  }
}

export default connect<StateProps, {}, {}>(mapStateToProps)(
  ScrollToComponentContainer
);
