import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { ThemeProvider } from './themeContext';
import i18n from 'i18n';

export interface Props {
  theme: 'red' | 'blue';
  headerText: string;
  headerIcon: ReactNode;
  onCancel: () => void;
}

export const appRoot: HTMLElement =
  document.getElementById('app') || document.createElement('div');

const ActionModal: React.FC<Props> = ({
  theme = 'blue',
  headerText = i18n.t('action.confirm'),
  headerIcon,
  onCancel,
  children
}) => {
  const size = useBreakpointValue({
    base: 'mobile',
    md: 'tablet'
  });

  return (
    <Modal isOpen onClose={onCancel} isCentered size={size}>
      <ModalOverlay />
      <ThemeProvider value={theme}>
        <ModalContent>
          <ModalHeader p={0}>
            <Flex
              direction={{ base: 'column', 'sm-md': 'row' }}
              align={{ base: 'start', 'sm-md': 'center' }}
              gap={4}
              borderTopRadius="0.375rem"
              p={{ base: 8, 'sm-md': '2.5rem 3.5rem 2rem' }}
              bgColor={theme === 'red' ? 'light-orange' : 'azure'}
              borderBottom="1px solid"
              borderBottomColor={theme === 'red' ? 'dark-red' : 'cerulean'}
            >
              {headerIcon}
              <Heading size="h3" as="h3">
                {headerText}
              </Heading>
              <ModalCloseButton
                _hover={{ bgColor: 'transparent' }}
                w={8}
                h={8}
              />
            </Flex>
          </ModalHeader>
          <ModalBody
            p={0}
            m={{ base: 8, 'sm-md': '2rem 3.5rem 3.5rem' }}
            textAlign="start"
          >
            {children}
          </ModalBody>
        </ModalContent>
      </ThemeProvider>
    </Modal>
  );
};

export default ActionModal;
