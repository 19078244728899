import { combineReducers } from 'redux-immutable'; // immutablejs equivalent of Redux's combineReducers

import {
  makeApiReducer,
  normalizeApiActions
} from 'app/models/helpers/apiReducerHelpers';
import { actionClass } from './nanodegreeActions';

const fetchNanodegreeEnrollments = normalizeApiActions(
  'FETCH_NANODEGREE_ENROLLMENTS',
  actionClass
);

export default combineReducers({
  fetchNanodegreeEnrollments: makeApiReducer(fetchNanodegreeEnrollments)
});
