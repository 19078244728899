import gql from 'app/models/helpers/gql';
import sessionSchema from 'app/models/session/sessionSchema';

const { Me } = sessionSchema;

export function getMe() {
  const request = {
    query: `{
      me ${Me}
    }`
  };

  return gql(request).then((res) => res.data.me);
}
