import { all, call, put, takeEvery } from 'redux-saga/effects';

import { createAction } from 'app/models/helpers/actionHelper';
import { makeSagaLifecycle } from 'app/models/helpers/sagaHelper';
import { actionTypes as sessionActionTypes } from 'app/models/session/sessionActions';

import { actionTypes } from './nanodegreeActions';
import * as requests from './nanodegreeExternalRequests';

export function* bootstrap() {
  yield all([
    put(createAction(actionTypes.FETCH_NANODEGREE_ENROLLMENTS_START)),
    put(createAction(actionTypes.FETCH_ALL_REVIEWS_PROJECTS_START))
  ]);
}

export function* fetchNanodegreeEnrollments() {
  const apiNanodegrees = yield call(requests.fetchNanodegreeEnrollments);
  yield put(
    createAction(
      actionTypes.FETCH_NANODEGREE_ENROLLMENTS_FULFILLED,
      apiNanodegrees
    )
  );
}

export function* fetchAllReviewsProjects() {
  const reviewsProjects = yield call(requests.fetchAllReviewsProjects);
  yield put(
    createAction(
      actionTypes.FETCH_ALL_REVIEWS_PROJECTS_FULFILLED,
      reviewsProjects
    )
  );
}

export function* watchBootstrap() {
  // Wait for the session saga to start so we can fire our own events that
  // kick off the fetching of enrollments and projects.
  yield takeEvery(sessionActionTypes.BOOTSTRAP_START, bootstrap);
}

export function* watchFetchNanodegreeEnrollments() {
  yield takeEvery(
    actionTypes.FETCH_NANODEGREE_ENROLLMENTS_START,
    makeSagaLifecycle(
      fetchNanodegreeEnrollments,
      'FETCH_NANODEGREE_ENROLLMENTS',
      actionTypes
    )
  );
}

export function* watchFetchAllReviewsProjects() {
  yield takeEvery(
    actionTypes.FETCH_ALL_REVIEWS_PROJECTS_START,
    makeSagaLifecycle(
      fetchAllReviewsProjects,
      'FETCH_ALL_REVIEWS_PROJECTS',
      actionTypes
    )
  );
}

export default function* moduleRoot() {
  yield all([
    watchBootstrap(),
    watchFetchNanodegreeEnrollments(),
    watchFetchAllReviewsProjects()
  ]);
}
