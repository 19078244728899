import { Box, Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  handleLogout: (e: React.SyntheticEvent<HTMLElement>) => void;
}

export function PageLayout(props: Props) {
  const { children } = props;

  return (
    <Flex position="absolute" top={0} left={0} bottom={0} right={0}>
      <Box
        boxSizing="border-box"
        w="full"
        h="full"
        minW="18.75rem"
        position="relative"
        ms={0}
      >
        <Flex direction="column" w="full" h="full" overflowY="auto">
          {children}
        </Flex>
      </Box>
    </Flex>
  );
}
