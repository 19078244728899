import { Map, fromJS } from 'immutable';

import { actionTypes } from './postsActions';

type State = Map<string, Map<string | number, {} | undefined> | null>;

export const initialState: State = fromJS({
  question: null,
  commentThreadsExpanded: {},
  commentFormsExpanded: {},
  answerFormsExpanded: {},
  moderatePostReasons: [],
  questionForm: {
    latestFileInputRef: document.createElement('input')
  }
});

export default function reducer(state: State = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_LATEST_FILE_INPUT_REF: {
      return state.setIn(['questionForm', 'latestFileInputRef'], payload.ref);
    }
    case actionTypes.CREATE_QUESTION_FULFILLED:
    case actionTypes.FETCH_QUESTION_FULFILLED: {
      return state.set('question', fromJS(payload.result));
    }
    case actionTypes.EXPAND_COMMENT_THREAD: {
      return state.mergeDeep(
        Map({
          commentThreadsExpanded: Map({ [payload.parentId]: true })
        })
      );
    }
    case actionTypes.COLLAPSE_COMMENT_THREAD: {
      return state.mergeDeep(
        Map({
          commentThreadsExpanded: Map({ [payload.parentId]: false })
        })
      );
    }
    case actionTypes.EXPAND_COMMENT_FORM: {
      return state.mergeDeep(
        Map({
          commentFormsExpanded: Map({
            [payload.parentId]: payload.commentFormType
          })
        })
      );
    }
    case actionTypes.COLLAPSE_COMMENT_FORM: {
      return state.mergeDeep(
        Map({
          commentFormsExpanded: Map({ [payload.parentId]: undefined })
        })
      );
    }
    case actionTypes.CREATE_COMMENT_FULFILLED: {
      return state.mergeDeep(
        Map({
          commentFormsExpanded: Map({ [payload.parentId]: undefined })
        })
      );
    }
    case actionTypes.EXPAND_ANSWER_FORM: {
      return state.mergeDeep(
        Map({
          answerFormsExpanded: Map({ [payload.parentId]: true })
        })
      );
    }
    case actionTypes.COLLAPSE_ANSWER_FORM: {
      return state.mergeDeep(
        Map({
          answerFormsExpanded: Map({ [payload.parentId]: false })
        })
      );
    }
    case actionTypes.CREATE_ANSWER_FULFILLED: {
      return state.mergeDeep(
        Map({
          answerFormsExpanded: Map({ [payload.questionId]: false })
        })
      );
    }
    case actionTypes.CREATE_QUESTION_READY:
    case actionTypes.FETCH_QUESTION_READY: {
      return state.set('question', null);
    }
    case actionTypes.FETCH_MODERATE_POST_REASONS_FULFILLED: {
      const { result } = payload;
      return state.set('moderatePostReasons', fromJS(result));
    }
  }

  return state;
}
