import { combineReducers } from 'redux-immutable'; // immutablejs equivalent of Redux's combineReducers

import {
  makeApiReducer,
  normalizeApiActions
} from 'app/models/helpers/apiReducerHelpers';
import { actionClass } from 'app/models/session/sessionActions';

const bootstrap = normalizeApiActions('BOOTSTRAP', actionClass);

export default combineReducers({
  bootstrap: makeApiReducer(bootstrap)
});
