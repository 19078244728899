import React from 'react';

import FixedWidthWrapper from 'app/components/FixedWidthWrapper';
import NewQuestionFormContainer from 'app/containers/questionFormContainers/NewQuestionFormContainer';
import { trackPageview } from 'app/helpers/analyticsHelper';

import i18n from 'i18n';

export class NewQuestionScene extends React.PureComponent<{}> {
  componentDidMount() {
    document.title = i18n.t('common.knowledgeUdacity');
    this.trackEvent('New Question');
  }

  trackEvent(eventName: string) {
    trackPageview(eventName);
  }

  render() {
    return (
      <FixedWidthWrapper>
        <NewQuestionFormContainer />
      </FixedWidthWrapper>
    );
  }
}

export default NewQuestionScene;
