import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { isPending } from 'app/models/helpers/apiStatusHelpers';

export const selectFilesApi = (
  state: Map<string, Map<string, string>>
): Map<string, any> => {
  return state.get('filesApi');
};

export const selectIsImageUploadPending = createSelector(
  selectFilesApi,
  (apiStatus): boolean => {
    return isPending(apiStatus.get('uploadImage'));
  }
);

export const selectFiles = (
  state: Map<string, Map<string, any>>
): Map<string, any> => state.get('files') || Map({});

export const selectFileStateByIdentifier = (
  state: Map<string, Map<string, any>>,
  identifier: string = ''
) => {
  const files = selectFiles(state);
  return files.get(identifier) || Map({});
};
