import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { PageLayout } from 'app/components/PageLayout';

import { handleLogout } from './PageLayoutContainerHelpers';

interface OwnProps {
  children: React.ReactNode;
}

interface LocationProps extends RouteComponentProps<any> {}

export type Props = OwnProps & LocationProps;

export function PageLayoutContainer(props: Props) {
  const { children } = props;

  return <PageLayout handleLogout={handleLogout}>{children}</PageLayout>;
}

export default withRouter(PageLayoutContainer);
