import { normalize, schema } from 'normalizr';

const userSchema = new schema.Entity('users');

const commentSchema = new schema.Entity('comments', {
  user: userSchema
});

export const commentListSchema = [commentSchema];

export const commentsSchema = new schema.Object({
  comments: [commentSchema]
});

const answerSchema = new schema.Entity('answers', {
  comments: [commentSchema],
  user: userSchema,
  lockedBy: userSchema
});

export const answersSchema = new schema.Object({
  answers: [answerSchema]
});

export const answerListSchema = [answerSchema];

export const questionSchema = new schema.Entity('questions', {
  comments: [commentSchema],
  answers: [answerSchema],
  user: userSchema,
  lockedBy: userSchema
});

export const questionsSchema = new schema.Object({
  questions: [questionSchema]
});

export const adminDeletePostReasonSchema = new schema.Entity(
  'moderatePostReasons'
);

export const moderatePostReasonsSchema = [adminDeletePostReasonSchema];

export const normalizeAdminDeleteReasons = (reasons) => {
  return normalize(reasons, moderatePostReasonsSchema);
};

export const normalizeQuestionModel = (payload) => {
  return normalize(payload, questionSchema);
};

export const normalizeAnswerModel = (payload) => {
  return normalize(payload, answerSchema);
};

export const normalizeCommentModel = (payload) => {
  return normalize(payload, commentSchema);
};

export const normalizeQuestionsCollection = (payload) => {
  return normalize(payload, questionsSchema);
};

export const normalizeAnswersCollection = (payload) => {
  return normalize(payload, answersSchema);
};

export const normalizeCommentsCollection = (payload) => {
  return normalize(payload, commentsSchema);
};
