export function onInactive(
  inactivityTarget: Window,
  handleInactivity: () => void,
  inactivityThresholdMs: number
) {
  let timeoutId: NodeJS.Timeout | undefined;

  const watchInactive = () => {
    timeoutId = setTimeout(() => {
      handleInactivity();
      timeoutId = undefined;
    }, inactivityThresholdMs);
  };

  const onActivity = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    watchInactive();
  };

  inactivityTarget.addEventListener('mousemove', onActivity);
  inactivityTarget.addEventListener('keypress', onActivity);

  watchInactive();
}
