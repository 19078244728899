import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import AnswerIcon from 'app/components/AnswerIcon';

import i18n from 'i18n';

export interface Props {
  totalAnswers: number;
  verifiedId?: string;
  acceptedId: string;
}

export default class AnswerCount extends React.PureComponent<Props> {
  render() {
    const { totalAnswers, acceptedId, verifiedId } = this.props;

    return (
      <Flex align="center" mt="-0.188rem">
        <Box me="0.125rem">
          <AnswerIcon
            totalPosts={totalAnswers}
            verifiedId={verifiedId}
            acceptedId={acceptedId}
          />
        </Box>

        <Text
          fontSize="0.825rem"
          letterSpacing="0.0625em"
          lineHeight="xs"
          textTransform="uppercase"
          color={totalAnswers === 0 ? 'slate-light' : 'slate'}
          fontWeight="semibold"
          ms="0.313rem"
        >
          {i18n.t('answer.answerCount', { count: totalAnswers })}
        </Text>
      </Flex>
    );
  }
}
