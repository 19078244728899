import { Heading } from '@chakra-ui/react';
import React from 'react';

import { headerStyles } from './headerStyles';

export interface Props {
  children: string | JSX.Element;
  styleType:
    | 'fieldset'
    | 'heavyHeader'
    | 'mediumHeader'
    | 'mediumHeaderGrayDark'
    | 'smallHeader';
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export default class Header extends React.PureComponent<Props> {
  render() {
    const { size, styleType, children } = this.props;

    return (
      <Heading
        size={size}
        as={size}
        {...headerStyles[styleType]}
        textTransform={
          styleType === 'fieldset'
            ? { base: 'uppercase', 'sm-md': 'none' }
            : styleType === 'smallHeader'
            ? 'uppercase'
            : 'none'
        }
      >
        {children}
      </Heading>
    );
  }
}
