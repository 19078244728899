import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';

export const actionClass = 'SESSION';

export const actionTypes = {
  ...createActionType('BOOTSTRAP', actionClass),
  SET_ROLES: 'SESSION/SET_ROLES'
};

export const actionCreators = {
  bootstrap() {
    return createAction(actionTypes.BOOTSTRAP_START);
  },
  setRoles(data) {
    return createAction(actionTypes.SET_ROLES, data);
  }
};
