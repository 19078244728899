import { Map, fromJS } from 'immutable';
import _get from 'lodash/get';

import apiStatuses from 'app/models/helpers/apiStatusHelpers';

import { actionTypes } from './filesActions';

type State = Map<any, any>;

export const initialState: State = fromJS({});

export default function reducer(state: State = initialState, action: any) {
  const { type, payload } = action;
  const fileIdentifierString = _get(
    payload,
    'fileIdentifierString',
    'file-placeholder'
  );

  switch (type) {
    case actionTypes.UPLOAD_IMAGE_START: {
      return state.setIn([fileIdentifierString, 'status'], apiStatuses.pending);
    }
    case actionTypes.UPLOAD_IMAGE_PROGRESS: {
      const { progress } = payload;
      return state.setIn([fileIdentifierString, 'progress'], progress);
    }
    case actionTypes.UPLOAD_IMAGE_REJECTED: {
      const { error } = payload;
      return state
        .setIn([fileIdentifierString, 'status'], apiStatuses.rejected)
        .setIn([fileIdentifierString, 'error'], error);
    }
    case actionTypes.UPLOAD_IMAGE_FULFILLED: {
      const { response } = payload;
      return state
        .setIn([fileIdentifierString, 'status'], apiStatuses.fulfilled)
        .setIn(
          [fileIdentifierString, 'attachments'],
          _get(response, 'attachments', {})
        );
    }
  }
  return state;
}
