import { createSelector } from 'reselect';
import { UnreadPost } from './unreadsTypes';

export const selectUnreads = (state) => state.toJS().unreads.byParentId;

export const selectHasAnyUnreads: (state) => boolean = createSelector(
  selectUnreads,
  (unreads): boolean => !!Object.keys(unreads).length
);

export const selectUnreadsByPostId = (state: any, postId: string) => {
  return state.toJS().unreads.byParentId[postId];
};

export const selectUnreadCommentsByPostId = (
  state: any,
  postId: string
): UnreadPost[] => {
  const unreads = selectUnreadsByPostId(state, postId) || {};
  return Object.keys(unreads).reduce(
    (accum: UnreadPost[] | never[], unreadId: string) => {
      const post = unreads[unreadId];
      if (post.type === 'comment' && post.parentID === postId) {
        return [...accum, post];
      }
      return accum;
    },
    []
  );
};
