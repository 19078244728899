import { List, Map } from 'immutable';
import _ from 'lodash';
import { createSelector } from 'reselect';

type SessionReturn = Map<string, Map<string, string | boolean>>;

export const selectSession = (state: any): SessionReturn =>
  state.get('session');

export const selectPermissions = createSelector(
  selectSession,
  (session: SessionReturn): List<string> =>
    session.getIn(['me', 'approvedActions'])
);

export const selectUser = createSelector(
  selectSession,
  (session: SessionReturn) => {
    return session.getIn(['me', 'user']);
  }
);

export const selectCurrentUserIsStaff = createSelector(
  selectSession,
  (session: SessionReturn) => {
    return session.getIn(['me', 'roles'], List([])).includes('staff');
  }
);

export const selectApiStatus = createSelector(
  (state: Map<string, string>, method: string) => {
    if (method) {
      const methodState = state.getIn(['sessionApi', method]);
      if (_.isUndefined(methodState)) {
        throw Error(`Key "${method}" not found on sessionApi.`);
      }
      return methodState;
    }
    return state.get('sessionApi');
  },
  (status) => status
);

export const selectEnrolledNanodegrees = createSelector(
  selectSession,
  (session: SessionReturn) => {
    return session.getIn(['me', 'enrolledNanodegrees']);
  }
);

export const selectReviewedNanodegrees = createSelector(
  selectSession,
  (session: SessionReturn) => {
    return session.getIn(['me', 'reviewedNanodegrees']);
  }
);

export const selectCertifications = createSelector(
  selectSession,
  (session: SessionReturn) => {
    return session.getIn(['me', 'certifications']);
  }
);
