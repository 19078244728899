import { fromJS, Map } from 'immutable';
import _noop from 'lodash/noop';
import React from 'react';
import { connect } from 'react-redux';

import PostVote from 'app/components/PostVote';
import { actionCreators } from 'app/models/posts/postsActions';
import {
  selectIsVotePending,
  selectPostById
} from 'app/models/posts/postsSelectors';

interface StateProps {
  post: Map<string, string>;
  isVotePending: boolean;
}

interface DispatchProps {
  upvotePost: (postId: string) => void;
  downvotePost: (postId: string) => void;
  clearvotePost: (postId: string) => void;
}

interface OwnProps {
  postId: string;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => {
  return {
    post: selectPostById(state, ownProps.postId),
    isVotePending: selectIsVotePending(state, '')
  };
};

const mapDispatchToProps: DispatchProps = {
  upvotePost: actionCreators.upvotePost,
  downvotePost: actionCreators.downvotePost,
  clearvotePost: actionCreators.clearvotePost
};

export const isUpvoted = (myVote: number): boolean => myVote === 1;
export const isDownvoted = (myVote: number): boolean => myVote === -1;

export class PostVoteContainer extends React.PureComponent<Props> {
  static defaultProps = {
    post: fromJS({})
  };

  upvotePost = () => {
    const {
      upvotePost = _noop,
      clearvotePost = _noop,
      postId,
      post = fromJS({}),
      isVotePending
    } = this.props;

    if (isVotePending) {
      return;
    }

    const myVote = post.get('myVote');

    if (!isUpvoted(myVote)) {
      upvotePost(postId);
    } else {
      clearvotePost(postId);
    }
  };

  downvotePost = () => {
    const {
      downvotePost = _noop,
      clearvotePost = _noop,
      postId,
      post = fromJS({}),
      isVotePending
    } = this.props;

    if (isVotePending) {
      return;
    }

    const myVote = post.get('myVote');

    if (!isDownvoted(myVote)) {
      downvotePost(postId);
    } else {
      clearvotePost(postId);
    }
  };

  render() {
    const { post = fromJS({}), isVotePending } = this.props;

    const score = post.get('score');
    const myVote = post.get('myVote');

    return (
      <PostVote
        isVotePending={isVotePending}
        upvotePost={this.upvotePost}
        downvotePost={this.downvotePost}
        score={score}
        myVote={myVote}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PostVoteContainer);
