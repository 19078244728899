import { createSelector } from 'reselect';
import { selectEntities } from '../entitiesSelectors';

export const selectUserById = createSelector(
  (state: Map<string, string>, userId?: string) => {
    if (!userId) {
      return undefined;
    }
    const results = selectEntities(state).getIn(['users', userId]);
    return results;
  },
  (user) => user
);
