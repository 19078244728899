import { Grid } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import FullWidthWrapper from 'app/components/FullWidthWrapper';
import ReadOnlyModeBanner from 'app/components/ReadOnlyModeBanner';
import NavHeaderSmallContainer from 'app/containers/NavHeaderSmallContainer';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';

interface Props {
  children: ReactNode;
}

export default class InnerPageLayout extends React.PureComponent<Props> {
  render() {
    const { children } = this.props;

    return (
      <FullWidthWrapper color="silver-lightest">
        <Grid gridTemplateRows="auto 2fr" minH="100vh">
          <PermissionsServiceContainer showIf={{ isReadOnly: true }}>
            <ReadOnlyModeBanner />
          </PermissionsServiceContainer>

          <NavHeaderSmallContainer />

          {children}
        </Grid>
      </FullWidthWrapper>
    );
  }
}
