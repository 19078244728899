import { UnorderedList, ListProps } from '@chakra-ui/react';
import React from 'react';

import TagItem from 'app/components/TagItem';

export interface Props {
  node?: string;
  project?: string;
  styleProps?: ListProps;
}

const TagList: React.FC<Props> = ({ node, project, styleProps }) => {
  return (
    <UnorderedList ms={0} {...styleProps}>
      {node && <TagItem name={node} data-ref="nanodegree-tag" />}
      {project && <TagItem name={project} data-ref="project-tag" />}
    </UnorderedList>
  );
};

export default TagList;
