import { Flex } from '@chakra-ui/react';
import React from 'react';

import { RatingInput, Reason } from 'app/models/ratings/ratingsTypes';

import FeedbackInput from './FeedbackInput';
import ReasonSelect from './ReasonSelect';
import RatingAnswer from './RatingAnswer';
import Submitted from './Submitted';

import i18n from 'i18n';

export enum PositionState {
  ANSWERED,
  SELECT_REASON,
  FEEDBACK,
  REQUEUE,
  SUCCESS,
  CLOSED
}

export interface RatingFormManagerProps {
  state: PositionState;
  onRatingClick: (answered: boolean) => void;
  onReasonSelect: (reason: Reason) => void;
  onFeedbackInputSubmit: (userInput: string) => void;
  onClose: () => void;
}

export const RatingFormManager: React.FC<RatingFormManagerProps> = ({
  state,
  onRatingClick,
  onReasonSelect,
  onFeedbackInputSubmit,
  onClose
}) => {
  switch (state) {
    case PositionState.ANSWERED:
      return <RatingAnswer onClick={onRatingClick} />;
    case PositionState.SELECT_REASON:
      return <ReasonSelect onSelect={onReasonSelect} />;
    case PositionState.FEEDBACK:
      return <FeedbackInput onFeedbackSubmit={onFeedbackInputSubmit} />;
    case PositionState.REQUEUE:
      return (
        <Submitted onClose={onClose}>
          {i18n.t('rating.questionReviewedAgain')}
        </Submitted>
      );
    case PositionState.SUCCESS:
      return (
        <Submitted onClose={onClose}>{i18n.t('rating.setAsAnswer')}</Submitted>
      );
  }

  return null;
};

export interface RatingFormProps {
  postId: string;
  questionId: string;
  handleRating: (answerId: string, ratingInput: RatingInput) => void;
  handleUpvote: (answerId: string) => void;
  handleAccept: (answerId: string) => void;
  handleRequeue: (questionId: string) => void;
}

export const RatingForm: React.FC<RatingFormProps> = ({
  postId,
  questionId,
  handleRating,
  handleAccept,
  handleUpvote,
  handleRequeue
}) => {
  const [state, setState] = React.useState<PositionState>(
    PositionState.ANSWERED
  );

  const onFormSubmit = (answered: boolean, body: string, reason: Reason) => {
    const ratingInput: RatingInput = { answered, body, reason };
    handleRating(postId, ratingInput);

    if (answered) {
      handleAccept(postId);
      handleUpvote(postId);
    } else {
      handleRequeue(questionId);
    }

    setState(PositionState.CLOSED);
  };

  const onRatingClick = (answer: boolean) => {
    if (answer) {
      onFormSubmit(answer, '', '');
    }

    const newPosition = answer
      ? PositionState.SUCCESS
      : PositionState.SELECT_REASON;

    setState(newPosition);
  };

  const onReasonSelect = (reason: Reason) => {
    if (reason !== 'other') {
      onFormSubmit(false, '', reason);
    }

    const newPosition =
      reason === 'other' ? PositionState.FEEDBACK : PositionState.REQUEUE;

    setState(newPosition);
  };

  const onFeedbackInputSubmit = (body: string) => {
    onFormSubmit(false, body, 'other');
    setState(PositionState.REQUEUE);
  };

  const closeRatingForm = () => setState(PositionState.CLOSED);

  if (state === PositionState.CLOSED) {
    return null;
  }

  return (
    <Flex
      as="form"
      direction="column"
      bgColor="silver-lightest"
      border="1px solid"
      borderColor="silver-light"
      borderRadius="secondary"
      p="1rem 2rem"
      mb={2}
    >
      <RatingFormManager
        state={state}
        onRatingClick={onRatingClick}
        onReasonSelect={onReasonSelect}
        onFeedbackInputSubmit={onFeedbackInputSubmit}
        onClose={closeRatingForm}
      />
    </Flex>
  );
};

export default RatingForm;
