import { HighlightResult } from 'react-instantsearch-core';
import { IUser } from 'app/models/entities/users/usersEntitiesTypes';
import { IStats } from 'app/models/activity/activityTypes';

export interface QuestionData {
  isDeletedQuestion: boolean;
  myVote: number;
  user: {
    id: string;
    displayName: string;
    profileImageUrl: string;
  };
}

export interface SearchClickAnalytics {
  page: number;
  query: string;
  searchPosition?: number;
  nanodegreeKey?: string;
  projectID?: string;
  rubricID?: number;
  totalPages?: number;
  totalAnsweredResults?: number;
}

export interface SearchParams {
  query?: string;
  page?: number;
  unanswered?: boolean;
  nanodegreeKey?: string;
  projectID?: number;
  rubricID?: number;
}

export interface AlgoliaSearchParams {
  totalResults?: number;
  totalAnsweredResults?: number;
  totalPages?: number;
}

export interface AlgoliaHit {
  answer_count: number;
  accepted_answer_id: number;
  comment_count: number;
  content: string;
  created_at: number;
  is_accepted: boolean;
  is_verified: boolean;
  locale: string;
  myVote?: number;
  objectID: string;
  parent_id: number;
  post_type: string;
  program_key: string;
  programTitle?: string;
  project_id: number;
  project_title: string;
  question_title: string;
  root_id: number;
  score: number;
  user?: IUser;
  user_id: string;
  verified_answer_id: number;
  _snippetResult?: SnippetResult;
  _highlightResult?: HighlightResult<any>;
}

export interface AlgoliaDisplayResult {
  acceptedAnswerId: number;
  createdAt: string;
  answerCount: number;
  id: number;
  programTitle?: string;
  projectTitle: string;
  title: string;
  url: string;
  user?: IUser;
  userActivityStats?: IStats;
  projectId: number;
  content: string | string[];
  verifiedAnswerId: number;
  _highlightResult?: HighlightResult<any>;
  _snippetResult?: SnippetResult;
}

export interface SnippetResult {
  text?: {
    matchLevel: string;
    value: string;
  };
  content?: {
    matchLevel: string;
    value: string;
  };
  body_safe?: string;
}

export interface SearchSession {
  sessionID: string;
  searchVariant: string;
}

export enum SEARCH_INDEX_ID {
  ALL_RESULTS = 'all',
  ANSWERED_RESULTS = 'answered',
  UNANSWERED_RESULTS = 'unanswered'
}
