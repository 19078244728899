import { all, call, put, takeEvery } from 'redux-saga/effects';

import { makeSagaLifecycle } from 'app/models/helpers/sagaHelper';
import {
  normalizeAnswersCollection,
  normalizeQuestionsCollection
} from 'app/models/posts/postsNormalizers';

import { actionTypes as activityActions } from './activityActions';
import constants from './activityConstants';
import * as requests from './activityExternalRequests';

export function* getQuestions({
  payload: { userId = '', offset = 0, limit = constants.PAGE_SIZE } = {}
}) {
  const payload = { userUid: userId, offset, limit };
  const results = yield call(requests.getQuestions, payload);

  const normalizedResults = normalizeQuestionsCollection({
    questions: results
  });

  yield put({
    type: activityActions.FETCH_QUESTIONS_FULFILLED,
    payload: normalizedResults
  });
}

export function* getAnswers({
  payload: { userId = '', offset = 0, limit = constants.PAGE_SIZE } = {}
}) {
  const payload = { userUid: userId, offset, limit };
  const results = yield call(requests.getAnswers, payload);

  const normalizedResults = normalizeAnswersCollection({
    answers: results
  });

  yield put({
    type: activityActions.FETCH_ANSWERS_FULFILLED,
    payload: normalizedResults
  });
}

export function* getUserActivityStats({ payload }) {
  const result = yield call(requests.getUserActivityStats, payload);

  yield put({
    type: activityActions.FETCH_USER_ACTIVITY_STATS_FULFILLED,
    payload: { result, id: payload }
  });
}

export function* watchGetQuestions() {
  yield takeEvery(
    activityActions.FETCH_QUESTIONS_START,
    makeSagaLifecycle(getQuestions, 'FETCH_QUESTIONS', activityActions)
  );
}

export function* watchGetAnswers() {
  yield takeEvery(
    activityActions.FETCH_ANSWERS_START,
    makeSagaLifecycle(getAnswers, 'FETCH_ANSWERS', activityActions)
  );
}

export function* watchGetUserActivityStats() {
  yield takeEvery(
    activityActions.FETCH_USER_ACTIVITY_STATS_START,
    makeSagaLifecycle(
      getUserActivityStats,
      'FETCH_USER_ACTIVITY_STATS',
      activityActions
    )
  );
}

export default function* moduleRoot() {
  yield all([
    watchGetQuestions(),
    watchGetAnswers(),
    watchGetUserActivityStats()
  ]);
}
