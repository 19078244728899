/*
 * Load config vars from within `/src/app`.
 * This allows for simpler absolute paths when
 * importing elsewhere from here.
 */

import CONFIG from 'appConfig.json';
import ENV from 'config';

export default CONFIG[ENV.REACT_APP_ENV];
