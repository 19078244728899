import { CloseButton, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

import i18n from 'i18n';

export interface Props {
  onClose: () => any;
}

const Submitted: React.FC<Props> = ({ onClose, children }) => (
  <>
    <Flex justify="space-between" align="center">
      <Heading size="h4" as="h5" lineHeight="base">
        {i18n.t('rating.thanksForFeedback')}
      </Heading>
      <CloseButton onClick={onClose} color="silver" />
    </Flex>
    <Text>{children}</Text>
  </>
);

export default Submitted;
