import { chakra, IconButton, keyframes } from '@chakra-ui/react';

const VoteButton = chakra(IconButton, {
  baseStyle: {
    display: 'inline-block',
    width: '2.75rem',
    height: { base: '2.5rem', 'sm-md': '1.5rem' },
    _active: { shadow: 'none', bg: 'transparent' },
    _focus: { shadow: 'none', bg: 'transparent' }
  }
});

export const UpVoteButton = chakra(VoteButton, {
  baseStyle: {
    _hover: {
      bg: 'transparent',
      transform: { 'sm-md': 'translateY(-0.3rem)' },
      _focus: { shadow: 'none' }
    },
    '> svg': {
      transform: {
        base: 'translateY(0)',
        'sm-md': 'translateY(-0.1rem)'
      },
      _hover: { color: { 'sm-md': 'cerulean' } }
    }
  }
});

export const DownVoteButton = chakra(VoteButton, {
  baseStyle: {
    _hover: {
      bg: 'transparent',
      transform: { 'sm-md': 'translateY(0.2rem)' },
      _focus: { shadow: 'none' }
    },
    '> svg': {
      transform: {
        base: 'translateY(0)',
        'sm-md': 'translateY(-0.9rem)'
      },
      _hover: { color: { 'sm-md': 'cerulean' } }
    }
  }
});

export const ReadOnlyScore = chakra('div', {
  baseStyle: {
    display: { base: 'flex', 'sm-md': 'block' },
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2
  }
});

export const animationDuration = '500ms';

export const bounceUp = keyframes(`
  0% {
    transform: translateY(-0.3rem);
  }

  30% {
    transform: translateY(-0.6rem);
  }

  60% {
    transform: translateY(-0.6rem);
  }

  100% {
    transform: translateY(-0.3rem);
  }
  `);

export const bounceDown = keyframes(`
  0% {
    transform: translateY(0.2rem);
  }

  30% {
    transform: translateY(0.6rem);
  }

  60% {
    transform: translateY(0.6rem);
  }

  100% {
    transform: translateY(0.2rem);
  }
  `);
