import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AnswerList from 'app/components/AnswerList';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import { isPending } from 'app/models/helpers/apiStatusHelpers';
import {
  selectApiStatus,
  selectCurrentQuestion
} from 'app/models/posts/postsSelectors';
import { selectUnreads } from 'app/models/unreads/unreadsSelectors';
import { actionCreators as unreadsActions } from 'app/models/unreads/unreadsActions';

export interface Props {
  deepLinkId?: string;
}

export const AnswerListContainer: React.FC<Props> = ({ deepLinkId }) => {
  const question = useSelector(selectCurrentQuestion);
  const getAnswersByQuestionIdStatus = useSelector((state: any) =>
    selectApiStatus(state, 'getAnswersByQuestionId')
  );
  const isLoading = isPending(getAnswersByQuestionIdStatus);
  const unreads = useSelector(selectUnreads);

  const dispatch = useDispatch();

  const questionId = useMemo(() => question.get('id'), [question]);
  const answers = useMemo(() => question.get('answers').toJS(), [question]);
  const markAsRead = useMemo(
    () =>
      answers
        .filter(({ id }) => unreads?.[questionId]?.[id])
        .map(({ id }) => id),
    [unreads, answers, questionId]
  );

  useEffect(() => {
    dispatch(postsActions.getAnswersByQuestionId(questionId));
  }, [dispatch, questionId]);

  useEffect(() => {
    if (!!markAsRead.length) {
      dispatch(unreadsActions.markAsRead(markAsRead, questionId));
    }
  }, [dispatch, markAsRead, questionId]);

  if (!question) {
    return null;
  }

  return (
    <AnswerList
      deepLinkId={deepLinkId}
      answers={answers}
      unreads={unreads}
      isLoading={isLoading}
    />
  );
};

export default AnswerListContainer;
