import { Map } from 'immutable';

import apiStatuses from './apiStatusHelpers';

interface ApiActions {
  READY: string;
  PENDING: string;
  FULFILLED: string;
  REJECTED: string;
}

export function makeApiReducer(actions: ApiActions) {
  const initialState = Map({
    status: apiStatuses.ready,
    error: null
  });

  function apiReducer(
    state: Map<string, string | null> = initialState,
    action: any
  ) {
    const { type, payload } = action;

    switch (type) {
      case actions.READY:
        return state.merge({
          status: apiStatuses.ready,
          error: null
        });
      case actions.PENDING:
        return state.merge({
          payload,
          status: apiStatuses.pending,
          error: null
        });
      case actions.FULFILLED:
        return state.merge({
          payload,
          status: apiStatuses.fulfilled,
          error: null
        });
      case actions.REJECTED:
        return state.merge({
          status: apiStatuses.rejected,
          error: payload
        });
    }

    return state;
  }

  return apiReducer;
}

export function normalizeApiActions(
  actionName: string,
  actionClass: string
): ApiActions {
  if (actionName && !actionClass) {
    throw new TypeError(
      '"actionClass" is a required arg on normalizeApiActions()'
    );
  }
  const READY = actionName
    ? `${actionClass}/${actionName}_READY`
    : 'DEFAULT_API_READY';
  const PENDING = actionName
    ? `${actionClass}/${actionName}_PENDING`
    : 'DEFAULT_API_PENDING';
  const FULFILLED = actionName
    ? `${actionClass}/${actionName}_FULFILLED`
    : 'DEFAULT_API_FULFILLED';
  const REJECTED = actionName
    ? `${actionClass}/${actionName}_REJECTED`
    : 'DEFAULT_API_REJECTED';

  return { READY, PENDING, FULFILLED, REJECTED };
}
