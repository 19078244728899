import { Heading, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export interface Props {
  navText: string;
  navIcon: React.ReactNode;
  navLink: string;
}

export default class NavListItem extends React.PureComponent<Props> {
  render() {
    const { navText, navIcon, navLink } = this.props;
    return (
      <Link
        as={ReactRouterLink}
        to={navLink}
        display="flex"
        alignItems="center"
        w="max-content"
      >
        {navIcon}
        <Heading
          size="h6"
          as="h6"
          color="white"
          fontSize=".8125rem"
          lineHeight="1.5rem"
          wordBreak="normal"
          display={{ base: 'none', 'sm-md': 'inline-block' }}
          ms={{ base: 2, 'sm-md': '0.3rem' }}
        >
          {navText}
        </Heading>
      </Link>
    );
  }
}
