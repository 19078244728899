import React from 'react';
import { connect } from 'react-redux';

import NavHeaderSmall from 'app/components/NavHeaderSmall';
import { selectSearchQueryString } from 'app/models/search//searchSelectors';

interface NavHeaderSmallStateProps {
  searchQueryString: string;
}

export type Props = NavHeaderSmallStateProps;

const mapStateToProps = (state): NavHeaderSmallStateProps => {
  return {
    searchQueryString: selectSearchQueryString(state)
  };
};

export class NavHeaderSmallContainer extends React.PureComponent<Props> {
  render(): JSX.Element {
    const { searchQueryString } = this.props;
    return <NavHeaderSmall searchQueryString={searchQueryString} />;
  }
}

export default connect<NavHeaderSmallStateProps, {}, {}>(
  mapStateToProps,
  {}
)(NavHeaderSmallContainer);
