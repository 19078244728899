import { chakra, Flex } from '@chakra-ui/react';

export const PostItem = chakra('article', {
  baseStyle: {
    display: { 'sm-md': 'grid' },
    gridGap: { 'sm-md': 1 },
    gridTemplateColumns: { 'sm-md': '4.625rem 1fr' },
    gridTemplateRows: { 'sm-md': 'repeat(2, auto)' },
    p: { base: '0 0 1.25rem 0', 'sm-md': '1.5rem 0 1.25rem' }
  }
});

export const PostVoteWrapper = chakra('div', {
  baseStyle: {
    mt: { base: '1.25rem', 'sm-md': '1px' },
    mb: { base: '0.625rem', 'sm-md': 0 },
    gridRow: { 'sm-md': '1 / -1' }
  }
});

export const CreatedAtText = chakra('span', {
  baseStyle: {
    me: '0.4rem',
    color: 'slate-light',
    fontSize: 'sm',
    fontWeight: 'semibold',
    whiteSpace: 'nowrap'
  }
});

export const AnswerCommentWrapper = chakra(Flex, {
  baseStyle: {
    justify: 'end',
    m: { base: '0.5rem 0 0 0', 'sm-md': '0.5rem 0' },
    textAlign: 'end',
    wordBreak: 'normal'
  }
});

export const CommentWrapper = chakra('div', {
  baseStyle: {
    display: { base: 'none', lg: 'inline-flex' },
    mt: '0.5px',
    me: '0.7rem'
  }
});
