import _omit from 'lodash/omit';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import SearchFilterSummary from 'app/components/SearchFilterSummary';
import {
  selectNodeTitle,
  selectProjectTitle
} from 'app/models/nanodegree/nanodegreeSelectors';
import { actionCreators } from 'app/models/search/searchActions';
import {
  selectFilterByUnanswered,
  selectNanodegreeProjectFilter
} from 'app/models/search/searchSelectors';
import { SearchParams } from 'app/models/search/searchTypes';
import { START_PAGE } from 'app/models/search/searchConstants';

interface LocationProps extends RouteComponentProps<any> {}

interface DispatchProps {
  resetSearchFilters: (params: SearchParams) => void;
}

interface SearchFilterSummaryContainerStateProps {
  filterByUnanswered: boolean;
  nodeTitle?: string;
  projectTitle?: string;
}

export type SearchFilterSummaryContainerProps = SearchFilterSummaryContainerStateProps &
  DispatchProps &
  LocationProps;

const mapStateToProps = (state): SearchFilterSummaryContainerStateProps => {
  const filter = selectNanodegreeProjectFilter(state);
  return {
    filterByUnanswered: selectFilterByUnanswered(state),
    nodeTitle:
      filter && filter.nanodegreeKey
        ? selectNodeTitle(state, filter.nanodegreeKey)
        : undefined,
    projectTitle:
      filter && filter.projectID
        ? selectProjectTitle(state, filter.projectID)
        : undefined
  };
};

const mapDispatchToProps: DispatchProps = {
  resetSearchFilters: actionCreators.resetSearchFilters
};

export class SearchFilterSummaryContainer extends React.PureComponent<
  SearchFilterSummaryContainerProps
> {
  handleFilterReset = () => {
    const { resetSearchFilters, location, history } = this.props;
    resetSearchFilters({
      nanodegreeKey: undefined,
      page: START_PAGE,
      projectID: undefined,
      query: '',
      rubricID: undefined,
      unanswered: false
    });

    const searchQuery = queryString.parse(location.search);

    history.push({
      search: queryString.stringify(
        _omit(searchQuery, [
          'nanodegree',
          'project',
          'rubric',
          'unanswered',
          'page'
        ])
      )
    });
  };

  render() {
    const { filterByUnanswered, nodeTitle, projectTitle } = this.props;

    return (
      <SearchFilterSummary
        unanswered={filterByUnanswered}
        nodeTitle={nodeTitle}
        projectTitle={projectTitle}
        handleFilterReset={this.handleFilterReset}
      />
    );
  }
}

export default withRouter(
  connect<SearchFilterSummaryContainerStateProps, DispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(SearchFilterSummaryContainer)
);
