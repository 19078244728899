import { Box } from '@chakra-ui/react';
import React from 'react';

import TabItem from 'app/components/TabItem';
import { IActivityOption } from 'app/models/activity/activityTypes';

export interface Tab {
  text: string;
  id: IActivityOption;
}

export interface Props {
  handleChange: (type: IActivityOption) => void;
  selectedGroup: IActivityOption;
  tabs: Tab[];
}

export default class TabList extends React.PureComponent<Props> {
  render() {
    const { handleChange, selectedGroup, tabs = [] } = this.props;

    return (
      <Box
        as="article"
        borderBottom="1px solid"
        borderBottomColor="silver-lighter"
      >
        {tabs.map((tab: Tab) => (
          <TabItem
            key={tab.id}
            text={tab.text}
            id={tab.id}
            handleChange={handleChange}
            selectedGroup={selectedGroup}
          />
        ))}
      </Box>
    );
  }
}
