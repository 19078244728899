import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';
import { CommentFormType } from './postsTypes';

export const actionClass = 'POSTS';

export const actionTypes = {
  ...createActionType('FETCH_QUESTION', actionClass),
  ...createActionType('FETCH_ANSWERS_BY_QUESTION_ID', actionClass),
  ...createActionType('UPDATE_QUESTION', actionClass),
  ...createActionType('CREATE_QUESTION', actionClass),
  ...createActionType('DELETE_POST', actionClass),
  ...createActionType('UPVOTE_POST', actionClass),
  ...createActionType('DOWNVOTE_POST', actionClass),
  ...createActionType('CLEARVOTE_POST', actionClass),
  ...createActionType('UPDATE_ANSWER', actionClass),
  ...createActionType('CREATE_ANSWER', actionClass),
  ...createActionType('UPDATE_COMMENT', actionClass),
  ...createActionType('CREATE_COMMENT', actionClass),
  ...createActionType('ACCEPT_ANSWER', actionClass),
  ...createActionType('UNACCEPT_ANSWER', actionClass),
  ...createActionType('VERIFY_ANSWER', actionClass),
  ...createActionType('UNVERIFY_ANSWER', actionClass),
  ...createActionType('FETCH_MODERATE_POST_REASONS', actionClass),
  ...createActionType('ADMIN_DELETE_POST', actionClass),
  ...createActionType('REPORT_POST', actionClass),
  ...createActionType('UNARCHIVE_POST', actionClass),
  ...createActionType('MOVE_COMMENT_TO_ANSWER', actionClass),
  EXPAND_COMMENT_THREAD: 'POSTS/EXPAND_COMMENT_THREAD',
  COLLAPSE_COMMENT_THREAD: 'POSTS/COLLAPSE_COMMENT_THREAD',
  EXPAND_ANSWER_FORM: 'POSTS/EXPAND_ANSWER_FORM',
  COLLAPSE_ANSWER_FORM: 'POSTS/COLLAPSE_ANSWER_FORM',
  EXPAND_COMMENT_FORM: 'POSTS/EXPAND_COMMENT_FORM',
  COLLAPSE_COMMENT_FORM: 'POSTS/COLLAPSE_COMMENT_FORM',
  SET_LATEST_FILE_INPUT_REF: 'POSTS/SET_LATEST_FILE_INPUT_REF'
};

export const actionCreators = {
  moveCommentToAnswer(commentId: string, parentId: string) {
    return createAction(actionTypes.MOVE_COMMENT_TO_ANSWER_START, {
      commentId,
      parentId
    });
  },
  setLatestFileInputRef(ref: HTMLInputElement) {
    return createAction(actionTypes.SET_LATEST_FILE_INPUT_REF, { ref });
  },
  getQuestion(id: string) {
    return createAction(actionTypes.FETCH_QUESTION_START, { id });
  },
  getAnswersByQuestionId(id: string) {
    return createAction(actionTypes.FETCH_ANSWERS_BY_QUESTION_ID_START, { id });
  },
  resetGetQuestion() {
    return createAction(actionTypes.FETCH_QUESTION_READY);
  },
  createQuestion(payload: any) {
    return createAction(actionTypes.CREATE_QUESTION_PENDING, payload);
  },
  resetCreateQuestion() {
    return createAction(actionTypes.CREATE_QUESTION_READY);
  },
  updateQuestion(question: any, questionId: string) {
    return createAction(actionTypes.UPDATE_QUESTION_PENDING, {
      questionId,
      question
    });
  },
  resetUpdateQuestion() {
    return createAction(actionTypes.UPDATE_QUESTION_READY);
  },
  deletePost(postId: string, parentId?: string) {
    return createAction(actionTypes.DELETE_POST_PENDING, { postId, parentId });
  },
  resetDeletePost() {
    return createAction(actionTypes.DELETE_POST_READY);
  },
  upvotePost(postId: string) {
    return createAction(actionTypes.UPVOTE_POST_PENDING, {
      postId
    });
  },
  downvotePost(postId: string) {
    return createAction(actionTypes.DOWNVOTE_POST_PENDING, {
      postId
    });
  },
  clearvotePost(postId: string) {
    return createAction(actionTypes.CLEARVOTE_POST_PENDING, {
      postId
    });
  },
  createAnswer(questionId: string, answer: any) {
    return createAction(actionTypes.CREATE_ANSWER_START, {
      questionId,
      body: answer
    });
  },
  resetCreateAnswer() {
    return createAction(actionTypes.CREATE_ANSWER_READY);
  },
  updateAnswer(answerId: string, parentId: string, answer: { body: any }) {
    return createAction(actionTypes.UPDATE_ANSWER_START, {
      answerId,
      parentId,
      answer
    });
  },
  resetUpdateAnswer() {
    return createAction(actionTypes.UPDATE_ANSWER_READY);
  },
  acceptAnswer(answerId: string) {
    return createAction(actionTypes.ACCEPT_ANSWER_PENDING, { answerId });
  },
  unacceptAnswer(answerId: string) {
    return createAction(actionTypes.UNACCEPT_ANSWER_PENDING, { answerId });
  },
  verifyAnswer(answerId: string) {
    return createAction(actionTypes.VERIFY_ANSWER_PENDING, { answerId });
  },
  unverifyAnswer(answerId: string) {
    return createAction(actionTypes.UNVERIFY_ANSWER_PENDING, { answerId });
  },
  updateComment(commentId: string, parentId: string, comment: any) {
    return createAction(actionTypes.UPDATE_COMMENT_START, {
      commentId,
      parentId,
      comment
    });
  },
  resetUpdateComment() {
    return createAction(actionTypes.UPDATE_COMMENT_READY);
  },
  expandCommentThread(parentId: string) {
    return createAction(actionTypes.EXPAND_COMMENT_THREAD, { parentId });
  },
  collapseCommentThread(parentId: string) {
    return createAction(actionTypes.COLLAPSE_COMMENT_THREAD, { parentId });
  },
  expandAnswerForm(parentId: string) {
    return createAction(actionTypes.EXPAND_ANSWER_FORM, { parentId });
  },
  collapseAnswerForm(parentId: string) {
    return createAction(actionTypes.COLLAPSE_ANSWER_FORM, { parentId });
  },
  expandCommentForm(
    parentId: string,
    commentFormType = CommentFormType.COMMENT
  ) {
    return createAction(actionTypes.EXPAND_COMMENT_FORM, {
      parentId,
      commentFormType
    });
  },
  collapseCommentForm(parentId: string) {
    return createAction(actionTypes.COLLAPSE_COMMENT_FORM, { parentId });
  },
  createComment(parentId: string, comment: any) {
    return createAction(actionTypes.CREATE_COMMENT_START, {
      parentId,
      comment
    });
  },
  resetCreateComment() {
    return createAction(actionTypes.CREATE_COMMENT_READY);
  },
  getModeratePostReasons() {
    return createAction(actionTypes.FETCH_MODERATE_POST_REASONS_START);
  },
  adminDeletePost(postId: string, reasonId: string, parentId?: string) {
    return createAction(actionTypes.ADMIN_DELETE_POST_START, {
      postId,
      parentId,
      reasonId
    });
  },
  resetAdminDeletePost() {
    return createAction(actionTypes.ADMIN_DELETE_POST_READY);
  },
  reportPost(postId: string, reasonId: string) {
    return createAction(actionTypes.REPORT_POST_START, {
      postId,
      reasonId
    });
  },
  unarchivePost(postId: string) {
    return createAction(actionTypes.UNARCHIVE_POST_START, {
      postId
    });
  }
};
