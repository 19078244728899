import { Map, fromJS } from 'immutable';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import React from 'react';
import { connect } from 'react-redux';

import ReplyForm from 'app/components/ReplyForm';
import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { actionCreators as alertsActions } from 'app/models/alerts/alertsActions';
import { selectIsImageUploadPending } from 'app/models/files/filesSelectors';
import { isFulfilled } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectComment
} from 'app/models/posts/postsSelectors';

import { validate, ValidationError } from '../commentFormContainerHelpers';
import i18n from 'i18n';

interface StateProps {
  updateCommentApiStatus: Map<string, string>;
  comment: Map<string, string>;
  isImageUploadPending: boolean;
}

interface DispatchProps {
  addAlert: (type: string, message: string) => void;
  updateComment: (commentId: string, parentId: string, content: {}) => void;
  resetUpdateComment: () => void;
}

interface OwnProps {
  commentId: string;
  onCancel: () => void;
}

export type Props = StateProps & DispatchProps & OwnProps;

interface State {
  validationErrors: ValidationError[];
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => ({
  comment: selectComment(state, ownProps.commentId),
  updateCommentApiStatus: selectApiStatus(state, 'updateComment'),
  isImageUploadPending: selectIsImageUploadPending(state)
});

const mapDispatchToProps: DispatchProps = {
  addAlert: alertsActions.addAlert,
  updateComment: postsActions.updateComment,
  resetUpdateComment: postsActions.resetUpdateComment
};

export class EditCommentFormContainer extends React.PureComponent<
  Props,
  State
> {
  static defaultProps = {
    comment: fromJS({})
  };

  state = {
    validationErrors: []
  };

  componentDidUpdate(prevProps: Props) {
    const { updateCommentApiStatus = fromJS({}), commentId } = this.props;
    if (commentId === updateCommentApiStatus.getIn(['payload', 'commentId'])) {
      this.checkUpdatedCommentStatus(prevProps);
    }
  }

  componentWillUnmount() {
    const { resetUpdateComment = _noop } = this.props;
    resetUpdateComment();
  }

  checkUpdatedCommentStatus(prevProps: Props) {
    const { updateCommentApiStatus: prevStatus } = prevProps;
    const { updateCommentApiStatus, onCancel } = this.props;

    const editSuccess =
      isFulfilled(updateCommentApiStatus) && !isFulfilled(prevStatus);

    if (editSuccess) {
      onCancel();
    }
  }

  validateComment(content: Mobiledoc): ValidationError[] {
    const validationErrors: ValidationError[] = validate(content);
    this.setState({ validationErrors });
    return validationErrors;
  }

  handleChange = (content: Mobiledoc): void => {
    this.validateComment(content);
  };

  dispatchUpdateComment = (content: Mobiledoc): void => {
    const {
      commentId,
      updateComment = _noop,
      comment = fromJS({})
    } = this.props;
    const hasFormError = !!this.validateComment(content).length;

    if (!hasFormError) {
      updateComment(commentId, comment.get('parentId'), { content });
    }
  };

  render() {
    const {
      updateCommentApiStatus,
      comment = fromJS({}),
      onCancel,
      isImageUploadPending
    } = this.props;

    const replyFormError = _find(this.state.validationErrors, {
      field: 'content'
    });

    return (
      <ReplyForm
        title={i18n.t('comment.edit')}
        subtitle={i18n.t('comment.write')}
        submitButtonText={i18n.t('comment.submit')}
        initialContent={{
          content: comment.get('content')
        }}
        isImageUploadPending={isImageUploadPending}
        onSubmit={this.dispatchUpdateComment}
        onChange={this.handleChange}
        onCancel={onCancel}
        errorMessage={_get(replyFormError, 'message', null)}
        apiStatus={updateCommentApiStatus}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(EditCommentFormContainer);
