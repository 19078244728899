import { chakra } from '@chakra-ui/react';

export const StatNumber = chakra('dt', {
  baseStyle: {
    m: 0,
    p: 0,
    fontSize: '1.125rem',
    lineHeight: 'sm',
    fontWeight: 'bold'
  }
});

export const StatText = chakra('dd', {
  baseStyle: {
    m: 0,
    p: 0,
    fontSize: 'sm',
    lineHeight: 'sm'
  }
});
