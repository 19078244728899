import { Box } from '@chakra-ui/react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export interface Props {
  isAdmin: boolean;
  id?: string;
  displayName: string;
}

export default class DisplayName extends React.PureComponent<Props> {
  render() {
    const { isAdmin, id, displayName } = this.props;

    return (
      <>
        {!isAdmin && (
          <Box as="span" me="0.4rem" data-ref="author">
            {displayName}
          </Box>
        )}
        {isAdmin && (
          <CopyToClipboard text={id}>
            <Box as="span" me="0.4rem" cursor="pointer" data-ref="author-admin">
              {displayName}
            </Box>
          </CopyToClipboard>
        )}
      </>
    );
  }
}
