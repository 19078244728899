import { Map, fromJS } from 'immutable';

import { mergeEntities } from 'app/models/entities/entitiesReducerHelpers';
import { actionTypes as postActions } from 'app/models/posts/postsActions';

type State = Map<string, string>;

export const initialState: State = fromJS({
  byId: {}
});

export default function adminDeleteReasons(
  state: State = initialState,
  action: any
) {
  const { type, payload } = action;
  switch (type) {
    case postActions.FETCH_MODERATE_POST_REASONS_FULFILLED: {
      const { moderatePostReasons } = payload.entities;
      return mergeEntities(state, moderatePostReasons);
    }
  }
  return state;
}
