import { chakra } from '@chakra-ui/react';
import _padStart from 'lodash/padStart';
import React, { ReactNode } from 'react';

import {
  CodeBlock,
  CodeLine,
  EditorOverlay,
  LineNumber
} from './factoryComponents';

export interface Props {
  children: string;
  isEditing: boolean;
  textInput: ReactNode;
}

export default class CodeBlockLayout extends React.PureComponent<Props> {
  static defaultProps = {
    children: ''
  };

  render() {
    const { children, isEditing, textInput } = this.props;

    const lines = children.split('\n');

    // pad line numbers for at least 2 digits, but allow for more:
    const lineCountDigits = Math.max(lines.length.toString().length, 2);

    return (
      <CodeBlock>
        <chakra.div position="relative">
          {isEditing && <EditorOverlay>{textInput}</EditorOverlay>}
          {lines.map((lineContent, i) => (
            <CodeLine key={i} data-ref="code-line">
              <LineNumber>{_padStart(`${i + 1}`, lineCountDigits)}</LineNumber>
              <chakra.div whiteSpace="pre-wrap">{lineContent}</chakra.div>
            </CodeLine>
          ))}
        </chakra.div>
      </CodeBlock>
    );
  }
}
