import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import CurrentPaginationContainer from 'app/containers/CurrentPaginationContainer';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import SearchFilterSummaryContainer from 'app/containers/SearchFilterSummaryContainer';
import i18n from 'i18n';

interface SearchResultsBarProps {
  searchResultsCount?: number;
}

export default class SearchResultsBar extends React.PureComponent<
  SearchResultsBarProps
> {
  render() {
    const { searchResultsCount } = this.props;

    return (
      <Flex
        justify="space-between"
        align="center"
        wrap="wrap"
        pt="0.6rem"
        pb="0.3rem"
      >
        {!!searchResultsCount && (
          <PermissionsServiceContainer showIf={{ isReadOnly: true }}>
            <Text
              mb={{ base: 4, lg: 0 }}
              fontWeight="semibold"
              fontSize="1.125rem"
              color="stone"
            >
              {i18n.t('search.recentQuestions')}
            </Text>
          </PermissionsServiceContainer>
        )}
        <PermissionsServiceContainer showIf={{ isReadOnly: false }}>
          <Box display={{ base: 'none', 'sm-md': 'inline-block' }}>
            <CurrentPaginationContainer />
          </Box>
          <SearchFilterSummaryContainer />
        </PermissionsServiceContainer>
      </Flex>
    );
  }
}
