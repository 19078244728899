import { Alert, AlertDescription } from '@chakra-ui/react';
import { Map } from 'immutable';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  CheckedIcon,
  ReplyIcon,
  TimeIcon,
  WarningIcon
} from '@udacity/chakra-veritas-icons';

import InfoBanner from 'app/components/InfoBanner';
import QuestionDetail from 'app/components/QuestionDetail';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import { selectCurrentQuestion } from 'app/models/posts/postsSelectors';
import { actionCreators as questionQueueActions } from 'app/models/QuestionQueue/questionQueueActions';
import { selectQuestionStatus } from 'app/models/QuestionQueue/questionQueueSelectors';
import {
  QuestionStatus,
  QuestionState
} from 'app/models/QuestionQueue/questionQueueTypes';
import permissionsService from 'app/services/permissionsService';
import { selectCurrentUserIsStaff } from 'app/models/session/sessionSelectors';

import i18n from 'i18n';

export interface Props {
  // props
  deepLinkId?: string;
  // state
  question: Map<any, any>;
  questionStatus: QuestionStatus;
  currentUserIsStaff: boolean;
  // dispatch
  resetDeletePost: () => void;
  resetAdminDeletePost: () => void;
  fetchQuestionStatus: (questionId: string) => void;
}

const mapStateToProps = (state) => {
  const question = selectCurrentQuestion(state);
  const questionStatus = question
    ? selectQuestionStatus(state, question.get('id'))
    : undefined;
  const currentUserIsStaff = selectCurrentUserIsStaff(state);
  return { question, questionStatus, currentUserIsStaff };
};

const mapDispatchToProps = {
  resetDeletePost: postsActions.resetDeletePost,
  resetAdminDeletePost: postsActions.resetAdminDeletePost,
  fetchQuestionStatus: questionQueueActions.fetchQuestionStatus
};

export class QuestionDetailContainer extends React.Component<Props> {
  static defaultProps = {
    question: Map(),
    questionStatus: {
      state: QuestionState.OPEN,
      atRisk: false
    }
  };

  state = {
    canShowBanner: true
  };

  componentDidMount(): void {
    const { question, fetchQuestionStatus, currentUserIsStaff } = this.props;
    // Question author and staff are authorized to access question status from the backend.
    const questionId = question.get('id');
    if (permissionsService.isQuestionAuthor(questionId) || currentUserIsStaff) {
      fetchQuestionStatus(questionId);
    }
  }

  componentWillUnmount(): void {
    this.props.resetDeletePost();
    this.props.resetAdminDeletePost();
  }

  handleCloseBanner = () => {
    this.setState({ canShowBanner: false });
  };

  renderBannerForQuestionStatus(): ReactElement | undefined {
    const { question, questionStatus, currentUserIsStaff } = this.props;

    if (
      !permissionsService.isQuestionAuthor(question.get('id')) &&
      !currentUserIsStaff
    ) {
      return undefined;
    }

    switch (questionStatus?.state) {
      case QuestionState.OPEN:
      case QuestionState.QUEUED: {
        return (
          <InfoBanner
            key="queuedBanner"
            icon={<TimeIcon w={8} h={8} me={2} color="blue" />}
            message={i18n.t('questionDetail.queuedAndAwaiting')}
            onClose={this.handleCloseBanner}
          />
        );
      }
      case QuestionState.PENDING: {
        return (
          <InfoBanner
            key="pendingBanner"
            icon={<ReplyIcon w={8} h={8} me={2} color="blue" />}
            message={i18n.t('questionDetail.mentorCommented')}
            onClose={this.handleCloseBanner}
          />
        );
      }
      case QuestionState.REVIEWED: {
        return (
          <InfoBanner
            key="reviewedBanner"
            icon={<CheckedIcon w={8} h={8} me={2} color="blue" />}
            message={i18n.t('questionDetail.mentorAnswered')}
            onClose={this.handleCloseBanner}
          />
        );
      }
      default: {
        return undefined;
      }
    }
  }

  render() {
    const {
      question,
      questionStatus,
      deepLinkId,
      currentUserIsStaff
    } = this.props;
    const { canShowBanner } = this.state;
    const banner = this.renderBannerForQuestionStatus();

    const showAtRiskBanner = currentUserIsStaff && questionStatus?.atRisk;
    const atRiskBanner = (
      <Alert key="atRiskBanner" status="warning" variant="embedded">
        <WarningIcon w={8} h={8} me={2} color="yellow" />
        <AlertDescription fontSize="md" lineHeight="1.75rem">
          {i18n.t('questionDetail.needsSpecialAttention')}
        </AlertDescription>
      </Alert>
    );

    return (
      <>
        {showAtRiskBanner && atRiskBanner}
        {canShowBanner && banner}
        <QuestionDetail question={question.toJS()} deepLinkId={deepLinkId} />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionDetailContainer);
