import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useBreakpointValue
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@udacity/chakra-veritas-icons';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import i18n from 'i18n';

export interface SearchInputProps {
  handleSubmit: (input: string) => void;
  clearForm: () => void;
  initialQuery?: string;
  query: string;
  theme: 'blue' | 'default';
  refine?: (value: string) => void;
  isAlgolia?: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
  initialQuery,
  query,
  handleSubmit,
  clearForm,
  refine,
  theme,
  isAlgolia
}) => {
  const [textInputValue, setTextInputValue] = useState(initialQuery ?? '');
  const isBlueTheme = theme === 'blue';
  const prevTextInputValue = useRef<string>(textInputValue);
  const textInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (refine) refine(textInputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // clears url and updates search results
  useEffect(() => {
    if (
      prevTextInputValue.current.length > 0 &&
      !textInputValue.length &&
      query
    ) {
      clearForm();
      textInput.current?.focus();
      if (refine) refine('');
    }

    prevTextInputValue.current = textInputValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInputValue]);

  // clears text from form input
  const clearFormInput = () => {
    setTextInputValue('');
    textInput.current?.focus();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextInputValue(event.target.value);
  };

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    textInput.current?.blur();

    if (refine) refine(textInputValue);
    handleSubmit(textInputValue);
  };

  const inputStyle = useBreakpointValue({
    base: { paddingInlineStart: '3rem', height: '2.625rem' },
    'sm-md': {
      paddingInlineStart: '4.2rem',
      height: isAlgolia ? '3rem' : '4.125rem'
    }
  });

  return (
    <form onSubmit={handleFormSubmit}>
      <InputGroup>
        <InputLeftElement h="full" ps={{ base: '0.25rem', 'sm-md': '1.1rem' }}>
          <IconButton
            aria-label={i18n.t('search.button')}
            icon={
              <SearchIcon
                w={8}
                h={8}
                color={isBlueTheme ? 'silver-lighter' : 'cerulean-dark'}
              />
            }
            variant="minimal"
            _hover={{ bgColor: 'transparent' }}
            _focus={{ outline: 'none', bgColor: 'transparent' }}
            _active={{ outline: 'none', bgColor: 'transparent' }}
            type="submit"
          />
        </InputLeftElement>
        <Input
          aria-label={i18n.t('search.search')}
          ref={textInput}
          name="textInputValue"
          type="text"
          onChange={handleChange}
          value={textInputValue}
          placeholder={i18n.t('search.searchQuestion')}
          _placeholder={
            isBlueTheme ? { color: 'silver-lighter' } : { color: 'slate-light' }
          }
          autoComplete="off"
          w="full"
          borderRadius="0.375rem"
          borderColor={isBlueTheme ? 'transparent' : 'silver-light'}
          bgColor={isBlueTheme ? 'blue-dark' : 'white'}
          color={isBlueTheme ? 'white' : 'slate-light'}
          lineHeight={
            isBlueTheme ? '1.6rem' : { base: '2.25rem', 'sm-md': '2.5rem' }
          }
          style={inputStyle}
          _hover={{ outline: 'none' }}
        />

        {!!textInputValue && (
          <InputRightElement
            h="full"
            p={{ base: '0 0.5rem 0 0', 'sm-md': ' 0 1rem 0 0.65rem' }}
          >
            <IconButton
              aria-label={i18n.t('search.reset')}
              icon={
                <CloseIcon
                  w={10}
                  h={10}
                  color={isBlueTheme ? 'silver-lighter' : 'cerulean-dark'}
                />
              }
              variant="minimal"
              _hover={{ bgColor: 'transparent' }}
              _focus={{ outline: 'none', bgColor: 'transparent' }}
              _active={{ outline: 'none', bgColor: 'transparent' }}
              type="reset"
              onClick={clearFormInput}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </form>
  );
};

export default SearchInput;
