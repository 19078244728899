export interface IActivityQuestion {
  createdAt: string;
  id: string;
  summary: string;
  title: string;
  answerCount: number;
  commentCount: number;
  acceptedAnswerId: string | null;
}
export interface IActivityAnswer {
  createdAt: string;
  id: string;
  questionId: string;
  summary: string;
  title: string;
  commentCount: number;
  acceptedAt: string | null;
}

export enum ACTIVITY_OPTION {
  questions = 'questions',
  answers = 'answers'
}

export type IActivityOption =
  | ACTIVITY_OPTION.questions
  | ACTIVITY_OPTION.answers;

export interface IStats {
  upVotesReceived?: number;
  upVotesGiven?: number;
  answersAccepted?: number;
  answersPosted?: number;
}

export const statsMock = {
  answersAccepted: 2,
  upVotesGiven: 3,
  upVotesReceived: 8,
  answersPosted: 10
};
