export const ALL_RESULTS = 'all';
export const ANSWERED_RESULTS = 'answered';
export const START_PAGE = 1;
export const ALGOLIA_PAGE_TOTAL = 50;
export const PAGE_SIZE = 20;
export const SHARE_ALGOLIA_ANALYTICS = true;
export const ANY_FILTER_KEY = '__pseudo_nd__any';

export default {
  HIGHLIGHT_OPEN_TAG: '[highlight]',
  HIGHLIGHT_CLOSE_TAG: '[/highlight]'
};

export interface OptionType {
  id: string;
  value: string;
  type: string;
}
