import _compact from 'lodash/compact';
import React, { ReactNode } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

export interface Props extends RouteComponentProps<any> {
  children: ReactNode;
}

export class CleanPath extends React.Component<Props> {
  componentWillMount() {
    this.cleanSlashes();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      history: {
        location: { pathname: prevPathname }
      }
    } = prevProps;
    const {
      history: {
        location: { pathname }
      }
    } = this.props;

    if (prevPathname !== pathname) {
      this.cleanSlashes();
    }
  }

  cleanSlashes() {
    const { history } = this.props;
    const {
      location: { pathname, search }
    } = history;

    // replace all double-slashes in current URL path
    const pathWithQuery = `${pathname}${search}`;
    const pathArr = _compact(pathWithQuery.split('/'));
    const cleanPath = `/${pathArr.join('/')}`;

    if (cleanPath !== pathWithQuery) {
      history.replace(cleanPath);
      console.warn(`PATH NORMALIZED: ${pathWithQuery} -> ${cleanPath}`);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(CleanPath);
