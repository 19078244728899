import { PureComponent } from 'react';

import {
  MAX_DESKTOP_IMAGE_WIDTH,
  MAX_MOBILE_IMAGE_WIDTH
} from 'app/models/files/filesConstants';
import { breakpoints } from 'assets/styles/theme/breakpoints';

import { ImageCardPayload } from '../imageCardTypes';

export interface Props extends ImageCardPayload {
  localImageSrc?: string;
}

export default class ResponsiveImage extends PureComponent<Props, {}> {
  render() {
    const {
      payload: {
        desktopSrc,
        mobileSrc,
        filename,
        originalSrc,
        originalDimensions,
        localImageSrc
      }
    } = this.props;

    if (originalDimensions === undefined) {
      return (
        <img data-testid="static-image" src={originalSrc} alt={filename} />
      );
    }

    const originalWidth = originalDimensions.width;
    const mobileWidth =
      MAX_MOBILE_IMAGE_WIDTH < originalWidth
        ? MAX_MOBILE_IMAGE_WIDTH
        : originalWidth;
    const desktopWidth =
      MAX_DESKTOP_IMAGE_WIDTH < originalWidth
        ? MAX_DESKTOP_IMAGE_WIDTH
        : originalWidth;

    if (desktopSrc) {
      return (
        <img
          data-testid="responsive-image"
          srcSet={`${mobileSrc} ${mobileWidth}w,
                   ${desktopSrc} ${desktopWidth}w,
                   ${originalSrc} ${originalWidth}w`}
          sizes={`(max-width: ${breakpoints['sm-md']}) 95vw, 670px`}
          src={desktopSrc}
          alt={filename}
        />
      );
    }

    return (
      <img data-testid="static-image" src={localImageSrc} alt={filename} />
    );
  }
}
