import { Box } from '@chakra-ui/react';
import React from 'react';

import {
  animationDuration,
  GradientShadow,
  Slider,
  slideToBottomStyles,
  slideToTopStyles
} from './factoryComponents';

export interface Props {
  score: number;
}

interface State {
  prevScore: number;
  transitionClass: string;
}

export default class VoteCounter extends React.PureComponent<Props, State> {
  state = {
    prevScore: this.props.score,
    transitionClass: ''
  };

  timeoutID: NodeJS.Timer;

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  componentDidUpdate(prevProps: Props) {
    const { score: prevScore } = prevProps;
    const { score } = this.props;
    if (score !== prevScore) {
      this.calculateAnimation(score, prevScore);
    }
  }

  calculateAnimation(score: number, prevScore: number) {
    let transitionClass;

    if (score > prevScore) {
      transitionClass = 'slideToTop';
    } else if (score < prevScore) {
      transitionClass = 'slideToBottom';
    }

    clearTimeout(this.timeoutID);

    this.setState(
      {
        prevScore,
        transitionClass
      },
      () => {
        this.timeoutID = setTimeout(() => {
          this.setState({ transitionClass: '' });
        }, parseInt(animationDuration, 10));
      }
    );
  }

  render() {
    const { score } = this.props;
    const { prevScore, transitionClass } = this.state;

    let scoreMarkup = <Slider>{score}</Slider>;

    if (transitionClass === 'slideToTop') {
      scoreMarkup = (
        <Slider {...slideToTopStyles}>
          <div>{score}</div>
          <div>{prevScore}</div>
        </Slider>
      );
    } else if (transitionClass === 'slideToBottom') {
      scoreMarkup = (
        <Slider {...slideToBottomStyles}>
          <div>{prevScore}</div>
          <div>{score}</div>
        </Slider>
      );
    }

    return (
      <Box
        position="relative"
        m={0}
        p="0.5rem 0"
        h={8}
        minW={{ base: '1rem', 'sm-md': 'full' }}
        overflow="hidden"
        textAlign="center"
        fontWeight="semibold"
      >
        <GradientShadow shadow="inset 0 0.5rem 1rem white" />
        {scoreMarkup}
        <GradientShadow shadow="inset 0 -0.5rem 1rem white" />
      </Box>
    );
  }
}
