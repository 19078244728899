import { SearchClickAnalytics } from 'app/models/search/searchTypes';

export function handleSearchResultClick(
  props: SearchClickAnalytics,
  id: string,
  searchPosition: number,
  trackSearchClick: (id: string, analyticsObject: SearchClickAnalytics) => void
) {
  const analyticsObject: SearchClickAnalytics = {
    page: props.page,
    query: props.query,
    searchPosition,
    nanodegreeKey: props.nanodegreeKey,
    projectID: props.projectID,
    rubricID: props.rubricID
  };

  trackSearchClick(id, analyticsObject);
}
