import { all, call, put, take, takeEvery } from 'redux-saga/effects';

import { IAction, makeSagaLifecycle } from 'app/models/helpers/sagaHelper';

import createUploadFileChannel from './createUploadFileChannel';
import { actionCreators as fileActions, actionTypes } from './filesActions';

import CONFIG from 'appConfigLoader';

export function* uploadImage(action: IAction) {
  const {
    payload: { file, fileIdentifierString }
  } = action;

  const channel = yield call(
    createUploadFileChannel,
    CONFIG.imageUploadEndpoint,
    file
  );

  while (true) {
    const { progress = 0, err, success, response } = yield take(channel);
    if (err) {
      yield put(fileActions.imageUploadRejected(fileIdentifierString, err));
      return;
    }
    if (success) {
      yield put(fileActions.imageUploadSuccess(fileIdentifierString, response));
      return;
    }
    yield put(fileActions.imageUploadProgress(fileIdentifierString, progress));
  }
}

export function* watchUploadImage() {
  yield takeEvery(
    actionTypes.UPLOAD_IMAGE_START,
    makeSagaLifecycle(uploadImage, 'UPLOAD_IMAGE', actionTypes)
  );
}

export default function* moduleRoot() {
  yield all([watchUploadImage()]);
}
