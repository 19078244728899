import { Box, Flex, Grid, GridItem, Heading, Link } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import AnswerCount from 'app/components/AnswerCount';
import NotificationBadge from 'app/components/NotificationBadge';
import PostMetadata from 'app/components/PostMetadata';
import PostVoteContainer from 'app/containers/PostVoteContainer';
import TagListContainer from 'app/containers/TagListContainer';
import { AlgoliaDisplayResult } from 'app/models/search/searchTypes';
import { UnreadPost } from 'app/models/unreads/unreadsTypes';
import { connect } from 'react-redux';
import { selectUserById } from 'app/models/entities/users/usersByIdSelectors';
import { List } from 'immutable';

export interface Props {
  question: AlgoliaDisplayResult;
  handleClick: () => void;
  unreads: { [postId: string]: UnreadPost } | undefined;
}

interface SearchResultItemStateProps {
  questionUser?: List<Map<string, any>>;
}

const mapStateToProps = (state, props: Props): SearchResultItemStateProps => ({
  questionUser: selectUserById(state, props.question.user?.id)
});

export type SearchResultIteamProps = Props & SearchResultItemStateProps;

export const SearchResultItem: React.FC<SearchResultIteamProps> = ({
  questionUser,
  question: {
    id,
    user: fallbackQuestionUser,
    answerCount,
    acceptedAnswerId,
    verifiedAnswerId,
    createdAt,
    programTitle,
    projectId
  },
  question,
  handleClick,
  unreads = {}
}) => {
  const unreadCount = Object.keys(unreads).length;
  return (
    <Grid
      as="article"
      borderTop="1px solid"
      borderTopColor="silver-lighter"
      p={{ base: '0 0 1.25rem 0', 'sm-md': '1.5rem 0 1.25rem 0' }}
      gridTemplateColumns={{ 'sm-md': '3.5rem 1fr' }}
      gridTemplateRows={{ 'sm-md': 'repeat(4, auto)' }}
      gap={{ 'sm-md': 1 }}
    >
      <GridItem
        mt={{ base: 5, 'sm-md': '1px' }}
        mb={{ base: '0.625rem', 'sm-md': 0 }}
        gridRow={{ 'sm-md': '1 / -1' }}
      >
        <PostVoteContainer postId={String(id)} />
      </GridItem>

      <Flex align="center" gap={2} sx={{ mark: { bgColor: 'orange-lighter' } }}>
        <Heading size="h4" as="h2" fontSize="1.125rem">
          <Link
            as={ReactRouterLink}
            to={`/questions/${String(id)}`}
            onClick={handleClick}
            color="inherit"
            _hover={{ color: 'inherit' }}
          >
            {question.title}
          </Link>
        </Heading>
        {unreadCount > 0 && <NotificationBadge unreadTotal={unreadCount} />}
      </Flex>

      <Grid
        alignItems="center"
        gridColumnGap={{ 'sm-md': '1rem' }}
        gridTemplateColumns={{ 'sm-md': 'auto auto' }}
      >
        <Box alignSelf="flex-start">
          <PostMetadata
            fromMentor={false}
            user={questionUser?.toJS() || fallbackQuestionUser}
            createdAt={createdAt}
          />
        </Box>

        <Box alignSelf="flex-start" textAlign="end" wordBreak="normal" m={0}>
          <Link
            as={ReactRouterLink}
            to={`/questions/${String(id)}`}
            onClick={handleClick}
            display="inline-block"
          >
            <AnswerCount
              totalAnswers={answerCount}
              acceptedId={String(acceptedAnswerId) || ''}
              verifiedId={String(verifiedAnswerId) || ''}
            />
          </Link>
        </Box>
      </Grid>

      <Box display={{ base: 'none', 'sm-md': 'block' }}>
        <TagListContainer nodeTitle={programTitle} projectId={projectId} />
      </Box>
    </Grid>
  );
};

export default connect<SearchResultItemStateProps, {}, Props>(mapStateToProps)(
  SearchResultItem
);
