import _throttle from 'lodash/throttle';
import { RefObject, useEffect, useState } from 'react';

export function usePositionSticky(ref: RefObject<HTMLDivElement>) {
  const [isSticky, setIsSticky] = useState(false);

  const scrollListener = _throttle(() => {
    if (ref.current) {
      const { y: yPos } = ref.current.getBoundingClientRect() as DOMRect;
      const newStickyState = yPos <= 0;
      setIsSticky(newStickyState);
    }
  }, 50);

  useEffect(() => {
    document.addEventListener('scroll', scrollListener, true);

    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, [ref, scrollListener]);

  return isSticky;
}
