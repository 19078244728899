import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectQuestionStatus } from 'app/models/QuestionQueue/questionQueueSelectors';
import RatingForm from 'app/components/RatingForm';
import { selectCurrentQuestion } from 'app/models/posts/postsSelectors';
import { QuestionState } from 'app/models/QuestionQueue/questionQueueTypes';
import permissionsService from 'app/services/permissionsService';
import { actionCreators as ratingsActions } from 'app/models/ratings/ratingsActions';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import { actionCreators as questionQueueActions } from 'app/models/QuestionQueue/questionQueueActions';
import { RatingInput } from 'app/models/ratings/ratingsTypes';

export interface Props {
  postId: string;
}

export const RatingFormContainer: React.FC<Props> = ({ postId }) => {
  const currentQuestion = useSelector(selectCurrentQuestion);
  const questionId = currentQuestion.get('id');
  const status = useSelector((state: any) =>
    selectQuestionStatus(state, questionId)
  );

  const dispatch = useDispatch();
  const rate = (answerId: string, ratingInput: RatingInput) =>
    dispatch(ratingsActions.createRating(answerId, ratingInput));
  const acceptAnswer = (answerId: string) =>
    dispatch(postsActions.acceptAnswer(answerId));
  const upvoteAnswer = (answerId: string) =>
    dispatch(postsActions.upvotePost(answerId));
  const requeueQuestion = (questionId: string) =>
    dispatch(questionQueueActions.postQuestionRequeue(questionId));

  if (
    status?.state === QuestionState.REVIEWED &&
    permissionsService.canRateAnswer(postId, questionId)
  ) {
    return (
      <RatingForm
        postId={postId}
        questionId={questionId}
        handleRating={rate}
        handleAccept={acceptAnswer}
        handleUpvote={upvoteAnswer}
        handleRequeue={requeueQuestion}
      />
    );
  }

  return null;
};

export default RatingFormContainer;
