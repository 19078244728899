import React from 'react';
import { ReplyIcon } from '@udacity/chakra-veritas-icons';

import NotificationBadge from 'app/components/NotificationBadge';
import { PublicActionButton } from 'app/components/PostActionBar/factoryComponents';
import { UnreadPost } from 'app/models/unreads/unreadsTypes';
import permissions from 'app/services/permissionsService';

import i18n from 'i18n';

export interface Props {
  onCommentClick?: () => void;
  commentCount: number;
  commentThreadExpanded: boolean;
  postUnreads: UnreadPost[];
}

export default class PostToggleComments extends React.PureComponent<Props> {
  render() {
    const {
      commentCount,
      commentThreadExpanded,
      onCommentClick,
      postUnreads = []
    } = this.props;

    if (!onCommentClick) {
      return null;
    }

    let buttonLabel;

    if (commentThreadExpanded) {
      buttonLabel = i18n.t('comment.hide');
    } else if (commentCount === 0 && permissions.canComment()) {
      buttonLabel = i18n.t('comment.comment');
    } else {
      buttonLabel = i18n.t('comment.showCommentCount', { count: commentCount });
    }
    const unreadCount = postUnreads.length;

    if (onCommentClick) {
      return (
        <>
          {unreadCount > 0 && <NotificationBadge unreadTotal={unreadCount} />}
          <PublicActionButton
            variant="minimal"
            size="sm"
            leftIcon={<ReplyIcon w={6} h={6} />}
            onClick={onCommentClick}
          >
            {buttonLabel}
          </PublicActionButton>
        </>
      );
    } else {
      return null;
    }
  }
}
