import { createAction } from 'app/models/helpers/actionHelper';

export const actionTypes = {
  CLEAR_FIRST_ALERT: 'ALERT/CLEAR_FIRST_ALERT',
  ADD_ALERT: 'ALERT/ADD_ALERT'
};

export const actionCreators = {
  clearFirstAlert() {
    return createAction(actionTypes.CLEAR_FIRST_ALERT);
  },
  addAlert(type: string, message: string) {
    return createAction(actionTypes.ADD_ALERT, { type, message });
  }
};
