import { Box, Heading } from '@chakra-ui/react';
import { WarningIcon } from '@udacity/chakra-veritas-icons';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import React from 'react';

import Select from 'app/components/Select';
import ActionModal from 'app/components/modals/ActionModal';
import ActionModalButtons from 'app/components/modals/ActionModal/ActionModalButtons';

import i18n from 'i18n';

export interface Props {
  handleModeration: any;
  isCallToActionPending: boolean;
  moderatePostReasons: [{ label: string; value: string }];
  getModeratePostReasons: () => void;
  handleToggleClose: () => void;
  callToAction: string;
  optionsHeader: string;
  headerText: string;
}

interface State {
  selectedReason: { value: string; label: string } | undefined;
}

export default class PostModerationModal extends React.PureComponent<
  Props,
  State
> {
  state = { selectedReason: undefined };

  componentDidMount() {
    const { getModeratePostReasons } = this.props;
    getModeratePostReasons();
  }

  handleSelectChange = (value: { value: string; label: string }) => {
    this.setState({ selectedReason: value });
  };

  handleSubmit = () => {
    const { handleToggleClose, handleModeration = _noop } = this.props;
    const { selectedReason } = this.state;
    if (!selectedReason) {
      return;
    }
    handleModeration(_get(selectedReason, 'value'));
    handleToggleClose();
  };

  render() {
    const {
      moderatePostReasons,
      isCallToActionPending,
      handleToggleClose,
      callToAction,
      optionsHeader,
      headerText
    } = this.props;
    const { selectedReason } = this.state;
    return (
      <ActionModal
        headerIcon={<WarningIcon w={10} h={10} color="red" />}
        theme="red"
        onCancel={handleToggleClose}
        headerText={headerText}
      >
        <Heading
          size="h2"
          mb={2}
          color="dark-night-blue"
          fontSize="md"
          lineHeight="h5"
          fontWeight="semibold"
        >
          {optionsHeader}
        </Heading>
        <Box mb={6}>
          <Select
            ariaLabel={i18n.t('post.moderateSelect')}
            searchable
            options={moderatePostReasons}
            value={selectedReason}
            onChange={this.handleSelectChange}
            selectProps={{ size: 'md', menuPosition: 'fixed' }}
          />
        </Box>
        <ActionModalButtons
          theme="red"
          disabled={!selectedReason || isCallToActionPending}
          primary={{
            text: callToAction,
            callback: this.handleSubmit
          }}
          secondary={{
            text: i18n.t('common.nevermind'),
            callback: handleToggleClose
          }}
        />
      </ActionModal>
    );
  }
}
