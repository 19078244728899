import { combineReducers } from 'redux-immutable'; // immutablejs equivalent of Redux's combineReducers

import {
  makeApiReducer,
  normalizeApiActions
} from 'app/models/helpers/apiReducerHelpers';

import { actionClass } from './filesActions';

const uploadImageApiActions = normalizeApiActions('UPLOAD_IMAGE', actionClass);

export default combineReducers({
  uploadImage: makeApiReducer(uploadImageApiActions)
});
