import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { isBlankInput } from 'app/helpers/validationHelpers';
import i18n from 'i18n';

export interface ValidationError {
  field: string;
  message: string;
}

export const validate = (content: Mobiledoc): ValidationError[] => {
  const errors: ValidationError[] = [];

  if (isBlankInput(content)) {
    errors.push({
      field: 'content',
      message: i18n.t('answer.addDetails')
    });
  }

  return errors;
};
