import { Map } from 'immutable';
import _isUndefined from 'lodash/isUndefined';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import CenteredLoader from 'app/components/CenteredLoader';
import ErrorBoundary from 'app/components/ErrorBoundary';
import FixedWidthWrapper from 'app/components/FixedWidthWrapper';
import FullWidthWrapper from 'app/components/FullWidthWrapper';
import PageNotFound from 'app/components/PageNotFound';
import AnswerListContainer from 'app/containers/AnswerListContainer';
import ArchivedPostPageContainer from 'app/containers/ArchivedPostPageContainer';
import IncompletePostBannerContainer from 'app/containers/IncompletePostBannerContainer';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import QuestionDetailContainer from 'app/containers/QuestionDetailContainer';
import CreateAnswerFormContainer from 'app/containers/answerForms/CreateAnswerFormContainer';
import { trackPageview } from 'app/helpers/analyticsHelper';
import {
  hasError,
  isFulfilled,
  isReady
} from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import { actionCreators as questionQueueActions } from 'app/models/QuestionQueue/questionQueueActions';
import {
  selectApiStatus,
  selectCurrentQuestion
} from 'app/models/posts/postsSelectors';

export interface Props extends RouteComponentProps<any> {
  // props
  question: Map<string, string> | undefined;
  // state
  getQuestionStatus: Map<string, string>;
  // dispatch
  getQuestion: (questionId: string) => void;
  resetGetQuestion: () => void;
  fetchQuestionAssignments: () => void;
}

interface PropsFromState {
  question: Map<string, string>;
  getQuestionStatus: Map<string, string>;
}

const mapStateToProps = (state: any): PropsFromState => {
  return {
    question: selectCurrentQuestion(state),
    getQuestionStatus: selectApiStatus(state, 'getQuestion')
  };
};

const mapDispatchToProps = {
  getQuestion: postsActions.getQuestion,
  resetGetQuestion: postsActions.resetGetQuestion,
  fetchQuestionAssignments: questionQueueActions.fetchAssignments
};

export class ViewQuestionScene extends React.Component<Props> {
  componentDidMount() {
    const {
      getQuestion,
      getQuestionStatus,
      match: {
        params: { questionId }
      },
      fetchQuestionAssignments
    } = this.props;
    this.trackEvent('View Question');
    if (isReady(getQuestionStatus)) {
      getQuestion(questionId);
    }
    fetchQuestionAssignments();
  }

  componentWillUnmount(): void {
    this.props.resetGetQuestion();
  }

  trackEvent(eventName: string) {
    trackPageview(eventName);
  }

  render() {
    const {
      match: {
        params: { questionId }
      },
      location
    } = this.props;

    const deepLinkId = location.hash.replace(/#/, '');

    const { question, getQuestionStatus } = this.props;

    const isLoading = !isFulfilled(getQuestionStatus);

    const hasNotFoundError =
      _isUndefined(question) && (hasError(getQuestionStatus) || !isLoading);

    if (isLoading && !hasNotFoundError) {
      return <CenteredLoader />;
    }

    if (hasNotFoundError) {
      return <PageNotFound />;
    }

    // Return public archived post view
    if (!_isUndefined(question)) {
      if (question.get('postType') === 'archivedPost') {
        return <ArchivedPostPageContainer />;
      }
    }

    return (
      <>
        <IncompletePostBannerContainer postId={questionId} />
        <FullWidthWrapper color="white">
          <FixedWidthWrapper>
            <ErrorBoundary>
              <QuestionDetailContainer
                deepLinkId={deepLinkId}
                data-ref="question-detail"
              />
            </ErrorBoundary>
            <ErrorBoundary>
              <AnswerListContainer
                deepLinkId={deepLinkId}
                data-ref="answer-list"
              />
            </ErrorBoundary>
          </FixedWidthWrapper>
        </FullWidthWrapper>
        <PermissionsServiceContainer showIf={{ canAnswer: true }}>
          <FixedWidthWrapper styleProps={{ pt: 8, pb: 24 }}>
            <ErrorBoundary>
              <CreateAnswerFormContainer questionId={questionId} />
            </ErrorBoundary>
          </FixedWidthWrapper>
        </PermissionsServiceContainer>
      </>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewQuestionScene)
);
