import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import CenteredLoader from 'app/components/CenteredLoader';
import QuestionFormContainer from 'app/containers/questionFormContainers/QuestionFormContainer';
import { FormData } from 'app/containers/questionFormContainers/questionFormContainerHelpers';
import { selectIsImageUploadPending } from 'app/models/files/filesSelectors';
import { selectReviewedNanodegrees } from 'app/models/session/sessionSelectors';
import { isFulfilled, isRejected } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectCurrentQuestion
} from 'app/models/posts/postsSelectors';
import permissions from 'app/services/permissionsService';

import i18n from 'i18n';

interface LocationProps extends RouteComponentProps<any> {}

interface OwnProps {
  questionId: string;
}

interface StateProps {
  currentQuestion: Map<any, any>;
  apiStatus: Map<string, string>;
  getQuestionStatus: Map<string, string>;
  isImageUploadPending: boolean;
  reviewedNanodegrees: Array<string>;
}

interface DispatchProps {
  getQuestion: (id: string) => void;
  updateQuestion: (formData: FormData, questionId: string) => void;
  resetUpdateQuestion: () => void;
  resetGetQuestion: () => void;
}

export type Props = StateProps & DispatchProps & LocationProps & OwnProps;

const mapStateToProps = (state): StateProps => ({
  currentQuestion: selectCurrentQuestion(state),
  apiStatus: selectApiStatus(state, 'updateQuestion'),
  getQuestionStatus: selectApiStatus(state, 'getQuestion'),
  isImageUploadPending: selectIsImageUploadPending(state),
  reviewedNanodegrees: selectReviewedNanodegrees(state)
});

const mapDispatchToProps: DispatchProps = {
  getQuestion: postsActions.getQuestion,
  updateQuestion: postsActions.updateQuestion,
  resetUpdateQuestion: postsActions.resetUpdateQuestion,
  resetGetQuestion: postsActions.resetGetQuestion
};

export class EditQuestionContainer extends React.PureComponent<Props> {
  static defaultProps = {
    currentQuestion: Map()
  };

  state = {
    isLoading: true
  };

  componentWillMount() {
    this.checkEditPermissionsAndRedirect();
  }

  componentDidMount() {
    const { getQuestion, questionId } = this.props;
    getQuestion(questionId);
  }

  componentDidUpdate() {
    this.checkEditPermissionsAndRedirect();

    const { getQuestionStatus } = this.props;
    const { isLoading } = this.state;

    if (isRejected(getQuestionStatus)) {
      this.redirect();
    }

    if (isLoading && isFulfilled(getQuestionStatus)) {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this.props.resetUpdateQuestion();
  }

  redirect() {
    const { history, questionId } = this.props;
    history.push(`/questions/${questionId}`);
  }

  checkEditPermissionsAndRedirect() {
    const { currentQuestion, questionId } = this.props;
    if (currentQuestion.size) {
      const canEdit = permissions.canEditQuestion(questionId);
      if (!canEdit) {
        this.redirect();
      }
    }
  }

  handleUpdate = (formData: FormData, questionId: string) => {
    const { updateQuestion, resetGetQuestion } = this.props;
    // Reset the status of the getQuestion API with the assumption that update
    // will cause a change to the edited question, ensuring that the question
    // will be refetched when going back viewing the question.
    resetGetQuestion();
    updateQuestion(formData, questionId);
    this.redirect();
  };

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return <CenteredLoader />;
    }

    const {
      currentQuestion,
      apiStatus,
      questionId,
      isImageUploadPending
    } = this.props;

    return (
      <QuestionFormContainer
        questionId={questionId}
        questionData={currentQuestion.toJS()}
        apiStatus={apiStatus}
        pageTitle={i18n.t('question.edit')}
        questionMutation={this.handleUpdate}
        isImageUploadPending={isImageUploadPending}
      />
    );
  }
}

export default withRouter(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EditQuestionContainer)
);
