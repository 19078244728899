import { Box, Button, FormControl, FormLabel, Heading } from '@chakra-ui/react';
import { Map } from 'immutable';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import PostLegalDisclaimer from 'app/components/PostLegalDisclaimer';
import TextInput from 'app/components/TextInput';
import ToastPortal from 'app/components/Toast/ToastPortal';
import NanodegreeTagsContainer from 'app/containers/NanodegreeTagsContainer';
import RichTextEditorContainer from 'app/containers/RichTextEditorContainer';
import { QuestionInput } from 'app/containers/questionFormContainers/questionFormContainerHelpers';
import { emptyMobiledoc, Mobiledoc } from 'app/helpers/mobiledocHelpers';
import {
  defaultLinkProtocol,
  safeUserInput
} from 'app/helpers/userContentHelpers';
import { IQuestion } from 'app/models/entities/questions/questionsEntitiesTypes';
import { isPending, isRejected } from 'app/models/helpers/apiStatusHelpers';
import { decodeMobiledoc } from 'app/models/helpers/encodeGraphqlContent';
import { MAX_TITLE_LENGTH } from 'app/models/posts/postsConstants';
import { selectEnrollmentByKey } from 'app/models/nanodegree/nanodegreeSelectors';
import { Nanodegree, Project } from 'app/models/nanodegree/nanodegreeTypes';

import i18n from 'i18n';

interface LocationProps extends RouteComponentProps<any> {}

interface Error {
  field: string;
}

export interface OwnProps {
  errors: Error[];
  apiStatus: Map<string, string>;
  pageTitle: string;
  questionData?: IQuestion;
  handleSubmit: (formInput: QuestionInput) => void;
  validate: (formInput: QuestionInput) => void;
  isImageUploadPending: boolean;
}

export interface State {
  formData: {
    title: string;
    body: Mobiledoc;
    projectLink?: string;
  };
  selectedNanodegree?: Nanodegree;
  selectedProject?: Project;
  shouldLockPreviouslySelectedProgramAndProject: boolean;
}

interface StateProps {
  selectedNanodegree: Nanodegree | undefined;
}

const mapStateToProps = (state, props: OwnProps): StateProps => ({
  selectedNanodegree: selectEnrollmentByKey(state, props.questionData?.node.key)
});

export type Props = OwnProps & StateProps & LocationProps;

export class QuestionForm extends React.PureComponent<Props, State> {
  state: State = {
    formData: {
      title: '',
      body: emptyMobiledoc
    },
    shouldLockPreviouslySelectedProgramAndProject: false
  };

  componentDidMount() {
    const { questionData } = this.props;

    if (questionData && !_isEmpty(questionData)) {
      this.setState({
        shouldLockPreviouslySelectedProgramAndProject: true
      });
      this.setDefaultFormData(questionData);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { questionData: nextQuestionData } = nextProps;

    const { questionData } = this.props;
    if (
      nextQuestionData &&
      !_isEmpty(nextQuestionData) &&
      !_isEqual(nextQuestionData, questionData)
    ) {
      this.setDefaultFormData(nextQuestionData);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { errors } = this.props;
    const { shouldLockPreviouslySelectedProgramAndProject } = this.state;

    if (
      shouldLockPreviouslySelectedProgramAndProject ||
      (errors.length && !_isEqual(this.state, prevState))
    ) {
      this.handleRevalidation();
    }
  }

  setDefaultFormData({ title, content, link }: IQuestion) {
    const { selectedNanodegree, questionData } = this.props;
    if (selectedNanodegree) {
      const selectedProject = _find(selectedNanodegree?.projects, {
        reviewsProjectId: questionData?.projectID
      });
      this.setState({
        formData: {
          title,
          body: decodeMobiledoc(content),
          projectLink: link
        },
        selectedNanodegree,
        selectedProject
      });
    }
  }

  handleFormInput = (event: React.SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    let { formData } = this.state;
    formData = {
      ...formData,
      [name]: value
    };

    this.setState({ formData });
  };

  handleMobiledocInput = (content: {}) => {
    let { formData } = this.state;
    formData = {
      ...formData,
      body: content
    };

    this.setState({ formData });
  };

  handleNanodegreeProjectChange = (selection: {
    nanodegree?: Nanodegree;
    project?: Project;
  }) => {
    this.setState({
      selectedNanodegree: selection.nanodegree,
      selectedProject: selection.project
    });
  };

  composePayload = () => {
    const { formData, selectedNanodegree, selectedProject } = this.state;
    const project = {
      projectId: selectedProject?.reviewsProjectId,
      rubricId: selectedProject?.rubricId
    };

    return {
      formData,
      project,
      nanodegreeKey: selectedNanodegree && selectedNanodegree.key
    };
  };

  handleRevalidation = () => {
    const { validate, errors } = this.props;

    if (errors.length > 0) {
      const payload = this.composePayload();
      validate(payload);
    }
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const { handleSubmit, apiStatus } = this.props;
    if (isPending(apiStatus)) {
      return;
    }
    const payload = this.composePayload();
    handleSubmit(payload);
  };

  formatProjectLink = () => {
    const {
      formData,
      formData: { projectLink = '' }
    } = this.state;

    if (!_isEmpty(projectLink)) {
      this.setState({
        formData: {
          ...formData,
          projectLink: defaultLinkProtocol(safeUserInput(projectLink))
        }
      });
    }
  };

  render() {
    const {
      errors,
      apiStatus,
      pageTitle,
      questionData,
      isImageUploadPending
    } = this.props;

    const {
      formData: { title, projectLink = '' },
      selectedNanodegree,
      selectedProject,
      shouldLockPreviouslySelectedProgramAndProject
    } = this.state;

    const isStatusPending = isPending(apiStatus);

    const titleError = _find(errors, { field: 'title' });

    const bodyError = _find(errors, { field: 'body' });

    const isFormDisabled = isStatusPending || isImageUploadPending;
    const submitLabel = questionData?.isArchived
      ? i18n.t('question.unarchive')
      : i18n.t('question.submit');

    return (
      <form data-ref="question-form" onSubmit={this.handleSubmit}>
        <Box p="2rem 0 5rem">
          <Heading
            size="h1"
            as="h1"
            display={{ base: 'none', lg: 'inline-block' }}
            mb={{ lg: 12 }}
            mt={{ lg: 6 }}
          >
            {pageTitle}
          </Heading>

          <FormControl as="fieldset" mb={{ base: 5, lg: 10 }}>
            <FormLabel mb={1}>{i18n.t('question.question')}</FormLabel>
            <TextInput
              type="text"
              name="title"
              handleChange={this.handleFormInput}
              value={title}
              hasError={!!titleError || title.length > MAX_TITLE_LENGTH}
              errorText={_get(titleError, 'message')}
              hintText={i18n.t('question.characterLimit', {
                currentLength: title.length,
                maxLength: MAX_TITLE_LENGTH
              })}
              placeholder={i18n.t('question.placeholder')}
              autofocus
            />
          </FormControl>

          <Box as="fieldset" mb={{ base: 5, lg: 10 }}>
            <RichTextEditorContainer
              autofocus={false}
              autofocusDelay={0}
              title={i18n.t('question.details')}
              subtitle={i18n.t('question.includeAllInfo')}
              defaultContent={questionData?.content}
              hasError={!!bodyError}
              errorText={_get(bodyError, 'message')}
              handleChange={this.handleMobiledocInput}
            />
          </Box>

          <FormControl as="fieldset" mb={{ base: 5, lg: 10 }}>
            <FormLabel mb={1}>
              {i18n.t('question.githubOrProjectLink')}
              <Box
                as="span"
                fontSize="sm"
                fontWeight="normal"
                color="silver-dark"
                ms={2}
              >
                {i18n.t('question.optional')}
              </Box>
            </FormLabel>

            <TextInput
              type="url"
              name="projectLink"
              handleChange={this.handleFormInput}
              value={projectLink}
              handleBlur={this.formatProjectLink}
              errorText=""
              placeholder={'https://'}
            />
          </FormControl>

          <NanodegreeTagsContainer
            shouldLockPreviouslySelectedProgramAndProject={
              shouldLockPreviouslySelectedProgramAndProject
            }
            errors={errors}
            handleChange={this.handleNanodegreeProjectChange}
            selectedNanodegree={selectedNanodegree}
            selectedProject={selectedProject}
            initialNanodegreeKey={questionData?.nanodegreeKey}
            initialProjectId={questionData?.projectID}
            options={{
              autoselectSingleOptions: true,
              showLessonQuestionTooltip: true
            }}
          />

          <footer>
            <Button
              variant="primary"
              type="submit"
              disabled={isFormDisabled}
              w={{ base: '100%', 'sm-md': 'auto' }}
            >
              {submitLabel}
            </Button>

            <PostLegalDisclaimer />

            {isRejected(apiStatus) && (
              <ToastPortal
                type="error"
                message={i18n.t('question.couldNotBeSaved')}
              />
            )}
          </footer>
        </Box>
      </form>
    );
  }
}

export default withRouter(
  connect<StateProps, {}, OwnProps>(mapStateToProps)(QuestionForm)
);
