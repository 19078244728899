export const Button = {
  variants: {
    default: {
      borderRadius: 'secondary',
      bgColor: 'cerulean',
      color: 'white',
      fontSize: '0.8125rem',
      fontWeight: 'semibold',
      padding: '0 2rem',
      w: 'max-content'
    }
  }
};
