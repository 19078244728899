import _ from 'lodash';
import React from 'react';

type ReactArray = Array<React.ReactElement<any>>;

export function trimFirstElements(renderedContent: ReactArray): ReactArray {
  return _.reduce(
    renderedContent,
    (elementList: ReactArray, element: React.ReactElement<any>) => {
      if (
        _.isEmpty(_.trim(element.props.children)) &&
        _.isEmpty(element.props.payload) &&
        _.isEmpty(elementList)
      ) {
        return elementList;
      }
      return [...elementList, element];
    },
    []
  );
}

export const trimBodyContent: (list: ReactArray) => ReactArray = _.flow(
  trimFirstElements,
  _.reverse,
  trimFirstElements,
  _.reverse
);
