import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';
import { RatingInput } from './ratingsTypes';

export const actionClass = 'RATINGS';

export const actionTypes = {
  ...createActionType('CREATE_RATING', actionClass)
};

export const actionCreators = {
  createRating(postId: string, input: RatingInput) {
    return createAction(actionTypes.CREATE_RATING_START, { postId, input });
  }
};
