import { Map, fromJS } from 'immutable';

import { actionTypes } from './activityActions';
import constants from './activityConstants';

type State = Map<string, any>;

export const initialState = fromJS({
  activityResults: {
    totalResults: 0,
    questions: [],
    answers: []
  },
  activityParams: {
    questions: { offset: 0, limit: constants.PAGE_SIZE },
    answers: { offset: 0, limit: constants.PAGE_SIZE }
  },
  userActivityStats: {}
});

export default function reducer(state: State = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_QUESTIONS_START: {
      return state.setIn(['activityParams', 'questions'], fromJS(payload));
    }

    case actionTypes.FETCH_ANSWERS_START: {
      return state.setIn(['activityParams', 'answers'], fromJS(payload));
    }

    case actionTypes.FETCH_QUESTIONS_FULFILLED: {
      const { result } = payload;

      if (result.questions.length > 0) {
        return state.merge(fromJS({ activityResults: result }));
      }

      // If the most recently fetched page of questions is empty then there
      // are no more questions to consume, reset the question offset to the
      // prior page
      let questionsParams = state.getIn(['activityParams', 'questions']).toJS();
      return state.setIn(
        ['activityParams', 'questions'],
        fromJS({
          ...questionsParams,
          offset: Math.max(questionsParams.offset - questionsParams.limit, 0)
        })
      );
    }

    case actionTypes.FETCH_ANSWERS_FULFILLED: {
      const { result } = payload;

      if (result.answers.length > 0) {
        return state.merge(fromJS({ activityResults: result }));
      }

      // If the most recently fetched page of answers is empty then there
      // are no more answers to consume, reset the answer params to the
      // prior page and do not update results
      let answersParams = state.getIn(['activityParams', 'answers']).toJS();
      return state.setIn(
        ['activityParams', 'answers'],
        fromJS({
          ...answersParams,
          offset: Math.max(answersParams.offset - answersParams.limit, 0)
        })
      );
    }

    case actionTypes.FETCH_USER_ACTIVITY_STATS_FULFILLED: {
      const { result } = payload;
      return state.merge(
        fromJS({
          userActivityStats: result
        })
      );
    }
  }

  return state;
}
