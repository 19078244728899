import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import i18n from 'i18n';

export interface Props {
  totalComments: number;
}

export default class CommentCount extends React.PureComponent<Props> {
  render() {
    const { totalComments } = this.props;

    return (
      <Flex alignItems="center">
        <Text
          ms="0.313rem"
          fontSize="0.825rem"
          fontWeight="semibold"
          lineHeight="xs"
          letterSpacing="0.0625em"
          textTransform="uppercase"
          color={!totalComments ? 'slate-light' : 'slate'}
        >
          {i18n.t('comment.commentCount', { count: totalComments })}
        </Text>
      </Flex>
    );
  }
}
