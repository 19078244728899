import _ from 'lodash';

import CONFIG from 'appConfigLoader';

// tslint:disable-next-line: no-var-requires
const MobiledocRenderer = require('mobiledoc-text-renderer').default;

const ImageCardText = {
  name: 'Image',
  type: 'text',
  render({ payload }) {
    return _.get(payload, 'filename');
  }
};

const CodeBlockText = {
  name: 'Code',
  type: 'text',
  render({ payload }) {
    return _.get(payload, 'content');
  }
};

export interface Mobiledoc {
  version?: string;
  atoms?: JSX.Element[];
  cards?: JSX.Element[];
  markups?: HTMLElement[];
  sections?: any[];
}

export const emptyMobiledoc = {
  version: CONFIG.mobiledoc,
  atoms: [],
  cards: [],
  markups: [],
  sections: [[1, 'p', []]]
};

export const textContentFromMobiledoc = (
  mobiledoc: Mobiledoc | string | string[]
): string => {
  const renderer = new MobiledocRenderer({
    cards: [ImageCardText, CodeBlockText]
  });

  return renderer.render(mobiledoc).result;
};

export const isMobiledocBodyEmpty = (body: Mobiledoc): boolean => {
  if (!body || !body.version) {
    return true;
  }

  return _.isEmpty(textContentFromMobiledoc(body));
};
