import { all, call, put, takeEvery } from 'redux-saga/effects';

import { makeSagaLifecycle } from 'app/models/helpers/sagaHelper';

import { actionTypes } from './ratingsActions';
import * as requests from './ratingsExternalRequests';

export function* createRating(action) {
  const {
    payload: { postId, input }
  } = action;
  const data = yield call(requests.createRating, { postId, input: input });

  yield put({
    type: actionTypes.CREATE_RATING_FULFILLED,
    payload: {
      ratings: [data.postId]
    }
  });
}

export function* watchCreateRating() {
  yield takeEvery(
    actionTypes.CREATE_RATING_START,
    makeSagaLifecycle(createRating, 'CREATE_RATING', actionTypes)
  );
}

// root saga loads watcher sagas into middlware
export default function* moduleRoot() {
  yield all([watchCreateRating()]);
}
