import React from 'react';

import PageNotFound from 'app/components/PageNotFound';
import { trackPageview } from 'app/helpers/analyticsHelper';

export class NotFound extends React.PureComponent<any> {
  componentDidMount() {
    this.trackEvent('404 Not Found');
  }

  trackEvent(eventName: string) {
    trackPageview(eventName);
  }

  render() {
    return <PageNotFound />;
  }
}

export default NotFound;
