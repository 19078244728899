import { Box, Grid, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import FixedWidthWrapper from 'app/components/FixedWidthWrapper';
import pencil from 'assets/images/illustration-chat-with-pencil.svg';

import i18n from 'i18n';

export default class ActivityAnswerEmptyState extends React.Component {
  render() {
    return (
      <Grid h="70vh">
        <Box
          as="main"
          maxW="22.5rem"
          mt={12}
          alignSelf="start"
          justifySelf="center"
          textAlign="center"
        >
          <Image
            src={pencil}
            alt={i18n.t('post.pencilIllustration')}
            w="6.5rem"
            h="auto"
            m="0 auto"
          />

          <FixedWidthWrapper>
            <Text color="silver-dark" lineHeight="base" mb={6}>
              {i18n.t('answer.postedAppearHere')}
            </Text>
            <Link as={ReactRouterLink} to="/" variant="primary">
              {i18n.t('answer.findQuestion')}
            </Link>
          </FixedWidthWrapper>
        </Box>
      </Grid>
    );
  }
}
