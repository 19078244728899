import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';

export const actionClass = 'UNREADS';

export const actionTypes = {
  ...createActionType('FETCH_UNREADS', actionClass),
  ...createActionType('MARK_AS_READ', actionClass),
  POLL_UNREADS_START: `${actionClass}/POLL_UNREADS_START`,
  POLL_UNREADS_CANCEL: `${actionClass}/POLL_UNREADS_CANCEL`,
  POLL_UNREADS_REJECTED: `${actionClass}/POLL_UNREADS_REJECTED`
};

export const actionCreators = {
  getUnreads(questionIDs?: string[]) {
    return createAction(actionTypes.FETCH_UNREADS_START, questionIDs);
  },
  markAsRead(postIDs: string[], questionID: string) {
    return createAction(actionTypes.MARK_AS_READ_START, {
      postIDs,
      questionID
    });
  },
  startPollUnreads() {
    return createAction(actionTypes.POLL_UNREADS_START, undefined);
  },
  cancelPollUnreads() {
    return createAction(actionTypes.POLL_UNREADS_CANCEL, undefined);
  }
};
