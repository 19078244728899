import { Map, fromJS } from 'immutable';
import _isUndefined from 'lodash/isUndefined';
import { createSelector } from 'reselect';
import { isPseudoNanodegreeKey } from 'app/models/nanodegree/nanodegreeHelpers';
import {
  selectProjectIdsByNanodegreeKey,
  selectReviewsProjectsById
} from 'app/models/nanodegree/nanodegreeSelectors';
import { PseudoNanodegreeType } from 'app/models/nanodegree/nanodegreeTypes';

import { PAGE_SIZE, ALGOLIA_PAGE_TOTAL, START_PAGE } from './searchConstants';

const defaultResults = fromJS({
  totalResults: 0,
  unansweredResults: 0,
  totalPages: 0
});

export const selectSearchResultRefs = (state) => {
  const results = state.getIn(['search', 'searchResults']);

  if (!results?.get('totalResults')) {
    return defaultResults;
  }

  return results;
};

export const selectAdditionalQuestionData = (state) => {
  return state.getIn(['search', 'questionData']);
};

export const selectSearchQuery = (state: Map<string, any>) => {
  return state.getIn(['search', 'searchQuery']);
};

export const selectSearchQueryString = (state) => {
  return state.getIn(['search', 'searchQueryString']);
};

export const selectTotalAnsweredPercent = createSelector(
  selectSearchResultRefs,
  (searchResultRefs) => {
    const totalResults = searchResultRefs.get('totalResults', 0);
    const unansweredTotal = searchResultRefs.get('unansweredResults', 0);

    return Math.floor(((totalResults - unansweredTotal) / totalResults) * 100);
  }
);

export const selectTotalPages = createSelector(
  selectSearchQuery,
  (searchQuery) => {
    const pageTotal = searchQuery.toJS().totalPages;
    return pageTotal <= ALGOLIA_PAGE_TOTAL ? pageTotal : ALGOLIA_PAGE_TOTAL;
  }
);

export const selectTotalResults = createSelector(
  selectSearchResultRefs,
  (searchResultRefs) => {
    return searchResultRefs.get('totalResults', 0);
  }
);

export const selectSearchPage = createSelector(
  selectSearchQuery,
  (searchQueryRefs) => {
    return searchQueryRefs.get('page', START_PAGE);
  }
);

export const selectCurrentPaginationStart = createSelector(
  selectSearchPage,
  (currentPage) => {
    const start = (currentPage - 1) * PAGE_SIZE + 1;

    return start;
  }
);

export const selectCurrentPaginationEnd = createSelector(
  selectSearchPage,
  selectSearchResultRefs,
  (currentPage, searchResultRefs) => {
    const totalResults = searchResultRefs.get('totalResults', 0);
    const totalResultsPages = Math.ceil(totalResults / PAGE_SIZE);
    const end =
      currentPage === totalResultsPages
        ? totalResults
        : currentPage * PAGE_SIZE;
    return end;
  }
);

export const selectNanodegreeProjectFilter = createSelector(
  selectSearchQuery,
  (searchQuery) => ({
    nanodegreeKey: searchQuery.get('nanodegreeKey'),
    projectID: searchQuery.get('projectID'),
    rubricID: searchQuery.get('rubricID')
  })
);

export const selectFilterByUnanswered = createSelector(
  selectSearchQuery,
  (searchQueryRefs) => {
    return searchQueryRefs.get('unanswered');
  }
);

export const selectSearchProjectIds = (state) => {
  const { nanodegreeKey, projectID } = selectNanodegreeProjectFilter(state);
  if (projectID) {
    return [projectID];
  } else if (
    nanodegreeKey &&
    !isPseudoNanodegreeKey(nanodegreeKey, PseudoNanodegreeType.ANY)
  ) {
    const projectIds = selectProjectIdsByNanodegreeKey(state, nanodegreeKey);
    const reviewsProjectsById = selectReviewsProjectsById(state);
    return projectIds.filter((id) => {
      return reviewsProjectsById[id] && !reviewsProjectsById[id].isCareer;
    });
  }
  return undefined;
};

export const selectApiStatus = createSelector(
  (state: Map<string, string>, method?: string) => {
    if (method) {
      const methodState = state.getIn(['searchApi', method]);

      if (_isUndefined(methodState)) {
        throw Error(`Key "${method}" not found on searchApi.`);
      }

      return methodState;
    }

    return state.get('searchApi');
  },
  (status) => status
);

export const selectSearchSession = (state) => {
  return state.getIn(['search', 'searchSession']);
};
