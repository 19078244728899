import { normalize, schema } from 'normalizr';

import { questionSchema } from 'app/models/posts/postsNormalizers';

export const normalizeSearchResults = (results) => {
  const searchResults = new schema.Object({
    questions: [questionSchema]
  });
  return normalize(results, searchResults);
};
