import { Box, Grid, Image, Link, Text } from '@chakra-ui/react';
import { EditIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import FixedWidthWrapper from 'app/components/FixedWidthWrapper';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import pencil from 'assets/images/illustration-chat-with-pencil.svg';

import i18n from 'i18n';

export default class ActivityQuestionEmptyState extends React.Component {
  render() {
    return (
      <Grid h="70vh">
        <Box
          as="main"
          maxW="22.5rem"
          mt={12}
          alignSelf="start"
          justifySelf="center"
          textAlign="center"
        >
          <Image
            src={pencil}
            alt={i18n.t('post.pencilIllustration')}
            w="6.5rem"
            h="auto"
            m="0 auto"
          />

          <PermissionsServiceContainer showIf={{ canAsk: true }}>
            <FixedWidthWrapper>
              <Text color="silver-dark" lineHeight="base" mb={6}>
                {i18n.t('question.askAppearHere')}
              </Text>
              <Link as={ReactRouterLink} to="/questions/new" variant="primary">
                <EditIcon w={6} h={6} me={2} />
                {i18n.t('question.post')}
              </Link>
            </FixedWidthWrapper>
          </PermissionsServiceContainer>

          <PermissionsServiceContainer showIf={{ canAsk: false }}>
            <FixedWidthWrapper>
              <Text color="silver-dark" lineHeight="base" mb={6}>
                {i18n.t('question.notAskedQuestions')}
              </Text>
            </FixedWidthWrapper>
          </PermissionsServiceContainer>
        </Box>
      </Grid>
    );
  }
}
