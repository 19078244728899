import { Flex } from '@chakra-ui/react';
import {
  BadgeCheckedIcon,
  ChatCheckedIcon
} from '@udacity/chakra-veritas-icons';
import _noop from 'lodash/noop';
import React from 'react';

import {
  acceptedHoverStyles,
  AnswerStatusButton,
  ButtonText,
  verifiedHoverStyles
} from './factoryComponents';
import i18n from 'i18n';

export interface Props {
  className?: string;
  isAccepted: boolean;
  isVerified: boolean;
  isAcceptPending: boolean;
  isVerifyPending: boolean;
  canAccept: boolean;
  canVerify: boolean;
  handleToggleAccepted: () => void;
  handleToggleVerified: () => void;
}

export const getAcceptedText = ({ isAccepted }: Props) => {
  if (isAccepted) {
    return i18n.t('answer.accepted');
  }
  return i18n.t('answer.setAsAccepted');
};

export const getVerifiedText = ({ isVerified }: Props) => {
  if (isVerified) {
    return i18n.t('answer.udacity');
  }
  return i18n.t('answer.setAsUdacity');
};

export default class AnswerAcceptVerify extends React.PureComponent<Props> {
  render() {
    const {
      canAccept,
      canVerify,
      isAccepted,
      isVerified,
      isAcceptPending,
      isVerifyPending
    } = this.props;

    const acceptedText = getAcceptedText(this.props);
    const verifiedText = getVerifiedText(this.props);
    const handleToggleAccepted = canAccept
      ? this.props.handleToggleAccepted
      : _noop;
    const handleToggleVerified = canVerify
      ? this.props.handleToggleVerified
      : _noop;

    return (
      <Flex justify={{ base: 'end', 'sm-md': 'start' }}>
        {(canVerify || isVerified) && (
          <AnswerStatusButton
            onClick={handleToggleVerified}
            disabled={isVerifyPending}
            display={!canVerify ? 'none' : 'flex'}
            bgColor={isVerified ? 'light-purple' : 'transparent'}
            border="1px solid"
            borderColor={
              isVerified ? 'purple' : canVerify ? 'slate-darker' : 'transparent'
            }
            _hover={canVerify ? { ...verifiedHoverStyles } : {}}
            me={2}
          >
            <BadgeCheckedIcon w={6} h={6} />
            <ButtonText color="inherit">{verifiedText}</ButtonText>
          </AnswerStatusButton>
        )}

        {(canAccept || isAccepted) && (
          <AnswerStatusButton
            onClick={handleToggleAccepted}
            disabled={isAcceptPending}
            display={!canAccept ? 'none' : 'flex'}
            bgColor={isAccepted ? 'light-green' : 'transparent'}
            border="1px solid"
            borderColor={
              isAccepted ? 'green' : canAccept ? 'slate-darker' : 'transparent'
            }
            _hover={canAccept ? { ...acceptedHoverStyles } : {}}
          >
            <ChatCheckedIcon w={6} h={6} />
            <ButtonText color="inherit">{acceptedText}</ButtonText>
          </AnswerStatusButton>
        )}
      </Flex>
    );
  }
}
