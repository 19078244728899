import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { DownloadIcon } from '@udacity/chakra-veritas-icons';
import { PureComponent } from 'react';

import { FILE_SIZE_LIMIT } from 'app/models/files/filesConstants';
import { formatBytes } from 'app/models/files/filesHelpers';

import i18n from 'i18n';

export interface Props {
  isDraggingOver: boolean;
}

export default class FileDropTarget extends PureComponent<Props> {
  render() {
    const { isDraggingOver } = this.props;

    return (
      <Grid
        position="absolute"
        top={0}
        left={0}
        w="full"
        h="full"
        bgColor="white"
        zIndex={900}
        alignItems="center"
        justifyItems="center"
        _after={{
          content: '" "',
          display: 'block',
          position: 'absolute',
          border: '2px dashed',
          borderColor: isDraggingOver ? 'cerulean' : 'silver-lighter',
          bgColor: isDraggingOver ? 'cerulean-lightest' : 'white',
          w: 'calc(100% - 2rem)',
          h: 'calc(100% - 2rem)',
          zIndex: 'hide'
        }}
      >
        <Flex direction="column" justify="center" align="center" gap={4}>
          <Box
            display="inline-block"
            borderRadius="100%"
            bgColor="teal-lightest"
            p={5}
          >
            <DownloadIcon w={10} h={10} color="teal" />
          </Box>
          <Text fontWeight="semibold">{i18n.t('image.dragAndDrop')}</Text>
          <Text size="sm">
            {i18n.t('image.maxSize', { size: formatBytes(FILE_SIZE_LIMIT) })}
          </Text>
        </Flex>
      </Grid>
    );
  }
}
