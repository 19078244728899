import React from 'react';
import isFunction from 'lodash/isFunction';
import * as ReactMobiledoc from 'react-mobiledoc-editor';

export interface CardButtonProps {
  className: string;
  card: any;
  type: 'button';
  tabIndex: number;
  ariaLabel?: string;
  afterInsertCallback?: () => void;
}

export const CardButton: React.FC<CardButtonProps> = ({
  className,
  card,
  type,
  tabIndex,
  afterInsertCallback,
  ariaLabel,
  children
}) => {
  const { editor } = React.useContext<any>(
    ReactMobiledoc.ReactMobileDocContext
  );

  const grabSelectedContentBeforeOverwritingWithCard = (
    editor: any
  ): string | undefined => {
    return editor?._hasSelection()
      ? window
          .getSelection()
          ?.toString()
          .replace(/\n\n/g, '\n')
      : undefined;
  };

  const insertNewParagraphAfterCardBlock = (editor: any): void => {
    editor?.run((postEditor: any) => {
      const newLine = editor.builder.createMarkupSection('p');
      postEditor.insertSection(newLine);
    });
  };

  const handleClick = () => {
    const selection = grabSelectedContentBeforeOverwritingWithCard(editor);

    const payload = { content: selection?.trim() || '' };

    editor?.insertCard(card.name, payload, true);

    if (isFunction(afterInsertCallback)) {
      setTimeout(() => {
        // Delay callback to allow associated redux updates to propagate.
        afterInsertCallback();
      }, 1);
    }

    insertNewParagraphAfterCardBlock(editor);
  };

  return (
    <button
      aria-label={ariaLabel}
      className={className}
      type={type}
      onClick={handleClick}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};
