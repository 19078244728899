import { Map } from 'immutable';
import { createSelector } from 'reselect';

export const selectBaseEntities = (state) => state.get('entities');

export const selectEntities = createSelector(
  selectBaseEntities,
  (entities) => {
    return Map({
      answers: entities.getIn(['answers', 'byId']),
      comments: entities.getIn(['comments', 'byId']),
      questions: entities.getIn(['questions', 'byId']),
      moderatePostReasons: entities.getIn(['moderatePostReasons', 'byId']),
      users: entities.getIn(['users', 'byId'])
    });
  }
);
