import React from 'react';

import i18n from 'i18n';
import styles from './linkTooltip.module.scss';

interface Props {
  url: string;
  editLink: () => void;
}

interface State {
  anchor: string;
  url: string;
}

export default class LinkDialog extends React.PureComponent<Props, State> {
  handleClick = () => {
    const { editLink } = this.props;
    editLink();
  };

  render() {
    const { url } = this.props;
    return (
      <div className={styles['link-tooltip']}>
        {url.length ? (
          <a
            data-ref="external-link"
            className={styles['url-wrapper']}
            href={url}
            rel="external noopener noreferrer"
            target="_blank"
          >
            <span className={styles['url-display']}>{url}</span>
          </a>
        ) : (
          <span
            data-ref="external-link-fallback"
            className={styles['no-url url-wrapper']}
          >
            {i18n.t('error.noUrlDefined')}
          </span>
        )}
        <button className={styles.button} onClick={this.handleClick}>
          {i18n.t('action.edit')}
        </button>
      </div>
    );
  }
}
