import React from 'react';

import ContentWrapper from 'app/components/ContentWrapper';
import Logo from 'app/components/Logo';
import NavListContainer from 'app/containers/NavListContainer';
import SearchFilterToggle from 'app/components/SearchFilter/SearchFilterToggle';
import SearchInputContainer from 'app/containers/SearchInputContainer';

import { Box, Flex } from '@chakra-ui/react';

export default class NavHeaderLarge extends React.PureComponent {
  render() {
    return (
      <Box
        bgColor="blue-darker"
        p={{ base: '0.85rem 0', 'sm-md': '3.8rem 0 4.3rem 0' }}
      >
        <ContentWrapper>
          <Flex
            justify="space-between"
            align="start"
            mb={{ base: 4, 'sm-md': '3.25rem' }}
          >
            <a href="/" target="_self">
              <Logo />
            </a>
            <NavListContainer />
          </Flex>
          <Flex gap="0.625rem" w="full">
            <Box flex={1}>
              <SearchInputContainer theme="default" searchOnLoad />
            </Box>
            <Box display={{ base: 'block', md: 'none' }}>
              <SearchFilterToggle />
            </Box>
          </Flex>
        </ContentWrapper>
      </Box>
    );
  }
}
