export const globalStyles = {
  'html, body': {
    height: '100%'
  },
  a: {
    color: 'cerulean',
    textDecoration: 'none'
  },
  'ul, ol': {
    padding: 0,
    margin: 0,
    listStyle: 'none'
  },
  '.post-body-content p': {
    margin: 0,
    maxWidth: 'none',
    minHeight: '1.75rem'
  },
  '.post-body-content strong': {
    fontWeight: 'bold'
  },
  '.post-body-content em': {
    fontStyle: 'italic'
  },
  '.post-body-content ul': {
    margin: 0,
    paddingStart: '1.25rem',
    listStyleType: 'disc'
  },
  '.post-body-content ol': {
    margin: 0,
    paddingStart: '1.25rem',
    listStyleType: 'decimal'
  },
  '.post-body-content blockquote': {
    fontSize: 'md',
    borderRadius: 'secondary',
    margin: 0,
    bgColor: 'silver-lightest',
    padding: '0.75rem 1rem',
    maxWidth: 'full'
  },
  '.toolbar-button': {
    borderRadius: 'secondary',
    border: 0,
    background: 'none',
    p: '0 0.25rem 0.25rem',
    w: 'full',
    color: 'stone',
    _hover: {
      bgColor: 'blue-lightest'
    },
    "&[class*='active']": {
      svg: {
        color: 'cerulean'
      }
    }
  }
};
