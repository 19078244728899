import _ from 'lodash';

import {
  Mobiledoc,
  textContentFromMobiledoc
} from 'app/helpers/mobiledocHelpers';

export function isBlankInput(value: Mobiledoc | string | string[]) {
  const valueToCheck = _.has(value, 'version') // value.version indicates mobiledoc content
    ? _.trim(textContentFromMobiledoc(value))
    : value;

  return _.isEmpty(valueToCheck); // return true === missing field
}
