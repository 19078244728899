import { keyframes, ListItem } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';

const flash = keyframes(`
  0% {
    background: 'yellow-lighter';
  }
  100% {
    background: transparent;
  }
`);

interface CommentListItemWrapperProps {
  children?: ReactNode;
  isFocused?: boolean;
  isUnread?: boolean;
}

export const CommentListItemWrapper = forwardRef<
  HTMLLIElement,
  CommentListItemWrapperProps
>(({ children, isFocused, isUnread }, ref) => {
  return (
    <ListItem
      ref={ref}
      listStyleType="none"
      p="1.5rem 0 1.5rem 1rem"
      ps={{ 'sm-md': '2rem' }}
      borderStart="1px solid"
      borderStartColor="silver-lighter"
      animation={isFocused || isUnread ? `${flash} 3s ease-out` : 'none'}
      sx={{ '&:first-of-type': { pt: 4 }, '&:last-of-type': { pb: 4 } }}
    >
      {children}
    </ListItem>
  );
});
