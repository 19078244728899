import { Map, fromJS } from 'immutable';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import React from 'react';
import { connect } from 'react-redux';

import ReplyForm from 'app/components/ReplyForm';
import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { selectIsImageUploadPending } from 'app/models/files/filesSelectors';
import { isFulfilled } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import { selectAnswer, selectApiStatus } from 'app/models/posts/postsSelectors';

import { validate, ValidationError } from '../answerFormContainerHelpers';
import i18n from 'i18n';

interface StateProps {
  updateAnswerApiStatus: Map<string, string>;
  answer: Map<string, string>;
  isImageUploadPending: boolean;
}

interface DispatchProps {
  updateAnswer: (
    answerId: string,
    parentId: string,
    answer: { body: any }
  ) => void;
  resetUpdateAnswer: () => void;
}

interface OwnProps {
  answerId: string;
  onCancel: () => void;
}

export type Props = StateProps & DispatchProps & OwnProps;

interface State {
  validationErrors: ValidationError[];
}

const mapStateToProps = (state: any, ownProps: OwnProps): StateProps => ({
  answer: selectAnswer(state, ownProps.answerId),
  updateAnswerApiStatus: selectApiStatus(state, 'updateAnswer'),
  isImageUploadPending: selectIsImageUploadPending(state)
});

const mapDispatchToProps: DispatchProps = {
  updateAnswer: postsActions.updateAnswer,
  resetUpdateAnswer: postsActions.resetUpdateAnswer
};

export class EditAnswerFormContainer extends React.PureComponent<Props, State> {
  static defaultProps = {
    answer: fromJS({})
  };

  state = {
    validationErrors: []
  };

  componentDidUpdate(prevProps: Props) {
    const { answerId, updateAnswerApiStatus = fromJS({}) } = this.props;
    if (answerId === updateAnswerApiStatus.getIn(['payload', 'answerId'])) {
      this.checkUpdatedAnswerStatus(prevProps);
    }
  }

  componentWillUnmount() {
    const { resetUpdateAnswer = _noop } = this.props;
    resetUpdateAnswer();
  }

  checkUpdatedAnswerStatus(prevProps: Props) {
    const { updateAnswerApiStatus: prevStatus } = prevProps;
    const { updateAnswerApiStatus, onCancel } = this.props;

    const editSuccess =
      isFulfilled(updateAnswerApiStatus) && !isFulfilled(prevStatus);

    if (editSuccess) {
      onCancel();
    }
  }

  validateAnswer(content: Mobiledoc): ValidationError[] {
    const validationErrors: ValidationError[] = validate(content);
    this.setState({ validationErrors });
    return validationErrors;
  }

  handleChange = (content: Mobiledoc): void => {
    this.validateAnswer(content);
  };

  dispatchUpdateAnswer = (content: Mobiledoc): void => {
    const { answerId, updateAnswer = _noop, answer = fromJS({}) } = this.props;
    const hasFormError = !!this.validateAnswer(content).length;

    if (!hasFormError) {
      updateAnswer(answerId, answer.get('parentId'), { content });
    }
  };

  render() {
    const {
      updateAnswerApiStatus,
      answer = fromJS({}),
      onCancel,
      isImageUploadPending
    } = this.props;

    const replyFormError = _find(this.state.validationErrors, {
      field: 'content'
    });

    return (
      <ReplyForm
        title={i18n.t('answer.edit')}
        subtitle={i18n.t('answer.write')}
        initialContent={{
          content: answer.get('content')
        }}
        isImageUploadPending={isImageUploadPending}
        onSubmit={this.dispatchUpdateAnswer}
        onChange={this.handleChange}
        onCancel={onCancel}
        errorMessage={_get(replyFormError, 'message', null)}
        apiStatus={updateAnswerApiStatus}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(EditAnswerFormContainer);
