import { fromJS } from 'immutable';
import _omitBy from 'lodash/omitBy';
import _isNull from 'lodash/isNull';

import { actionTypes } from 'app/models/session/sessionActions';

export const initialState = fromJS({
  me: {
    approvedActions: [],
    user: {}
  }
});

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.BOOTSTRAP_FULFILLED: {
      return state.mergeDeep({ me: _omitBy(payload, _isNull) });
    }
    case actionTypes.SET_ROLES: {
      return state.setIn(['me', 'roles'], fromJS(payload));
    }
  }

  return state;
}
