import { Box, Flex, Link } from '@chakra-ui/react';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import AnswerCount from 'app/components/AnswerCount';
import CommentCount from 'app/components/CommentCount';
import Header from 'app/components/Header';
import HighlightedText from 'app/components/HighlightedText';
import NotificationBadge from 'app/components/NotificationBadge';
import PostVoteContainer from 'app/containers/PostVoteContainer';
import { IActivityQuestion } from 'app/models/activity/activityTypes';
import { UnreadPost } from 'app/models/unreads/unreadsTypes';

import {
  AnswerCommentWrapper,
  CommentWrapper,
  CreatedAtText,
  PostItem,
  PostVoteWrapper
} from './factoryComponents';
import i18n from 'i18n';

export interface Props {
  post: IActivityQuestion;
  unreads: { [postId: string]: UnreadPost } | undefined;
}

export default class ActivityQuestionItem extends React.PureComponent<Props> {
  render() {
    const {
      post: {
        title,
        createdAt,
        id,
        commentCount,
        answerCount,
        acceptedAnswerId
      },
      unreads = {}
    } = this.props;
    const unreadCount = Object.keys(unreads).length;
    const locales = { es };

    return (
      <PostItem>
        <PostVoteWrapper>
          <PostVoteContainer postId={id} />
        </PostVoteWrapper>

        <Header size="h2" styleType="mediumHeader" data-ref="title">
          <Flex align="center">
            <Link
              as={ReactRouterLink}
              to={`/questions/${id}`}
              data-ref="header-link"
              color="inherit"
            >
              <HighlightedText text={title} />
            </Link>
            {unreadCount > 0 && (
              <Box p={2}>
                <NotificationBadge unreadTotal={unreadCount} />
              </Box>
            )}
          </Flex>
        </Header>

        <Flex alignItems="center" justify="space-between" gap={{ 'sm-md': 4 }}>
          <Box display="inline-block" alignSelf="start">
            <CreatedAtText>
              {formatDistanceToNow(parseInt(createdAt, 10), {
                addSuffix: true,
                locale: locales[i18n.language]
              })}
            </CreatedAtText>
          </Box>
          <AnswerCommentWrapper>
            <CommentWrapper data-ref="comment-count">
              <Link
                as={ReactRouterLink}
                to={`/questions/${id}`}
                data-ref="comment-link"
              >
                <CommentCount totalComments={commentCount} />
              </Link>
            </CommentWrapper>
            <Link
              as={ReactRouterLink}
              to={`/questions/${id}`}
              data-ref="answer-link"
            >
              <AnswerCount
                totalAnswers={answerCount}
                acceptedId={acceptedAnswerId || ''}
                data-ref="answer-count"
              />
            </Link>
          </AnswerCommentWrapper>
        </Flex>
      </PostItem>
    );
  }
}
