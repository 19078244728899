import { Flex } from '@chakra-ui/react';
import { ChatCheckedIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';

import {
  AnswerStatusButton,
  ButtonText
} from 'app/components/AnswerAcceptVerify/factoryComponents';

import i18n from 'i18n';

export default class ActivityAcceptedAnswer extends React.Component {
  render() {
    return (
      <Flex justify={{ base: 'end', 'sm-md': 'start' }}>
        <AnswerStatusButton
          disabled
          bgColor="light-green"
          border="1px solid"
          borderColor="green"
          color="slate"
        >
          <ChatCheckedIcon w={6} h={6} />
          <ButtonText color="inherit">{i18n.t('answer.accepted')}</ButtonText>
        </AnswerStatusButton>
      </Flex>
    );
  }
}
