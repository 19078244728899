import {
  ApiEnrollment,
  ApiModule,
  Nanodegree,
  Project
} from './nanodegreeTypes';

import _isEmpty from 'lodash/isEmpty';

export function nanodegreesByKeyFromApiEnrollments(
  enrollments: Partial<ApiEnrollment>[]
): { [key: string]: Nanodegree } {
  const nanodegrees = enrollments
    .filter((e: Partial<ApiEnrollment>) => !_isEmpty(e?.root_node))
    .map((e: Partial<ApiEnrollment>) => ({
      key: e?.root_node?.key,
      title: e?.root_node?.title,
      projects: e.root_node ? projectsFromApiEnrollment(e.root_node) : [],
      hasKnowledgeReviews: !!e.includes_knowledge_reviews
    }));

  return nanodegrees.reduce((ndsByKey, nd) => {
    if (nd.key) ndsByKey[nd.key] = nd;
    return ndsByKey;
  }, {});
}

function projectsFromApiEnrollment(
  node: ApiEnrollment['root_node']
): Project[] {
  // NDs put their projects under .parts[].modules...
  // Parts just have a top level .modules...
  const modules =
    node.semantic_type === 'Part'
      ? node.modules
      : node.parts.reduce(
          (allModules: ApiModule[], part) => allModules.concat(part.modules),
          []
        );

  const lessons = modules?.reduce(
    (allLessons: ApiModule['lessons'], module: ApiModule) =>
      allLessons.concat(module.lessons),
    []
  );

  // Get the projects from each lesson. Not all lessons have a project so be aware of that.
  const projects = lessons?.reduce(
    (allProjects: Project[], lesson: ApiModule['lessons'][number]) => {
      if (lesson.project) {
        allProjects.push({
          title: lesson.project.title,
          rubricId: lesson.project.rubric_id,
          reviewsProjectId: lesson.project.reviews_project_id
        });
      }
      return allProjects;
    },
    []
  );

  return projects ?? [];
}
