import { IStats } from 'app/models/activity/activityTypes';

export interface IUser {
  id: string;
  displayName: string;
  profileImageUrl?: string;
  activity?: IStats;
}

export interface IUsers {
  byId: {
    [key: string]: IUser;
  };
}

export const IUserDefault = {
  id: '',
  displayName: '',
  profileImageUrl: ''
};

export const IUserMock = {
  id: '9',
  displayName: 'Lenny Learner',
  profileImageUrl: 'www.picture'
};
