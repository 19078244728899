import { keyframes, ListItem } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';

const flash = keyframes(`
  0% {
    background: 'yellow-lighter';
  }
  100% {
    background: transparent;
  }
`);

interface AnswerListItemWrapperProps {
  children?: ReactNode;
  isFocused?: boolean;
  isUnread?: boolean;
}

export const AnswerListItemWrapper = forwardRef<
  HTMLLIElement,
  AnswerListItemWrapperProps
>(({ children, isFocused, isUnread }, ref) => {
  return (
    <ListItem
      ref={ref}
      listStyleType="none"
      display="grid"
      gridTemplateColumns={{ base: '1fr 1fr', 'sm-md': '2.813rem 1fr' }}
      gridRowGap={{ 'sm-md': 3 }}
      gridColumnGap={{ 'sm-md': 4 }}
      p="1.75rem 1rem 1.5rem 0"
      ps={{ 'sm-md': 4 }}
      borderTop="1px solid"
      borderTopColor="silver-lighter"
      bgColor={isUnread ? 'yellow-lighter' : 'white'}
      transition="background 3s ease-out"
      animation={isFocused ? `${flash} 3s ease-out` : 'none'}
      sx={{
        '&:first-of-type': { pt: 4, borderTop: 'none' }
      }}
    >
      {children}
    </ListItem>
  );
});
