import { Tooltip } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface ToolbarTooltipProps {
  label: ReactNode;
}

const ToolbarTooltip: React.FC<ToolbarTooltipProps> = ({ children, label }) => {
  return (
    <Tooltip
      shouldWrapChildren
      hasArrow
      placement="top"
      variant="light"
      openDelay={100}
      label={label}
    >
      {children}
    </Tooltip>
  );
};

export default ToolbarTooltip;
