import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import FullWidthWrapper from 'app/components/FullWidthWrapper';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import Header from 'app/components/Header';
import TipIcon from 'app/components/TipIcon';
import i18n from 'i18n';

export const tips = [
  i18n.t('tip.searchKeyword'),
  i18n.t('tip.searchError'),
  i18n.t('tip.tryOtherSites')
];

export default class SearchNoResultsFound extends React.PureComponent<any> {
  render() {
    return (
      <FullWidthWrapper>
        <main>
          <Header size="h2" styleType="mediumHeaderGrayDark">
            {i18n.t('search.noResults')}
          </Header>
          <TipIcon />
          <UnorderedList ml="1.6rem">
            {tips.map((tip: string, index: number) => (
              <ListItem key={index} lineHeight="1.5rem" fontSize="sm">
                {tip}
              </ListItem>
            ))}
          </UnorderedList>
          <PermissionsServiceContainer showIf={{ canAsk: true }}>
            <Text size="sm" me={2} mt={6}>
              {i18n.t('answer.cantFind')}
              <Link as={ReactRouterLink} to="/questions/new" ms={1}>
                {i18n.t('question.postYourQuestion')}
              </Link>
            </Text>
          </PermissionsServiceContainer>
        </main>
      </FullWidthWrapper>
    );
  }
}
