import { Map } from 'immutable';

export let activeKeys: Map<string, boolean> = Map();

export function monitorActiveKeys() {
  document.addEventListener('keydown', (e: { key: string }) => {
    const keyName = e.key;
    activeKeys = activeKeys.set(keyName, true);
  });

  document.addEventListener('keyup', (e: { key: string }) => {
    const keyName = e.key;
    activeKeys = activeKeys.set(keyName, false);
  });
}
