export interface ApiModule {
  readonly lessons: Array<{
    readonly project: {
      readonly title: string;
      readonly reviews_project_id: number;
      readonly rubric_id: number;
    } | null;
  }>;
}

export interface ApiEnrollment {
  readonly status: string;
  readonly includes_knowledge_reviews: boolean;

  readonly root_node: {
    readonly key: string;
    readonly title: string;
    readonly semantic_type: string;
    readonly parts: Array<{
      readonly modules: ApiModule[];
    }>;
    readonly modules?: ApiModule[];
  };
}

export interface Project {
  readonly title: string;
  readonly reviewsProjectId: number;
  readonly rubricId?: number;
}

export interface Nanodegree {
  readonly key: string;
  readonly title: string;
  readonly projects: Project[];
  readonly hasKnowledgeReviews: boolean;
}

export interface ReviewsProject {
  id: number;
  title: string;
  isCareer: boolean;
}

export interface ApiReviewsProject {
  id: number;
  name: string;
  is_cert_project: boolean;
  is_career: boolean;
}

export enum PseudoNanodegreeType {
  ANY = 'PSEUDO_ND_ANY',
  CERTIFICATIONS = 'PSEUDO_ND_CERTIFICATIONS'
}
