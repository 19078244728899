import { fromJS } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

import { actionTypes } from './searchActions';

import { START_PAGE } from 'app/models/search/searchConstants';

export const initialState = fromJS({
  questionData: {},
  searchQuery: {
    nanodegreeKey: undefined,
    page: START_PAGE,
    projectID: undefined,
    query: '',
    rubricID: undefined,
    unanswered: false
  },
  searchQueryString: '',
  searchResults: {
    totalResults: 0,
    unansweredResults: 0,
    totalPages: 0
  },
  searchSession: {
    sessionID: ''
  }
});

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.INITIALIZE_SEARCH_SESSION: {
      return state.mergeDeep({
        searchSession: {
          sessionID: uuidv4()
        }
      });
    }

    case actionTypes.SEARCH_QUESTIONS_START: {
      return state.mergeDeep({
        searchQuery: payload
      });
    }

    case actionTypes.SEARCH_QUESTIONS_FULFILLED: {
      const { results } = payload;

      return state.mergeDeep({
        searchResults: results?.result ?? {}
      });
    }

    case actionTypes.SEARCH_QUESTIONS_READY: {
      return initialState;
    }

    case actionTypes.UPDATE_SEARCH_QUERY_STRING: {
      return state.set('queryString', payload);
    }

    case actionTypes.RESET_SEARCH_FILTERS: {
      const newState = state.setIn(
        ['searchQuery'],
        initialState.getIn(['searchQuery'])
      );

      return newState;
    }

    case actionTypes.SET_SEARCH_PARAMS: {
      return state.mergeDeep({
        searchQuery: payload
      });
    }

    case actionTypes.SET_ALGOLIA_RESULTS_FULFILLED: {
      return state.mergeDeep({
        searchResults: payload
      });
    }

    case actionTypes.RETRIEVE_QUESTION_DATA_PENDING: {
      const newQuestionData = payload.reduce(
        (agg: { [k: string]: null | object }, qid: number) => ({
          ...agg,
          [String(qid)]: null
        }),
        {}
      );

      return state.mergeDeep({
        questionData: newQuestionData
      });
    }

    case actionTypes.RETRIEVE_QUESTION_DATA_FULFILLED: {
      const newQuestionData = {};

      Object.entries(payload.searchData).forEach(
        ([k, rawData]: [string, any]) => {
          newQuestionData[rawData.id] = {
            isDeletedQuestion: rawData === null,
            myVote: rawData?.myVote ?? 0,
            user: rawData?.user ?? {},
            programTitle: rawData?.node?.title ?? ''
          };
        }
      );

      return state.mergeDeep({
        questionData: newQuestionData
      });
    }
  }

  return state;
}
