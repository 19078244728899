import { END, buffers, eventChannel } from 'redux-saga';
import { NotUndefined, Buffer } from '@redux-saga/types';

function getCookie(name) {
  const re = new RegExp(`${name}=([^;]+)`);
  const value = re.exec(document.cookie);
  return value != null ? unescape(value[1]) : null;
}

export default function createUploadFileChannel(endpoint: string, file: File) {
  const formData = new FormData();
  formData.append('uploadFile', file);

  const buffer: Buffer<NotUndefined> = buffers.sliding(2);

  return eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();
    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = (e.loaded / e.total) * 100;
        emitter({ progress });
      }
    };
    const onFailure = (e: ProgressEvent | null) => {
      emitter({ err: new Error('Upload failed') });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, response } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true, response: JSON.parse(response) });
          emitter(END);
        } else {
          onFailure(null);
        }
      }
    };
    xhr.open('POST', endpoint, true);
    xhr.setRequestHeader('Authorization', `Bearer ${getCookie('_jwt')}`);
    xhr.send(formData);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffer);
}
