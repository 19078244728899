import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { CaretDownIcon, CaretUpIcon } from '@udacity/chakra-veritas-icons';

import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import permissions from 'app/services/permissionsService';

import VoteCounter from './VoteCounter';
import {
  animationDuration,
  bounceDown,
  bounceUp,
  DownVoteButton,
  ReadOnlyScore,
  UpVoteButton
} from './factoryComponents';
import i18n from 'i18n';

export interface Props {
  isVotePending: boolean;
  upvotePost: () => void;
  downvotePost: () => void;
  score: number;
  myVote: number;
}

interface State {
  upVoteFocused: boolean;
  downVoteFocused: boolean;
}

export default class PostVote extends React.PureComponent<Props, State> {
  state = {
    upVoteFocused: false,
    downVoteFocused: false
  };

  timeoutID: NodeJS.Timer;

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  upvotePost = () => {
    const { upvotePost, isVotePending } = this.props;
    if (!isVotePending) {
      upvotePost();
      this.handleButtonFocus('upVoteFocused');
    }
  };

  downvotePost = () => {
    const { downvotePost, isVotePending } = this.props;
    if (!isVotePending) {
      downvotePost();
      this.handleButtonFocus('downVoteFocused');
    }
  };

  handleButtonFocus = (stateKey: 'downVoteFocused' | 'upVoteFocused') => {
    clearTimeout(this.timeoutID);
    this.setState({ ...this.state, [stateKey]: true }, () => {
      this.timeoutID = setTimeout(() => {
        this.setState({ ...this.state, [stateKey]: false });
      }, parseInt(animationDuration, 10));
    });
  };

  render() {
    const { myVote, isVotePending, score } = this.props;
    const { upVoteFocused, downVoteFocused } = this.state;
    const isReadOnly = permissions.isReadOnly();

    return (
      <Box
        display="inline-block"
        fontSize="sm"
        color="dark-night-blue"
        transform={{ 'sm-md': 'translateY(-0.3rem)' }}
      >
        <Flex
          align="center"
          flexFlow={{ 'sm-md': 'column nowrap' }}
          borderRadius="secondary"
          m={{ 'sm-md': 0 }}
          p={isReadOnly ? '0.7rem 0.5rem 0.5rem' : 0}
          bgColor={
            isReadOnly
              ? 'cerulean-lightest'
              : { base: 'silver-lightest', 'sm-md': 'transparent' }
          }
        >
          <PermissionsServiceContainer showIf={{ isReadOnly: false }}>
            <UpVoteButton
              aria-label={i18n.t('post.upvote')}
              icon={
                <CaretUpIcon
                  w={10}
                  h={10}
                  color={myVote === 1 ? 'cerulean' : 'titanium'}
                />
              }
              disabled={isVotePending}
              onClick={this.upvotePost}
              variant="minimal"
              animation={
                upVoteFocused ? `${bounceUp} ${animationDuration} ease` : 'none'
              }
            />
          </PermissionsServiceContainer>

          <PermissionsServiceContainer showIf={{ isReadOnly: false }}>
            <VoteCounter score={score} />
          </PermissionsServiceContainer>

          <PermissionsServiceContainer showIf={{ isReadOnly: true }}>
            <ReadOnlyScore>
              <Box fontSize="xs" whiteSpace="nowrap">
                <Trans i18nKey="post.voteCount" count={score}>
                  <Box as="strong" fontWeight="bold" fontSize="sm">
                    {{ count: score }}
                  </Box>
                  vote
                </Trans>
              </Box>
            </ReadOnlyScore>
          </PermissionsServiceContainer>

          <PermissionsServiceContainer showIf={{ isReadOnly: false }}>
            <DownVoteButton
              aria-label={i18n.t('post.downvote')}
              icon={
                <CaretDownIcon
                  w={10}
                  h={10}
                  color={myVote === -1 ? 'cerulean' : 'titanium'}
                />
              }
              disabled={isVotePending}
              onClick={this.downvotePost}
              variant="minimal"
              animation={
                downVoteFocused
                  ? `${bounceDown} ${animationDuration} ease`
                  : 'none'
              }
            />
          </PermissionsServiceContainer>
        </Flex>
      </Box>
    );
  }
}
