import { Box, UnorderedList } from '@chakra-ui/react';
import _isEmpty from 'lodash/isEmpty';
import React from 'react';

import AnswerListItem from 'app/components/AnswerListItem';
import CenteredLoader from 'app/components/CenteredLoader';
import { IAnswer } from 'app/models/entities/answers/answersEntitiesTypes';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';

import i18n from 'i18n';

export interface Props {
  answers: IAnswer[];
  isLoading: boolean;
  deepLinkId?: string;
  unreads: UnreadPostByParentId;
}

export default class AnswerList extends React.PureComponent<Props> {
  render() {
    const { answers, isLoading, deepLinkId, unreads } = this.props;

    if (_isEmpty(answers)) {
      return isLoading ? <CenteredLoader /> : null;
    }

    return isLoading ? (
      <CenteredLoader />
    ) : (
      <>
        <Box
          data-ref="answer-count"
          fontSize="sm"
          fontWeight="semibold"
          borderTop="1px solid"
          borderTopColor="silver-lighter"
          pt={8}
        >
          {i18n.t('answer.answerCount', { count: answers.length })}
        </Box>

        <UnorderedList ms={0}>
          {answers.map((a) => {
            return (
              <AnswerListItem
                key={a.id}
                answer={a}
                deepLinkId={deepLinkId}
                unreads={unreads[a.parentId || -1]}
              />
            );
          })}
        </UnorderedList>
      </>
    );
  }
}
