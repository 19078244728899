import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import FixedWidthWrapper from 'app/components/FixedWidthWrapper';
import IllustrationGhost from 'app/components/IllustrationGhost';
import i18n from 'i18n';

import { Button, Flex, Heading, Text } from '@chakra-ui/react';

export interface Props extends RouteComponentProps<any> {}

export class PageNotFound extends React.PureComponent<Props> {
  componentDidMount() {
    document.title = i18n.t('error.udacityPageNotFound');
  }

  handleHomeClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    const { history } = this.props;
    history.push('/');
  };

  render() {
    return (
      <Flex
        h={{
          base: 'calc(100vh - 120px)',
          'sm-md': 'calc(100vh - 134px)',
          md: 'calc(100vh - 82px)'
        }}
        w="100vw"
        justify="center"
        align="center"
      >
        <Flex
          as="main"
          direction="column"
          gap={4}
          maxW="22.5rem"
          justify="center"
          align="center"
        >
          <IllustrationGhost />
          <Heading
            size="h2"
            mt="0.125rem"
            mb={2}
            lineHeight="h5"
            fontWeight="light"
          >
            {i18n.t('error.404')}
          </Heading>
          <FixedWidthWrapper styleProps={{ textAlign: 'center' }}>
            <Text
              textAlign="center"
              fontWeight={200}
              fontSize="h3"
              lineHeight="2.25rem"
              data-ref="copy"
              mb={6}
            >
              {i18n.t('error.pageNotFound')}
            </Text>
            <Button
              variant="primary"
              type="button"
              onClick={this.handleHomeClick}
              mb={4}
              bgColor="cerulean"
              borderRadius="secondary"
              paddingInline={8}
              fontSize="0.813rem"
            >
              {i18n.t('common.returnToKnowledge')}
            </Button>
          </FixedWidthWrapper>
        </Flex>
      </Flex>
    );
  }
}

export default withRouter(PageNotFound);
