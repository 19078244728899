import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import PostActionBar from 'app/components/PostActionBar';
import { isPending } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectAnswerById,
  selectApiStatus
} from 'app/models/posts/postsSelectors';
import permissions from 'app/services/permissionsService';

export interface Props {
  // props
  answerId: string;
  onEdit: () => void;
  // state
  answer: Map<any, any>;
  deletePostStatus: Map<any, any>;
  // dispatch
  reportPost: (postId: string, reasonId: string) => void;
  deleteAnswer: (postId: string, parentId: string) => void;
  adminDeletePost: (postId: string, parentId: string, reasonId: string) => void;
}

const mapStateToProps = (state, ownProps) => {
  return {
    answer: selectAnswerById(state, ownProps.answerId),
    deletePostStatus: selectApiStatus(state, 'deletePost')
  };
};

const mapDispatchToProps = {
  deleteAnswer: postsActions.deletePost,
  adminDeletePost: postsActions.adminDeletePost,
  reportPost: postsActions.reportPost
};

export class AnswerActionBarContainer extends React.Component<Props> {
  report = (reasonId: string) => {
    const { answerId, reportPost } = this.props;
    reportPost(answerId, reasonId);
  };

  delete = () => {
    const { answerId, answer, deleteAnswer } = this.props;
    deleteAnswer(answerId, answer.get('parentId'));
  };

  adminDelete = (reasonId: string) => {
    const { answerId, answer, adminDeletePost } = this.props;
    adminDeletePost(answerId, reasonId, answer.get('parentId'));
  };

  render() {
    const { answerId, deletePostStatus, onEdit, ...props } = this.props;

    const canDelete = permissions.canDeletePost(answerId);
    const canEdit = permissions.canEditAnswer(answerId);
    const handleDelete = canDelete ? this.delete : null;
    const handleEdit = canEdit ? onEdit : null;
    const deletePostPending = isPending(deletePostStatus);
    const canDeletePostAdmin = permissions.canDeletePostAdmin();
    const handleAdminDeletePost = canDeletePostAdmin ? this.adminDelete : null;
    const handleReport = permissions.canReport(answerId) ? this.report : null;

    return (
      <PostActionBar
        allowComments
        postId={answerId}
        handleEdit={handleEdit}
        handleReport={handleReport}
        handleDelete={handleDelete}
        isDeletePostPending={deletePostPending}
        handleAdminDeletePost={handleAdminDeletePost}
        type="answer"
        {...props}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnswerActionBarContainer);
