import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  color?: 'silver-lightest' | 'white';
}

export default class FullWidthWrapper extends React.PureComponent<Props> {
  render() {
    const { children, color = 'white' } = this.props;
    return (
      <Box minW="full" bgColor={color}>
        {children}
      </Box>
    );
  }
}
