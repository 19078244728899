import { Heading, Image } from '@chakra-ui/react';
import React from 'react';
import { CloseIcon } from '@udacity/chakra-veritas-icons';

import Portal from 'app/components/Portal';
import { PageLayoutContainer } from 'app/containers/PageLayoutContainer';

import {
  CloseButton,
  LightboxContainer,
  LightboxContent,
  LightboxHeader
} from './factoryComponents';
import i18n from 'i18n';

export interface Props {
  handleClose: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  filename: string;
  src?: string;
}

export default class ImageLightboxPortal extends React.PureComponent<Props> {
  render() {
    const { handleClose, filename, src } = this.props;

    return (
      <Portal>
        <LightboxContainer>
          <PageLayoutContainer>
            <LightboxHeader>
              <Heading size="h5" as="h5">
                {filename}
              </Heading>
              <CloseButton
                aria-label={i18n.t('image.close')}
                icon={<CloseIcon w={8} h={8} color="silver" />}
                onClick={handleClose}
              />
            </LightboxHeader>

            <LightboxContent>
              <Image
                src={src}
                alt={i18n.t('image.userContent', { filename: filename })}
                maxW="full"
              />
            </LightboxContent>
          </PageLayoutContainer>
        </LightboxContainer>
      </Portal>
    );
  }
}
