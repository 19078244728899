import { List, Map, fromJS } from 'immutable';

import { actionTypes as activityActions } from 'app/models/activity/activityActions';
import { mergeEntities } from 'app/models/entities/entitiesReducerHelpers';
import { actionTypes as postActions } from 'app/models/posts/postsActions';
import { actionTypes as searchActions } from 'app/models/search/searchActions';

type State = Map<string, any>;

export const initialState: State = fromJS({
  byId: {}
});

const deleteQuestionAnswersOrComments = (
  state: State = initialState,
  postId: string,
  parentId?: string
) => {
  if (state.getIn(['byId', parentId, 'answers'], List([])).includes(postId)) {
    return state.updateIn(['byId', parentId, 'answers'], (list) =>
      list.filter((id) => id !== postId)
    );
  }
  if (state.getIn(['byId', parentId, 'comments'], List([])).includes(postId)) {
    return state.updateIn(['byId', parentId, 'comments'], (list) =>
      list.filter((id) => id !== postId)
    );
  }

  const archivedPost = { postId, postType: 'archivedPost' };
  return state.updateIn(['byId', postId], () => fromJS(archivedPost));
};

export default function reducer(state: State = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case postActions.UPDATE_QUESTION_FULFILLED:
    case postActions.CREATE_QUESTION_FULFILLED:
    case postActions.FETCH_QUESTION_FULFILLED: {
      const { questions } = payload.entities;
      return mergeEntities(state, questions);
    }

    case postActions.DELETE_POST_FULFILLED: {
      const { postId, parentId } = payload;
      return deleteQuestionAnswersOrComments(state, postId, parentId);
    }

    case postActions.ADMIN_DELETE_POST_FULFILLED: {
      const { postId, parentId } = payload;
      return deleteQuestionAnswersOrComments(state, postId, parentId);
    }

    case searchActions.SEARCH_QUESTIONS_FULFILLED: {
      const { questions } = payload.results.entities;
      return mergeEntities(state, questions);
    }

    case activityActions.FETCH_QUESTIONS_FULFILLED: {
      const { questions } = payload.entities;
      return mergeEntities(state, questions);
    }

    case postActions.CREATE_ANSWER_FULFILLED: {
      const { questionId, answerId } = payload;
      return state.updateIn(
        ['byId', questionId, 'answers'],
        (answerIds: List<any>) => {
          return answerIds.push(answerId);
        }
      );
    }

    case postActions.CREATE_COMMENT_FULFILLED: {
      const { parentId, commentId } = payload;
      if (state.getIn(['byId', parentId])) {
        return state.updateIn(
          ['byId', parentId, 'comments'],
          (commentIds: List<any>) => {
            return commentIds.push(commentId);
          }
        );
      }
      return state;
    }

    case postActions.UPVOTE_POST_FULFILLED:
    case postActions.DOWNVOTE_POST_FULFILLED:
    case postActions.CLEARVOTE_POST_FULFILLED: {
      const { id, score, myVote } = payload;
      if (state.getIn(['byId', id])) {
        return state.mergeIn(['byId', id], {
          score,
          myVote
        });
      }
      return state;
    }

    case postActions.VERIFY_ANSWER_FULFILLED: {
      const { id, parentId } = payload;
      if (state.getIn(['byId', parentId])) {
        return state.setIn(['byId', parentId, 'verifiedAnswerId'], id);
      }
      return state;
    }

    case postActions.UNVERIFY_ANSWER_FULFILLED: {
      const { id, parentId } = payload;
      if (
        state.getIn(['byId', parentId]) &&
        state.getIn(['byId', parentId, 'verifiedAnswerId']) === id
      ) {
        return state.setIn(['byId', parentId, 'verifiedAnswerId'], null);
      }
      return state;
    }

    case postActions.ACCEPT_ANSWER_FULFILLED: {
      const { id, parentId } = payload;
      if (state.getIn(['byId', parentId])) {
        return state.setIn(['byId', parentId, 'acceptedAnswerId'], id);
      }
      return state;
    }

    case postActions.UNACCEPT_ANSWER_FULFILLED: {
      const { id, parentId } = payload;
      if (
        state.getIn(['byId', parentId]) &&
        state.getIn(['byId', parentId, 'acceptedAnswerId']) === id
      ) {
        return state.setIn(['byId', parentId, 'acceptedAnswerId'], null);
      }
      return state;
    }
  }

  return state;
}
