import 'assets/styles/index.scss';

import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { useEffect } from 'react';

import AccessCheckContainer from 'app/containers/AccessCheckContainer';
import ErrorBoundary from 'app/components/ErrorBoundary';
import ToastContainer from 'app/containers/ToastContainer';
import UserSessionContainer from 'app/containers/UserSessionContainer';
import { initDatadog } from 'app/services/datadogService';
import { theme } from 'assets/styles/theme';

import Routes from './Routes';
import store from './store';

const App = () => {
  useEffect(() => {
    initDatadog();
  }, []);

  return (
    <ErrorBoundary>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <UserSessionContainer>
            <AccessCheckContainer>
              <>
                {Routes}
                <ToastContainer />
              </>
            </AccessCheckContainer>
          </UserSessionContainer>
        </Provider>
      </ChakraProvider>
    </ErrorBoundary>
  );
};

export default App;
