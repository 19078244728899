import { Mobiledoc } from 'app/helpers/mobiledocHelpers';
import { isBlankInput } from 'app/helpers/validationHelpers';
import { MAX_TITLE_LENGTH } from 'app/models/posts/postsConstants';
import i18n from 'i18n';
import validation from 'app/services/validationRulesService';

export interface FormData {
  title: string;
  body: Mobiledoc;
  projectLink?: string;
  nanodegreeKey?: string;
  projectId?: number;
  rubricId?: number;
}

export interface QuestionInput {
  formData: {
    title: string;
    body: Mobiledoc;
    projectLink?: string;
  };
  nanodegreeKey?: string;
  project?: {
    projectId: number | undefined;
    rubricId: number | undefined;
  };
}

interface ErrorMessage {
  field: string;
  message?: string | null;
}

export const getErrors = (formInput: QuestionInput) => {
  const {
    formData: { title, body, projectLink = '' },
    nanodegreeKey,
    project
  } = formInput;

  const errors: ErrorMessage[] = [];
  const warnings: ErrorMessage[] = [];

  if (isBlankInput(title) && validation.isTitleQuestionRequired()) {
    errors.push({
      field: 'title',
      message: i18n.t('question.fillOutField')
    });
  } else if (title.length > MAX_TITLE_LENGTH) {
    errors.push({
      field: 'title',
      message: null // fall back to "hintText"
    });
  }

  if (isBlankInput(body) && validation.isBodyQuestionRequired()) {
    errors.push({
      field: 'body',
      message: i18n.t('question.addDetails')
    });
  }

  if (!nanodegreeKey && validation.isNanodegreeQuestionRequired()) {
    errors.push({ field: 'nanodegree' });
  }

  if (!project) {
    if (!nanodegreeKey && validation.isProjectQuestionWarning()) {
      warnings.push({ field: 'project' });
    }
    if (nanodegreeKey && validation.isProjectQuestionRequired()) {
      errors.push({ field: 'project' });
    }
  }

  if (isBlankInput(projectLink)) {
    warnings.push({ field: 'projectLink' });
  }

  return {
    errors,
    warnings
  };
};

export function buildFormData(formInput: QuestionInput): FormData {
  return {
    ...formInput.formData,
    ...formInput.project,
    nanodegreeKey: formInput.nanodegreeKey
  };
}
