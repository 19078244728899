import _ from 'lodash';

import ENV from 'config';

const PAGE_SIZE = _.includes(['development', 'staging'], ENV.REACT_APP_ENV)
  ? 5
  : 20;

const ALL_RESULTS = 1000;

export default {
  PAGE_SIZE,
  ALL_RESULTS,
  START_PAGE: 1,
  HIGHLIGHT_OPEN_TAG: '[highlight]',
  HIGHLIGHT_CLOSE_TAG: '[/highlight]'
};
