import { Flex, Text } from '@chakra-ui/react';
import { PureComponent } from 'react';
import { ImageIcon } from '@udacity/chakra-veritas-icons';

import { FILE_SIZE_LIMIT } from 'app/models/files/filesConstants';
import { formatBytes } from 'app/models/files/filesHelpers';

import i18n from 'i18n';

export default class ImagePlaceholder extends PureComponent<{}, {}> {
  render() {
    return (
      <Flex
        data-testid="image-placeholder"
        direction="column"
        justify="center"
        align="center"
        bgColor="white"
        color="slate"
        border="2px dashed"
        borderColor="silver-lighter"
        p={8}
      >
        <Flex
          justify="center"
          align="center"
          borderRadius="circle"
          m="0 auto 1rem"
          bgColor="silver-lightest"
          w="5rem"
          h="5rem"
        >
          <ImageIcon w={8} h={8} color="silver" />
        </Flex>

        <Text
          size="xs"
          fontWeight="bold"
          color="cerulean"
          lineHeight={1}
          letterSpacing="1px"
          textTransform="uppercase"
        >
          Select an image
        </Text>

        <Text size="sm" lineHeight={1} mt={4}>
          {i18n.t('image.maxSize', { size: formatBytes(FILE_SIZE_LIMIT) })}
        </Text>
      </Flex>
    );
  }
}
