import _every from 'lodash/every';
import React from 'react';

import permissions from 'app/services/permissionsService';

export interface Props {
  children: React.ReactNode;
  showIf: {};
}

export default class PermissionsServiceContainer extends React.PureComponent<
  Props
> {
  render() {
    const { showIf, children } = this.props;

    const shouldRenderChildren = _every(
      showIf,
      (value: boolean, key: string) => {
        return permissions[key]() === value;
      }
    );

    return shouldRenderChildren ? children : null;
  }
}
