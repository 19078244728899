import gql from 'app/models/helpers/gql';
import { RatingInput } from './ratingsTypes';

export function createRating({
  postId,
  input
}: {
  postId: string;
  input: RatingInput;
}) {
  const request = {
    query: `mutation CreateRating($postId: String!, $input: RatingInput) {
      createRating(postId: $postId, input: $input) {
        rating {
          postId
        }
      }
    }`,
    variables: { postId, input }
  };

  return gql(request).then((res) => res.data);
}
