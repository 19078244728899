import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import InvisibleInput from 'app/components/InvisibleInput';

import CodeBlockLayout from './CodeBlockLayout';
import { CodeBlockPayload } from './codeBlockCardTypes';
import { theme } from 'assets/styles/theme';

export interface Props extends CodeBlockPayload {
  isEditing?: boolean;
  isInEditor?: boolean;
  save?: (arg1: { content: string }, arg2: boolean) => void;
  edit?: () => void;
}

interface State {
  content: string;
}

export default class CodeBlock extends React.PureComponent<Props, State> {
  /* Required mobiledoc attributes */
  static type = 'dom';

  static displayName = 'Code';
  /* End mobiledoc attributes */

  state = {
    content: ''
  };

  componentDidMount() {
    const { payload, edit, isInEditor } = this.props;

    this.setState({ content: payload.content });

    if (isInEditor && edit) {
      edit();
    }
  }

  handleInput = (e: React.SyntheticEvent) => {
    const { save } = this.props;
    const { value } = e.target as HTMLInputElement;

    this.setState({ content: value });

    if (save) {
      save({ content: value }, false);
    }
  };

  render() {
    const { isEditing, payload, isInEditor } = this.props;
    const { content } = this.state;

    const textInput = (
      <InvisibleInput
        focusOnLoad={!!(isInEditor && isEditing)}
        handleInput={this.handleInput}
        defaultValue={payload.content}
      />
    );

    return (
      <ChakraProvider theme={theme}>
        <CodeBlockLayout textInput={textInput} isEditing={!!isEditing}>
          {content}
        </CodeBlockLayout>
      </ChakraProvider>
    );
  }
}
