import { Map, fromJS } from 'immutable';
import _noop from 'lodash/noop';
import React from 'react';
import { connect } from 'react-redux';

import QuestionFormContainer from 'app/containers/questionFormContainers/QuestionFormContainer';
import { selectIsImageUploadPending } from 'app/models/files/filesSelectors';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectCurrentQuestion
} from 'app/models/posts/postsSelectors';

import { FormData } from '../questionFormContainerHelpers';
import i18n from 'i18n';

interface StateProps {
  currentQuestion: Map<string, {}>;
  apiStatus: Map<string, string>;
  isImageUploadPending: boolean;
}

interface DispatchProps {
  createQuestion: (formData: FormData) => void;
  resetCreateQuestion: () => void;
}

export type Props = StateProps & DispatchProps;

const mapStateToProps = (state): StateProps => ({
  currentQuestion: selectCurrentQuestion(state),
  apiStatus: selectApiStatus(state, 'createQuestion'),
  isImageUploadPending: selectIsImageUploadPending(state)
});

const mapDispatchToProps: DispatchProps = {
  createQuestion: postsActions.createQuestion,
  resetCreateQuestion: postsActions.resetCreateQuestion
};

export class NewQuestionFormContainer extends React.PureComponent<Props> {
  static defaultProps = {
    currentQuestion: Map({})
  };

  componentWillUnmount() {
    const { resetCreateQuestion = _noop } = this.props;
    resetCreateQuestion();
  }

  render() {
    const {
      apiStatus,
      currentQuestion = fromJS({}),
      createQuestion,
      isImageUploadPending
    } = this.props;

    return (
      <QuestionFormContainer
        questionId={currentQuestion.get('id')}
        pageTitle={i18n.t('question.askTechnicalMentor')}
        apiStatus={apiStatus}
        questionMutation={createQuestion}
        isImageUploadPending={isImageUploadPending}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(NewQuestionFormContainer);
