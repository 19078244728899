import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import PostActionBar from 'app/components/PostActionBar';
import { isPending } from 'app/models/helpers/apiStatusHelpers';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectApiStatus,
  selectCommentById
} from 'app/models/posts/postsSelectors';
import permissions from 'app/services/permissionsService';

interface StateProps {
  comment: Map<any, any>;
  deletePostStatus: Map<any, any>;
  moveCommentToAnswerStatus: Map<any, any>;
}

interface DispatchProps {
  deleteComment: (commentId: string, parentId: string) => void;
  adminDeletePost: (postId: string, parentId: string, reasonId: string) => void;
  reportPost: (postId: string, reasonId: string) => void;
  moveCommentToAnswer: (commentId: string, parentId: string) => void;
}

interface OwnProps {
  commentId: string;
  onEdit: () => void;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state, ownProps): StateProps => {
  return {
    comment: selectCommentById(state, ownProps.commentId),
    deletePostStatus: selectApiStatus(state, 'deletePost'),
    moveCommentToAnswerStatus: selectApiStatus(state, 'moveCommentToAnswer')
  };
};

const mapDispatchToProps: DispatchProps = {
  deleteComment: postsActions.deletePost,
  adminDeletePost: postsActions.adminDeletePost,
  reportPost: postsActions.reportPost,
  moveCommentToAnswer: postsActions.moveCommentToAnswer
};

export class CommentActionBarContainer extends React.Component<Props> {
  delete = () => {
    const { commentId, comment, deleteComment } = this.props;
    deleteComment(commentId, comment.get('parentId'));
  };

  adminDelete = (reasonId: string) => {
    const { commentId, comment, adminDeletePost } = this.props;
    adminDeletePost(commentId, reasonId, comment.get('parentId'));
  };

  report = (reasonId: string) => {
    const { commentId, reportPost } = this.props;
    reportPost(commentId, reasonId);
  };

  moveCommentToAnswer = () => {
    const { commentId, comment, moveCommentToAnswer } = this.props;

    moveCommentToAnswer(commentId, comment.get('parentId'));
  };

  render() {
    const {
      commentId,
      deletePostStatus,
      onEdit,
      moveCommentToAnswerStatus
    } = this.props;

    const canMoveCommentToAnswer = permissions.canMoveCommentToAnswer();
    const canEdit = permissions.canEditComment(commentId);
    const canDelete = permissions.canDeletePost(commentId);

    const handleDelete = canDelete ? this.delete : null;
    const handleEdit = canEdit ? onEdit : null;
    const handleMoveCommentToAnswer = canMoveCommentToAnswer
      ? this.moveCommentToAnswer
      : null;
    const isDeletePostPending = isPending(deletePostStatus);
    const isMoveCommentToAnswerPending = isPending(moveCommentToAnswerStatus);
    const canDeletePostAdmin = permissions.canDeletePostAdmin();
    const handleAdminDeletePost = canDeletePostAdmin ? this.adminDelete : null;
    const handleReport = permissions.canReport(commentId) ? this.report : null;

    return (
      <PostActionBar
        postId={commentId}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        isDeletePostPending={isDeletePostPending}
        handleAdminDeletePost={handleAdminDeletePost}
        handleReport={handleReport}
        handleMoveCommentToAnswer={handleMoveCommentToAnswer}
        isMoveCommentToAnswerPending={isMoveCommentToAnswerPending}
        type="comment"
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(CommentActionBarContainer);
