import { List, Map } from 'immutable';
import _isUndefined from 'lodash/isUndefined';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { selectEntities } from 'app/models/entities/entitiesSelectors';
import { isPending } from 'app/models/helpers/apiStatusHelpers';

import {
  commentListSchema,
  moderatePostReasonsSchema,
  questionSchema,
  answerListSchema
} from './postsNormalizers';

export const selectCurrentQuestionId = (state: Map<string, string>) =>
  state.getIn(['posts', 'question']);

export const selectQuestions = (state: Map<string, string>) =>
  state.getIn(['entities', 'questions', 'byId']);

export const selectQuestionById = (
  state: Map<string, string>,
  postId: string
) => {
  return state.getIn(['entities', 'questions', 'byId', postId]);
};

export const selectQuestionForm = (state: Map<string, any>) => {
  return state.getIn(['posts', 'questionForm']);
};

export const selectLatestFileInputRef = createSelector(
  selectQuestionForm,
  (questionFormRefs) => {
    return questionFormRefs.get('latestFileInputRef');
  }
);

export const selectAnswerById = (
  state: Map<string, string>,
  postId: string
) => {
  return state.getIn(['entities', 'answers', 'byId', postId]);
};

export const selectCommentById = (
  state: Map<string, string>,
  postId: string
) => {
  return state.getIn(['entities', 'comments', 'byId', postId]);
};

export const selectModeratePostReasonsIds = (state: Map<string, string>) => {
  return state.getIn(['posts', 'moderatePostReasons']);
};

export const selectModeratePostReasons = createSelector(
  selectModeratePostReasonsIds,
  selectEntities,
  (moderatePostReasonsIds, entities) => {
    if (!moderatePostReasonsIds.size) {
      return List([]);
    }

    return denormalize(
      moderatePostReasonsIds,
      moderatePostReasonsSchema,
      entities
    );
  }
);

export const selectCurrentQuestion = createSelector(
  selectCurrentQuestionId,
  selectQuestions,
  selectEntities,
  (questionId, questions, entities) => {
    if (!questionId) {
      return undefined;
    }
    const question = questions.get(questionId);
    if (!question) {
      return undefined;
    }
    return denormalize(question, questionSchema, entities);
  }
);

export const selectPostById = createSelector(
  selectQuestionById,
  selectAnswerById,
  selectCommentById,
  (question, answer, comment) => {
    if (question) {
      return question;
    }
    if (answer) {
      return answer;
    }
    if (comment) {
      return comment;
    }
    return Map({});
  }
);

export const selectSiblingAnswerIds = createSelector(
  selectQuestionById,
  (question) => {
    return question.get('answers');
  }
);

export const selectAnswer = createSelector(
  selectAnswerById,
  (answer) => answer
);

export const selectComment = createSelector(
  selectCommentById,
  (comment) => comment
);

export const selectComments = createSelector(
  selectPostById,
  selectEntities,
  (post, entities) => {
    if (!post || post === Map({})) {
      return List([]);
    }

    const commentIds = post.get('comments', List([]));
    const denormalized = denormalize(commentIds, commentListSchema, entities);

    return denormalized;
  }
);

export const selectIsQuestionArchived = createSelector(
  selectPostById,
  (post) => {
    return post.get('isArchived', false);
  }
);

export const selectCommentsCount = createSelector(selectPostById, (post) => {
  if (!post || post === Map({})) {
    return 0;
  }

  return post.get('comments', List).size;
});

export const selectIsThreadExpanded = createSelector(
  (state: Map<string, string>, postId: string) =>
    state.getIn(['posts', 'commentThreadsExpanded', postId]),
  (isExpanded = false) => isExpanded
);

export const selectIsAnswerFormExpanded = createSelector(
  (state: Map<string, string>, postId: string) =>
    state.getIn(['posts', 'answerFormsExpanded', postId]),
  (isExpanded = false) => isExpanded
);

export const selectIsCommentFormExpanded = (
  state: Map<string, string>,
  postId: string
) => {
  return state.getIn(['posts', 'commentFormsExpanded', postId]);
};

export const selectApiStatus = createSelector(
  (state: Map<string, string>, method?: string) => {
    if (method) {
      const methodState = state.getIn(['postsApi', method]);
      if (_isUndefined(methodState)) {
        throw Error(`Key "${method}" not found on postsApi.`);
      }
      return methodState;
    }
    return state.get('postsApi');
  },
  (status) => status
);

export const selectIsVotePending = createSelector(
  selectApiStatus,
  (apiStatus) => {
    return (
      isPending(apiStatus.get('upvotePost')) ||
      isPending(apiStatus.get('downvotePost')) ||
      isPending(apiStatus.get('clearvotePost'))
    );
  }
);

export const selectIsAcceptPending = createSelector(
  selectApiStatus,
  (apiStatus) => {
    return (
      isPending(apiStatus.get('acceptAnswer')) ||
      isPending(apiStatus.get('unacceptAnswer'))
    );
  }
);

export const selectIsVerifyPending = createSelector(
  selectApiStatus,
  (apiStatus) => {
    return (
      isPending(apiStatus.get('verifyAnswer')) ||
      isPending(apiStatus.get('unverifyAnswer'))
    );
  }
);

export const selectAnswers = createSelector(
  selectPostById,
  selectEntities,
  (post, entities) => {
    if (!post || post === Map({})) {
      return List([]);
    }

    const answerIds = post.get('answers', List([]));
    const denormalized = denormalize(answerIds, answerListSchema, entities);
    return denormalized;
  }
);

export const selectMostRecentAnswer = createSelector(
  selectAnswers,
  (answers) => {
    const mostRecent = answers
      .filter((a) => a.get('fromMentor'))
      .sort(
        (firstAnswer, secondAnswer) =>
          firstAnswer.get('createdAt') - secondAnswer.get('createdAt')
      );

    return mostRecent.last();
  }
);
