import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import _find from 'lodash/find';
import {
  selectCertifications,
  selectCurrentUserIsStaff
} from 'app/models/session/sessionSelectors';
import {
  isPseudoNanodegreeKey,
  makePseudoNanodegree
} from './nanodegreeHelpers';
import { NanodegreeState } from './nanodegreeReducer';
import {
  Nanodegree,
  PseudoNanodegreeType,
  ReviewsProject
} from './nanodegreeTypes';

const selectNanodegrees = (state: Map<string, NanodegreeState>) => {
  return state.get('nanodegrees');
};

const selectEnrollmentsByKey = createSelector(
  selectNanodegrees,
  (ndState: NanodegreeState): { [key: string]: Nanodegree } => {
    return ndState.enrollmentsByKey;
  }
);

export const selectEnrollmentByKey = (
  state: Map<string, NanodegreeState>,
  ndKey?: string
): Nanodegree | undefined => {
  const enrollments = selectEnrollmentsByKey(state);
  const selectedEnrollment = _find(enrollments, { key: ndKey });

  return selectedEnrollment;
};

const selectGraduatedByKey = createSelector(
  selectNanodegrees,
  (ndState: NanodegreeState): { [key: string]: Nanodegree } => {
    return ndState.graduatedByKey;
  }
);

export const selectEnrolledNanodegrees = createSelector(
  selectEnrollmentsByKey,
  (enrollmentsByKey) => Object.values(enrollmentsByKey)
);

export const selectGraduatedNanodegrees = createSelector(
  selectGraduatedByKey,
  (graduatedByKey) => Object.values(graduatedByKey)
);

const selectNodeTitlesByKey = createSelector(
  selectNanodegrees,
  (ndState: NanodegreeState): { [key: string]: string } => {
    return ndState.nodeTitlesByKey;
  }
);

export const selectNodeTitle = (
  state: Map<string, NanodegreeState>,
  ndKey: string
) => {
  return selectNodeTitlesByKey(state)[ndKey];
};

export const selectReviewsProjectsById = createSelector(
  selectNanodegrees,
  (ndState: NanodegreeState): { [id: number]: ReviewsProject } => {
    return ndState.reviewsProjectsById;
  }
);

export const selectProjectTitle = (
  state: Map<string, NanodegreeState>,
  id: number
) => {
  const reviewsProject = selectReviewsProjectsById(state)[id];
  return reviewsProject ? reviewsProject.title : undefined;
};

export const selectPseudoNanodegrees = createSelector(
  selectCertifications,
  selectCurrentUserIsStaff,
  selectReviewsProjectsById,
  (
    certificationList: List<number>,
    isStaff: boolean,
    reviewsProjectsById: { [id: number]: ReviewsProject }
  ): Nanodegree[] => {
    const pseudoNanodegrees: Nanodegree[] = [];

    const certifications = certificationList
      ? certificationList.toJS()
      : undefined;

    if (certifications && certifications.length > 0) {
      const certifiedProjectIdTitlePairs = certifications
        .map((id) => {
          const reviewsProject = reviewsProjectsById[id];
          return {
            id,
            title: reviewsProject && reviewsProject.title
          };
        })
        .sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));

      pseudoNanodegrees.push(
        makePseudoNanodegree(
          PseudoNanodegreeType.CERTIFICATIONS,
          certifiedProjectIdTitlePairs
        )
      );
    }

    if (isStaff) {
      const allProjectIdTitlePairs = Object.values(reviewsProjectsById)
        .map((reviewsProject) => ({
          id: reviewsProject.id,
          title: reviewsProject.title
        }))
        .sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));
      pseudoNanodegrees.push(
        makePseudoNanodegree(PseudoNanodegreeType.ANY, allProjectIdTitlePairs)
      );
    }

    return pseudoNanodegrees;
  }
);

export const selectProjectIdsByNanodegreeKey = (
  state: Map<string, NanodegreeState>,
  key: string
): number[] => {
  let ndForKey: Nanodegree | undefined;

  if (isPseudoNanodegreeKey(key)) {
    const pseudoNanodegrees = selectPseudoNanodegrees(state);
    ndForKey = pseudoNanodegrees.find((nd) => nd.key === key);
  } else {
    ndForKey =
      selectEnrollmentsByKey(state)[key] || selectGraduatedByKey(state)[key];
  }

  return ndForKey ? ndForKey.projects.map((p) => p.reviewsProjectId) : [];
};

export const selectHasAnyNanodegreeWithKnowledgeReviews = createSelector(
  selectEnrolledNanodegrees,
  selectGraduatedNanodegrees,
  (enrolled, graduated) => {
    return enrolled.concat(graduated).some((nd) => nd.hasKnowledgeReviews);
  }
);

export const selectApiStatus = createSelector(
  (state, method?: string) => {
    if (method) {
      const methodState = state.getIn(['nanodegreeApi', method]);

      if (methodState === undefined) {
        throw Error(`Key "${method}" not found on nanodegreeApi.`);
      }

      return methodState;
    }

    return state.get('nanodegreeApi');
  },
  (status) => status
);
