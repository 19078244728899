export const Modal = {
  sizes: {
    mobile: {
      dialog: {
        width: 'calc(100vw - 2rem)'
      }
    },
    tablet: {
      dialog: {
        width: '36.5rem'
      }
    },
    desktop: {
      dialog: {
        width: '50rem'
      }
    }
  },
  baseStyle: {
    dialog: {
      overflowY: 'auto',
      maxH: '80vh',
      maxW: '50rem'
    }
  },
  defaultProps: {
    size: 'tablet'
  }
};
