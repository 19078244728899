import { Text } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';

import pencilIllustration from 'assets/images/illustration-pencil.svg';
import ActionModal from 'app/components/modals/ActionModal';
import ActionModalButtons from 'app/components/modals/ActionModal/ActionModalButtons';

import i18n from 'i18n';

export interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  isQuestionMutationPending: boolean;
}

export default class IncompletePostModal extends React.PureComponent<Props> {
  render() {
    const { onConfirm, onCancel, isQuestionMutationPending } = this.props;
    return (
      <ActionModal
        headerText={i18n.t('post.modifyToIncreaseAnswers')}
        headerIcon={
          <img
            src={pencilIllustration}
            width="70"
            alt={i18n.t('post.continueEditingPost')}
          />
        }
        theme="blue"
        onCancel={onCancel}
      >
        <Text lineHeight="base" mb={6}>
          <Trans i18nKey="post.recommendAddingLink">
            We recommend adding a <strong>Github or Project Link</strong>
            if applicable.
          </Trans>
        </Text>
        <ActionModalButtons
          theme="red"
          disabled={isQuestionMutationPending}
          primary={{
            text: i18n.t('post.postAsIs'),
            callback: onConfirm
          }}
          secondary={{
            text: i18n.t('post.continueEditing'),
            callback: onCancel
          }}
        />
      </ActionModal>
    );
  }
}
