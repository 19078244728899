import { all, call, put, takeEvery } from 'redux-saga/effects';

import { identify } from 'app/helpers/analyticsHelper';
import { createErrorAction } from 'app/models/helpers/actionHelper';

import { actionTypes } from './sessionActions';
import { actionCreators as unreadsActionCreators } from 'app/models/unreads/unreadsActions';
import * as requests from './sessionExternalRequests';

let isBootstrapped = false;

export function* bootstrap() {
  try {
    const me = yield call(requests.getMe);
    const {
      user: { id, displayName }
    } = me;

    if (!isBootstrapped) {
      isBootstrapped = true;

      // Segment integration
      identify({ id, name: displayName });

      yield put(unreadsActionCreators.startPollUnreads());
    }

    yield put({
      payload: me,
      type: actionTypes.BOOTSTRAP_FULFILLED
    });
  } catch (error) {
    yield put(
      createErrorAction(actionTypes.BOOTSTRAP_REJECTED, error as Error)
    );
  }
}

export function* watchBootstrap() {
  yield takeEvery(actionTypes.BOOTSTRAP_START, bootstrap);
}

// root saga loads watcher sagas into middlware
export default function* moduleRoot() {
  yield all([watchBootstrap()]);
}
