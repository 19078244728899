import _ from 'lodash';

import { activeKeys } from 'app/helpers/trackKeypress';

/*
 * Helper functions for tab key input behavior within a <textarea>
 */

/*
 * Primary entry point.
 * Insert tab (spaces) into current text line or selected range of lines.
 * Arguments: textarea currently typing in
 * Returns: content with appropriate spaces added
 */
export function insertTab(textarea: HTMLTextAreaElement): string {
  const { value } = textarea;
  const caretRange = getCaret(textarea);

  const tabbedValue = _.map(value.split('\n'), (line, lineNumber) => {
    const linePos = caretPosInLine(lineNumber, caretRange, value);
    if (linePos > -1) {
      if (activeKeys.get('Shift')) {
        return removeTabFromStart(line);
      }
      return insertTabAtPos(line, linePos);
    }
    return line;
  });

  return tabbedValue.join('\n');
}

export function hasSelection(range: { start: number; end: number }): boolean {
  return !(range.start === range.end);
}

/*
 * Read cursor start and end position within element
 */
export function getCaret(
  element: HTMLTextAreaElement
): { start: number; end: number } {
  return {
    start: element.selectionStart,
    end: element.selectionEnd
  };
}

export function insertTabAtPos(content: string, pos: number): string {
  const spaces = pos % 2 ? ' ' : '  '; // enforce tab stops every two characters
  return [content.substr(0, pos), spaces, content.substr(pos)].join('');
}

export function removeTabFromStart(line: string): string {
  if (line.substr(0, 2) === '  ') {
    return line.substr(2);
  }
  return _.trimStart(line);
}

// return -1 if line is not a match
// return 0 for start of line
// return int for character pos within line
export function caretPosInLine(
  lineNumber: number,
  caretRange: { start: number; end: number },
  value: string
): number {
  const textBeforeRange = value.substr(0, caretRange.start);
  const textInRange = value.substr(
    caretRange.start,
    caretRange.end - caretRange.start
  );
  const linesBeforeRange = textBeforeRange.split('\n');
  const linesInRange = textInRange.split('\n');

  if (hasSelection(caretRange)) {
    const lineSelection = {
      start: _.initial(linesBeforeRange).length,
      count: linesInRange.length
    };

    const isWithinRange =
      lineNumber >= lineSelection.start &&
      lineNumber < lineSelection.start + lineSelection.count;

    // when text is selected, return 0 for start of line
    return isWithinRange ? 0 : -1;
  }

  const lastLineBeforeRange = _.last(linesBeforeRange) || '';

  // no text selected, return cursor position within line
  return linesBeforeRange.length === lineNumber + 1
    ? lastLineBeforeRange.length
    : -1;
}
