import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import ArchivedPostPage from 'app/components/ArchivedPostPage';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import permissions from 'app/services/permissionsService';

interface LocationProps extends RouteComponentProps<any> {}

interface DispatchProps {
  unarchivePost: (questionId: string) => void;
}

export type Props = DispatchProps & LocationProps;

const mapDispatchToProps: DispatchProps = {
  unarchivePost: postsActions.unarchivePost
};

export class ArchivedPostPageContainer extends React.Component<Props> {
  handleRestore = (): void => {
    const {
      unarchivePost,
      match: {
        params: { questionId }
      }
    } = this.props;
    if (questionId) {
      unarchivePost(questionId);
    }
  };

  render() {
    const canUnarchivePost = permissions.canDeletePostAdmin();

    return (
      <ArchivedPostPage
        handleRestore={this.handleRestore}
        canUnarchivePost={canUnarchivePost}
      />
    );
  }
}

export default withRouter(
  connect<{}, DispatchProps, {}>(
    null,
    mapDispatchToProps
  )(ArchivedPostPageContainer)
);
