import React from 'react';
import { connect } from 'react-redux';

import { actionCreators } from 'app/models/ui/uiActions';
import { selectIsDraggingFile } from 'app/models/ui/uiSelectors';

interface OwnProps {
  children: React.ReactNode;
}

interface StateProps {
  isDraggingFile: boolean;
}

interface DispatchProps {
  fileDragStart: () => void;
  fileDragEnd: () => void;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state): StateProps => ({
  isDraggingFile: selectIsDraggingFile(state)
});

const mapDispatchToProps: DispatchProps = {
  fileDragStart: actionCreators.fileDragStart,
  fileDragEnd: actionCreators.fileDragEnd
};

export class DragAndDropAppContainer extends React.Component<Props> {
  exitTimeout: NodeJS.Timer;

  handleDrop = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { fileDragEnd } = this.props;
    fileDragEnd();
  };

  handleDragStart = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { fileDragStart, isDraggingFile } = this.props;

    clearTimeout(this.exitTimeout);

    if (!isDraggingFile) {
      fileDragStart();
    }
  };

  handleDragEnd = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const { fileDragEnd, isDraggingFile } = this.props;
    if (isDraggingFile) {
      this.exitTimeout = setTimeout(fileDragEnd, 50);
    }
  };

  render() {
    const { children } = this.props;
    return (
      <div
        data-testid="drag-drop-container"
        onDragOver={this.handleDragStart}
        onDragLeave={this.handleDragEnd}
        onDrop={this.handleDrop}
      >
        {children}
      </div>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(DragAndDropAppContainer);
