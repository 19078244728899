import { ChakraProvider } from '@chakra-ui/react';
import _has from 'lodash/has';
import _isFunction from 'lodash/isFunction';
import React from 'react';
import { Provider } from 'react-redux';

import { generateFileIdentifierString } from 'app/models/files/filesHelpers';
import { RenderLocalArgs } from 'app/models/files/filesTypes';
import store from 'app/store';
import { theme } from 'assets/styles/theme';

import ImageCardContainer from './ImageCardContainer';
import { ImageCardPayload } from './imageCardTypes';

export interface Props extends ImageCardPayload {
  isEditing?: boolean;
  isInEditor?: boolean;
  save?: (arg1: ImageCardPayload['payload'], arg2: boolean) => void;
  edit?: () => void;
}

export default class ImageCard extends React.PureComponent<
  Props,
  ImageCardPayload
> {
  /* Required mobiledoc attributes */
  static type = 'dom';

  static displayName = 'Image';
  /* End mobiledoc attributes */

  constructor(props) {
    super(props);

    const { payload } = this.props;

    this.state = {
      payload
    };
  }

  componentDidMount() {
    const { isInEditor, payload, edit } = this.props;

    if (isInEditor && !_has(payload, 'desktopSrc') && _isFunction(edit)) {
      edit();
    }
  }

  handleFileSelect = (fileRef) => {
    this.setState({
      payload: {
        ...this.state.payload,
        fileIdentifierString: generateFileIdentifierString(fileRef)
      }
    });
  };

  handleSave = (newPayload: ImageCardPayload['payload']) => {
    const { save } = this.props;
    if (_isFunction(save)) {
      save(newPayload, true); // true = transition out of edit mode
    }
  };

  renderLocalFile = ({
    localImageSrc,
    width,
    height,
    filename
  }: RenderLocalArgs) => {
    this.setState({
      payload: {
        ...this.state.payload,
        filename,
        originalDimensions: { width, height },
        localImageSrc // render local file preview
      }
    });
  };

  render() {
    const { isEditing, isInEditor } = this.props;
    const { payload } = this.state;

    return (
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <ImageCardContainer
            payload={payload}
            isEditing={!!isEditing}
            isInEditor={!!isInEditor}
            handleSave={this.handleSave}
            handleFileSelect={this.handleFileSelect}
            renderLocalFile={this.renderLocalFile}
          />
        </Provider>
      </ChakraProvider>
    );
  }
}
