import {
  createAction,
  createActionType
} from 'app/models/helpers/actionHelper';

import {
  AlgoliaSearchParams,
  SearchClickAnalytics,
  SearchParams
} from 'app/models/search/searchTypes';

export const actionClass = 'SEARCH';

export const actionTypes = {
  INITIALIZE_SEARCH_SESSION: `${actionClass}/INITIALIZE_SEARCH_SESSION`,
  ...createActionType('SEARCH_QUESTIONS', actionClass),
  ...createActionType('SEARCH_RESULT_CLICK', actionClass),
  UPDATE_SEARCH_QUERY_STRING: `${actionClass}/UPDATE_SEARCH_QUERY_STRING`,
  RESET_SEARCH_FILTERS: `${actionClass}/RESET_SEARCH_FILTERS`,
  SET_SEARCH_PARAMS: `${actionClass}/SET_SEARCH_PARAMS`,
  ...createActionType('SET_ALGOLIA_RESULTS', actionClass),
  ...createActionType('RETRIEVE_QUESTION_DATA', actionClass)
};

export const actionCreators = {
  upsertSearchQuestions(payload: any) {
    return createAction(actionTypes.SEARCH_QUESTIONS_FULFILLED, payload);
  },
  initializeSearchSession() {
    return createAction(actionTypes.INITIALIZE_SEARCH_SESSION);
  },
  resetSearchQuestions() {
    return createAction(actionTypes.SEARCH_QUESTIONS_READY);
  },
  resetSearchFilters(params: SearchParams) {
    return createAction(actionTypes.RESET_SEARCH_FILTERS, params);
  },
  setSearchParameters(params: SearchParams) {
    return createAction(actionTypes.SET_SEARCH_PARAMS, params);
  },
  updateSearchQueryString(queryString: string) {
    return createAction(actionTypes.UPDATE_SEARCH_QUERY_STRING, queryString);
  },
  trackSearchClick(id: string, analyticsObject: SearchClickAnalytics) {
    return createAction(actionTypes.SEARCH_RESULT_CLICK_START, {
      id,
      analyticsObject
    });
  },
  setAlgoliaSearchResults(params: AlgoliaSearchParams) {
    return createAction(actionTypes.SET_ALGOLIA_RESULTS_START, params);
  },
  retrieveQuestionData(params: number[]) {
    return createAction(actionTypes.RETRIEVE_QUESTION_DATA_START, params);
  }
};
