import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { FilterIcon } from '@udacity/chakra-veritas-icons';

import SearchFilterContainer from 'app/containers/SearchFilterContainer';

import i18n from 'i18n';

interface State {
  isOpen: boolean;
}

export default class SearchFilterToggle extends React.PureComponent<{}, State> {
  state = {
    isOpen: false
  };

  openOverlay = () => {
    this.setState({
      isOpen: true
    });
  };

  closeOverlay = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { isOpen } = this.state;

    return (
      <>
        {isOpen && (
          <SearchFilterContainer
            handleClose={this.closeOverlay}
            layout="fullscreen"
          />
        )}
        <IconButton
          aria-label={i18n.t('search.filter')}
          icon={<FilterIcon w={6} h={6} color="cerulean" />}
          onClick={this.openOverlay}
          variant="secondary"
          bgColor="white-dark"
          borderRadius="secondary"
          p={{ base: '0 0.5rem', 'sm-md': '0 1.2rem' }}
          height="full"
          _focus={{ bgColor: 'white-dark' }}
          _active={{ bgColor: 'white-dark' }}
        />
      </>
    );
  }
}
