import React from 'react';
import { connect } from 'react-redux';

import PostToggleComments from 'app/components/PostToggleComments';
import { actionCreators as postsActions } from 'app/models/posts/postsActions';
import {
  selectCommentsCount,
  selectIsThreadExpanded
} from 'app/models/posts/postsSelectors';
import { selectUnreadCommentsByPostId } from 'app/models/unreads/unreadsSelectors';
import { UnreadPost } from 'app/models/unreads/unreadsTypes';

interface StateProps {
  commentCount: number;
  isThreadExpanded: boolean;
  postUnreads: UnreadPost[];
}

interface DispatchProps {
  expandCommentThread: (postId: string) => void;
  collapseCommentThread: (postId: string) => void;
  expandCommentForm: (postId: string) => void;
  collapseCommentForm: (postId: string) => void;
}

interface DefaultProps {
  initialState: 'expand' | 'collapse';
}

interface OwnProps {
  postId: string;
}

export type Props = StateProps & DispatchProps & DefaultProps & OwnProps;

const mapStateToProps = (state, ownProps): StateProps => {
  return {
    commentCount: selectCommentsCount(state, ownProps.postId),
    isThreadExpanded: selectIsThreadExpanded(state, ownProps.postId),
    postUnreads: selectUnreadCommentsByPostId(state, ownProps.postId)
  };
};

const mapDispatchToProps: DispatchProps = {
  expandCommentThread: postsActions.expandCommentThread,
  collapseCommentThread: postsActions.collapseCommentThread,
  expandCommentForm: postsActions.expandCommentForm,
  collapseCommentForm: postsActions.collapseCommentForm
};

export class PostToggleCommentsContainer extends React.Component<Props> {
  static defaultProps: DefaultProps = {
    initialState: 'collapse'
  };

  componentDidMount() {
    const { expandCommentThread, initialState, postId } = this.props;

    if (initialState === 'expand') {
      expandCommentThread(postId);
    }
  }

  toggleComments = () => {
    const {
      postId,
      expandCommentThread,
      collapseCommentThread,
      expandCommentForm,
      collapseCommentForm,
      isThreadExpanded,
      commentCount
    } = this.props;
    if (isThreadExpanded) {
      collapseCommentThread(postId);
      collapseCommentForm(postId);
    } else {
      expandCommentThread(postId);
      if (!commentCount) {
        expandCommentForm(postId);
      }
    }
  };

  render() {
    const { commentCount, isThreadExpanded, postUnreads } = this.props;

    return (
      <PostToggleComments
        onCommentClick={this.toggleComments}
        commentCount={commentCount}
        commentThreadExpanded={isThreadExpanded}
        postUnreads={postUnreads}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PostToggleCommentsContainer);
