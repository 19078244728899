import './i18n';

import App from 'app/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { initializeSegmentTracking } from 'app/helpers/analyticsHelper';
import { monitorActiveKeys } from 'app/helpers/trackKeypress';

initializeSegmentTracking();
monitorActiveKeys();

ReactDOM.render(<App />, document.getElementById('root'));
