import { chakra } from '@chakra-ui/react';
import _isFunction from 'lodash/isFunction';
import React from 'react';

import TextInputWrapper from 'app/components/TextInputWrapper';

export interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress?: (e: React.KeyboardEvent<any>) => void;
  value: string;
  placeholder: string;
  required?: boolean;
  hasError?: boolean;
  errorText: string;
  hintText?: string;
  name: string;
  type: string;
  handleBlur?: () => void;
  autofocus?: boolean;
}

export default class TextInput extends React.PureComponent<Props> {
  textInput: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    const { autofocus } = this.props;
    if (autofocus) {
      window.requestAnimationFrame(() => {
        if (this.textInput.current) {
          this.textInput.current.focus();
        }
      });
    }
  }

  handleBlur = () => {
    const { handleBlur } = this.props;
    if (_isFunction(handleBlur)) {
      handleBlur();
    }
  };

  render() {
    const {
      value,
      handleChange,
      handleKeyPress,
      required,
      placeholder,
      hasError = false,
      errorText = '',
      hintText = '',
      name,
      type
    } = this.props;

    return (
      <TextInputWrapper
        hintText={hintText}
        errorText={errorText || hintText}
        hasError={hasError}
      >
        <chakra.input
          ref={this.textInput}
          type={type}
          name={name}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          value={value}
          required={required}
          autoComplete="off"
          border={0}
          bgColor="transparent"
          p="0.75rem 1rem"
          w="full"
          _placeholder={{ color: 'slate-light' }}
          _focus={{ shadow: 'none', outline: 'none' }}
          _active={{ shadow: 'none', outline: 'none' }}
        />
      </TextInputWrapper>
    );
  }
}
