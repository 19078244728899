export const persistImmutableStoreMiddleware = (keyPath: string[]) => (
  store
) => (next) => (action) => {
  const currentState = store.getState().getIn(keyPath);
  const result = next(action);
  const nextState = store.getState().getIn(keyPath);

  if (currentState !== nextState) {
    saveLocalState(nextState, keyPath);
  }

  return result;
};

export const saveLocalState = (state, keyPath: string[]) => {
  try {
    localStorage.setItem(JSON.stringify(keyPath), JSON.stringify(state));
  } catch {
    console.warn('Application state could not be stored locally.');
  }
};

export const readLocalState = (keyPath: string[]): { [key: string]: any } => {
  try {
    const storedState = localStorage.getItem(JSON.stringify(keyPath));
    if (storedState) {
      return JSON.parse(storedState);
    }
  } catch {
    console.warn('Unable to restore state from local storage.');
  }
  return {};
};
