import { Text } from '@chakra-ui/react';
import React from 'react';

import i18n from 'i18n';

export interface CurrentPaginationProps {
  searchResultsTotalCount?: number;
  totalAnsweredPercent?: number;
  currentPaginationStart?: number;
  currentPaginationEnd?: number;
}

export class CurrentPagination extends React.PureComponent<
  CurrentPaginationProps
> {
  render() {
    const {
      currentPaginationStart = 0,
      currentPaginationEnd = 0,
      searchResultsTotalCount = 0,
      totalAnsweredPercent = 0
    } = this.props;

    return (
      <>
        {searchResultsTotalCount > 0 && (
          <Text
            size="sm"
            color="dark-night-blue"
            lineHeight="base"
            data-ref="current-pagination"
          >
            {i18n.t('pagination.questionsShownOfTotal', {
              paginationStart: currentPaginationStart,
              paginationEnd: currentPaginationEnd,
              count: searchResultsTotalCount
            })}{' '}
            {i18n.t('pagination.percentageAnswered', {
              percentage: totalAnsweredPercent
            })}
          </Text>
        )}
      </>
    );
  }
}
