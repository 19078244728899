import { Box, UnorderedList } from '@chakra-ui/react';
import React from 'react';

import CommentListItem, {
  CommentListItemWrapper
} from 'app/components/CommentListItem';
import CommentFormContainer from 'app/containers/CommentFormContainer';
import PermissionsServiceContainer from 'app/containers/PermissionsServiceContainer';
import { IComment } from 'app/models/entities/comments/commentsEntitiesTypes';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';

export interface Props {
  comments: IComment[];
  isThreadExpanded: boolean;
  parentId: string;
  deepLinkId?: string;
  unreads: UnreadPostByParentId;
}

export default class CommentList extends React.PureComponent<Props> {
  static defaultProps = {
    comments: [],
    deepLinkId: undefined
  };

  render() {
    const {
      comments,
      isThreadExpanded,
      parentId,
      deepLinkId,
      unreads
    } = this.props;

    return (
      <Box mt={5} display={isThreadExpanded ? 'block' : 'none'}>
        <UnorderedList ms={0}>
          {comments.map((comment) => {
            return (
              <CommentListItem
                key={comment.id}
                comment={comment}
                unreads={unreads[comment!.parentId || -1]}
                deepLinkId={deepLinkId}
              />
            );
          })}
          <CommentListItemWrapper>
            <PermissionsServiceContainer showIf={{ canComment: true }}>
              <Box
                bgColor="silver-lightest"
                p={{ base: '1rem 0.75rem', lg: '1.75rem 2rem' }}
              >
                <CommentFormContainer parentId={parentId} />
              </Box>
            </PermissionsServiceContainer>
          </CommentListItemWrapper>
        </UnorderedList>
      </Box>
    );
  }
}
