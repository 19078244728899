import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  IconButton
} from '@chakra-ui/react';
import { CloseIcon } from '@udacity/chakra-veritas-icons';
import React from 'react';

import ContentWrapper from 'app/components/ContentWrapper';
import SearchFilterTitle from 'app/components/SearchFilter/SearchFilterTitle';
import NanodegreeTagsContainer from 'app/containers/NanodegreeTagsContainer';
import { Nanodegree, Project } from 'app/models/nanodegree/nanodegreeTypes';
import i18n from 'i18n';

export interface Props {
  handleFilterChange: (selection: {
    nanodegree?: Nanodegree;
    project?: Project;
  }) => void;
  handleFilterByUnanswered: (filterArg: boolean) => void;
  selectedNanodegree?: Nanodegree;
  selectedProject?: Project;
  initialNanodegreeKey?: string;
  initialProjectId?: number;
  filterByUnanswered: boolean;
  isOpen: boolean;
  handleClose: () => void;
}

export default class SearchFilterOverlay extends React.PureComponent<Props> {
  handleUnansweredChange = ({ target: { checked } }) => {
    const { handleFilterByUnanswered } = this.props;
    handleFilterByUnanswered(checked);
  };

  render() {
    const {
      handleFilterChange,
      selectedNanodegree,
      selectedProject,
      initialNanodegreeKey,
      initialProjectId,
      filterByUnanswered,
      isOpen,
      handleClose
    } = this.props;

    return (
      <Drawer
        isOpen={isOpen}
        onClose={handleClose}
        placement="bottom"
        size="full"
      >
        <DrawerContent>
          <DrawerHeader
            bgColor="white"
            py={4}
            px={0}
            borderBottom="1px solid"
            borderBottomColor="silver-lighter"
          >
            <ContentWrapper>
              <Flex justify="space-between" align="center">
                <SearchFilterTitle />
                <IconButton
                  aria-label="close"
                  icon={<CloseIcon w={8} h={8} />}
                  variant="minimal"
                  height="2.5rem"
                  isRound
                  onClick={handleClose}
                />
              </Flex>
            </ContentWrapper>
          </DrawerHeader>
          <DrawerBody
            bgColor="silver-lightest"
            p={{ base: '2rem 0 1rem', 'sm-md': '2rem 0' }}
          >
            <ContentWrapper>
              <Checkbox
                id="overlay-filter-by-unanswered"
                isChecked={filterByUnanswered}
                onChange={this.handleUnansweredChange}
                spacing={4}
                mb={{ base: 6, 'sm-md': 10 }}
              >
                {i18n.t('search.unanswered')}
              </Checkbox>
              <NanodegreeTagsContainer
                handleChange={handleFilterChange}
                selectedNanodegree={selectedNanodegree}
                selectedProject={selectedProject}
                initialNanodegreeKey={initialNanodegreeKey}
                initialProjectId={initialProjectId}
                options={{
                  includeGraduatedNanodegrees: true,
                  includePseudoNanodegrees: true
                }}
              />
            </ContentWrapper>
          </DrawerBody>
          <DrawerFooter bgColor="silver-lightest">
            <Button
              data-ref="submit-button"
              type="submit"
              variant="primary"
              bgColor="cerulean"
              borderRadius="secondary"
              w="full"
              onClick={handleClose}
            >
              {i18n.t('search.viewResults')}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }
}
