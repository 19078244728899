import { List, Map, fromJS } from 'immutable';

import { actionTypes as activityActions } from 'app/models/activity/activityActions';
import { mergeEntities } from 'app/models/entities/entitiesReducerHelpers';
import { actionTypes as postActions } from 'app/models/posts/postsActions';

type State = Map<string, string | boolean | null>;

export const initialState: State = fromJS({
  byId: {}
});

const deleteAnswerOrComment = (state, postId, parentId) => {
  // delete answer or comment refs if they match
  if (state.hasIn(['byId', parentId, 'comments'])) {
    return state.updateIn(['byId', parentId, 'comments'], (list) =>
      list.filter((id) => id !== postId)
    );
  }

  return state.deleteIn(['byId', postId]);
};

export default function questionsReducer(
  state: State = initialState,
  action: any
) {
  const { type, payload } = action;
  switch (type) {
    case postActions.CREATE_ANSWER_FULFILLED:
    case postActions.UPDATE_ANSWER_FULFILLED:
    case postActions.FETCH_ANSWERS_BY_QUESTION_ID_FULFILLED:
    case postActions.FETCH_QUESTION_FULFILLED: {
      const { answers } = payload.entities;
      return mergeEntities(state, answers);
    }

    case postActions.CREATE_COMMENT_FULFILLED: {
      const { parentId, commentId } = payload;
      if (state.getIn(['byId', parentId])) {
        return state.updateIn(
          ['byId', parentId, 'comments'],
          (commentIds: List<Map<string, string>>) => {
            return commentIds.push(commentId);
          }
        );
      }
      return state;
    }

    case postActions.DELETE_POST_FULFILLED: {
      const { postId, parentId } = payload;
      return deleteAnswerOrComment(state, postId, parentId);
    }

    case postActions.ADMIN_DELETE_POST_FULFILLED: {
      const { postId, parentId } = payload;
      return deleteAnswerOrComment(state, postId, parentId);
    }

    case postActions.ACCEPT_ANSWER_FULFILLED:
    case postActions.VERIFY_ANSWER_FULFILLED:
    case postActions.UPVOTE_POST_FULFILLED:
    case postActions.DOWNVOTE_POST_FULFILLED:
    case postActions.CLEARVOTE_POST_FULFILLED: {
      const { id, ...rest } = payload;
      if (state.getIn(['byId', id])) {
        return state.mergeIn(['byId', id], rest);
      }
      return state;
    }

    case postActions.UNACCEPT_ANSWER_FULFILLED: {
      const { id } = payload;
      if (state.getIn(['byId', id])) {
        return state.mergeIn(['byId', id], {
          accepted: false,
          acceptedAt: null
        });
      }
      return state;
    }

    case postActions.UNVERIFY_ANSWER_FULFILLED: {
      const { id } = payload;
      if (state.getIn(['byId', id])) {
        return state.mergeIn(['byId', id], {
          verified: false,
          verifiedAt: null
        });
      }
      return state;
    }
    case activityActions.FETCH_ANSWERS_FULFILLED: {
      const { answers } = payload.entities;
      return mergeEntities(state, answers);
    }
  }
  return state;
}
