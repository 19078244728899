import { ListProps } from '@chakra-ui/react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import TagList from 'app/components/TagList';
import { selectProjectTitle } from 'app/models/nanodegree/nanodegreeSelectors';

export interface LocationProps extends RouteComponentProps<any> {}

export interface TagListContainerStateProps {
  projectTitle?: string;
}

export interface OwnProps {
  nodeTitle?: string;
  projectId?: number;
  styleProps?: ListProps;
}

export type TagListContainerProps = OwnProps &
  TagListContainerStateProps &
  LocationProps;

const mapStateToProps = (state, props: OwnProps) => ({
  projectTitle: props.projectId
    ? selectProjectTitle(state, props.projectId)
    : undefined
});

export class TagListContainer extends React.PureComponent<
  TagListContainerProps
> {
  render(): JSX.Element {
    const { nodeTitle, projectTitle, styleProps } = this.props;

    return (
      <TagList
        node={nodeTitle}
        project={projectTitle}
        styleProps={styleProps}
      />
    );
  }
}

export default withRouter(
  connect<TagListContainerStateProps, OwnProps>(mapStateToProps)(
    TagListContainer
  )
);
