import CONFIG from '../../appConfigLoader';
import auth from '@udacity/ureact-hoth';
import { datadogRum } from '@datadog/browser-rum';

export const initDatadog = () => {
  if (!CONFIG.datadogEnabled) return;

  datadogRum.init({
    applicationId: 'e2c56b49-c8dd-417c-b0f7-cd4458f700f7',
    clientToken: 'pub6d4afde3839a07b93841ada70173b8c0',
    site: 'datadoghq.com',
    service: 'knowledge-web',
    version: '2.0.0',
    sampleRate: 20,
    premiumSampleRate: 20,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    env: process.env.REACT_APP_ENV
  });

  datadogRum.setUser({ id: auth.getCurrentUserId() });

  datadogRum.startSessionReplayRecording();
};
