import _trim from 'lodash/trim';
import parseURI from 'parseuri';

export function defaultLinkProtocol(url: string) {
  const trimmedUrl = _trim(url, ' ./');

  const { protocol, authority, relative } = parseURI(trimmedUrl);

  return [protocol || 'https', '://', authority, relative].join('');
}

export function safeUserInput(userContent: string = '') {
  const jsProtocol = new RegExp(/^\s*javascript:\s*/i);

  const cleanText = userContent.replace(jsProtocol, '');

  if (cleanText.search(jsProtocol) > -1) {
    return safeUserInput(cleanText);
  }

  return cleanText;
}
