import { chakra, Flex, keyframes } from '@chakra-ui/react';

export const Slider = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    lineHeight: '1rem',
    position: 'relative'
  }
});

export const GradientShadow = chakra('div', {
  baseStyle: {
    display: { base: 'none', 'sm-md': 'block' },
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 'docked',
    w: 'full',
    h: 'full'
  }
});

const topPosition = 0;
const bottomPosition = '-1.5rem';
export const animationDuration = '500ms';

const slideUp = keyframes(`
  0% {
    transform: translateY(${bottomPosition});
  }
  50% {
    transform: translateY(${bottomPosition});
  }
  100% {
    transform: translateY(${topPosition});
  }
`);

const slideDown = keyframes(`
  0% {
    transform: translateY(${topPosition});
  }
  50% {
    transform: translateY(${topPosition});
  }
  100% {
    transform: translateY(${bottomPosition});
  }
`);

export const slideToTopStyles = {
  transform: `translateY(${topPosition})`,
  animation: `${slideUp} ${animationDuration} ease-out`
};

export const slideToBottomStyles = {
  transform: `translateY(${bottomPosition})`,
  animation: `${slideDown} ${animationDuration} ease-out`
};
