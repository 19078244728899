import AuthService from '@udacity/ureact-hoth';
import _ from 'lodash';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import CONFIG from 'appConfigLoader';

const JWT_COOKIE_NAME = '_jwt';

export function handleAuthorization() {
  return new Promise<void>((resolve, reject) => {
    if (!AuthService.isAuthenticated()) {
      AuthService.authenticate({
        env: CONFIG.authType,
        returnUrl: window.location.href
      });
      return reject();
    }

    return resolve();
  });
}

export function getCurrentUser() {
  if (AuthService.isAuthenticated()) {
    return jwtDecode(getJWTToken());
  } else {
    return {};
  }
}

export function getUserId(): string | undefined {
  if (AuthService.isAuthenticated()) {
    const currentUser = getCurrentUser();
    return currentUser.key || currentUser.uid;
  } else {
    return undefined;
  }
}

function getJWTToken() {
  return cookies.get(JWT_COOKIE_NAME);
}

export function getCurrentUserId() {
  const currentUser = getCurrentUser();
  return currentUser.key || currentUser.uid;
}

const logout = () => {
  AuthService.signOut();

  // make sure the signOut call stack finishes before redirecting
  _.defer(() => {
    window.location.href = 'https://www.udacity.com/account/logout';
  });
};

export default { logout };
