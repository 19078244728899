import CONFIG from 'appConfigLoader';

const {
  validationRules: { questionValidationRequired, questionValidationWarning }
} = CONFIG;

const validation = {
  isTitleQuestionRequired: () => questionValidationRequired.title,

  isBodyQuestionRequired: () => questionValidationRequired.body,

  isNanodegreeQuestionRequired: () => questionValidationRequired.nanodegree,

  isProjectQuestionWarning: () => questionValidationWarning.project,

  isProjectQuestionRequired: () => questionValidationRequired.project
};

export default validation;
