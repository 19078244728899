import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { PureComponent } from 'react';

import FixedWidthWrapper from 'app/components/FixedWidthWrapper';

import i18n from 'i18n';

export default class IncompletePostBanner extends PureComponent {
  render() {
    return (
      <Box p={6} bgColor="orange-lightest">
        <FixedWidthWrapper>
          <Flex gap={4}>
            <Icon color="orange" w={10} h={10}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path d="M23 13H9v10h14V13zM9 11h16V9H7v2h2zm14 0a2 2 0 0 1 2 2zM7 13a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2v10a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V13zm6 3.5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2h-6z" />
              </svg>
            </Icon>
            <>
              <Heading size="h5" as="h5" mb={1} color="slate">
                {i18n.t('post.incomplete')}
              </Heading>
              <Text size="sm" color="slate">
                {i18n.t('post.archivedReason')}
              </Text>
            </>
          </Flex>
        </FixedWidthWrapper>
      </Box>
    );
  }
}
