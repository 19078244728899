import { Flex, Spinner } from '@chakra-ui/react';

import React from 'react';

export default class CenteredLoader extends React.PureComponent<{}> {
  render() {
    return (
      <Flex justify="center" py="7.5rem">
        <Spinner />
      </Flex>
    );
  }
}
