import { Box, chakra, Flex, Link } from '@chakra-ui/react';
import React from 'react';
import { Trans } from 'react-i18next';

import ContentWrapper from 'app/components/ContentWrapper';
import routeConstants from 'app/helpers/routeConstants';

import i18n from 'i18n';

const MessageWrapper = chakra(Box, {
  baseStyle: {
    bgColor: 'slate',
    color: 'white',
    p: '0.76rem 0',
    fontSize: 'sm'
  }
});

interface State {
  isExpanded: boolean;
}

export default class ReadOnlyModeBanner extends React.Component<{}, State> {
  state = {
    isExpanded: false
  };

  handleExpand = (): void => {
    this.setState({ isExpanded: true });
  };

  handleUnexpand = (): void => {
    this.setState({ isExpanded: false });
  };

  render() {
    const { isExpanded } = this.state;

    return (
      <>
        <MessageWrapper
          display={{ base: 'none', 'sm-md': 'block' }}
          textAlign={{ 'sm-md': 'center' }}
        >
          {i18n.t('readOnly.inMode')}{' '}
          <Trans i18nKey="readOnly.toParticipateEnroll">
            To participate,
            <Link
              href={routeConstants.SIGN_UP}
              _hover={{ color: 'cerulean-lighter' }}
            >
              enroll
            </Link>
            in any Udacity Nanodegree
          </Trans>
        </MessageWrapper>

        <MessageWrapper display={{ base: 'block', 'sm-md': 'none' }}>
          <ContentWrapper>
            <Flex justify="space-between">
              {i18n.t('readOnly.inMode')}

              <chakra.button
                onClick={isExpanded ? this.handleUnexpand : this.handleExpand}
                bgColor="transparent"
                color="cerulean"
                m="0 1px"
                textDecoration="underline"
                fontSize="sm"
                letterSpacing="-0.1px"
              >
                {isExpanded
                  ? i18n.t('readOnly.seeLess')
                  : i18n.t('readOnly.learnMore')}
              </chakra.button>
            </Flex>

            {isExpanded && (
              <span>
                <chakra.hr
                  m="1rem 0"
                  borderTop="1px solid"
                  borderTopColor="titanium"
                />

                <Trans i18nKey="readOnly.toParticipateSignInOrEnroll">
                  To participate,
                  <Link
                    href={routeConstants.SIGN_IN}
                    _hover={{ color: 'cerulean-lighter' }}
                  >
                    sign in
                  </Link>
                  or
                  <Link
                    href={routeConstants.SIGN_UP}
                    _hover={{ color: 'cerulean-lighter' }}
                  >
                    enroll
                  </Link>
                  in any Udacity Nanodegree or free course!
                </Trans>
              </span>
            )}
          </ContentWrapper>
        </MessageWrapper>
      </>
    );
  }
}
