import React from 'react';

import ActivityList from 'app/components/ActivityList';
import CenteredLoader from 'app/components/CenteredLoader';
import PaginationFooter from 'app/components/PaginationFooter';
import TabList from 'app/components/TabList';
import {
  ACTIVITY_OPTION,
  IActivityAnswer,
  IActivityOption,
  IActivityQuestion
} from 'app/models/activity/activityTypes';
import constants from 'app/models/activity/activityConstants';
import { UnreadPostByParentId } from 'app/models/unreads/unreadsTypes';

import i18n from 'i18n';

export interface Tab {
  text: string;
  id: IActivityOption;
}

export interface Props {
  posts: {
    questions?: IActivityQuestion[];
    answers?: IActivityAnswer[];
  };
  pages: {
    questions: number;
    answers: number;
  };
  getActivity: (type: IActivityOption, offset: number, limit: number) => void;
  selectedGroup: IActivityOption;
  isLoading: boolean;
  unreads: UnreadPostByParentId;
}

const PAGE_SIZE = constants.PAGE_SIZE;

export default class ActivityDash extends React.PureComponent<Props> {
  handleHeaderClick = (selectedGroup: IActivityOption) => {
    const { pages, getActivity } = this.props;
    const currentOffset = (pages[selectedGroup] - 1) * PAGE_SIZE;
    getActivity(selectedGroup, currentOffset, PAGE_SIZE);
  };

  handleNextPage = () => {
    const { pages, getActivity, selectedGroup } = this.props;
    const currentOffset = (pages[selectedGroup] - 1) * PAGE_SIZE;
    getActivity(selectedGroup, currentOffset + PAGE_SIZE, PAGE_SIZE);
  };

  handlePreviousPage = () => {
    const { pages, getActivity, selectedGroup } = this.props;
    const currentOffset = (pages[selectedGroup] - 1) * PAGE_SIZE;
    getActivity(
      selectedGroup,
      Math.max(currentOffset - PAGE_SIZE, 0),
      PAGE_SIZE
    );
  };

  render() {
    const {
      posts,
      pages,
      isLoading,
      selectedGroup = ACTIVITY_OPTION.questions,
      unreads
    } = this.props;
    const activityHeaders: Tab[] = [
      { text: i18n.t('question.myQuestions'), id: ACTIVITY_OPTION.questions },
      { text: i18n.t('answer.myAnswers'), id: ACTIVITY_OPTION.answers }
    ];
    const hasPosts = posts[selectedGroup] && posts[selectedGroup].length > 0;

    return (
      <>
        <TabList
          handleChange={this.handleHeaderClick}
          selectedGroup={selectedGroup}
          tabs={activityHeaders}
        />
        {isLoading ? (
          <CenteredLoader />
        ) : (
          <>
            <ActivityList
              posts={posts[selectedGroup]}
              data-ref="post-list"
              selectedGroup={selectedGroup}
              unreads={unreads}
            />
            {hasPosts && (
              <PaginationFooter
                previousPage={this.handlePreviousPage}
                shouldShowPrevious={pages[selectedGroup] > 1 && !isLoading}
                nextPage={this.handleNextPage}
                shouldShowNext={!isLoading}
                page={pages[selectedGroup]}
                isLoading={false}
              />
            )}
          </>
        )}
      </>
    );
  }
}
