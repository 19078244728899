import React from 'react';
import { Button, Flex, Heading } from '@chakra-ui/react';
import { CheckedIcon, CancelIcon } from '@udacity/chakra-veritas-icons';

import i18n from 'i18n';

export interface Props {
  onClick: (answered: boolean) => void;
}

const RatingAnswer: React.FC<Props> = ({ onClick }) => (
  <>
    <Heading size="h4" as="h5" lineHeight="base" mb={6}>
      {i18n.t('question.didMentorAnswerQuestion')}
    </Heading>

    <Flex justify="space-around">
      <Button
        variant="default"
        p="0 2rem 0 1.5rem"
        leftIcon={<CheckedIcon w={6} h={6} />}
        onClick={() => onClick(true)}
      >
        {i18n.t('common.yes')}
      </Button>
      <Button
        variant="default"
        p="0 2rem 0 1.5rem"
        bgColor="red"
        leftIcon={<CancelIcon w={6} h={6} />}
        onClick={() => onClick(false)}
      >
        {i18n.t('common.no')}
      </Button>
    </Flex>
  </>
);

export default RatingAnswer;
