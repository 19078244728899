import { combineReducers } from 'redux-immutable'; // immutablejs equivalent of Redux's combineReducers

import {
  makeApiReducer,
  normalizeApiActions
} from 'app/models/helpers/apiReducerHelpers';

import { actionClass } from './searchActions';

const searchQuestions = normalizeApiActions('SEARCH_QUESTIONS', actionClass);
const searchResultClick = normalizeApiActions(
  'SEARCH_RESULT_CLICK',
  actionClass
);

export default combineReducers({
  searchQuestions: makeApiReducer(searchQuestions),
  searchResultClick: makeApiReducer(searchResultClick)
});
