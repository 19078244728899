import { chakra, Grid } from '@chakra-ui/react';

export const CodeBlock = chakra('code', {
  baseStyle: {
    display: 'block',
    color: 'slate-dark',
    fontFamily: 'mono',
    borderRadius: 'secondary',
    bgColor: 'charcoal-light',
    border: '1px solid',
    borderColor: 'slate-lighter',
    m: '0.5rem 0',
    p: 3,
    w: 'full'
  }
});

export const EditorOverlay = chakra('div', {
  baseStyle: {
    width: 'calc(100% - 3rem)',
    height: 'full',
    position: 'absolute',
    top: 0,
    right: 0
  }
});

export const CodeLine = chakra(Grid, {
  baseStyle: {
    position: 'relative',
    width: 'full',
    gridColumnGap: 4,
    gridTemplateColumns: '2rem 1fr'
  }
});

export const LineNumber = chakra('div', {
  baseStyle: {
    color: 'slate-lighter',
    borderEnd: '1px solid',
    borderEndColor: 'slate-lighter',
    userSelect: 'none',
    whiteSpace: 'pre-wrap'
  }
});
